<template>
  <b-container>
    <FormResetPassword/>
  </b-container>
</template>

<script>
import FormResetPassword from '@/components/formResetPassword';

/**
 * @vuese
 * Page de permettant de définir un nouveau mot de passe
 * @group PAGES
 */
export default {
  name: 'ResetPasswordPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Mot de passe oublié',
      meta: [
        {
          name: 'description',
          content:
              'Page vous permettant de réinitialiser votre mot de passe.'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Mot de passe oublié'
        }
      ]
    };
  },
  components: {
    FormResetPassword
  }
};
</script>
