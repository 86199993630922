<template>
  <b-container class="app-menu" role="navigation">
    <b-navbar id="navbar-connected" toggleable="lg" type="light">
      <b-navbar-toggle id="navbar-toggler-connected" target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav class="nav-center">
        <b-navbar-nav class="align-items-center">
          <b-nav-item
              :to="{
              name: 'home',
            }"
              alt="Logo action bas carbone"
              aria-label="Accueil"
          >
            <b-img src="/img/logo_abc.svg" class="logo-menu" alt=""/>
          </b-nav-item>
          <b-nav-item
              class="links--connected menu-item menu-item--connected"
              :to="{ name: 'home' }"
          >
            accueil
          </b-nav-item>
          <b-nav-item
              :to="{ name: 'whoAreWe' }"
              class="links--connected menu-item"
          >qui sommes-nous ?
          </b-nav-item
          >
          <b-nav-item
              class="links--connected menu-item menu-item--connected"
              :to="{ name: 'actuPage' }"
          >
            à la une
          </b-nav-item>
          <b-nav-item
              class="links--connected menu-item menu-item--connected"
              :to="{ name: 'reportNew' }"
          >mon parcours
          </b-nav-item>
          <b-nav-item
              class="links--connected menu-item menu-item--connected"
              :to="{ name: 'findOutMore' }"
          >
            en savoir plus
          </b-nav-item>
          <b-nav-item
              class="links--connected menu-item menu-item--connected"
              :to="{ name: 'contact' }"
          >
            contact
          </b-nav-item>
          <b-nav-item
              class="links--connected menu-item--user"
              :to="{
              name: 'myAccountPage',
            }"
          >
            <b-icon icon="person-circle" aria-hidden="true"/>&nbsp;{{
              user.firstName.substring(0, 1)
            }}.&nbsp;{{ user.lastName }}
          </b-nav-item
          >
          <b-nav-item
              class="links menu-item"
              :to="{
              name: 'logout',
            }"
              title="Déconnexion"
          >
            <b-icon icon="box-arrow-right" aria-hidden="true"/>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

/**
* @vuese
* Menu de navigation pour les utilisateurs connectés
*/
export default {
  name: 'AppMenu',
  data() {
    return {
      menuIsActive: false
    };
  },
  methods: {
    onClickConnected() {
      const navbarToggler = document.getElementById('navbar-toggler-connected');
      let isOpen = false;
      if (navbarToggler && navbarToggler.hasAttribute('aria-expanded')) {
        isOpen = true;
      }
      let navbar = document.getElementById('nav-collapse');
      let isClassShowPresent = navbar.classList.contains('show');

      if (isOpen == 'true' && isClassShowPresent) {
        this.$root.$emit('bv::toggle::collapse', 'nav-collapse')
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.onClickConnected);
  },
  computed: {
    ...mapGetters('UserStore', ['user']),
    ...mapGetters(['report'])
  }
};
</script>

<style lang="scss" scoped>
.links--connected {
  color: var(--text-color);
  letter-spacing: 0.1rem;
  display: block;
  text-transform: uppercase;

  a {
    padding-left: 14px !important;
    padding-right: 14px !important;
    padding-top: 1rem;
    padding-bottom: 1rem;

    &:hover {
      background: rgba(var(--text-color), 0.15);
    }

    &.router-link-active {
      background: var(--text-color);
      color: var(--nav-focus-color) !important;
    }
  }
}

@media screen and (max-width: 1290px) {
  .menu-item,
  .menu-item--user {
    font-size: 14px;
  }
  .links--connected a {
    padding-top: 1.1rem;
    padding-bottom: 1.1rem;
  }
}

@media screen and (max-width: 1160px) {
  .menu-item,
  .menu-item--user {
    font-size: 12px;
  }
  .links--connected a {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }
}

@media screen and (max-width: 1095px) {
  .menu-item,
  .menu-item--user {
    font-size: 10px;
  }

  .links--connected a {
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
  }
}
</style>
