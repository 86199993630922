<template>
  <b-row>
    <b-col class="mt-2 mb-2" sm="3">
      <app-abc-step
          v-b-toggle.sidebarFilters
          stepLetter="A"
          stepExp="Je sélectionne mes filtres"
          class="abc-step-a"
      />
    </b-col>
    <b-col class="mt-2 mb-2" sm="4">
      <b-link href="#Steps">
        <app-abc-step
            stepLetter="b"
            stepExp="Je sélectionne mes étapes climats"
            class="abc-step-b"
        />
      </b-link>
    </b-col>
    <b-col class="mt-2 mb-2" sm="5">
      <b-link href="#Graph">
        <app-abc-step
            stepLetter="c"
            stepExp="J'évalue la baisse de mes émissions de CO2"
            class="abc-step-c"
        />
      </b-link>
    </b-col>
    <b-sidebar
        id="sidebarFilters"
        backdrop
        no-header-close
        header-class="d-flex justify-content-center align-items-center p-0"
        body-class="pb-3"
    >
      <template slot="header">
        <aside
            class="abc-step flex flex-column flex-center abc-step-a l-1 w-100"
        >
          <h2 class="abc-step__title l-1 pt-2">a</h2>
          <p class="ta-center abc-step__exp mb-2 l-1">
            Je sélectionne mes filtres
          </p>
          <div
              class="h3 mb-0 bg-dark-trans w-100 text-center mt-auto pb-2 pt-2"
          >
            {{ cardsFiltred.length - cardsCompletude.length }} étapes
          </div>
        </aside>
      </template>
      <app-cards-filters
          id="report-admin-edit"
          ref="cardsFilters"
          :filter-name="filtersPreview.name"
          filter-draft="2"
          @filtred="onFiltred"
          @loader="onLoader"
      />
      <b-btn
          @click.prevent="filterActive"
          title="Appliquer les filtres"
          class="mt-3 mb-3 w-100 mobile-button-sticky position-sticky"
      >
        Mettre à jour les résultats
      </b-btn>
      <div class="d-flex justify-content-center align-items-center">
        <b-btn
            squared
            variant="danger"
            size="sm"
            @click="clearFilters"
            title="Nettoyer les filtres"
            aria-label="Nettoyer les filtres"
            class="p-2 mt-3 capitalize"
        >
          Nettoyer
          <b-icon-trash aria-hidden="true"/>
        </b-btn>
      </div>
    </b-sidebar>

    <b-input-group size="lg" class="mb-3">
      <b-input-group-prepend is-text>
        <b-icon-search aria-hidden="true"/>
      </b-input-group-prepend>
      <b-form-input
          required
          size="lg"
          v-model="filtersName"
          placeholder="Recherche libre"
          aria-label="Recherche libre"
      />
    </b-input-group>
    <div class="page-title-container">
      <div class="d-flex justify-content-between align-items-center">
        <h1 class="page-title" id="Steps">
          Étapes disponibles:&nbsp;<span
        ><strong class="pl-1">{{ cardsFiltred.length - cardsCompletude.length }}</strong></span
        >
        </h1>
      </div>
      <div class="ml-auto">
        <b-btn
            class="ml-auto mr-2"
            variant="light"
            title="Cocher toutes les étapes de la liste ci-dessous"
            @click.prevent="checkAllFiltred"
        >
          <b-icon-check2-square aria-hidden="true"/>
          Tout cocher
        </b-btn>
        <b-btn
            class="ml-auto"
            variant="light"
            title="Gérer les étapes climats"
            v-if="isAdmin"
            :to="{
            name: 'adminCardsIndex',
          }"
        >
          <b-icon-gear-fill aria-hidden="true"/>
          Gérer
        </b-btn>
      </div>
      <div class="d-flex">
        <card-legend/>
      </div>
    </div>
    <b-overlay :show="loader" variant="white" rounded="sm" no-fade>
      <div v-for="(cat, kcat) in cardsFiltredCategories" :key="kcat">
        <h3 class="sticky-top bd-bottom uppercase page-title pb-1 pt-1">
          {{ cat }} :
          <strong>{{ cardsByCategories[cat] | length }}</strong>
        </h3>
        <b-row>
          <b-col
              sm="12"
              md="6"
              lg="3"
              v-for="(card, kcard) in cardsByCategories[cat]"
              :key="kcard"
          >
            <app-admin-card
                :card="card"
                :edit="true"
                class="mb-4 d-flex"
                @saved="onCardSaved"
            >
              <template slot="body-left">
                <btn-add-report-card :card="card"/>
              </template>
            </app-admin-card>
          </b-col>
        </b-row>
      </div>
    </b-overlay>
    <b-col
        id="Graph"
        class="pt-1 bg-white graph-medium"
        v-if="reportPreview.cards.length > 0"
    >
      <app-report-preview-mobile/>
      <div
          class="
          btns-mobile
          bg-white
          p-1
          d-flex
          justify-content-center
          align-items-center
          mt-4
        "
      >
        <b-btn
            @click.prevent="visualizeReport"
            variant="basic"
            title="Visualiser le rapport PDF"
            aria-label="Visualiser le rapport PDF"
            class="mr-1"
        >
          <b-icon-eye aria-hidden="true"/>
          Visualiser
        </b-btn>
        <b-btn block @click.prevent="onGenerateFromData" variant="basic" title="Télécharger le rapport PDF" aria-label="Télécharger le rapport PDF">
          <b-icon-download aria-hidden="true"/>&nbsp;Télécharger
        </b-btn>
        <b-btn
            variant="basic"
            class="ml-1"
            @click.prevent="confirmNewReport"
            title="Créer un nouveau rapport"
            aria-label="Créer un nouveau rapport"
        >
          Nouveau rapport
        </b-btn>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import appReportStepsMixin from '@/components/dyn/appReportStepsMixin';
import appCardsFiltersMixin from '@/components/dyn/appCardsFiltersMixin';
import appReportPreviewMixin from './appReportPreviewMixin';

import btnAddReportCard from '@/components//btnAddReportCard.vue';
import AppReportPreviewMobile from '@/components//appReportPreviewMobile.vue';
import CardLegend from '@/components//cardLegend.vue';

/**
* @vuese
* Le contenu de la page "Mon Parcours", version mobile
*/
export default {
  components: {btnAddReportCard, AppReportPreviewMobile, CardLegend},
  name: 'appReportStepsMobile',
  mixins: [appReportPreviewMixin, appReportStepsMixin, appCardsFiltersMixin],
  methods: {
    filterActive(){
      this.searchCardsFiltred();
    }
  },
};
</script>

<style scoped lang="scss">
@media screen and (max-width: 500px) {
  .btns-mobile {
    flex-direction: column;
    gap: 1rem;
    button {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media screen and (min-width: 675px) {
  .graph-medium {
    z-index: 1030;
  }
}
</style>
