<template>
  <div class="legends">
    <div class="legend legend-direct">Étape directe</div>
    <div class="legend legend-indirect">Étape {{ term }}</div>
    <div v-if="isAdmin && show" class="legend legend-completude">
      Étape pour complétude
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

/**
 * @vuese
 * Légende des fiches climats
 */
export default {
  name: 'CardLegend',
  computed: {
    ...mapGetters('UserStore', ['isAdmin'])
  },
  props: {
    // Un des types de fiche
    term: {
      default: 'transversale',
      type: String
    },
    // Affichage ou non de la légende pour les étapes de complétude
    show: {
      default: true,
      type: Boolean
    }
  }
};
</script>