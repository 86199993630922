<template>
  <div>
    <b-row class="account-list">
      <b-col lg="5">
        <h1 class="page-title">Mon Compte</h1>
        <b-card>
          <dl>
            <dt>Nom prénom :</dt>
            <dd>{{ user.lastName }} {{ user.firstName }}</dd>
            <dt>Adresse mail :</dt>
            <dd>{{ user.email }}</dd>
            <dt>Numéro de téléphone :</dt>
            <dd>{{ user.phone }}</dd>
            <dt>Structure :</dt>
            <dd>
              <span v-if="user.company">{{ user.company.name }}</span>
            </dd>
            <dt>Fonction :</dt>
            <dd>{{ user.companyRole }}</dd>
            <dt>Adhérent de :</dt>
            <dd>
              <span v-if="user.companyFrom">{{ user.companyFrom.name }}</span>
            </dd>
          </dl>
        </b-card>
        <nav class="d-flex flex-column">
          <b-button
              :to="{
              name: 'myAccountEditPage',
            }"
              title="Édition compte"
              aria-label="Modifier mes informations utilisateurs"
              variant="basic"
              class="btn-basic--account mt-4"
          >
            <b-icon-lock aria-hidden="true"/>
            Modifier
          </b-button>
          <b-button
              :to="{
              name: 'logout',
            }"
              title="Déconnexion"
              class="btn-basic--account mt-4"
              variant="basic"
          >
            <b-icon icon="box-arrow-right" aria-hidden="true"/>
            Se déconnecter
          </b-button>
        </nav>
      </b-col>
      <b-col lg="7" class="d-none d-lg-block">
        <b-img
            src="/img/illu_02_page_mon_compte.svg"
            alt=""
            class="illuAccount"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

/**
* @vuese
* Les informations du compte utilisateur
*/
export default {
  name: 'AppShowMyAccount',
  computed: {
    ...mapGetters('UserStore', ['user'])
  }
};
</script>

<style lang="scss">
  .account-list {
    padding-top: 30px;
  }

  .card-account {
    background-color: var(--background-color);
  }

  .account-list ul {
    list-style: none;
    padding-left: 0;
    margin-top: 25px;
  }

  .account-list li {
    text-transform: uppercase;
    margin-bottom: 25px;
  }

  .cadenas {
    width: 30px;
    margin-right: 20px;
  }

  .btn-logout {
    width: 100%;
    margin: 25px 10px 10px 0 !important;
  }

  .illuAccount {
    display: block;
    width: 100%;
    height: auto;
  }

  dl {
    font-weight: bolder;
    margin-left: 1rem;
    margin-bottom: 2rem;
  }

  .btn-basic--account {
    width: 100%;
  }
</style>