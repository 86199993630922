<template>
  <div></div>
</template>

<script>
import {mapActions} from 'vuex';

/**
 * @vuese
 * Page de déconnexion de la plateforme
 * @group PAGES
 */
export default {
  name: 'LogoutPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Se déconnecter',
      meta: [
        {
          name: 'description',
          content: 'Se déconnecter de Parcours ABC'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Se déconnecter'
        }
      ]
    };
  },
  methods: {
    ...mapActions('UserStore', ['logout'])
  },
  mounted() {
    this.logout();
  }
};
</script>

<style scoped>
</style>
