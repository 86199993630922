<template>
  <b-container
      id="app-menu-anno"
      role="navigation"
      class="app-menu"
      :class="{ 'app-menu--hidden': !showNavbar }"
  >
    <b-row>
      <b-col>
        <div class="text-center">
          <router-link
              :to="{
              name: 'home',
            }"
          >
            <img
                srcset="
                img/logo_abc_sm.svg.jpg 302w,
                img/logo_abc_full.md    377w,
                img/logo_abc_full.svg   453w
              "
                sizes="(max-width: 375px) 302px,
     (max-width: 780px) 377px,
            453px"
                src="img/logo_abc_full.svg"
                alt="Logo action bas carbone"
            />
          </router-link>
        </div>
        <div class="text-intro uppercase">
          <p>évaluez le poids carbone de vos choix de construction</p>
        </div>
      </b-col>

      <b-navbar id="navbar" toggleable="lg">
        <b-navbar-toggle 
          id="navbar-toggler" 
          target="nav-collapse"
        >
        </b-navbar-toggle>
        <b-collapse 
          id="nav-collapse" 
          is-nav class="nav-center"
        >
          <b-navbar-nav>
            <b-nav-item :to="{ name: 'home' }" class="links menu-item">
              accueil
            </b-nav-item>
            <b-nav-item :to="{ name: 'whoAreWe' }" class="links menu-item"
            >qui sommes-nous ?
            </b-nav-item
            >
            <b-nav-item :to="{ name: 'findOutMore' }" class="links menu-item"
            >en savoir plus
            </b-nav-item
            >
            <b-nav-item :to="{ name: 'actuPage' }" class="links menu-item"
            >à la une
            </b-nav-item
            >
            <b-nav-item :to="{ name: 'contact' }" class="links menu-item"
            >contact
            </b-nav-item
            >
          </b-navbar-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item :to="{ name: 'login' }" class="links menu-item"
            >connexion&nbsp;/&nbsp;inscription
            </b-nav-item
            >
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-row>
  </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import logoAbc from '/src/assets/logo_abc.svg';

/**
* @vuese
* Menu de navigation
*/
export default {
  name: 'AppMenuAnno',
  computed: {
    ...mapGetters(['report'])
  },
  data() {
    return {
      showNavbar: true,
      logoAbc: logoAbc,
      lastScrollPosition: 0,
      scrollValue: 0
    };
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
    document.addEventListener('click', this.onClick);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    /**
     * @vuese
     * Affichage ou non du logo en fonction de la position de la scrollbar
     */
    onScroll() {
      // Get the current scroll position
      const currentScrollPosition =
          window.scrollY || document.documentElement.scrollTop;
      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero
      if (currentScrollPosition < 0) {
        return;
      }
      // Here we determine whether we need to show or hide the navbar
      this.showNavbar = currentScrollPosition === 0;
      // Set the current scroll position as the last scroll position
      this.lastScrollPosition = currentScrollPosition;
    },
    onClick() {
      const navbarToggler = document.getElementById('navbar-toggler');
      let isOpen = navbarToggler.getAttribute('aria-expanded');

      let navbar = document.getElementById('nav-collapse');
      let isClassShowPresent = navbar.classList.contains('show');

      if (isOpen == 'true' && isClassShowPresent) {
        this.$root.$emit('bv::toggle::collapse', 'nav-collapse')
      }
    }
  }
};
</script>

<style lang="css" scoped>
  .app-menu {
    height: auto;
    transform: translate3d(0, 0, 0);
    transition: 0.25s all ease-out;
  }

  @media screen and (min-width: 500px) {
    .app-menu.app-menu--hidden {
      transform: translate3d(0, -78%, 0);
    }

    .app-menu--hidden .navbar.navbar-light.navbar-expand-lg {
      position: absolute;
      width: 100%;
      background: white;
      top: 78%;
    }
  }

  .menu-item {
    text-transform: uppercase;
  }

  @media screen and (max-width: 1109px) {
    .menu-item {
      font-size: 13px;
    }
  }
</style>
