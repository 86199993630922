<template>
  <div class="form-wrapper">
    <form @submit.prevent="save()">
      <b-form-group label="Nom" label-for="sectorName">
        <b-input
            required
            v-model="sector.name"
            autofocus
            id="sectorName"
            class="mt-2"
        />
      </b-form-group>
      <p v-if="sector.total"><strong>Total CO2 :</strong> {{ sector.total }}</p>
      <div class="d-flex justify-content-center align-items-center">
        <b-btn
            type="submit"
            :variant="load ? 'link' : 'secondary'"
            size="lg"
            :disabled="load"
        >
          <span v-if="!load">Enregistrer</span>
          <span v-else><b-spinner small/></span>
        </b-btn>
      </div>
    </form>
  </div>
</template>

<script>
import {http} from '@/http';

/**
* @vuese
* Formulaire pour les secteur
* @group COMPONENTS_ADMIN
*/
export default {
  name: 'FormAdminSector',
  props: {
    // Nom du secteur
    sector: {
      type: Object,
      default() {
        return {
          name: ''
        };
      }
    }
  },
  data() {
    return {
      load: false,
      form: {
        name: ''
      },
      sectorParents: []
    };
  },
  computed: {
    options: () => {
      return this.sectorParents.map((item) => {
        return {
          text: item['name'],
          value: item['@id']
        };
      });
    }
  },
  methods: {
    /**
     * @vuese
     * Récupération des informations sur les secteurs
     * @returns {Promise<AxiosResponse<any>>}
     */
    populateForm() {
      return http.get('/api/sectors').then((res) => {
        this.sectorParents = res.data['hydra:member'];
        return res;
      });
    },
    /**
     * @vuese
     * Correction de la valeur totale d'un secteur
     * @returns {Promise<AxiosResponse<any>>}
     */
    fixTotal() {
      this.load = true;
      return http
          .post('/admin/cards/sectors/' + this.sector.uid)
          .then((res) => {
            this.load = false;
            this.sector.total = res.data;
            this.$emit('input', this.sector);
            return res;
          });
    },
    /**
     * @vuese
     * Création d'un nouveau secteur
     * @returns {Promise<AxiosResponse<any>>}
     */
    save() {
      let dataSend = JSON.parse(JSON.stringify(this.sector));

      this.load = true;

      let url = '/api/sectors';
      let method = 'post';
      let mode = 'new';
      if (dataSend['@id']) {
        url = dataSend['@id'];
        method = 'put';
        mode = 'update';
      }

      return http[method](url, dataSend).then((res) => {
        // Sauvegarde du secteur
        this.$emit('saved', res.data);
        // Création ou mise à jour
        this.$emit(mode, res.data);
        this.fixTotal();
        this.load = false;
        return res;
      });
    }
  },
  created() {
    this.populateForm();
  }
};
</script>