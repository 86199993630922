<template>
  <b-container class="mt-4">
    <b-row>
      <b-col>
        <h1 class="page-title mt-2">Mentions légales</h1>
        <hr/>
      </b-col>
    </b-row>
    <b-row>
      <app-content slug="rgpd"/>
    </b-row>
  </b-container>
</template>

<script>

/**
 * @vuese
 * Page des mentions légales
 * @group PAGES
 */
export default {
  name: 'LegalsPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Mentions légales',
      meta: [
        {
          name: 'description',
          content: 'Mentions légales de Parcours ABC.'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Mentions légales'
        }
      ]
    };
  }
};
</script>
