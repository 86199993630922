<template>
  <b-container fluid>
    <b-overlay :show="load" variant="light" rounded="sm" no-fade>
      <b-row>
        <b-col class="mt-5">
          <app-bread-crumbs
              :routes="[
              { name: 'adminHome', title: 'Administration' },
              { name: 'adminCardsIndex', title: 'Étapes Climats' },
              { name: 'adminCardsHierarchy', title: 'Hiérarchie des étapes' },
            ]"
          />
          <div class="page-title-container sticky-top pt-1 pb-1">
            <h1 class="page-title">
              <b-btn
                  :to="{ name: 'adminCardsIndex' }"
                  variant="link"
                  title="Retour"
              >
                <b-icon-arrow-left aria-hidden="true"/>
              </b-btn>
              Hiérarchie des étapes
            </h1>
            <div>
              <btn-admin-card-edit-new
                  variant="secondary"
                  size="lg"
                  @saved="onSaved"
              />
              <b-btn
                  variant="success"
                  @click.prevent="fixValues"
                  size="lg"
                  class="ml-2"
              >
                Recalculer toutes les valeurs
              </b-btn>
            </div>
            <div class="pt-2">
              <p>⚠ Pour remettre à zéro une étape indirecte, changez-la de position à la racine du secteur, puis repositionnez-la à l'emplacement souhaité.</p></div>
          </div>
          <card-legend/>
          <div v-for="(sector, ksector) in sectors" :key="ksector">
            <h2 class="mb-0">
              {{ sector.name }} :
              <span v-if="sector.load" class="badge-loader"
              ><b-spinner small/> Chargement</span
              >
            </h2>
            <small class="text-normal"
            ><strong>{{ sector.total }}</strong> KGCO2E - Par personne - par
              an</small
            >
            <div>
              <input-card-nested
                  :sector="sector"
                  :cards="sector.cards"
                  @saved="onSaved"
                  @change="onChange"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>

<script>
import {http} from '@/http';


import AppBreadCrumbs from '@/components/appBreadCrumbs';
import BtnAdminCardEditNew from '@/components/Admin/btnAdminCardEditNew.vue';
import inputCardNested from '@/components/inputCardNested.vue';
import CardLegend from '@/components/cardLegend.vue';

/**
 * @vuese
 * Page de hiérarchie des fiches climat
 * @group PAGES_ADMIN
 */
export default {
  name: 'AdminCardsHierarchyPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Hiérarchie des étapes climats',
      meta: [
        {
          name: 'description',
          content:
              'Visualisation et modification de la hiérarchie des étapes climat.'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Hiérarchie des étapes climats'
        }
      ]
    };
  },
  components: {
    AppBreadCrumbs,
    BtnAdminCardEditNew,
    CardLegend,
    inputCardNested
  },
  data() {
    return {
      load: {
        default: false
      },
      sectors: {}
    };
  },
  methods: {
    /**
     * @vuese
     * Vérification si le parent est une étape indirecte
     * @arg Fiche parente
     * @returns {boolean}
     */
    allowFromParent(itemParent) {
      return itemParent.cardFormat.slug === 'indirect';
    },
    /**
     * @vuese
     * Récupération des enfants d'une étape
     * @arg Idx d'une fiche, 
     * @arg La fiche parente
     * @returns {*}
     */
    getChild(idx, itemParent = null) {
      if (itemParent === null) {
        return this.allCardsSorted[idx];
      }
      return itemParent.children[idx];
    },
    /**
     * @vuese
     * Mise à jour des étapes à la racine du secteur lors d'un changement de position d'une étape
     * @arg Position hiérarchique de la fiche, 
     * @arg Slug du secteur
     */
    onChange(position, sectorSlug) {
      this.getRootCards(sectorSlug);
    },
    /**
     * @vuese
     * Sauvegarde des étapes à la racine du secteur lors d'un changement de position d'une étape
     * @arg Position hiérarchique de la fiche, 
     * @arg Slug du secteur
     */
    onSaved(position, sectorSlug) {
      this.getRootCards(sectorSlug);
    },
    /**
     * @vuese
     * Trie des étapes
     * @arg Toutes les fiches climats
     * @returns {*}
     */
    sortItems(cards) {
      cards.sort((a, b) => a.position - b.position);
      cards.forEach((card) => {
        return card.children.sort((a, b) => a.position - b.position);
      });
      return cards;
    },
    /**
     * @vuese
     * Correction des valeurs des secteurs
     * @returns {Promise<AxiosResponse<any>>}
     */
    fixValues() {
      return http.post('admin/cards/fix').then((res) => {
        this.getRootCards();
        return res;
      });
    },
    /**
     * @vuese
     * Récupération des étapes à la racine d'un secteur
     * @arg Slug du secteur
     */
    getRootCards(sectorSlug = false) {
      if (sectorSlug) {
        this.sectors[sectorSlug].load = true;
      } else {
        this.load = true;
      }
      http.get('admin/cards').then((res) => {
        this.$refs['load-' + sectorSlug];
        if (sectorSlug) {
          this.sectors[sectorSlug].load = false;
        } else {
          this.load = false;
        }

        this.sectors = res.data['hydra:member'];
        return res;
      });
    }
  },
  mounted() {
    this.getRootCards();
  }
};
</script>