<template>
  <footer v-if="isOpen">
    <cookie-law class="Cookie--custom">
      <div slot-scope="props">
        <div class="Cookie--message" slot="message">
          Parcours ABC utilise des cookies.
          <div>
            <router-link :to="{ name: 'legals' }"
            >Cliquer ici pour en savoir plus
            </router-link
            >
          </div>
        </div>
        <button
            class="Cookie__button mr-2"
            @click="
            allowCookies();
            props.accept();
          "
        >
          <span>Accepter</span>
        </button>
        <button class="Cookie__button" @click="props.close">
          <span>Ne pas accepter</span>
        </button>
      </div>
    </cookie-law>
  </footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law';

/**
* @vuese
* La bannière de consentement pour les cookies
*/
export default {
  name: 'CookieConsentBanner',
  components: {CookieLaw},
  data() {
    return {
      isOpen: true
    };
  },
  methods: {
    /**
     * @vuese
     * Autorise l'utilisation de cookies sur le site
     */
    allowCookies() {
      // this.$matomo.rememberCookieConsentGiven();
      this.isOpen = false;
    }
  }
};
</script>