<template>
  <div class="img-media-preview-container">
    <!-- 'bouton' pour zoomer -->
    <div
        v-b-modal="id + '-zoom'"
        class="img-media-preview"
        :style="'background-image: url(' + urlImg + '/' + src.src + ')'"
    ></div>
    <b-modal
        :id="id + '-zoom'"
        size="xl"
        hide-header
        modal-class="img-zoom"
        footer-class="d-flex justify-content-around"
        scrollable
    >
      <div class="bg-dark p-3">
        <b-img class="img-media-full" :src="urlImgFull + '/' + src.src"/>
      </div>
      <div class="d-flex justify-content-center align-items-top mt-2">
        <b-form-group
            label="Description de l'image"
            label-for="img-description"
            class="image--meta  mr-2"
        >
          <input-html id="img-description" v-model="src.meta"/>
        </b-form-group>
        <b-form-group
            label="Méta-description"
            label-for="img-meta-description"
            class="image--meta ml-2"
        >
          <input-html id="img-meta-description" v-model="src.alt"/>
        </b-form-group>
      </div>

      <template slot="modal-footer">
        <b-btn variant="success" size="lg" @click.prevent="onSaved">
          Enregistrer
        </b-btn>
      </template>
    </b-modal>
  </div>
</template>

<script>
import inputHtml from '/src/components/inputHtml.vue';

/**
* @vuese
* Prévisualisation d'une image
*/
export default {
  name: 'ImgMediaPreview',
  components: {inputHtml},
  props: {
    id: {
      type: String,
      default() {
        const rand = Math.random().toString(36).substring(2);
        return 'img-preview-' + rand;
      }
    },
    src: {
      type: Object,
      default: () => {
        return {
          src: 'default.png',
          atl: '',
          meta: ''
        };
      }
    },
    edit: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },

  data() {
    return {
      urlImg: process.env.VUE_APP_IMG_PREVIEW_URL,
      urlImgFull: process.env.VUE_APP_IMG_FULL_URL
    };
  },
  methods: {
    onSaved() {
      this.$emit('input', this.src);
      this.$bvModal.hide(this.id + '-zoom');
    }
  }
};
</script>

<style lang="scss" scoped>
  .img-media-preview-container {
    &:hover {
      .img-media-preview {
        -webkit-box-shadow: inset 0 0 5px 500px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 5px 500px rgba(0, 0, 0, 0.3);
      }
    }
  }

  .btn-add-img,
  .img-media-preview {
    display: inline-block;
    width: 90px;
    height: 90px;
    border: 1px solid var(--border-color);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #fff;
    border-radius: 0.225rem;
  }

  .img-media-full {
    height: auto;
    width: 100%;
  }

  .image--meta {
    max-width: 45%;
  }
</style>