<template>
  <b-card
      class="card card-fiche"
      :class="'card-fiche-' + card.cardFormat.slug + ' '"
  >
    <div
        v-if="typeof exemplary.imgPrimary !== 'undefined'"
        class="card-imageContainer"
    >
      <b-img-lazy
          class="card-image"
          v-bind="mainProps"
          :src="urlImg + '/' + exemplary.imgPrimary[0].src"
          :alt="exemplary.title"
      />
    </div>
    <div v-else class="card-imageContainer">
      <picture>
        <source srcset="img/image-placeholder.webp" type="image/webp"/>
        <b-img class="card-image" src="img/image-placeholder.png" alt=""/>
      </picture>
    </div>
    <template #header>
      <div
          class="
          flex flex-column
          justify-content-start
          align-items-center
          card-header card-header--actu
        "
      >
        <slot>
          <router-link
              :to="'/se-connecter'"
              class="appCardActu-title h2 mt-2 mb-0"
              v-if="!user.uid"
          >
            {{ card.name }}
          </router-link>

          <a
              v-if="!user.isAdmin && user.uid"
              :href="'/etape/' + card.uid"
              class="h2 appCard-title mt-2 mb-0"
          >
            {{ card.name }}
          </a>
          <h2 v-if="user.isAdmin" class="mt-0 mb-0 appCard-title">
            {{ card.name }}
          </h2>
        </slot>
        <slot name="title-right"></slot>
      </div>
    </template>
    <div
        class="
        d-flex
        flex-wrap
        justify-content-around
        align-items-center
        flex-column
        appCard-body
        appcard-body--actu
      "
    >
      <slot name="body-left"></slot>
      <div>
        <b-btn
            @click="downloadCard(card)"
            variant="link"
            v-if="download && user.uid"
            title="Télécharger la fiche"
            aria-label="Télécharger la fiche"
        >
          <b-icon-download aria-hidden="true"/>
        </b-btn>
        <router-link
            class="btn"
            :to="'/se-connecter'"
            v-if="!user.uid"
            download
            title="Télécharger la fiche"
        >
          <b-icon-download aria-hidden="true"/>
        </router-link>
        <btn-admin-card-edit
            class="ml-auto"
            v-if="edit && user.isAdmin"
            :card="card"
            @saved="onSaved"
        />
      </div>
    </div>
    <div
        v-if="card.val !== 0"
        class="card-value mt-1 d-flex justify-content-center align-items-center"
    >
      <span class="unit-value">{{ card.val }} </span>
      <aside class="unit-info">
        <ul class="unit-info--ul">
          <li>kgCO2e</li>
          <li>par personne</li>
          <li>par an</li>
        </ul>
      </aside>
    </div>
    <div
        v-else
        class="
        card-value card-value--empty
        mt-1
        d-flex
        justify-content-center
        align-items-center
      "
    >
      Condition nécessaire
    </div>
  </b-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {http} from '@/http.js';
import _ from 'lodash';

/**
* @vuese
* Une fiche climat à mettre en avant
*/
export default {
  name: 'AppCardActu',
  props: {
    // Les fiches climats mise en avant
    card: {
      type: Object,
      default() {
        return {};
      }
    },
    // Mode du bouton: édition
    edit: {
      type: Boolean,
      default() {
        return false;
      }
    },
    // Mode du bouton: téléchargement
    download: {
      type: Boolean,
      default() {
        return true;
      }
    },
    // Fiche climat sélectionnée ?
    select: {
      type: Boolean,
      default() {
        return false;
      }
    },
    cardCategory: String
  },
  data() {
    return {
      urlImg: process.env.VUE_APP_IMG_FULL_URL,
      pdfUrl: process.env.VUE_APP_API_URL + '/pdf/cards',
      exemplary: {},
      mainProps: {
        blank: true,
        blankColor: '#bbb'
      }
    };
  },
  methods: {
    ...mapActions('UiStore', [
      'loaderOn',
      'loaderVariant',
      'loaderMessage',
      'loaderVariant',
      'loaderOff'
    ]),
    /**
     * @vuese
     * Ouverture de la fiche
     */
    openCard(card) {
      // @arg La fiche climat
      this.$emit('open', card);
    },
    /**
     * @vuese
     * Sauvegarde de la fiche
     */
    onSaved(card) {
      // @arg La fiche climat
      this.$emit('saved', card);
    },
    /**
     * @vuese
     * Mise à jour des fiches affichés
     */
    onCardSaved() {
      this.$refs.cardsFilters.searchCardsFiltred();
    },
    /**
     * @vuese
     * Téléchargement de l'étape au format pdf
     * @arg La fiche climat
     * @returns {Promise<AxiosResponse<any>>}
     */
    downloadCard(card) {
      this.loaderOn('Génération du PDF');
      this.loaderVariant('info');

      let url = '/pdf/cards/' + card.uid;
      try {
        return http
            .get(url, {
              responseType: 'blob'
            })
            .then((res) => {
              this.loaderOff();

              const blob = new Blob([res.data], {type: 'application/pdf'});
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = card.name + '--' + card.idxFull + '.pdf';
              link.click();
              return res;
            })
            .catch((e) => {
              this.loaderOff(e);
            });
      } catch (e) {
        this.loaderOff(e);
      }
      },
    /**
     * @vuese
     * Récupération d'une image de façon aléatoire d'un des ouvrages exemplaires associés à l'étape
     */
    getExemplary() {
      const urls = this.card.exemplaries;
      const cleanUrls = urls.map((url) =>
          url.toString().replace('/api/exemplaries/', '')
      );

      let randomUrl = _.sample(cleanUrls);

      if (randomUrl !== undefined) {
        http.get('/api/exemplaries/' + randomUrl).then((res) => {
          this.exemplary = res.data;
          return res;
        });
      }
    }
  },
  created() {
    this.getExemplary();
  },
  computed: {
    ...mapGetters('UserStore', ['user'])
  }
};
</script>

<style scoped>

  .appCardActu-title {
    color: var(--text-color-fiche) !important;
    text-align: center;
  }

  .card-imageContainer {
    text-align: center;
  }

  .card-image {
    height: 200px;
    width: 80%;
    object-fit: cover;
  }

  @media screen and (min-width: 992px) and (max-width: 1100px) {
    .card-image {
      height: 180px;
    }
  }

  @media screen and (max-width: 375px) {
    .card-image {
      height: 150px;
    }
  }
</style>