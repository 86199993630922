<template>
  <b-container class="bread-crumbs">
    <app-bread-crumbs
        :routes="[
        { name: 'login', title: 'Connexion / Inscription' },
        { name: 'myAccountPage', title: 'Mon compte' },
        { name: 'myAccountEditPage', title: 'Modifier mon compte' },
      ]"
        class="capitalize"
    />
    <b-row>
      <b-col>
        <h1 class="uppercase page-title">Modifier mon compte</h1>
        <form-edit-account/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import formEditAccount from '/src/components/formEditAccount.vue';

/**
 * @vuese
 * Page de modification de son compte utilisateur
 * @group PAGES
 */
export default {
  name: 'UserEditPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Modifier mon compte',
      meta: [
        {
          name: 'description',
          content:
              'Modifiez les informations relatives à votre compte utilisateur.'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Modifier mon compte'
        }
      ]
    };
  },
  components: {
    formEditAccount
  }
};
</script>
