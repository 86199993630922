import Vue from 'vue';

import dayjs from 'dayjs';

require('dayjs/locale/fr');
dayjs.locale('fr');


/**
 * Récupère la longueur d'un objet ou d'un tableau
 */
Vue.filter('length', function (value) {
  let type = typeof value;
  if (type === 'object' || type === 'array') return value.length;
});

/**
 * Formatage de la date
 */
Vue.filter('date', (value) => {
  if (value === undefined || value === null || value === false) {
    return '-';
  }
  return dayjs(value).format('DD/MM/YYYY');
});

/**
 * Formatage de la date/heure
 */
Vue.filter('datetime', (value) => {
  if (value === undefined || value === null || value === false) {
    return '-';
  }

  return dayjs(value).format('DD/MM/YYYY HH:mm');
});

/**
 * Formatage de l'heure
 */
Vue.filter('time', (value) => {
  if (value === undefined || value === null || value === false) {
    return '-';
  }
  return dayjs(value).format('HH:mm');
});

/**
 * Récupération de la date en fonction de la date actuelle, à partir d'un nombre de jours donné
 */
Vue.filter('fromNow', (value) => {
  if (value === undefined || value === null || value === false) {
    return '-';
  }
  const datedayjs = dayjs(value);
  if (datedayjs._isValid) {
    const dateNow = dayjs();
    const dataLimitFrom = dateNow.add(1, 'days');
    const isBefore = datedayjs.isBefore(dataLimitFrom);
    if (isBefore) {
      return datedayjs.from();
    }
    return datedayjs.format('llll');
  }
});

/**
 * Formatage de la valeur par défaut
 */
Vue.filter('formatSize', (value, defaultValue) =>
    value === null || value === undefined ? defaultValue : value
);

/**
 * Formatage de la valeur par défaut
 */
Vue.filter('def', (value, defaultValue) =>
    value === null || value === undefined ? defaultValue : value
);

/**
 * Formatage de la valeur par défaut
 */
Vue.filter('default', (value, defaultValue) =>
    value === null || value === undefined ? defaultValue : value
);
