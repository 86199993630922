<template>
  <b-container>
    <b-row class="d-none d-lg-block">
      <b-col>
        <app-bread-crumbs
            :routes="[
            { name: 'home', title: 'Accueil' },
            { name: 'reportNew', title: 'Mon parcours' },
          ]"
        />
      </b-col>
    </b-row>
    <app-admin-who-are-we class="d-none d-lg-block"/>
    <app-report-steps v-if="$mq !== 'md'"/>
    <app-report-steps-mobile v-if="$mq === 'md'"/>
  </b-container>
</template>

<script>
/* eslint-disable */
import appReportSteps from '@/components/appReportSteps.vue';
import appReportStepsMobile from '../components/appReportStepsMobile.vue';
import appMenuReport from '@/components/appMenuReport';
import appAdminWhoAreWe from '@/components/Admin/appAdminWhoAreWe.vue';

import {mapGetters} from 'vuex';

/**
 * @vuese
 * Page d'édition d'un rapport
 * @group PAGES
 */
export default {
  components: {
    appReportSteps,
    appReportStepsMobile,
    appMenuReport,
    appAdminWhoAreWe
  },
  name: 'ReportEditPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Mon parcours',
      meta: [
        {
          name: 'description',
          content:
              'Retrouver toutes les solutions ici et constituez votre propre parcours en fonction de vos besoins.'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Mon parcours'
        }
      ]
    };
  },
  computed: {
    ...mapGetters('UserStore', ['user']),
    ...mapGetters('ReportStore', ['filters'])
  }
};
</script>
