var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-upload"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('label',{staticClass:"btn btn-dark",attrs:{"for":_vm.id + '-input-files'}},[_vm._v("Ajouter vos fichiers")]),_c('input',{staticClass:"input-file",attrs:{"id":_vm.id + '-input-files',"type":"file","multiple":true},on:{"input":_vm.onInputFile}})]),_c('div',{staticClass:"app-upload-header mb-2 d-flex justify-content-between align-items-center"},[_c('file-upload',{ref:_vm.id,attrs:{"post-action":_vm.getPostUploadUrl(),"chunk-enabled":"","chunk":{
        action: _vm.getPostUploadUrl(true),
        headers: _vm.headers,
        minSize: 10548576,
        maxActive: 3,
        maxRetries: 5,
      },"extensions":_vm.extensions.join(','),"accept":_vm.accept,"headers":_vm.headers,"drop":true,"data":_vm.data,"multiple":true,"thread":3,"size":0,"autoUpload":true,"no-caret":""},on:{"input-filter":_vm.inputFilter,"input-file":_vm.inputFile},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),_c('div',{staticClass:"dropdown-menu"},[_c('label',{staticClass:"dropdown-item pointer pl-3 strong",attrs:{"for":_vm.name}},[_vm._v(" Ajouter un fichier ")]),_c('a',{staticClass:"dropdown-item pointer pl-3 strong",attrs:{"href":"#"},on:{"click":_vm.onAddFolder}},[_vm._v(" Ajouter un dossier ")])]),_c('div',{staticClass:"ml-auto"},[_vm._t("header-menu")],2)],1),_c('label',{staticClass:"mb-0",attrs:{"id":"app-upload-title"}},[_c('div',{staticClass:"l-1 mb-2"},[_c('small',{staticClass:"text-sm text-muted"},[_vm._v("*"+_vm._s(_vm.extensions.join(', ')))])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$refs[_vm.id] && _vm.$refs[_vm.id].dropActive),expression:"$refs[id] && $refs[id].dropActive"}],staticClass:"drop-active"},[_c('h3',[_vm._v("Fichiers")])]),_c('div',{staticClass:"upload"},[_c('div',{staticClass:"table-responsive mb-0"},[_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',[(!_vm.files.length)?_c('tr',[_c('td',{staticClass:"p-0 bg-light",attrs:{"colspan":"8"}},[_c('label',{staticClass:"text-center p-5 w-100 pointer",attrs:{"for":_vm.id + '-input-files'}},[_c('div',[_c('b-icon-cloud-upload',{staticClass:"h1",staticStyle:{"font-size":"4rem"},attrs:{"aria-hidden":"true"}})],1),_c('h4',{staticClass:"mb-0 l-1"},[_vm._t("content",function(){return [_c('strong',[_vm._v("Glisser vos fichiers")]),_vm._m(1),_c('strong',[_vm._v("sélectionner")])]})],2),_c('small',{staticClass:"text-sm text-muted"},[_vm._v("*"+_vm._s(_vm.extensions.join(', ')))])])])]):_vm._e(),_vm._l((_vm.files),function(file,index){return _c('tr',{key:file.id,class:{
              'is-active': file.active,
            }},[_c('td',{staticClass:"text-center"},[_c('small',{staticClass:"strong"},[_vm._v(_vm._s(index + 1))])]),_c('td',{staticClass:"text-center"},[(file.thumb)?_c('img',{attrs:{"src":file.thumb,"width":"40","height":"auto","alt":""}}):_c('small',{staticClass:"l-1 word-wrap"},[_vm._v("."+_vm._s(_vm._f("extension")(file.name)))])]),_c('td',[_c('div',{staticClass:"filename word-wrap"},[_vm._v(" "+_vm._s(file.name)+" ")])]),_c('td',[(file.active || file.progress !== '0.00')?_c('div',{staticClass:"progress"},[_c('div',{class:{
                    'progress-bar': true,
                    'bg-danger': file.error,
                    'bg-success': !file.error,
                    'progress-bar-animated': file.active,
                  },style:({ width: file.progress + '%' }),attrs:{"role":"progressbar"}},[_vm._v(" "+_vm._s(file.progress)+"% ")])]):_vm._e()]),_c('td',[_c('small',[_vm._v(_vm._s(_vm._f("formatSize")(file.size)))])]),_c('td',[_c('small',[_vm._v(_vm._s(_vm._f("formatSize")(file.speed))+"/s")])]),(file.error)?_c('td',{staticClass:"text-center text-danger"},[_c('strong',[_vm._v(_vm._s(file.error))])]):(file.success)?_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"text-success strong"},[_c('b-icon-check',{staticClass:"h2 mb-0",attrs:{"aria-hidden":"true"}})],1)]):_c('td')])})],2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"sticky bg-white"},[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("#")]),_c('th'),_c('th',{staticClass:"col-name"},[_vm._v("Nom")]),_c('th',[_vm._v("Progression")]),_c('th',[_vm._v("Taille")]),_c('th',[_vm._v("Vitesse")]),_c('th',{staticClass:"text-center"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('small',[_vm._v("ou")])])
}]

export { render, staticRenderFns }