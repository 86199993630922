import axios from 'axios';
import Ls from '@/ls';

const token = Ls.get('token', false);

/* Auth token */
if (token && token !== 'false') {
  axios.defaults.headers.common.Authorization = 'Bearer ' + token;
}

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    withCredentials: true,
    crossDomain: true,
  },
});

export { http };
