import {http} from '@/http.js';
import Ls from '@/ls.js';
import _ from 'lodash';
import flat from '@/flat';

export const CardStore = {
  namespaced: true,
  getters: {
    filters: (state) => {
      return {
        name: state.filtersName,
        categories: state.filtersCategories,
        draft: state.filtersDraft,
        formats: state.filtersFormats,
        sectors: state.filtersSectors,
        tags: state.filtersTags
      };
    },
    filtersName: (state) => state.filtersName,
    filtersCategories: (state) => state.filtersCategories,
    filtersDraft: (state) => state.filtersDraft,
    filtersFormats: (state) => state.filtersFormats,
    filtersSectors: (state) => state.filtersSectors,
    filtersTags: (state) => state.filtersTags,
    cardsCategories: (state) => state.cardsCategories,
    cardsFormats: (state) => state.cardsFormats,
    tagCategories: (state) => state.tagCategories,
    sectors: (state) => state.sectors,
    tags: (state) => state.tags,
    date: (state) => state.date,
    time: (state) => state.time,
    timeout: (state) => state.timeout,
    toggle: (state) => state.toggle
  },
  state: {
    toggle: true,
    time: Date.now(),
    cardsCategories: Ls.get('cardsCategories', []),
    cardsFormats: Ls.get('cardsFormats', []),
    tagCategories: Ls.get('tagCategories', []),
    sectors: Ls.get('sectors', []),
    tags: Ls.get('tags', []),
    filtersName: Ls.get('filtersName', ''),
    filtersCategories: Ls.get('filtersCategories', []),
    filtersDraft: 0,
    filtersFormats: Ls.get('filtersFormats', []),
    filtersSectors: Ls.get('filtersSectors', []),
    filtersTags: Ls.get('filtersTags', [])
  },
  mutations: {
    SET_FILTER(state, dataFilter) {
      state[dataFilter.key] = Ls.set(dataFilter.key, dataFilter.value);
    },
    SET_FILTERS(state, data) {
      state.filtersCategories = Ls.set('filtersCategories', data.categories);
      state.filtersDraft = Ls.set('filtersDraft', data.draft);
      state.filtersFormats = Ls.set('filtersFormats', data.formats);
      state.filtersSectors = Ls.set('filtersSectors', data.sectors);
      state.filtersTags = Ls.set('filtersTags', data.tags);
    },
    CLEAR_FILTERS(state) {
      state.filtersCategories = Ls.set('filtersCategories', []);
      state.filtersDraft = Ls.set('filtersDraft', 0);
      state.filtersFormats = Ls.set('filtersFormats', []);
      state.filtersSectors = Ls.set('filtersSectors', []);
      state.filtersTags = Ls.set('filtersTags', []);
    },
    SET_POPULATE(state, data) {
      state.cardsCategories = Ls.set('cardsCategories', data.categories);
      state.cardsFormats = Ls.set('cardsFormats', data.formats);
      state.tagCategories = Ls.set('tagCategories', data.tagCategories);
      state.sectors = Ls.set('sectors', data.sectors);
      state.tags = Ls.set('tags', data.tags);
    },
    SET_TOGGLE(state) {
      state.toggle = !state.toggle;
    },
    SET_CHANGE(state) {
      clearTimeout(state.timeout);
      state.timeout = setTimeout(() => {
        state.time = Date.now();
      }, 500);
    }
  },
  actions: {
    /**
     * Récupération des filtres
     * @param commit
     * @param dataFilter
     */
    setFilter({commit}, dataFilter) {
      commit('SET_FILTER', dataFilter);
      commit('SET_CHANGE');
    },
    /**
     * Ajout d'un filtre
     * @param state
     * @param commit
     * @param dataFilter
     */
    addFilter({state, commit}, dataFilter) {
      dataFilter.value = _.uniq(state[dataFilter.key].concat(dataFilter.value));
      commit('SET_FILTER', dataFilter);
      commit('SET_CHANGE');
    },
    /**
     * Suppression d'un filtre
     * @param state
     * @param commit
     * @param dataFilter
     */
    removeFilter({state, commit}, dataFilter) {
      dataFilter.value = _.uniq(
          _.difference(state[dataFilter.key], dataFilter.value)
      );
      commit('SET_FILTER', dataFilter);
      commit('SET_CHANGE');
    },
    checkAllSectors({state, commit}) {
      const allSectors = flat.flat(state.sectors, 'children');
      const dataFilter = {
        key: 'filtersSectors',
        value: allSectors
      };
      commit('SET_FILTER', dataFilter);
    },
    toggleCheckAllFilters({state, commit}) {
      commit('SET_TOGGLE');
      if (!state.toggle) {
        const filtersDraft = 'all';
        commit('SET_FILTER', {
          key: 'filtersDraft',
          value: filtersDraft
        });
        const filtersFormats = _.map(
            state.cardsFormats,
            (format) => format.slug
        );
        commit('SET_FILTER', {
          key: 'filtersFormats',
          value: filtersFormats
        });
        const filtersSectors = flat.flat(state.sectors, 'children');
        commit('SET_FILTER', {
          key: 'filtersSectors',
          value: filtersSectors
        });
        const filtersTags = flat.flat(state.tags, 'children');
        commit('SET_FILTER', {
          key: 'filtersTags',
          value: filtersTags
        });
        const filtersCategories = _.map(
            state.cardsCategories,
            (categorie) => categorie.uid
        );
        commit('SET_FILTER', {
          key: 'filtersCategories',
          value: filtersCategories
        });
        commit('SET_CHANGE');
      } else {
        commit('CLEAR_FILTERS');
        commit('SET_CHANGE');
      }
    },
    clearFilters({commit}) {
      commit('CLEAR_FILTERS');
      commit('SET_CHANGE');
    },
    getPopulate({commit}) {
      return http.get('admin/cards/populate?for=filters').then((res) => {
        commit('SET_POPULATE', res.data);
        return res;
      });
    }
  }
};
