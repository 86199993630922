<template>
  <b-container
      class="appWelcome image-fondA"
      :style="{ backgroundImage: 'url(/img/forme_fond_A.svg)' }"
  >
    <b-row class="d-flex flex">
      <h2 class="pink uppercase">Bienvenue !</h2>
      <app-content slug="accueil-1" class="my-2 app-content--transparent"/>
      <b-col sm="12" lg="3" class="backg-A">
        <a v-if="!user.uid" href="/se-connecter">
          <header class="d-flex align-items-center justify-content-evenly">
            <h3>A</h3>
            <p>J'évalue mes priorités</p>
          </header>

          <b-img
              src="/img/illu_etape_A.svg"
              alt=""
          />
        </a>
        <div v-else>
          <header class="d-flex align-items-center justify-content-evenly">
            <h3>A</h3>
            <p>J'évalue mes priorités</p>
          </header>
          <b-img
              src="/img/illu_etape_A.svg"
              alt=""
          />
        </div>
      </b-col>

      <b-col class="more">
        <b-icon icon="plus" aria-hidden="true"/>
      </b-col>

      <b-col sm="12" lg="3" class="backg-B">
        <a v-if="!user.uid" href="/se-connecter">
          <header class="d-flex align-items-center justify-content-evenly">
            <h3>B</h3>
            <p>Je les sélectionne</p>
          </header>
          <b-img
              src="/img/illu_etape_B.svg"
              alt=""
          />
        </a>
        <div v-else>
          <header class="d-flex align-items-center justify-content-evenly">
            <h3>B</h3>
            <p>Je les sélectionne</p>
          </header>
          <b-img
              src="/img/illu_etape_B.svg"
              alt=""
          />
        </div>
      </b-col>

      <b-col class="more">
        <b-icon icon="plus" aria-hidden="true"/>
      </b-col>

      <b-col sm="12" lg="3" class="backg-C">
        <a v-if="!user.uid" href="/se-connecter">
          <header class="d-flex align-items-center justify-content-evenly">
            <h3>C</h3>
            <p>Je reçois mon rapport</p>
          </header>
          <b-img
              src="/img/illu_etape_C.svg"
              alt=""
          />
        </a>
        <div v-else>
          <header class="d-flex align-items-center justify-content-evenly">
            <h3>C</h3>
            <p>Je reçois mon rapport</p>
          </header>
          <b-img
              src="/img/illu_etape_C.svg"
              alt=""
          />
        </div>
      </b-col>
    </b-row>
    <btn-calcul-co/>
    <picture>
      <source
          srcset="/img/illus_reduction_emission_co2.webp"
          type="image/webp"
      />
      <b-img
          class="mt-3 image-objectifs"
          src="/img/illus_reduction_emission_co2.png"
          alt="Les objectifs de réduction de CO2: 4,5 tonnes de CO2 e par an en 2030 et 2 tonnes de CO2 e par an en 2050 contre 9 tonnes de CO2 e par an aujourd’hui"
      />
    </picture>
  </b-container>
</template>

<script>
import BtnCalculCo from '@/components/btnCalculCo';
import {mapGetters} from 'vuex';

/**
* @vuese
* Bandeau affichant les blocs A, B et C de la page d'accueil
*/
export default {
  name: 'AppWelcome',
  components: {
    BtnCalculCo
  },
  computed: {
    ...mapGetters('UserStore', ['user'])
  }
};
</script>

<style scoped lang="scss">
  .appWelcome {
    background-color: var(--background-color--transparent) !important;
  }

  .image-fondA {
    background-repeat: no-repeat;
    background-position-y: 90px;
    background-size: 400px;
  }

  .backg-A,
  .backg-B,
  .backg-C {
    background-color: var(--background-color);
    color: var(--primary-color);
    text-align: center;
    border: 1px solid #8f8f8f;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.16);
  }

  .backg-A h3,
  .backg-B h3,
  .backg-C h3 {
    font-weight: bold;
    font-size: 50px !important;
    text-align: left;
    color: var(--primary-color);
  }

  .backg-A p,
  .backg-B p,
  .backg-C p {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
    color: var(--primary-color);
  }

  .more {
    display: flex;
    font-size: 100px;
    color: var(--primary-color);
    align-items: center;
    justify-content: center;
  }

  .image-objectifs {
    width: 80%;
    display: block;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }

  @media screen and (min-width: 768px) and (max-width: 1440px) {
    .more {
      font-size: 60px;
    }
  }

  @media screen and (max-width: 1024px) {
    .image-fondA {
      background-size: 0;
    }
  }
</style>
