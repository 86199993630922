<template>
  <div>
    <div class="adminHomePage">
      <b-container>
        <b-row>
          <b-col class="p-5">
            <h1 class="ta-center page-title uppercase">Administration</h1>
            <b-jumbotron class="mb-0 p-5">
              <h1 class="h2 strong">Bonjour, {{ user.firstName }}</h1>
              <p class="mb-0">
                Bienvenue sur votre espace administrateur de Parcours ABC,
              </p>
              <p class="mb-0">
                Nous sommes le
                <strong>{{ DateNow | date('dddd D MMMM YYYY') }}</strong>
              </p>
            </b-jumbotron>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div>
      <b-container>
        <b-row>
          <b-col class="pt-3">
            <div
                class="d-flex justify-content-center align-items-center flex-wrap"
            >
              <b-btn
                  :to="{ name: 'adminCardsIndex' }"
                  size="lg"
                  variant="basic"
                  class="mt-2 mr-2"
              >Étapes climats
              </b-btn
              >
              <b-btn
                  :to="{ name: 'reportNew' }"
                  size="lg"
                  class="mt-2 mr-2"
                  variant="basic"
              >Mon parcours
              </b-btn
              >
              <b-btn
                  :to="{ name: 'adminExemplaryIndex' }"
                  size="lg"
                  class="mt-2"
                  variant="basic"
              >Ouvrages exemplaires
              </b-btn
              >
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

/**
 * @vuese
 * Page d'accueil du backoffice
 * @group PAGES_ADMIN
 */
export default {
  name: 'AdminPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Tableau de bord',
      meta: [
        {
          name: 'description',
          content: 'Espace administrateur de Parcours ABC'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Tableau de bord'
        }
      ]
    };
  },
  computed: {
    ...mapGetters('UserStore', ['user'])
  },
  data() {
    return {
      DateNow: Date.now()
    };
  }
};
</script>

<style>
  .adminHomePage {
    background-color: var(--background-color-light);
  }
</style>