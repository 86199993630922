import {http} from '@/http.js';
import Ls from '../ls';
import router from '@/router';

export const UserStore = {
  namespaced: true,
  getters: {
    token: (state) => state.token,
    user: (state) => state.user,
    users: (state) => state.users,
    currentUserIri: (state) => 'api/users/' + state.user.uid,
    isLoggedIn: (state) => state.user.uid,
    isAdmin: (state) => state.user.isAdmin
  },
  state: {
    users: [],
    user: Ls.get('user', {}),
    token: Ls.get('token', '')
  },
  mutations: {
    LOGIN(state, data) {
      state.token = Ls.set('token', data.token);
      Ls.set('refresh_token', data.refresh_token);
      http.defaults.headers.common.Authorization = 'Bearer ' + data.token;
    },
    ME(state, user) {
      state.user = Ls.set('user', user);
    },
    LOGOUT(state) {
      state.user = {};
      state.token = '';
      state.report = {};
      Ls.clear();
      Ls.remove('token');
      Ls.remove('refresh_token');
      Ls.remove('user');
      Ls.remove('chosenCards');
      Ls.remove('filteredCards');
      Ls.remove('generatedCards');
      Ls.remove('chosenTags');
      Ls.remove('co2Tot');
      Ls.remove('cards');
      Ls.remove('selected');
      Ls.remove('report');
      delete http.defaults.headers.common.Authorization;
    }
  },
  actions: {
    async login({commit}, data) {
      let params = new FormData();
      params.append('_username', data.username);
      params.append('_password', data.password);
      return await http
          .post('login', params)
          .then(async (res) => {
            await commit('LOGIN', res.data);
            return res;
          })
          .then(async () => {
            return await http.get('/me').then(async (res2) => {
              commit('ME', res2.data);
              if (res2.data.isAdmin === true) {
                await router.push({name: 'adminHome'});
              } else {
                await router.push({name: 'reportNew'});
              }
              return res2;
            });
          });
    },
    async logout({commit}) {
      await commit('LOGOUT');
      return router.push({name: 'home'});
    },
    // eslint-disable-next-line no-unused-vars
    async forgottenPassword({commit}, data) {
      return await http
          .post('password/forgotten', {data: data})
          .then(async (res) => {
            return res;
          });
    },
    // eslint-disable-next-line no-unused-vars
    async resetPassword({commit}, data) {
      return await http
          .post('password/reset', {data: data})
          .then(async (res) => {
            return res;
          });
    },
    async me({commit}) {
      return await http.get('/me').then(async (res) => {
        commit('ME', res.data);
        return res;
      });
    }
  }
};
