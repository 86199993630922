<template>
  <b-form @submit.prevent="saveActu()">
    <div>
      <b-alert show variant="light">
        <b-row>
          <b-col offset="3">
            <div>
              <span>id : {{ form.uid | def('-') }}</span>
            </div>
            <app-draft :draft="form.draft"/>
          </b-col>
          <b-col class="text-right">
            <div>maj : {{ form.updatedAt | datetime | def('') }}</div>
            <b-btn
                v-if="form['@id'] && form.draft"
                @click.prevent="removeExemplary"
                variant="danger"
                title="Supprimer l'ouvrage exemplaire"
                aria-label="Supprimer l'ouvrage exemplaire"
                class="mr-2"
            >
              <b-icon-trash aria-hidden="true"/>
            </b-btn>
            <b-btn
                v-if="form['@id'] && form.draft"
                @click.prevent="publishExemplary"
                title="Publier l'étape"
                aria-label="Publier l'ouvrage exemplaire"
                variant="success"
                class="mr-2"
            >
              Publier
            </b-btn>
            <b-btn
                variant="light"
                class="b-trans"
                v-if="form['@id'] && !form.draft"
                @click.prevent="draftExemplary"
                title="Mettre l'étape en état de brouillon l'ouvrage exemplaire"
                aria-label="Mettre l'étape en état de brouillon l'ouvrage exemplaire"
            >
              Mettre en brouillon
            </b-btn>
          </b-col>
        </b-row>
      </b-alert>
    </div>
    <b-form-group label="Titre" label-for="exemplaryTitle">
      <b-form-input required v-model="form.title" id="exemplaryTitle"/>
    </b-form-group>

    <b-form-group label="Maître d'ouvrage" label-for="exemplaryProjectOwner">
      <b-form-input
          required
          v-model="form.projectOwner"
          id="exemplaryProjectOwner"
      />
    </b-form-group>

    <b-form-group label="Architecte" label-for="exemplaryArchitect">
      <b-form-input required v-model="form.architect" id="exemplaryArchitect"/>
    </b-form-group>

    <b-form-group label="Crédit de l'illustration" label-for="exemplaryCredit">
      <b-form-input required v-model="form.credit" id="exemplaryCredit"/>
    </b-form-group>

    <b-form-group>
      <input-image
          label="Vignette"
          v-model="form.imgPrimary"
          id="exemplaryImgPrimary"
      />
    </b-form-group>

    <b-form-group label="Contenu">
      <input-html v-model="form.content" id="exemplaryContent"/>
    </b-form-group>

    <input-image label="Images" v-model="form.img" id="exemplaryImage"/>

    <b-form-group label="Lien associé" label-for="exemplaryLink">
      <b-form-input type="url" v-model="form.link" id="exemplaryLink"/>
    </b-form-group>

    <div class="d-flex justify-content-center align-items-center mt-2">
      <b-button type="submit" :variant="load ? 'link' : 'dark'" size="lg">
        <span v-if="!load">Enregistrer</span>
        <span v-else><b-spinner small/></span>
      </b-button>
    </div>
  </b-form>
</template>

<script>
import {mapActions} from 'vuex';
import {http} from '@/http';
import appDraft from './appDraft.vue';

/**
* @vuese
* Formulaire de création/modification d'un ouvrage exemplaire
*/
export default {
  components: {appDraft},
  name: 'FormExemplary',
  props: {
    id: {
      type: [String, Boolean],
      default: () => false
    }
  },
  data() {
    return {
      load: false,
      form: {
        draft: true,
        imgPrimary: [],
        img: [],
        identifier: '',
        title: '',
        content: '',
        credit: '',
        architect: '',
        projectOwner: '',
        link: ''
      }
    };
  },
  methods: {
    ...mapActions('UserStore', ['login']),
    onClickImgItemPrimary(imgPrimary) {
      this.form.imgPrimary = {file: imgPrimary};
    },
    /**
     * @vuese
     * Création d'un nouvel ouvrage exemplaire
     * @returns {Promise<AxiosResponse<any>>}
     */
    saveActu() {
      this.load = true;

      let url = '/api/exemplaries';
      let method = 'post';
      let mode = 'new';
      if (this.form.id) {
        url = this.form['@id'];
        method = 'put';
        mode = 'update';
      }

      return http[method](url, this.form).then((res) => {
        this.load = false;
        this.$emit('saved', res.data);
        if (mode === 'new') {
          this.$router.push({
            name: 'adminExemplaryEdit',
            params: {
              id: res.data.id
            }
          });
        } else {
          this.form = res.data;
        }
        this.$router.push('/admin/ouvrages-exemplaires');

        return res;
      });
    },
    getItem() {
      this.load = true;
      return http.get('/api/exemplaries/' + this.id, this.form).then((res) => {
        this.form = res.data;
        this.load = false;
        return res;
      });
    },
    /**
     * @vuese
     * Mise à jour de l'ouvrage exemplaire vers le status: publié
     * @returns {Promise<AxiosResponse<any>>}
     */
    publishExemplary() {
      this.load = true;
      return http.put(this.form['@id'], {draft: false}).then((res) => {
        this.load = false;
        this.form = res.data;
        this.$emit('published', res.data);
        this.$emit('saved', res.data);
      });
    },
    /**
     * @vuese
     * Mise à jour de l'ouvrage exemplaire vers le status: en brouillon
     * @returns {Promise<AxiosResponse<any>>}
     */
    draftExemplary() {
      this.load = true;
      return http.put(this.form['@id'], {draft: true}).then((res) => {
        this.load = false;
        this.form = res.data;
        this.$emit('drafted', res.data);
        this.$emit('saved', res.data);
      });
    },
    /**
     * @vuese
     * Message de prévention avant suppression de l'ouvrage exemplaire
     * @returns {Promise<BvMsgBoxData>}
     */
    removeExemplary() {
      return this.$bvModal
          .msgBoxConfirm('Supprimer l\'ouvrage exemplaire.', {
            title: 'Veuillez confirmer',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Oui',
            cancelTitle: 'Non',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
          .then((value) => {
            if (value) {
              return http.delete(this.form['@id']).then((res) => {
                this.$emit('deleted', this.form);
                this.$router.push({
                  name: 'adminExemplaryIndex'
                });
                return res;
              });
            }
          });
    }
  },
  created() {
    if (this.id) {
      this.getItem();
    }
  }
};
</script>
