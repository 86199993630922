<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <app-bread-crumbs
            :routes="[
            { name: 'adminHome', title: 'Administration' },
            { name: 'adminParams', title: 'Classement' },
            { name: 'adminTestimonyEditPage', title: 'Modifier un témoignage' },
          ]"
        >
        </app-bread-crumbs>
        <h1 class="page-title">
          <b-btn :to="{ name: 'adminParams' }" variant="link" title="Retour"
          >
            <b-icon-arrow-left aria-hidden="true"/>
          </b-btn>
          Témoignage
        </h1>
        <div>
          <b-container fluid>
            <b-row>
              <b-col>
                <form-testimony :id="$route.params.id" @saved="onSaved"/>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import formTestimony from '@/components/formTestimony';

/**
 * @vuese
 * Page d'édition d'un témoignage
 * @group PAGES_ADMIN
 */
export default {
  name: 'AdminTestimonyEditPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Nouveau témoignage',
      meta: [
        {
          name: 'description',
          content: 'Création d\'un témoignage.'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Nouveau témoignage'
        }
      ]
    };
  },
  components: {
    formTestimony
  },
  methods: {
    /**
     * @vuese
     * Sauvegarde de la modification d'un témoignage
     * @param res
     */
    onSaved(res) {
      this.$router.push({
        name: 'testimonyIndex',
        params: {
          id: res.data.id
        }
      });
    }
  }
};
</script>