<template>
  <b-form @submit.prevent="editAccount()">
    <b-form-group label="Nom" label-for="userLastName">
      <b-form-input
          autofocus
          required
          v-model="form.lastName"
          id="userLastName"
      />
    </b-form-group>

    <b-form-group label="Prénom" label-for="userFirstName">
      <b-form-input
          autofocus
          required
          v-model="form.firstName"
          id="userFirstName"
      />
    </b-form-group>

    <b-form-group label="Numéro de téléphone" label-for="userPhone">
      <b-form-input
          autofocus
          required
          type="tel"
          title="Le numéro de tél. doit être au format: 06 01 02 03 05 ou +33 6 01 02 03 04 05 ou 003360102030405"
          pattern="^(?:0|\(?\+33\)?\s?|0033\s?)[1-79](?:[\.\-\s]?\d\d){4}$"
          v-model="form.phone"
          id="userPhone"
      />
    </b-form-group>

    <b-form-group label="Structure" label-for="structure" class="mt-2">
        <b-form-input v-model="form.company.name" required id="structure"/>
      </b-form-group>

    <b-form-group label="Fonction" label-for="userCompanyRole">
      <b-form-input
          autofocus
          required
          v-model="form.companyRole"
          id="userCompanyRole"
      />
    </b-form-group>

    <b-form-group
          label="Adhérent de :"
          label-for="organisme"
          class="mt-2"
      >
        <b-form-select
            v-model="form.from"
            :options="options"
            class="form-control custom-select"
            required
            id="organisme"
        />
      </b-form-group>

    <div class="d-flex justify-content-center align-items-center">
      <b-button
          type="submit"
          :variant="load ? 'link' : 'dark'"
          size="lg"
          class="mt-4 mb-4"
      >
        <span v-if="!load">Enregistrer</span>
        <span v-else><b-spinner/></span>
      </b-button>
    </div>

    <b-alert :show="sended" variant="success">
      <b-icon-check-circle aria-hidden="true"/>
      Vos données sont mises à jour.
    </b-alert
    >
  </b-form>
</template>
<script>
import {http} from '@/http';
import {mapGetters, mapActions} from 'vuex';
import methods from '@/methods';

/**
* @vuese
* Modification d'un compte utilisateur
*/
export default {
  name: 'FormEditAccount',
  props: {
    item: {}
  },
  computed: {
    ...mapGetters('UserStore', ['user'])
  },
  data() {
    return {
      load: false,
      sended: false,
      selected: null,
      options: [],
      form: {
        lastName: '',
        firstName: '',
        phone: '',
        companyRole: '',
        company: '',
        from: ''
      }
    };
  },
  methods: {
    ...mapActions('UserStore', ['me']),
    /**
     * @vuese
     * Modification et sauvegarde d'un compte utilisateur
     */
    editAccount() {
      this.load = true;
      const params = methods.jsonToFormData(this.form);
      return http.post('/me', params).then((res) => {
        // Sauvegarde des modifications
        this.$emit('saved', res.data);
        return this.me().then((res2) => {
          this.sended = true;
          setTimeout(() => {
            this.sended = false;
          }, 15000);
          this.load = false;
          return res2;
        });
      });
    },
    getPopulate() {
      return http.get('/api/company_froms').then((res) => {
        this.options = _.map(res.data['hydra:member'], (item) => {// eslint-disable-line
          return {
            value: item['@id'],
            text: item.name
          };
        });
        return res;
      });
    }
  },
  created() {
    this.form = JSON.parse(JSON.stringify(this.user));
    this.getPopulate();
  }
};
</script>
