import { UserStore } from '@/store/UserStore';
import { AdminUserStore } from '@/store/AdminUserStore';
import { ReportStore } from '@/store/ReportStore';
import { CardStore } from '@/store/CardStore';
import { UiStore } from '@/store/UiStore';

export default {
  AdminUserStore,
  CardStore,
  ReportStore,
  UiStore,
  UserStore,
};
