<template>
  <b-form
      @submit.prevent="saveReport"
  >
    <b-form-group
        label="Nom du rapport"
        label-for="name"
    >
      <b-form-input
          required
          v-model="form.name"
          id="name"
      />
    </b-form-group>
    <b-form-group
        label="Description"
        label-for="description"
    >
      <b-textarea
          rows=10
          v-model="form.description"
          id="description"
      />
    </b-form-group>
    <hr/>
    <div class="d-flex justify-content-between align-items-center">
      <b-button
          @click.prevent="removeReport"
          variant="light"
          title="Supprimer le rapport"
          aria-label="Supprimer le rapport"
      >
        <b-icon-trash aria-hidden="true"/>
      </b-button>
      <b-button
          type="submit"
          :variant="load ? 'link' : 'secondary'"
          size="lg"
      >
        <span v-if="!load">Enregistrer</span>
        <span v-else><b-spinner small/></span>
      </b-button>
    </div>
  </b-form>
</template>

<script>
import {http} from '@/http.js';

/**
* @vuese
* Formulaire de création d'une fiche climat
* @group COMPONENTS_ADMIN
*/
export default {
  name: 'FormAdminReport',
  props: {
    // Le rapport
    item: {
      type: Object,
      default: () => {
        return {
          name: '',
          description: '',
          cards: []
        };
      }
    }
  },
  watch: {
    'item.@id'() {
      this.form = this.item;
    }
  },
  data() {
    return {
      load: false,
      form: {
        name: '',
        description: '',
        cards: []
      }
    };
  },
  methods: {
    /**
    * @vuese
    * Sauvegarde du rapport
    */
    saveReport() {
      let formCopy = JSON.parse(JSON.stringify(this.form));

      formCopy.val = parseInt(formCopy.val);
      this.load = true;
      let url = '/api/reports';

      let method = 'post';
      let mode = 'new';
      if (formCopy['@id']) {
        url = formCopy['@id'];
        method = 'put';
        mode = 'update';
      }

      let cards = [];
      formCopy.cards.forEach((item) => {
        cards.push(item['@id']);
      });
      formCopy.cards = cards;
      return http[method](url, formCopy).then((res) => {
        // Sauvegarde
        this.$emit('saved', res.data);
        this.$emit(mode, res.data);
        return res;
      });
    },
    /**
    * @vuese
    * Suppression du rapport
    */
    removeReport() {
      return this.$bvModal.msgBoxConfirm('Supprimer le rapport.', {
        title: 'Veuillez confirmer',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Oui',
        cancelTitle: 'Non',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then(value => {
            if (value) {
              http.delete(this.item['@id']).then((res) => {
                // Suppression
                this.$emit('deleted', res.data);
                return res;
              });
            }
          });
    }
  },
  created() {
    this.form = this.item;
  }
};
</script>
