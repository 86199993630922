<template>
  <b-container class="bread-crumbs">
    <app-bread-crumbs
        :routes="[
        { name: 'login', title: 'Connexion / Inscription' },
        { name: 'myAccountPage', title: 'Mon Compte' },
      ]"
    >
    </app-bread-crumbs>
    <app-show-my-account/>
  </b-container>
</template>

<script>
import AppShowMyAccount from '/src/components/appShowMyAccount.vue';

/**
 * @vuese
 * Page de son compte utilisateur
 * @group PAGES
 */
export default {
  name: 'UserPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Mon compte',
      meta: [
        {
          name: 'description',
          content:
              'Retrouvez ici toutes les informations liées à votre compte: nom, prénom, mail...'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Mon compte'
        }
      ]
    };
  },
  components: {
    AppShowMyAccount
  }
};
</script>
