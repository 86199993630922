import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters('ReportStore', [
      'report',
      'reportPreview',
      'somme',
      'potentiel',
      'objectif',
      'effort',
      'selectedCardsCalculate'
    ])
  }
};
