<template>
  <b-container class="mt-4">
    <b-row class="form-contactPage">
      <b-col lg="3">
        <img class="illu-contactPage" src="/img/illu_02_contact.svg" alt=""/>
      </b-col>
      <b-col v-if="!sended">
        <b-form @submit.prevent="contact(form)" class="form-contactPage">
          <b-col>
            <b-form-group label="Nom" label-for="lastname" class="mt-2">
              <b-form-input
                  v-model="form.lastname"
                  autofocus
                  required
                  id="lastname"
                  minlength="2"
              />
            </b-form-group>

            <b-form-group label="Prénom" label-for="firstname" class="mt-2">
              <b-form-input v-model="form.firstname" required id="firstname" minlength="2"/>
            </b-form-group>

            <b-form-group label="Email" label-for="email" class="mt-2">
              <b-form-input
                  v-model="form.email"
                  type="email"
                  required
                  title="L'adresse mail doit contenir une .com, .fr etc. à la fin"
                  pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                  id="email"
              />
            </b-form-group>

            <b-form-group
                label="Numéro de téléphone"
                label-for="phone"
                class="mt-2"
            >
              <b-form-input
                  type="tel"
                  v-model="form.phone"
                  id="phone"
                  required
                  title="Le numéro de tél. doit être au format: 06 01 02 03 05 ou +33 6 01 02 03 04 05 ou 003360102030405"
                  pattern="^(?:0|\(?\+33\)?\s?|0033\s?)[1-79](?:[\.\-\s]?\d\d){4}$"
                  class="input"
              />
            </b-form-group>

            <b-form-group label="Structure" label-for="structure" class="mt-2">
              <b-form-input v-model="form.structure" required id="structure" minlength="2"/>
            </b-form-group>
          </b-col>

          <b-col class="message-contactPage">
            <b-form-group label="Message" label-for="message" class="mt-2">
              <b-form-textarea
                  v-model="form.message"
                  id="message"
                  class="input"
                  required
                  no-resize
                  rows="8"
              />
            </b-form-group>

            <b-form-group
                class="
                consent-contactPage
                d-flex
                flex-column
                justify-content-center
              "
            >
              <b-form-checkbox-group required>
                <b-form-checkbox
                    name="notRobotValidation"
                    v-model="form.notRobotValidation"
                    required
                >
                  Je ne suis pas un robot
                </b-form-checkbox>
              </b-form-checkbox-group>
              <b-form-checkbox-group required>
                <b-form-checkbox
                    name="infoAcceptation"
                    v-model="form.infoAcceptation"
                    required
                >
                  En cochant cette case, j'accepte que Novabuild et DRO
                  récupèrent mes informations.
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>

            <b-form-group>
              <Captcha
                  :sitekey="sitekey"
                  :loadRecaptchaScript="true"
                  ref="recaptcha"
                  @verify="onCaptchaVerified"
                  @expired="onCaptchaExpired"
                  size="invisible"
              >
              </Captcha>
            </b-form-group>
            <b-button
                type="submit"
                :variant="load ? 'link' : 'basic'"
                class="btn-send w-100"
                size="lg"
            >
              <span v-if="!load" class="uppercase">
                <b-icon-arrow-right aria-hidden="true"/> envoyer
              </span>
            </b-button>
          </b-col>
        </b-form>
      </b-col>
      <b-col v-else>
        <h3>Votre message a bien été envoyé.</h3>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import appContactFormMixin from '@/components/dyn/appContactFormMixin';

/**
* @vuese
* Formulaire de contact de la page "Contact"
*/
export default {
  name: 'FormPageContact',
  mixins: [appContactFormMixin]
};
</script>

<style scoped lang="scss">
.form-contactPage {
  background-color: var(--background-color--transparent) !important;
  display: flex;
  position: relative;
  z-index: 10;
}

.formContact {
  flex-wrap: wrap;
  justify-content: space-around;
}

.message-contactPage {
  margin-left: 20px;
  height: 34.38rem !important;
}

.message-contactPage textarea {
  height: 52%;
}

@media screen and (max-width: 849px) {
  .form-contactPage {
    display: block;
  }
}

.consent-contactPage {
  height: 14%;
  margin-top: 20px;
  margin-bottom: 1rem;
}

.btn-send {
  height: 14%;
}

.illu-contactPage {
  height: auto;
  width: 100%;
  @media screen and (max-width: 991px) {
    display: none;
  }
}
</style>
