<template>
  <b-form @submit.prevent="save()">
    <b-overlay :show="load">
      <div v-if="!hideHeader">
        <b-alert show variant="light">
          <b-row>
            <b-col offset="3">
              <div>
                <span>id : {{ form.uid | def('-') }}</span>
              </div>
              <div>
                <span
                >slug :
                  <router-link
                      :to="{
                      name: 'PagePage',
                      params: {
                        slug: form.slug,
                      },
                    }"
                  >{{ form.slug }}</router-link
                  ><btn-clip class="ml-1" :text="form.slug"
                  /></span>
                <div>
                  <router-link
                      :to="{
                      name: 'PagePage',
                      params: {
                        slug: form.slug,
                      },
                    }"
                      target="blank"
                  >
                    <b-icon-link/>
                    Accéder à la page
                  </router-link
                  >
                </div>
              </div>
            </b-col>
            <b-col class="text-right">
              <div>maj : {{ form.updatedAt | datetime | def('') }}</div>
              <b-btn
                  v-if="form['@id']"
                  @click.prevent="removePageCustom"
                  variant="danger"
                  title="Supprimer le témoignage"
                  class="mr-2"
              >
                <b-icon-trash/>
              </b-btn>
            </b-col>
          </b-row>
        </b-alert>
      </div>

      <b-form-group label="Titre :" label-for="title">
        <b-input v-model="form.title" id="title" class="mt-2"/>
      </b-form-group>
      <b-form-group label="Contenu :" label-for="content">
        <input-html
            v-model="form.content"
            id="content"
            class="mt-2 page-edition"
        />
      </b-form-group>

      <div
          v-if="!hideFooter"
          class="d-flex justify-content-center align-items-center mt-2"
      >
        <b-button type="submit" :variant="load ? 'link' : 'dark'" size="lg">
          <span v-if="!load">Enregistrer</span>
          <span v-else><b-spinner small/></span>
        </b-button>
      </div>
    </b-overlay>
  </b-form>
</template>

<script>
import {http} from '@/http';

/**
* @vuese
* Formulaire de création/modification d'une page custom
*/
export default {
  name: 'FormPageCustom',
  props: {
    id: {
      type: [String, Boolean],
      default: () => false
    },
    redirect: {
      type: Boolean,
      default: () => true
    },
    hideFooter: {
      type: Boolean,
      default: () => false
    },
    hideHeader: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      load: false,
      form: {
        lastName: '',
        firstName: '',
        content: ''
      }
    };
  },
  methods: {
    save() {
      this.load = true;

      let url = '/api/pages';
      let method = 'post';
      let mode = 'new';
      if (this.form.uid) {
        url = this.form['@id'];
        method = 'put';
        mode = 'update';
      }

      return http[method](url, this.form).then((res) => {
        this.load = false;
        this.$emit('saved', res.data);
        this.form = res.data;
        if (mode === 'new' && this.redirect) {
          this.$router.push('/admin/page/' + res.data.uid);
        }
        return res;
      });
    },
    getPageCustom() {
      this.load = true;
      return http.get('/api/pages/' + this.id, this.form).then((res) => {
        this.form = res.data;
        this.load = false;
        return res;
      });
    },
    removePageCustom() {
      return this.$bvModal
          .msgBoxConfirm('Supprimer la page.', {
            title: 'Veuillez confirmer',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Oui',
            cancelTitle: 'Non',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
          .then((value) => {
            if (value) {
              return http.delete(this.form['@id']).then((res) => {
                this.$emit('deleted', this.form);
                this.$router.push({
                  name: 'adminPageIndex'
                });
                return res;
              });
            }
          });
    }
  },
  created() {
    if (this.id) {
      this.getPageCustom();
    }
  }
};
</script>
