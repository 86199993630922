<template>
  <b-container>
    <app-bread-crumbs
        :routes="[
        { name: 'home', title: 'Accueil' },
        { name: 'reportNew', title: 'Rapport' },
        { name: 'exemplary', title: 'Ouvrage exemplaire' },
      ]"
    />
    <h1 class="my-2">{{ exemplary.title }}</h1>
    <div
        v-for="(imgPrimary, kimgPrimary) in exemplary.imgPrimary"
        :key="kimgPrimary"
    >
      <a v-if="exemplary.link" :href="exemplary.link" target="_blank">
        <img-media
            :src="imgPrimary.src"
            :alt="imgPrimary.alt"
            class="image--exemplary"
        >
        </img-media>
      </a>
    </div>
    <b-row class="subtitle-img">
      <span>
        <strong>Maître d'ouvrage : </strong> {{ exemplary.projectOwner }}</span
      >
      <span> <strong>Architecte : </strong> {{ exemplary.architect }}</span>
      <span>
        <strong>Crédit illustration : </strong> © {{ exemplary.credit }}</span
      >
    </b-row>

    <p v-html="exemplary.content" class="content"></p>
    <a
        v-if="exemplary.link"
        :href="exemplary.link"
        target="_blank"
        class="btn btn-basic px-5"
    >
      <b-icon-link aria-hidden="true"/>
      Lien associé</a
    >
    <b-row v-if="exemplary.img.length > 0">
      <b-col
          col
          sm="12"
          md="3"
          v-for="(img, kimg) in exemplary.img"
          :key="'B' + kimg"
      >
        <div>
          <img-media :src="img.src" class="images mt-5" :alt="img.alt">
          </img-media>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {mapActions} from 'vuex';
import {http} from '@/http';

export default {
  name: 'exemplaryPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Ouvrage exemplaire',
      meta: [
        {
          name: 'description',
          content: 'Pour en savoir plus sur un ouvrage exemplaire.'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Ouvrage exemplaire'
        }
      ]
    };
  },
  data() {
    return {
      filters: {},
      exemplary: {}
    };
  },
  methods: {
    ...mapActions('ReportStore', ['clearCards', 'addReportCard', 'setFilters']),
    /**
     * Récupération des informations de l'ouvrage exemplaire
     */
    getExemplary() {
      http.get('/api/exemplaries/' + this.$route.params.id).then((res) => {
        this.exemplary = res.data;
        return res;
      });
    }
  },
  created() {
    this.getExemplary();
  }
};
</script>

<style scoped>
.image--exemplary {
  height: 40vh;
  width: auto;
}

.subtitle-img span {
  margin: 10px 4px 0 0;
}
</style>
