<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <app-bread-crumbs
            :routes="[
            { name: 'adminHome', title: 'Administration' },
            { name: 'adminParams', title: 'Classement' },
            { name: 'adminTestimonyEditPage', title: 'Nouveau témoignage' },
          ]"
        >
        </app-bread-crumbs>
        <h1 class="page-title">
          <b-btn :to="{ name: 'adminParams' }" variant="link" title="Retour" aria-label="Retour à la page listant les témoignages"
          >
            <b-icon-arrow-left aria-hidden="true"/>
          </b-btn>
          Témoignage
        </h1>
        <div>
          <b-container fluid>
            <b-row>
              <b-col>
                <form-testimony :id="$route.params.id" @saved="onSaved"/>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import formTestimony from '@/components/formTestimony';

/**
 * @vuese
 * Page de création d'un témoignage
 * @group PAGES_ADMIN
 */
export default {
  name: 'AdminTestimonyNewPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Nouveau témoignage',
      meta: [
        {
          name: 'description',
          content: 'Création d\'un témoignage.'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Nouveau témoignage'
        }
      ]
    };
  },
  components: {
    formTestimony
  },
  methods: {
    /**
     * @vuese
     * Sauvegarde d'un nouveau témoignage
     * @arg Les données du témoignage
     */
    onSaved(res) {
      this.$router.push({
        name: 'testimonyIndex',
        params: {
          id: res.data.id
        }
      });
    }
  }
};
</script>