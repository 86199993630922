<template>
  <b-form-group label="Attribuer des Mots-clés :" label-for="checkbox-tags">
    <b-form-checkbox-group
        id="checkbox-tags"
        v-model="value"
        class="ml-1"
        @input="onInput($event, value)"
    >
      <input-checkbox-recursive
          :items="items"
          @input="onInput"
          :autocheck-children="true"
          @checkAllChildren="onCheckAllChildren"
          key-value="@id"
      />
    </b-form-checkbox-group>
  </b-form-group>
</template>

<script>
import {http} from '@/http.js';
import flat from '@/flat.js';
import _ from 'lodash';

/**
* @vuese
* Attribution de mot-clé à une fiche
*/
export default {
  name: 'InputCardTag',
  props: {
    id: {
      type: String,
      default() {
        const rand = Math.random().toString(36).substring(2);
        return 'input-card-tag-' + rand;
      }
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      show: false,
      items: []
    };
  },
  methods: {
    getList() {
      let url = '/tags/all';
      return http.get(url).then((res) => {
        this.items = res.data['hydra:member'];
        this.load = false;
        this.focusIdx = 0;
        return res;
      });
    },
    onCheckAllChildren(ev, value) {
      ev.preventDefault();
      const target = ev.target;

      let checkStatus = target.getAttribute('data-checked') ?? 'false';

      value = _.map(flat.flat(value, 'children'), (item) => item['@id']);
      let merged = [];
      if (checkStatus === 'true') {
        merged = _.difference(this.value, value);
        checkStatus = 'false';
      } else {
        checkStatus = 'true';
        merged = merged.concat(value, this.value);
      }
      target.setAttribute('data-checked', checkStatus);
      this.onInput(merged);
    },
    onInput(value) {
      this.$emit('input', value);
    }
  },
  created() {
    this.getList();
  }
};
</script>
