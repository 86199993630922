<template>
  <span>
    <b-btn
        @click="newItem"
        v-b-modal="'category-edit-' + id"
        :size="size"
        class="mt-2 mr-2"
        variant="dark"
        title="Nouveau type d'élément"
        aria-label="Nouveau type d'élément"
    ><b-icon-plus aria-hidden="true"/><slot></slot
    ></b-btn>
    <b-modal
        :id="'category-edit-' + id"
        title="Type d'élément"
        no-fade
        size="lg"
        scrollable
        hide-footer
    >
      <form-admin-category
          :category="category"
          @saved="onSaved"
          @new="onNew"
          @update="onUpdate"
      />
    </b-modal>
  </span>
</template>

<script>
import formAdminCategory from './formAdminCategory.vue';

/**
* @vuese
* Bouton d'édition d'un type d'élément
* @group COMPONENTS_ADMIN
*/
export default {
  name: 'BtnAdminCategoryEdit',
  components: {
    formAdminCategory
  },
  data() {
    return {
      item: {}
    };
  },
  props: {
    // Un type d'élément
    category: {
      type: Object,
      default() {
        return {
          name: ''
        };
      }
    },
    // Type de bouton
    variant: {
      type: String,
      default() {
        return 'link';
      }
    },
    // Identifiant d'un type d'élément
    id: {
      type: String,
      default() {
        const rand = Math.random().toString(36).substring(2);
        return '' + rand;
      }
    },
    // Taille du bouton
    size: {
      type: String,
      default: () => 'md'
    }
  },
  methods: {
    /**
     * @vuese
     * Modification d'un type d'élément
     */
    openItem() {
      // Ouverture de la fenêtre modale
      this.$root.$emit('bv::show::modal', 'category-edit-' + this.id);
    },
    /**
     * @vuese
     * Nouveau mot-clé
     */
    newItem() {
      this.category = {
        name: ''
      };
    },
    /**
     * @vuese
     * Sauvegarde d'un type d'élément
     * @arg La catégorie de mot-clé
     */
    onSaved(item) {
      // Sauvegarde d'un type d'élément
      // @arg La catégorie de mot-clé
      this.$emit('saved', item);
      // Fermeture de la fenêtre modale
      this.$root.$emit('bv::hide::modal', 'category-edit-' + this.id);
    },
    /**
     * @vuese
     * Mise à jour d'un type d'élément
     * @arg La catégorie de mot-clé
     */
    onUpdate(item) {
      // Mise à jour d'un type d'élément
      // @arg La catégorie de mot-clé
      this.$emit('update', item);
    },
    /**
     * @vuese
     * Nouvelle mot-clé
     * @arg La catégorie de mot-clé
     */
    onNew(item) {
      // Nouvell type d'élément
      // @arg La catégorie de mot-clé
      this.$emit('new', item);
    }
  }
};
</script>
