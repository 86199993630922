<template>
  <b-container>
    <nav
        class="
        fiche-btns
        flex
        justify-content-around
        align-content-between
        flex-wrap
        flex-gap
      "
    >
      <b-btn @click="downloadCard(card)" variant="basic" title="Télécharger" class="min-w">
        <b-icon-download aria-hidden="true"/>
        Télécharger
      </b-btn>
      <btn-add-report-card-step-page
          v-if="lastPageVisited === 'reportNew'"
          size="sm"
          class="check-square addCard"
          :card="card"
          @saved="onSaved"
      />
      <b-btn
          :to="{
          name: 'reportNew',
        }"
          variant="basic"
          title="Vers mon parcours"
          class="min-w"
      >
        <b-icon-arrow-right aria-hidden="true"/>
        Vers mon parcours
      </b-btn>
    </nav>
    <b-row class="mt-4 value--small">
      <b-col
          cols="4"
          class="value d-flex align-items-center justify-content-center flex-wrap"
      >
        <div
            class="d-flex align-items-center justify-content-center"
            v-if="card.val !== 0"
        >
          <span class="number-size mr-1">{{ card.val }}</span>
          <aside class="unit-info">
            <ul class="unit-info--ul">
              <li>kgCO2e</li>
              <li>par personne</li>
              <li>par an</li>
            </ul>
          </aside>
        </div>
        <div
            v-else
            class="
            card-value card-value--empty
            mt-1
            d-flex
            justify-content-center
            align-items-center
          "
        >
          Condition nécessaire
        </div>
      </b-col>
      <b-col class="title-ficheContainer" cols="8">
        <h2
            class="title-fiche d-flex align-items-center justify-content-center"
        >
          {{ card.name }}
        </h2>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col class="image-ficheContainer" cols="4">
        <b-img class="image-fiche" src="/img/illu_etape_html.png" alt="#"/>
      </b-col>
      <b-col
          cols="8"
          class="description-fiche d-flex flex-column align-items-center"
          v-if="card.description"
      >
        <p v-html="card.description" class="text-description"></p>
      </b-col>
    </b-row>
    <b-row
        v-if="card.pictures && card.pictures.length > 0"
        class="repere-fiche mt-4 position-relative"
    >
      <h3 class="mb-3 repere-title position-absolute">REPÈRES</h3>
      <main class="mb-3 d-flex flex-wrap justify-content-around">
        <div
            v-for="(picture, kpicture) in card.pictures"
            :key="kpicture"
            class="mt-4"
        >
          <img-media
              :alt="picture.alt"
              :src="picture.src"
              class="repere-image"
          />
          <p v-html="picture.meta"></p>
        </div>
      </main>
    </b-row>
    <b-card v-if="card.action" class="mt-4">
      <h4 class="ta-center subtitle-fiche">
        <b-icon-gear-fill aria-hidden="true"/>&nbsp;ACTIONS
      </h4>
      <hr/>
      <p v-html="card.action"></p>
    </b-card>

    <b-card v-if="card.descriptionGoFurther" class="mt-4">
      <h4 class="ta-center subtitle-fiche">
        <b-icon-binoculars-fill aria-hidden="true"/>&nbsp;ALLER PLUS LOIN
      </h4>
      <hr/>
      <p v-html="card.descriptionGoFurther"></p>
    </b-card>

    <b-row v-if="card.exemplaries && card.exemplaries.length > 0" class="mt-5">
      <h3 class="subtitle-fiche--ilsLontFait">ILS L'ONT FAIT</h3>
      <b-row
          class="d-flex flex-wrap justify-content-around align-items-baseline"
      >
        <b-col
            lg="4"
            md="6"
            sm="6"
            v-for="(exemplary, kexemplary) in showExemplary()"
            :key="kexemplary"
            :value="exemplary.uid"
            class="exemplary-mobile flex flex-column align-items-center mb-4"
        >
          <h5 class="exemplary-title mt-4">{{ exemplary.title }}</h5>
          <router-link :to="'/ouvrage-exemplaire/' + exemplary.uid" class="exemplary-link">
            <img-media
                v-for="(imgPrimary, kimgPrimary) in exemplary.imgPrimary"
                :key="kimgPrimary"
                :src="imgPrimary.src"
                class="imageExemplary mb-4"
                :alt="imgPrimary.alt"
            />
          </router-link>
          <router-link
              :to="'/ouvrage-exemplaire/' + exemplary.uid"
              class="h2 mt-0 mb-0 d-flex justify-content-center"
          >
            <b-button
                class="btn-basic btn-basic--exemplary"
                :variant="load ? 'link' : 'primary'"
            >
              <b-icon-eye-fill aria-hidden="true"/>
              VOIR L'OUVRAGE
            </b-button>
          </router-link>
        </b-col>
      </b-row>
    </b-row>
    <b-row
        v-if="
        card.cardFormat.slug === 'indirect' &&
        getCardsFiltred(card.children).length > 0
      "
        class="mt-5"
    >
      <b-col sm="12">
        <h3 class="mb-4 subtitle-fiche--etapesAssociees">ÉTAPES ASSOCIÉES</h3>
      </b-col>
      <b-col
          lg="4"
          md="6"
          sm="6"
          v-for="(children, kchildren) in getCardsFiltred(card.children)"
          :key="kchildren"
      >
        <app-card :card="children" :edit="false" class="mb-3 d-flex"/>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <p class="mt-2">
        Version :&nbsp;{{ card.version }}&nbsp;||&nbsp;Créée le
        {{ card.createdAt | date('m/d/Y') }}
      </p>
      <div class="w-100 flex justify-content-center">
        <b-btn @click="downloadCard(card)" variant="basic" title="Télécharger">
          <b-icon-download aria-hidden="true"/>
          Télécharger
        </b-btn>
      </div>
    </b-row>
  </b-container>
</template>

<script>
import {mapActions} from 'vuex';
import {http} from '@/http';
import _ from 'lodash';
import imgMedia from './imgMedia.vue';
import appCard from '@/components/appCard';
import BtnAddReportCardStepPage from './btnAddReportCardStepPage.vue';

/**
* @vuese
* Une fiche climat directe
*/
export default {
  name: 'AppCardDirect',
  components: {
    imgMedia,
    appCard,
    BtnAddReportCardStepPage
  },
  props: {
    // Mode du bouton: téléchargement
    download: {
      type: Boolean,
      default() {
        return false;
      }
    },
    // Fiche climat sélectionnée ?
    select: {
      type: Boolean,
      default() {
        return false;
      }
    },
    cardCategory: String
  },
  data() {
    return {
      lastPageVisited: localStorage.getItem("lastPageVisited"),
      pdfUrl: process.env.VUE_APP_API_URL + '/pdf/cards',
      card: {}
    };
  },
  methods: {
    ...mapActions('UiStore', [
      'loaderOn',
      'loaderVariant',
      'loaderMessage',
      'loaderVariant',
      'loaderOff'
    ]),
    /**
     * @vuese
     * Ouverture de la fiche
     */
    openCard(card) {
      // @arg La fiche climat
      this.$emit('open', card);
    },
    /**
     * @vuese
     * Récupération de la liste des étapes
     */
    getCard() {
      http.get('/api/cards/' + this.$route.params.id).then((res) => {
        this.card = res.data;
        return res;
      });
    },
    /**
     * @vuese
     * Sauvegarde de la fiche
     */
    onSaved(card) {
      // @arg La fiche climat
      this.$emit('saved', card);
    },
    /**
     * @vuese
     * Affichage des ouvrages exemplaires liés à la fiche
     */
    showExemplary() {
      return this.card.exemplaries;
    },
    /**
     * @vuese
     * Mise à jour des fiches affichés
     */
    onCardSaved() {
      this.$refs.cardsFilters.searchCardsFiltred();
    },
    /**
     * @vuese
     * Téléchargement de l'étape au format pdf
     * @arg La fiche climat
     * @returns {Promise<AxiosResponse<any>>}
     */
    downloadCard(card) {
      this.loaderOn('Génération du PDF');
      this.loaderVariant('info');

      let url = '/pdf/cards/' + card.uid;
      return http
          .get(url, {
            responseType: 'blob'
          })
          .then((res) => {
            this.loaderOff();

            const blob = new Blob([res.data], {type: 'application/pdf'});
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = card.name + '--' + card.idxFull + '.pdf';
            link.click();
            return res;
          })
          .catch(() => {
            this.loaderOff();
          });
    },
    getCardsFiltred(cards) {
      return _.filter(cards, (card) => {
        if (card.cardFormat !== undefined) {
          return card.cardFormat.slug !== 'completude' && !card.draft;
        }
        return false;
      });
    }
  },
  created() {
    this.getCard();
  }
};
</script>

<style lang="scss" scoped>
  ol li {
    list-style-type: disc;
  }

  .card-render {
    margin-top: 100px;
  }

  .card-render h3 {
    margin-top: 50px;
  }

  .category-name {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: inline;
    width: calc(100% / 6);
  }

  .category-name li {
    color: #cf5c78;
  }

  .value {
    background-color: #329db1;
    color: #fff;
    padding: 0.5rem;

    &--small {
      @media screen and (max-width: 425px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .text-description {
    margin-left: 10px;
    hyphens: none;
  }

  .repere-title {
    background-color: var(--background-color);
    top: -30px;
    width: fit-content;
    padding-left: 0.5rem;
    font-size: 1.5rem !important;
  }

  .repere-image {
    width: 100%;
    height: auto;
  }

  .repere-card {
    @media screen and (max-width: 768px) {
      margin-top: 1.5rem;
    }
  }

  .exemplary-link {
    width: 100%;
  }

  .exemplary-mobile {
    @media screen and (max-width: 576px) {
      width: 60% !important;
    }
  }

  .btn-basic {
    @media screen and (max-width: 576px) {
      &--exemplary {
        width: 100%;
      }
    }
  }

  .selection-card {
    margin-top: 50px;
  }

  .fiche-btns {
    height: auto;
  }

  .title-ficheContainer {
    @media screen and (max-width: 575px) {
      height: 53.5px;
    }
  }

  .title-fiche {
    background-color: #fded00;
    color: #282828;
    text-align: center;
    margin: 0 !important;
    height: 100%;
    padding: 0.5rem;
    @media screen and (max-width: 575px) {
      font-size: 16px !important;
    }
  }

  .subtitle-fiche {
    font-size: 1.5rem !important;

    &--ilsLontFait {
      border-bottom: 1px solid #c9c9c9;
      padding-bottom: 0.25rem;
    }

    &--etapesAssociees {
      border-bottom: 1px solid #c9c9c9;
      padding-bottom: 0.25rem;
    }
  }

  .image-ficheContainer {
    text-align: center;
  }

  .image-fiche {
    height: 200px;
    width: auto;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .description-fiche {
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .illu-fiche {
    margin: 1rem 0;
    height: 30vh;
    width: auto;
  }

  .repere-fiche {
    border: solid 1px var(--text-color);
    border-radius: 5px;
    margin-right: 0;
    margin-left: 0;
  }

  .number-size {
    font-size: 45px !important;

    @media screen and (max-width: 575px) {
      font-size: 25px !important;
    }
  }

  .exemplary-title {
    height: 50px;
    text-align: center;
    @media screen and (max-width: 575px) {
      width: 85vw;
    }
    @media screen and (max-width: 347px) {
      width: 300px;
    }
  }

  /* =====
  = TAGS =
  ======*/

  .card-tags {
    page-break-inside: avoid;
    margin: 20px 0;
    padding: 0;
  }

  .card-tag {
    color: var(--secondary-color);
  }
</style>
