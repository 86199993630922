<template>
  <b-container class="mb-4">
    <b-row class="flex mt-4">
      <b-col sm="12" lg="3" class="backg-A">
        <header class="d-flex align-items-center justify-content-evenly">
          <h3>A</h3>
          <p>J'évalue mes priorités</p>
        </header>
        <b-img
            src="/img/illu_etape_A.svg"
            alt="étape a: j'évalue mes priorités"
        />
      </b-col>
      <b-col class="more">
        <b-icon icon="plus" aria-hidden="true"/>
      </b-col>
      <b-col sm="12" lg="3" class="backg-B">
        <header class="d-flex align-items-center justify-content-evenly">
          <h3>B</h3>
          <p>Je les sélectionne</p>
        </header>
        <b-img src="/img/illu_etape_B.svg" alt="étape B: Je les sélectionne"/>
      </b-col>
      <b-col class="more">
        <b-icon icon="plus" aria-hidden="true"/>
      </b-col>
      <b-col sm="12" lg="3" class="backg-C">
        <header class="d-flex align-items-center justify-content-evenly">
          <h3>C</h3>
          <p>Je reçois mon rapport</p>
        </header>
        <b-img
            src="/img/illu_etape_C.svg"
            alt="étape C: Je reçois mon rapport"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

/**
* @vuese
* Bandeau des trois étapes d'utilisation de la plateforme, version admin
* @group COMPONENTS_ADMIN
*/
export default {
  name: 'AppAdminWhoAreWe'
};
</script>

<style scoped lang="scss">
  .backg-A,
  .backg-B,
  .backg-C {
    color: var(--primary-color);
    background: var(--background-color);
    width: auto;
    text-align: center;
    border: 1px solid #8f8f8f;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.16);
  }

  .backg-A p,
  .backg-B p,
  .backg-C p {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
  }

  .backg-A img,
  .backg-B img,
  .backg-C img {
    width: 250px;
    height: 250px;
  }

  @media screen and (max-width: 1100px) {
    .backg-A img,
    .backg-B img,
    .backg-C img {
      width: 200px;
      height: 200px;
    }
  }

  .backg-A h3,
  .backg-B h3,
  .backg-C h3 {
    font-weight: bold;
    font-size: 50px !important;
    text-align: left;
  }

  .more {
    display: flex;
    font-size: 100px;
    color: var(--primary-color);
    align-items: center;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
  }
</style>
