var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-menu app-menu-admin",attrs:{"role":"navigation"}},[_c('b-navbar',{attrs:{"id":"navbar-admin","toggleable":"lg","type":"light"}},[_c('b-navbar-toggle',{attrs:{"id":"navbar-toggler-admin","target":"nav-collapse"}}),_c('b-collapse',{staticClass:"nav-center",attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',[_c('b-nav-item',{staticClass:"d-flex flex-column align-items-center justify-content-center",attrs:{"to":{
            name: 'adminHome',
          },"aria-label":"Tableau de bord"}},[_c('b-img',{staticClass:"logo-menu",attrs:{"src":"/img/logo_abc.svg","alt":"Logo action bas carbone"}})],1),_c('b-nav-item',{staticClass:"links menu-item",attrs:{"to":{ name: 'adminCardsIndex' }}},[_vm._v("Étapes Climats ")]),_c('b-nav-item',{staticClass:"links menu-item",attrs:{"to":{ name: 'adminExemplaryIndex' }}},[_vm._v("Ouvrages exemplaires ")]),_c('b-nav-item',{staticClass:"links menu-item",attrs:{"to":{ name: 'adminPageIndex' }}},[_vm._v("Pages ")]),_c('b-nav-item',{staticClass:"links menu-item",attrs:{"to":{ name: 'adminParams' }}},[_vm._v("Classement ")]),_c('b-nav-item',{staticClass:"links menu-item",attrs:{"to":{ name: 'adminUsers' }}},[_vm._v("Gestion de site ")])],1),_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item',{staticClass:"menu-item ml-25",attrs:{"to":{
            name: 'myAccountPage',
          }}},[_c('b-icon',{attrs:{"icon":"person-circle","aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.user.firstName.substring(0, 1))+". "+_vm._s(_vm.user.lastName)+" ")],1),_c('b-nav-item',{staticClass:"ml-25",attrs:{"to":{
            name: 'logout',
          },"title":"Déconnexion"}},[_c('b-icon',{attrs:{"icon":"box-arrow-right","aria-hidden":"true"}})],1)],1)],1)],1),_c('b-button',{staticClass:"btn-toggle-editMode",attrs:{"title":"Passer en mode édition de contenu","aria-label":"Passer en mode édition de contenu","variant":_vm.editMode ? 'dark' : 'link'},on:{"click":function($event){$event.preventDefault();return _vm.toggleEditMode.apply(null, arguments)}}},[(_vm.editMode)?_c('small',[_vm._v("Mode édition ")]):_vm._e(),_c('b-icon',{attrs:{"icon":"pencil","aria-hidden":"true"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }