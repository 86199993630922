import {mapGetters, mapActions} from 'vuex';
import AppAdminCard from '@/components/Admin/appAdminCard';
import appReportPreview from '@/components/appReportPreview';
import appAbcStep from '@/components/appAbcStep';

import _ from 'lodash';
import AppAdminWhoAreWe from '@/components/Admin/appAdminWhoAreWe.vue';
import AppCardsFilters from '@/components/appCardsFilters.vue';

export default {
  name: 'AdminRapportPage',
  components: {
    appReportPreview,
    AppAdminCard,
    appAbcStep,
    AppAdminWhoAreWe,
    AppCardsFilters
  },
  computed: {
    ...mapGetters('UserStore', ['isAdmin']),
    ...mapGetters('ReportStore', [
      'report',
      'selected',
      'reportPreview',
      'filtersPreview',
      'hasA',
      'hasB',
      'hasC'
    ]),
    /**
     * Retourne les noms des filtres
     */
    filtersName: {
      get() {
        return this.$store.getters['CardStore/filtersName'];
      },
      set(value) {
        return this.setFilter({
          key: 'filtersName',
          value
        });
      }
    },
    /**
     * Classe les étapes par catégories
     * @returns {{}}
     */
    cardsByCategories() {
      const cardCategories = {};
      _.each(this.cardsFiltred, (item) => {
        if (item.cardCategory !== undefined && item.cardFormat.name !== 'completude') {
          let catName = item.cardCategory.name;
          if (cardCategories[catName] === undefined) {
            cardCategories[catName] = [];
          }
          cardCategories[catName].push(item);
        }
      });
      return cardCategories;
    },
    /**
     * Retourne les étapes de "complétude"
     * @returns {T[]}
     */
     cardsCompletude() {
      return _.compact(
          _.map(this.cardsFiltred, (item) => {
            if (item.cardFormat.name === 'completude') {
              return item;
            }
          })
      );
    },
    /**
     * Retourne le nom des catégories des étapes, de façon unique
     * @returns {*[]}
     */
    cardsFiltredCategories() {
      return _.sortBy(
          _.uniq(
              _.map(this.cardsFiltred, (item) => {
                return item?.cardCategory?.name;
              })
          )
      );
    }
  },
  data() {
    return {
      loader: false,
      timeout: '',
      cardsFiltred: [],
      cardsSelected: []
    };
  },
  methods: {
    ...mapActions('CardStore', ['setFilter']),
    ...mapActions('ReportStore', [
      'downloadPdf',
      'openReport',
      'addReportCard',
      'removeReportCard',
      'generateFromData',
      'setFilters'
    ]),
    onCardSaved() {
      this.$refs.cardsFilters.searchCardsFiltred();
    },
    /**
     * @vuese
     * Mise à jour des fiches filtrées
     * @arg Représente les données récupérées et filtrées
     */
    onFiltred(dataFiltred) {
      this.cardsFiltred = dataFiltred.cardsFiltred;
      this.setFilters(dataFiltred.filters);
      this.setFilters(dataFiltred.filters);
    },
    onLoader(loader) {
      this.loader = loader;
    },
    inCardsList(cardCheck) {
      return _.find(this.selected, (card) => card.id === cardCheck.id);
    },
    inLoadCard(cardUid) {
      return _.find(this.loadCards, (loadCard) => loadCard === cardUid);
    },
    addCard(card) {
      this.addReportCard(card);
    },
    openCard(card) {
      this.$emit('open', card);
    },
    onGenerateFromData() {
      this.generateFromData().then((res) => {
        this.downloadPdf();
        return res;
      });
    },
    checkAllFiltred() {
      _.forEach(this.cardsFiltred, (card) => {
        this.addCard(card);
      });
    }
  },
  created() {
    if (this.$route.name === 'adminReportEditPage') {
      this.openReport({
        '@id': '/api/reports/' + this.$route.params.id
      });
    }
  }
};
