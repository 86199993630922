<!-- FILTRE DESKTOP -->
<template>
  <div class="filter position-relative">
    <div class="d-flex justify-content-center align-items-center">
      <b-btn
          variant="light"
          @click.prevent="toggleCheckAllFilters"
          title="Nettoyer les filtres"
          class="mt-1 mb-3"
          v-if="toggle"
      >
        <b-icon-check2-square aria-hidden="true"/>
        Tout cocher
      </b-btn>
      <b-btn
          v-else
          variant="outline-dark"
          @click.prevent="toggleCheckAllFilters"
          title="Nettoyer les filtres"
          class="mt-1 mb-3"
      >
        <b-icon-check2-square class="muted" aria-hidden="true"/>
        Tout décocher
      </b-btn>
    </div>
    <!-- Filtres uniquement pour les admin -->
    <div v-if="user.isAdmin" class="pl-1 filter-border-top">
      <h2 class="filter-title">Statuts&nbsp;:</h2>
      <b-form-radio-group
          id="radio-sectors"
          v-model.lazy="filtersDraft"
          class="ml-1"
      >
        <b-form-radio value="all">
          <span class="ml-1">Tous</span>
        </b-form-radio>
        <b-form-radio value="0">
          <span class="ml-1">Publié</span>
        </b-form-radio>
        <b-form-radio value="1">
          <span class="ml-1">Brouillon</span>
        </b-form-radio>
      </b-form-radio-group>
    </div>

    <div v-if="isAdminCardsIndexPage && user.isAdmin">
      <h2 class="filter-title pl-1 pt-2">Format&nbsp;:</h2>
      <b-form-checkbox-group
          id="checkbox-sectors-format"
          v-model="filtersFormats"
          class="ml-1"
      >
        <b-form-checkbox value="direct">Direct</b-form-checkbox>
        <b-form-checkbox value="indirect">Indirect</b-form-checkbox>
      </b-form-checkbox-group>
    </div>
    <!-- FIN Filtres uniquement pour les admin -->
    <h2 class="filter-title pl-1">
      Secteurs :
      <button
          @click.stop="onCheckAllFilter($event, 'filtersSectors', sectors)"
          ref="btnCheckAllFilterSector"
          class="btn-checkAllFilter"
      >
        tout cocher <span class="check-all-name">secteurs</span>
      </button>
    </h2>
    <b-form-checkbox-group
        id="checkbox-sectors-sectors"
        v-model="filtersSectors"
        class="ml-1"
    >
      <input-checkbox-recursive
          :items="sectors"
          :value="filters.sectors"
          :parent-check="true"
          @checkAllChildren="onCheckAllChildren"
          :key-filter="'filtersSectors'"
      />
    </b-form-checkbox-group>

    <h2 class="filter-title pl-1">
      Mots-clés&nbsp;:
      <button
          @click.stop="onCheckAllFilter($event, 'filtersTags', tags)"
          class="btn-checkAllFilter"
      >
        tout cocher <span class="check-all-name">mots-clés</span>
      </button>
    </h2>
    <b-form-checkbox-group
        id="checkbox-categories"
        v-model="filtersTags"
        class="ml-1"
    >
      <input-checkbox-recursive
          :items="tags"
          :autocheck-children="true"
          @checkAllChildren="onCheckAllChildren"
          :key-filter="'filtersTags'"
      />
    </b-form-checkbox-group>

    <h2 class="filter-title pl-1">
      Filtres&nbsp;:
      <button
          @click.stop="
          onCheckAllFilter($event, 'filtersCategories', cardsCategories, false)
        "
          class="btn-checkAllFilter"
      >
        tout cocher <span class="check-all-name">filtre</span>
      </button>
    </h2>
    <b-form-checkbox-group
        id="checkbox-categories-type"
        v-model="filtersCategories"
        class="ml-1"
    >
      <b-form-checkbox
          v-for="(category, kcategory) in cardsCategories"
          :key="kcategory"
          :value="category.uid"
      >
        <span>{{ category.name }}</span>
      </b-form-checkbox>
    </b-form-checkbox-group>
    <b-btn
          @click.prevent="filterActive"
          title="Appliquer les filtres"
          class="mt-3 mb-3 w-100 position-sticky button-sticky"
      >
        Mettre à jour les résultats
      </b-btn>
  </div>
</template>

<script>
import appCardsFiltersMixin from '@/components/dyn/appCardsFiltersMixin';
import _ from 'lodash';
import flat from '@/flat.js';

/**
* @vuese
* Les filtres des fiches climats
*/
export default {
  name: 'AppCardsFilters',
  mixins: [appCardsFiltersMixin],
  methods: {
    /**
     * @vuese
     * Active tous les filtres d'une catégorie de filtre
     * @arg ev
     * @arg Valeur à ajouter
     * @arg Identifiant filtre
     * @arg Toggle
     */
    onCheckAllChildren(ev, valueToAdd, keyFilter, toggle = false) {
      const method = toggle === 'true' ? 'addFilter' : 'removeFilter';

      this[method]({
        value: valueToAdd,
        key: keyFilter,
        toggle
      });
    },
    /**
     * @vuese
     * Active tous les filtres disponibles
     * @arg ev
     * @arg Identifiant filtre
     * @arg Valeur
     * @arg Recursive
     */
    onCheckAllFilter(ev, keyFilter, value, recursive = true) {
      ev.preventDefault();
      const target = ev.target;
      let method = 'addFilter';

      let checkStatus = target.getAttribute('data-checked') ?? 'false';

      if (checkStatus === 'true') {
        method = 'removeFilter';
        checkStatus = 'false';
      } else {
        checkStatus = 'true';
      }
      let valueF = [];
      if (recursive) {
        valueF = flat.flat(value, 'children');
      } else {
        valueF = _.map(value, (item) => item.uid);
      }

      this[method]({
        key: keyFilter,
        value: valueF
      });

      target.setAttribute('data-checked', checkStatus);
    },
    filterActive(){
      this.searchCardsFiltred();
    }
  },
};
</script>
