<template>
  <b-container fluid>
    <b-row class="bread-crumbs">
      <b-col>
        <app-bread-crumbs
            :routes="[
            { name: 'adminHome', title: 'Administration' },
            { name: 'adminExemplaryIndex', title: 'Ouvrage exemplaire' },
          ]"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div
            class="
            page-title-container
            d-flex
            align-items-center
            justify-content-between
          "
        >
          <h1 class="page-title">Ouvrages exemplaires</h1>
          <b-btn
              variant="dark"
              size="lg"
              :to="{
              name: 'adminExemplaryNew',
            }"
          >
            <b-icon-plus aria-hidden="true"/>
            Nouveau
          </b-btn>
        </div>
        <b-form
            inline
            @submit.prevent="getList"
            class="d-flex justify-content-start align-items-center p-0 mb-3"
        >
          <b-form-input
              autofocus
              @keypress.enter="getList"
              placeholder="Rechercher"
              required
              size="lg"
              v-model="search.name"
          />
        </b-form>
        <b-overlay :show="load">
          <b-list-group>
            <b-list-group-item
                v-for="(item, kitem) in items"
                :key="kitem"
                button
                @click.prevent="openItem(item)"
                class="d-flex justify-content-center align-items-center"
            >
              <span class="small mr-1">{{ kitem + 1 }}.</span>
              <strong>{{ item.title }}</strong>
              <div class="ml-auto mr-2">
                <app-draft :draft="item.draft" class="mr-2"/>
                <span class="small ml-auto text-muted">
                  {{ item.updatedAt | datetime}}</span>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {http} from '@/http';

/**
 * @vuese
 * Page d'affichage des ouvrages exemplaires
 * @group PAGES_ADMIN
 */
export default {
  name: 'AdminExemplaryIndexPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Ouvrages exemplaires',
      meta: [
        {
          name: 'description',
          content: 'Visualisation des ouvrages exemplaires créés.'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Ouvrages exemplaires'
        }
      ]
    };
  },
  watch: {
    'search.name'() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getList();
      }, 500);
    }
  },
  data() {
    return {
      items: [],
      timeout: '',
      load: false,
      item: {},
      search: {
        name: ''
      },
      pdfUrl: process.env.VUE_APP_API_URL + '/pdf/exemplaries/'
    };
  },
  methods: {
    /**
     * @vuese
     * Récupération de la liste des ouvrages exemplaires
     * @returns {Promise<AxiosResponse<any>>}
     */
    getList() {
      let url = '/api/exemplaries';
      if (this.search.name !== '') {
        url += '?title=' + this.search.name;
      }
      this.load = true;
      return http.get(url).then((res) => {
        this.items = res.data['hydra:member'];
        this.load = false;
        return res;
      });
    },
    /**
     * @vuese
     * Ouvre dans un nouvel onglet un ouvrage exemplaire à éditer
     * @arg Un ouvrage exemplaire
     */
    openItem(item) {
      this.$router.push({
        name: 'adminExemplaryEdit',
        params: {
          id: item.uid
        }
      });
    }
  },
  created() {
    this.getList();
  }
};
</script>