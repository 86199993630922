<template>
  <div class="graphHrztlMob" v-if="reportPreview.cards">
    <b-row class="d-flex justify-content-center align-items-center">
      <slot name="left"></slot>
      <!-- Somme des étapes -->
      <b-col
          class="
          graphHrztlMob-reportSomme
          d-flex
          flex-column
          justify-content-center
        "
      >
        <strong class="graphHrztlMob-title"> Somme des étapes </strong>
        <div class="d-flex justify-content-center align-items-center">
          <span class="number-size">
            <strong>{{ somme | default('somme') }}</strong>
          </span>
          <aside class="unit-info pl-1">
            <ul class="unit-info--ul">
              <li>kgCO2e</li>
              <li>par personne</li>
              <li>par an</li>
            </ul>
          </aside>
        </div>
      </b-col>
      <!-- Objectif 2030 -->
      <b-col
          class="
          graphHrztlMob-reportObjectif
          d-flex
          flex-column
          justify-content-center
        "
      >
        <strong class="graphHrztlMob-title"> Objectif 2030 </strong>
        <div class="d-flex align-items-center">
          <span class="graphHrztlMob-number"
          ><strong>{{ objectif | default('objectif') }}</strong></span
          >
          <aside class="unit-info pl-1">
            <ul class="unit-info--ul unit-info--dark">
              <li>kgCO2e</li>
              <li>par personne</li>
              <li>par an</li>
            </ul>
          </aside>
        </div>
      </b-col>
      <!-- Potentiel de réduction -->
      <b-col
          class="
          graphHrztlMob-reportPotentiel
          d-flex
          flex-column
          align-items-center
          justify-content-center
        "
      >
        <strong class="graphHrztlMob-title"> Potentiel de réduction </strong>
        <div class="d-flex align-items-center">
          <span class="graphHrztlMob-number"
          ><strong>{{ potentiel | default('potentiel') }}</strong></span
          >
          <aside class="unit-info pl-1">
            <ul class="unit-info--ul unit-info--dark">
              <li>kgCO2e</li>
              <li>par personne</li>
              <li>par an</li>
            </ul>
          </aside>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <div
          class="
          report-graph-effort
          c-dark
          l-1
          d-flex
          justify-content-center
          align-items-center
        "
      >
        <strong class="pt-0 mb-0 mt-0 mr-1 l-1"
        >{{ effort | default('effort') }} %</strong
        >
        du potentiel de réduction
      </div>
    </b-row>
  </div>
</template>

<script>
import appReportGraphMixin from './appReportGraphMixin';

/**
* @vuese
* Graphique apparaissant sur le rapport, version horizontal mobile
*/
export default {
  name: 'AppReportGraphHorizontalMobile',
  mixins: [appReportGraphMixin]
};
</script>

<style scoped lang="scss">
  .graphHrztlMob {
    background-color: var(--primary-color);
  }

  //=== GRAPH ===//
  .graphHrztlMob-reportObjectif .graphHrztlMob-title,
  .graphHrztlMob-reportPotentiel .graphHrztlMob-title,
  .graphHrztlMob-reportSomme .graphHrztlMob-title {
    border-bottom: 2px solid #282828;
  }

  .graphHrztlMob-reportSomme {
    background-color: var(--primary-color);
    color: var(--white-color);
    height: 7.5rem;
  }

  .graphHrztlMob-reportSomme .graphHrztlMob-title {
    border-bottom: 2px solid var(--white-color);
  }

  .graphHrztlMob-reportObjectif {
    color: #282828;
    background-color: var(--white-color);
    height: 7.5rem;
  }

  .graphHrztlMob-reportPotentiel {
    background-color: #e2f3f5;
    color: #282828;
    height: 7.5rem;
  }

  .graphHrztlMob-title {
    border-bottom: 2px solid $black;
    margin-bottom: 0.5rem;
  }

  .graphHrztlMob-number {
    font-size: 25px !important;
  }

  @media screen and (max-width: 768px) {
    .graphHrztlMob-number {
      font-size: 20px !important;
    }
  }
</style>