<template>
  <b-container fluid>
    <b-row class="bread-crumbs">
      <b-col>
        <app-bread-crumbs
            :routes="[
            { name: 'adminHome', title: 'Administration' },
            { name: 'adminPageIndex', title: 'Gestion des pages' },
          ]"
        />
        <div
            class="
            page-title-container
            d-flex
            align-items-center
            justify-content-between
          "
        >
          <h1 class="page-title">Gestion des pages et blocs de texte</h1>
          <b-btn
              :to="{ name: 'adminPageNew' }"
              variant="dark"
              class="mb-2"
              size="lg"
          >
            Nouveau
          </b-btn>
        </div>
        <b-overlay :show="load">
          <b-list-group>
            <b-list-group-item
                v-for="(item, kitem) in items"
                :key="kitem"
                button
                @click.prevent="openItem(item)"
                class="d-flex justify-content-center align-items-center bloc-item"
            >
              <span class="small mr-1">{{ kitem + 1 }}.</span>
              <strong>{{ item.title }}</strong>
              <div class="ml-auto mr-2">
                <span class="small ml-auto text-muted">
                  {{ item.updatedAt | datetime }}
                </span>
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {http} from '@/http';

/**
 * @vuese
 * Page d'affichage des pages custom
 * @group PAGES_ADMIN
 */
export default {
  name: 'AdminPagesIndexPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Pages',
      meta: [
        {
          name: 'description',
          content: 'Gestion des pages et blocs de texte.'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Pages'
        }
      ]
    };
  },
  watch: {
    'search.name'() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getPageList();
      }, 500);
    }
  },
  data() {
    return {
      items: [],
      timeout: '',
      load: false,
      item: {},
      search: {
        name: ''
      }
    };
  },
  methods: {
    /**
     * @vuese
     * Récupération de la liste des pages et blocs de texte
     * @returns {Promise<AxiosResponse<any>>}
     */
    getPageList() {
      let url = '/api/pages';
      if (this.search.name !== '') {
        url += '?title=' + this.search.name;
      }
      this.load = true;
      return http.get(url).then((res) => {
        this.items = res.data['hydra:member'];
        this.load = false;
        return res;
      });
    },
    /**
     * @vuese
     * Ouvre dans un nouvel onglet la page à éditer
     * @param La page custom à ouvrir
     */
    openItem(item) {
      this.$router.push({
        name: 'adminPageEdit',
        params: {
          id: item.uid
        }
      });
    }
  },
  created() {
    this.getPageList();
  }
};
</script>

<style scoped>
.bloc-item:hover {
  color: var(--secondary-color) !important;
}
</style>