<template>
  <div class="app-menu app-menu-admin" role="navigation">
    <b-navbar id="navbar-admin" toggleable="lg" type="light">
      <b-navbar-toggle id="navbar-toggler-admin" target="nav-collapse"/>
      <b-collapse id="nav-collapse" is-nav class="nav-center">
        <b-navbar-nav>
          <b-nav-item
              class="d-flex flex-column align-items-center justify-content-center"
              :to="{
              name: 'adminHome',
            }"
              aria-label="Tableau de bord"
          >
            <b-img
                src="/img/logo_abc.svg"
                class="logo-menu"
                alt="Logo action bas carbone"
            />
          </b-nav-item>
          <b-nav-item class="links menu-item" :to="{ name: 'adminCardsIndex' }"
          >Étapes Climats
          </b-nav-item>
          <b-nav-item
              class="links menu-item"
              :to="{ name: 'adminExemplaryIndex' }"
          >Ouvrages exemplaires
          </b-nav-item>
          <b-nav-item class="links menu-item" :to="{ name: 'adminPageIndex' }"
          >Pages
          </b-nav-item>
          <b-nav-item class="links menu-item" :to="{ name: 'adminParams' }"
          >Classement
          </b-nav-item>
          <b-nav-item class="links menu-item" :to="{ name: 'adminUsers' }"
          >Gestion de site
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item
              :to="{
              name: 'myAccountPage',
            }"
              class="menu-item ml-25"
          >
            <b-icon icon="person-circle" aria-hidden="true"/>
            {{ user.firstName.substring(0, 1) }}.&nbsp;{{ user.lastName }}
          </b-nav-item
          >
          <b-nav-item
              :to="{
              name: 'logout',
            }"
              title="Déconnexion"
              class="ml-25"
          >
            <b-icon icon="box-arrow-right" aria-hidden="true"/>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-button
        class="btn-toggle-editMode"
        title="Passer en mode édition de contenu"
        aria-label="Passer en mode édition de contenu"
        :variant="editMode ? 'dark' : 'link'"
        @click.prevent="toggleEditMode"
    >
      <small v-if="editMode">Mode édition </small>
      <b-icon icon="pencil" aria-hidden="true"/>
    </b-button>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

/**
* @vuese
* Menu de navigation du backoffice
*/
export default {
  name: 'AppMenu',
  data() {
    return {
      menuIsActive: false
    };
  },
  mounted() {
    document.addEventListener('click', this.onClickAdmin);
  },
  methods: {
    ...mapActions(['toggleEditMode']),
    onClickAdmin() {
      const navbarToggler = document.getElementById('navbar-toggler-admin');
      let isOpen = false;
      if (navbarToggler.hasAttribute('aria-expanded')) {
        isOpen = true;
      }
      let navbar = document.getElementById('nav-collapse');
      let isClassShowPresent = navbar.classList.contains('show');

      if (isOpen == 'true' && isClassShowPresent) {
        this.$root.$emit('bv::toggle::collapse', 'nav-collapse')
      }
    }
  },
  computed: {
    ...mapGetters('UserStore', ['user']),
    ...mapGetters(['report', 'editMode'])
  }
};
</script>

<style lang="css" scoped>
.menu-item {
  text-transform: uppercase;
}


@media screen and (max-width: 1255px) {
  .menu-item {
    font-size: 14px;
  }
}

@media screen and (max-width: 1160px) {
  .menu-item {
    font-size: 12px;
  }
}


@media screen and (max-width: 1064px) {
  .menu-item {
    font-size: 11px;
  }
}

@media screen and (max-width: 1015px) {
  .menu-item {
    font-size: 10px;
  }
}
</style>
