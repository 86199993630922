<template>
  <span>
    <b-btn
        v-b-modal="'card-edit-' + id"
        :variant="variant"
        :size="size"
        :title="'Éditer l\'étape : ' + card.name"
        :aria-label="'Éditer l\'étape : ' + card.name"
    >
      <slot>
        <b-icon-pencil aria-hidden="true"/>
      </slot>
    </b-btn>

    <b-modal
        :id="'card-edit-' + id"
        title="Éditer l'étape"
        no-fade
        size="lg"
        scrollable
        hide-footer
        no-close-on-backdrop
    >
      <template slot="modal-header">
        <div
            v-if="card['@id']"
            class="w-100 d-flex justify-content-between align-items-center"
        >
          <div class="l-1">
            <div class="modal-title">
              <span>Éditer l'étape :</span>
            </div>
            <div class="action-title mb-2">
              {{ card.name }}
            </div>
            <app-draft :draft="card.draft"/>
          </div>
          <div>
            <div class="d-flex justify-content-end align-items-center">
              <button
                  class="close ml-auto"
                  title="Fermer la fenêtre"
                  aria-label="Fermer la fenêtre"
                  @click.prevent="closeModal()"
              >
                <b-icon-x aria-hidden="true"/>
              </button>
            </div>
            <div v-if="card['@id']">
              <div class="d-flex justify-content-between align-items-center">
                <b-btn
                    variant="danger"
                    v-if="card.draft"
                    class="mr-2"
                    @click.prevent="actionRef('removeCard')"
                    title="Supprimer l'étape climat"
                    aria-label="Supprimer l'étape climat"
                >
                  <b-icon-trash aria-hidden="true"/>
                </b-btn>
                <b-btn
                    v-if="card['@id'] && card.draft"
                    @click.prevent="actionRef('publishCard')"
                    title="Publier l'étape"
                    aria-label="Publier l'étape climat"
                    variant="success"
                    class="mr-2"
                >
                  Publier
                </b-btn>
                <b-btn
                    variant="light"
                    class="b-trans"
                    v-if="card['@id'] && !card.draft"
                    @click.prevent="actionRef('draftCard')"
                    title="Mettre l'étape en état de brouillon"
                >
                  Mettre en brouillon
                </b-btn>
                <b-btn variant="dark" @click.prevent="actionRef('saveCard')">
                  <span>Enregistrer</span>
                </b-btn>
              </div>
              <div class="text-right small muted mt-2">
                {{ card.updatedAt | datetime }}
              </div>
            </div>
          </div>
        </div>
        <div
            v-else
            class="w-100 d-flex justify-content-between align-items-top"
        >
          <div class="l-1">
            <div class="modal-title">
              <span>Créer une étape :</span>
            </div>
            <div class="action-title">Nouveau</div>
          </div>
          <b-btn
              variant="dark"
              @click.prevent="actionRef('saveCard')"
              size="lg"
          >
            <span>Enregistrer</span>
          </b-btn>
        </div>
      </template>
      <form-admin-card
          :header="false"
          :footer="false"
          :ref="'form-' + id"
          :value="card"
          @saved="onSaved"
          @error="onError"
      />
    </b-modal>
  </span>
</template>

<script>
import formAdminCard from './formAdminCard.vue';

/**
* @vuese
* Bouton d'édition d'une fiche climat
* @group COMPONENTS_ADMIN
*/
export default {
  name: 'BtnAdminCardEdit',
  components: {
    formAdminCard
  },
  props: {
    // Une fiche climat
    card: {
      type: Object,
      default() {
        return {
          name: 'Nouvelle étape'
        };
      }
    },
    // identifiant de la fiche
    id: {
      type: String,
      default() {
        const rand = Math.random().toString(36).substring(2);
        return 'card-edit-' + rand;
      }
    },
    // Type de bouton
    variant: {
      type: String,
      default: () => 'link'
    },
    // Taille du bouton
    size: {
      type: String,
      default: () => 'md'
    }
  },
  methods: {
    /**
     * @vuese
     * Application d'une action en fonction de la méthode passée en argument
     * @arg Méthode à utiliser
     * @returns {*}
     */
    actionRef(method) {
      return this.$refs['form-' + this.id][method]();
    },
    /**
     * @vuese
     * Sauvegarde des modifications faite sur une étape
     * @arg Une fiche climat
     */
    onSaved(item) {
      // Fermeture fenêtre modale
      this.$root.$emit('bv::hide::modal', 'card-edit-' + this.id);
      // Sauvegarde de la fiche
      this.$emit('saved', item);
    },
    /**
     * @vuese
     * Fermeture de la fenêtre modale d'édition d'une étape
     */
    closeModal() {
      this.$bvModal
          .msgBoxConfirm('Les données non enregistrées seront perdus.', {
            noCloseOnBackdrop: true,
            centered: true,
            okTitle: 'Confirmer',
            cancelTitle: 'annuler'
          })
          .then((res) => {
            if (res) {
              // Fermeture fenêtre modale
              this.$root.$emit('bv::hide::modal', 'card-edit-' + this.id);
            }
            return res;
          });
    },
    /**
     * @vuese
     * Récupération du message d'erreur
     * @arg Une erreur
     */
    onError(error) {
      this.$emit('error', error);
    }
  }
};
</script>
