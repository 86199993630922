import {http} from '@/http.js';
import methods from '@/methods';

export const AdminUserStore = {
  namespaced: true,
  getters: {
    users: (state) => state.users
  },
  state: {
    users: []
  },
  mutations: {
    SET_USERS(state, data) {
      state.users = data['hydra:member'];
    },
    SET_STATUS() {
    },
    SET_USERS_TYPE() {
    }
  },
  actions: {
    getUsers({commit}, data = {}) {
      let url = '/admin/users/' + data.type;
      let query = methods.buildQuery(data);

      url += '?' + query;
      return http.get(url).then((res) => {
        commit('SET_USERS_TYPE');
        return res;
      });
    },
    setMember({commit}, user) {
      return http.get(`/admin/users/${user.uid}/member`).then((res) => {
        commit('SET_STATUS');
        return res;
      });
    },
    setNoMember({commit}, user) {
      return http.post(`/admin/users/${user.uid}/no-member`).then((res) => {
        commit('SET_STATUS');
        return res;
      });
    },
    setApproved({commit}, user) {
      return http.post(`/admin/users/${user.uid}/approved`).then((res) => {
        commit('SET_STATUS');
        return res;
      });
    },
    setDeny({commit}, user) {
      return http.post(`/admin/users/${user.uid}/deny`).then((res) => {
        commit('SET_STATUS');
        return res;
      });
    }
  }
};
