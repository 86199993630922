var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-html"},[_c('vue-nestable',{attrs:{"keyProp":"uid","max-depth":50},on:{"change":_vm.emitChange},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return _c('vue-nestable-handle',{class:'fiche fiche-' +
        item.cardFormat.slug +
        ' d-flex justify-content-between align-items-center',attrs:{"item":item}},[_c('div',{staticClass:"mr-1"},[_c('small',{staticClass:"m-1"},[_vm._v(_vm._s(item.id)+".")]),_c('b-icon-grip-vertical',{attrs:{"aria-hidden":"true"}})],1),_c('div',{class:'legend legend-' + item.cardFormat.slug,attrs:{"title":'fiche ' + item.cardFormat.name}},[_c('h2',{staticClass:"mt-0 mb-0"},[_c('strong',[_vm._v(_vm._s(item.name))])])]),_c('div',{staticClass:"d-flex justify-content-center align-items-center ml-auto",attrs:{"title":item.draft
            ? 'Attention les étapes en brouillon ne sont pas prisent en compte dans les calcules hiérarchiques.'
            : item.val + 'kgCO2e par personne par an'}},[_c('app-draft',{staticClass:"mr-2",attrs:{"draft":item.draft,"title":"Attention les étapes en brouillon ne sont pas prisent en compte dans les calcules hiérarchiques"}}),_c('div',{staticClass:"card-value d-flex justify-content-start align-items-center",class:{
            draft: item.draft,
          }},[(item.val !== 0)?_c('span',{staticClass:"unit-value"},[_vm._v(_vm._s(item.val))]):_vm._e(),(item.val !== 0)?_c('span',{staticClass:"unit-info"},[_vm._v(" kgCO2e par personne par an ")]):_c('span',[_vm._v("Condition nécessaire")])]),_c('btn-admin-card-edit',{staticClass:"ml-1 mr-1",attrs:{"card":item},on:{"saved":_vm.onSaved}})],1)])}}]),model:{value:(_vm.cardsSorted),callback:function ($$v) {_vm.cardsSorted=$$v},expression:"cardsSorted"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }