<template>
  <b-container>
    <b-row>
      <h1 class="page-title uppercase mt-2">Plan de site</h1>
    </b-row>
    <app-plan-de-site/>
  </b-container>
</template>

<script>
import appPlanDeSite from '../components/appPlanDeSite.vue';

/**
 * @vuese
 * Page de plan du site
 * @group PAGES
 */
export default {
  components: {appPlanDeSite},
  name: 'SitemapPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Plan de site',
      meta: [
        {
          name: 'description',
          content: 'Retrouvez le plan de site de Parcours ABC ici.'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Plan de site'
        }
      ]
    };
  }
};
</script>
