<template>
  <b-card
      class="card card-fiche"
      :class="
      'card-fiche-' +
      card.cardFormat.slug +
      ' ' +
      (card.draft ? 'card-fiche-draft' : '')
    "
  >
    <template #header>
      <div class="d-flex justify-content-start align-items-center">
        <!-- Redirection en fonction de l'utilisateur -->
        <slot name="link">
          <router-link
              :to="'/login'"
              class="h2 appCard-title mt-0 mb-0"
              v-if="!user.uid"
          >
            {{ card.name }}
          </router-link>
          <router-link
              v-if="!user.isAdmin && user.uid"
              :to="'/etape/' + card.uid"
              class="h2 appCard-title mt-0 mb-0"
          >
            {{ card.name }}
          </router-link>
          <h2 v-if="user.isAdmin" class="appCard-title mt-0 mb-0">
            {{ card.name }}
          </h2>
        </slot>
        <!-- Titre à afficher -->
        <slot name="title-right"></slot>
      </div>
    </template>
    <div
        class="
        d-flex
        flex-wrap
        justify-content-around
        align-items-center
        flex-column
      "
    >
      <!-- Contenu à afficher -->
      <slot name="body-left"></slot>
      <div>
        <b-btn
            @click="downloadCard(card)"
            variant="download"
            v-if="download && user.uid"
            title="Télécharger la fiche"
            aria-label="Télécharger la fiche"
        >
          <b-icon-download aria-hidden="true"/>
        </b-btn>
        <router-link
            class="btn"
            :to="'/login'"
            v-if="!user.uid"
            download
            title="Télécharger la fiche"
            aria-label="Télécharger la fiche"
        >
          <b-icon-download aria-hidden="true"/>
        </router-link>
        <btn-admin-card-edit
            class="ml-auto"
            v-if="edit && user.isAdmin"
            variant="edit"
            :card="card"
            @saved="onSaved"
            @error="onError"
        />
      </div>
    </div>
    <div
        v-if="card.val !== 0"
        class="card-value mt-1 d-flex justify-content-center align-items-center"
    >
      <span class="unit-value">{{ card.val }} </span>
      <aside class="unit-info">
        <ul class="unit-info--ul">
          <li>kgCO2e</li>
          <li>par personne</li>
          <li>par an</li>
        </ul>
      </aside>
    </div>
    <div
        v-else
        class="
        card-value card-value--empty
        mt-1
        d-flex
        justify-content-center
        align-items-center
      "
    >
      Condition nécessaire
    </div>
  </b-card>
</template>

<script>
import BtnAdminCardEdit from './btnAdminCardEdit.vue';
import {mapActions, mapGetters} from 'vuex';
import {http} from '@/http.js';

/**
* @vuese
* Une fiche climat, version admin
* @group COMPONENTS_ADMIN
*/
export default {
  name: 'AppAdminCard',
  components: {
    BtnAdminCardEdit
  },
  props: {
    // La fiche climat
    card: {
      type: Object,
      default() {
        return {};
      }
    },
    // La fiche climat est-elle en cours d'édition ?
    edit: {
      type: Boolean,
      default() {
        return false;
      }
    },
    // La fiche climat est-elle en cours de téléchargement ?
    download: {
      type: Boolean,
      default() {
        return true;
      }
    },
    // La fiche climat est-elle sélectionné pour le rapport ?
    select: {
      type: Boolean,
      default() {
        return false;
      }
    },
    // La catégorie de la fiche climat
    cardCategory: String
  },
  data() {
    return {
      pdfUrl: process.env.VUE_APP_API_URL + '/pdf/cards'
    };
  },
  methods: {
    ...mapActions('UiStore', [
      'loaderOn',
      'loaderVariant',
      'loaderMessage',
      'loaderVariant',
      'loaderOff'
    ]),
    /**
      * @vuese
      * Ouverture de la fiche
      */
    openCard(card) {
      // Événement d'ouverture de la fiche
      this.$emit('open', card);
    },
    /**
      * @vuese
      * Sauvegarde des modifications faites sur une fiche
      */
    onSaved(card) {
      // Déclenché à la soumission du formulaire, pour enregistrer les données
      this.$emit('saved', card);
    },
    /**
     * @vuese
     * Gestion des erreurs
     */
    onError(error) {
      // Déclenché en cas d'erreur
      this.$emit('error', error);
    },
    /**
     * @vuese
     * Mise à jour de la recherche des fiches climats en fonction des filtres
     */
    onCardSaved() {
      this.$refs.cardsFilters.searchCardsFiltred();
    },
    /**
     * @vuese
     * Téléchargement au format pdf de l'étape
     * @arg La fiche en cours
     * @returns {Promise<AxiosResponse<any>>}
     */
    downloadCard(card) {
      this.loaderOn('Génération du PDF');
      this.loaderVariant('info');

      let url = '/pdf/cards/' + card.uid;
      return http
          .get(url, {
            responseType: 'blob'
          })
          .then((res) => {
            this.loaderOff();

            const blob = new Blob([res.data], {type: 'application/pdf'});
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = card.name + '--' + card.idxFull + '.pdf';
            link.click();
            return res;
          })
          .catch(() => {
            this.loaderOff();
          });
    }
  },
  computed: {
    ...mapGetters('UserStore', ['user'])
  }
};
</script>

<style scoped>
svg {
  height: 1.5em;
  width: 1.5em;
}
</style>