<template>
  <div class="input-html">
    <ckeditor
        :id="id"
        :editor="editor"
        tag-name="textarea"
        class="input-html-ckeditor"
        v-model="valueEdit"
        @input="emitInput"
    />
  </div>
</template>

<script>
/* 
 Repo: https://github.com/a-sauvaget/ckeditor5
 Tuto: https://blowstack.com/blog/create-ckeditor-5-custom-build
 Repo package: https://www.npmjs.com/package/ckeditor5-custom-build-novabuild
 */

import CustomEditor from 'ckeditor5-custom-build-novabuild';
/**
* @vuese
* WYSIWYG Éditeur de texte
*/
export default {
  name: 'inputHtml',
  props: {
    id: {
      type: String,
      default() {
        const rand = Math.random().toString(36).substring(2);
        return 'input-html-' + rand;
      }
    },
    value: {
      type: String,
      default() {
        return '<p></p>';
      }
    },
    config: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  watch: {
    value(nv) {
      this.valueEdit = nv;
    }
  },
  data() {
    return {
      valueEdit: '',
      editor: CustomEditor
    };
  },
  methods: {
    emitInput() {
      this.$emit('input', this.valueEdit);
    }
  },
  created() {
    this.valueEdit = this.value;
  }
};
</script>
