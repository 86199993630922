<template>
  <span>
    <b-btn
        @click="newItem"
        v-b-modal="'sector-edit-' + id"
        :size="size"
        class="mt-2 mr-2"
        variant="dark"
        title="Nouveau secteur"
        aria-label="Nouveau secteur"
    ><b-icon-plus aria-label="true"/><slot></slot
    ></b-btn>
    <b-modal
        :id="'sector-edit-' + id"
        title="Secteur"
        no-fade
        size="lg"
        scrollable
        hide-footer
    >
      <form-admin-sector
          :sector="sector"
          @saved="onSaved"
          @new="onNew"
          @update="onUpdate"
      />
    </b-modal>
  </span>
</template>

<script>
import formAdminSector from './formAdminSector.vue';

/**
* @vuese
* Bouton d'édition d'un secteur
* @group COMPONENTS_ADMIN
*/
export default {
  name: 'BtnAdminSectorEdit',
  components: {
    formAdminSector
  },
  props: {
    // Le secteur
    sector: {
      type: Object,
      default() {
        return {
          name: '',
          parent: ''
        };
      }
    },
    // Type de bouton
    variant: {
      type: String,
      default() {
        return 'link';
      }
    },
    // Identifiant du secteur
    id: {
      type: String,
      default() {
        const rand = Math.random().toString(36).substring(2);
        return '' + rand;
      }
    },
    // Taille du bouton
    size: {
      type: String,
      default: () => 'md'
    }
  },
  methods: {
    /**
     * @vuese
     * Modification du secteur
     * @arg Le secteur
     */
    openItem() {
      // Ouverture de la fenêtre modale 
      this.$root.$emit('bv::show::modal', 'sector-edit-' + this.id);
    },
    /**
     * @vuese
     * Nouveau secteur
     */
    newItem() {
      this.sector = {
        name: ''
      };
    },
    /**
     * @vuese
     * Sauvegarde du secteur
     * @arg Le secteur
     */
    onSaved(item) {
      // Suppression du secteur
      // @arg Le secteur
      this.$emit('saved', item);
      // Fermeture de la fenêtre modale
      this.$root.$emit('bv::hide::modal', 'sector-edit-' + this.id);
    },
    /**
     * @vuese
     * Suppression du secteur
     * @arg Le secteur
     */
    onUpdate(item) {
      // Mise à jour du secteur
      this.$emit('update', item);
    },
    /**
     * @vuese
     * Mise à jour du secteur
     * @arg Le secteur
     */
    onNew(item) {
      // Nouveau secteur
      // @arg Le secteur
      this.$emit('new', item);
    }
  }
};
</script>
