<template>
  <b-container fluid>
    <b-row class="bread-crumbs">
      <b-col>
        <app-bread-crumbs
            :routes="[
            { name: 'adminHome', title: 'Administration' },
            { name: 'adminParams', title: 'Classement' },
          ]"
        ></app-bread-crumbs>
        <h1 class="page-title">Classement</h1>
        <b-tabs no-fade :lazy="true">
          <b-tab title="Types d'éléments">
            <app-admin-table
                type="card_categories"
                @edit="openCategory"
                ref="categoryList"
            >
              <btn-category-edit
                  variant="secondary"
                  size="lg"
                  :category="category"
                  @saved="getItems('categoryList')"
                  ref="categoryEdit"
              >
                Nouveau
              </btn-category-edit>
            </app-admin-table>
          </b-tab>

          <b-tab title="Mots-clés">
            <app-admin-nested
                type="tags"
                ref="tagList"
                @edit="openTag"
                :maxDepth="2"
            >
              <btn-tag-edit
                  variant="secondary"
                  size="lg"
                  :tag="tag"
                  @saved="getItems('tagList')"
                  ref="tagEdit"
              >
                Nouveau
              </btn-tag-edit>
            </app-admin-nested>
          </b-tab>

          <b-tab title="Secteurs">
            <app-admin-nested
                type="sectors"
                ref="sectorList"
                :groups="[
                'sector',
                'sector:children',
                'sector:lite',
                'sector:parent',
              ]"
                @edit="openSector"
            >
              <btn-sector-edit
                  variant="secondary"
                  size="lg"
                  :sector="sector"
                  @saved="getItems('sectorList')"
                  ref="sectorEdit"
              >
                Nouveau
              </btn-sector-edit>
            </app-admin-nested>
          </b-tab>

          <b-tab title="Formats">
            <app-admin-table
                type="card_formats"
                ref="formatList"
                @edit="openFormat"
            >
              <btn-format-edit
                  variant="secondary"
                  size="lg"
                  :format="format"
                  @saved="getItems('formatList')"
                  ref="formatEdit"
              >
                Nouveau
              </btn-format-edit>
            </app-admin-table>
          </b-tab>

          <b-tab title="Entreprises">
            <app-admin-table
                type="company_froms"
                ref="companyList"
                @edit="openCompany"
            >
              <btn-company-edit
                  variant="secondary"
                  size="lg"
                  :company="company"
                  @saved="getItems('companyList')"
                  ref="companyEdit"
              >
                Nouveau
              </btn-company-edit>
            </app-admin-table>
          </b-tab>

          <b-tab title="Témoignages">
            <div class="mt-2 mb-2">
              <b-btn
                  variant="dark"
                  size="lg"
                  :to="{
                  name: 'adminTestimonyNew',
                }"
                aria-label="Nouveau témoignage"
                title="Nouveau témoignage"
              >
                <b-icon-plus aria-hidden="true"/>
                Nouveau
              </b-btn>
            </div>
            <b-overlay :show="load">
              <b-list-group>
                <b-list-group-item
                    v-for="(item, kitem) in items"
                    :key="kitem"
                    button
                    @click.prevent="openItem(item)"
                    class="d-flex justify-content-center align-items-center"
                >
                  <span class="small mr-1">{{ kitem + 1 }}.</span>
                  <strong>
                    <span class="uppercase">
                      {{ item.lastName }}
                    </span>
                    {{ item.firstName }}
                  </strong>
                  <div class="ml-auto mr-2">
                    <span class="small ml-auto text-muted">
                      {{ item.updatedAt | datetime }}
                    </span>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </b-overlay>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {http} from '@/http';

import appAdminTable from '@/components/Admin/appAdminTable';
import appAdminNested from '@/components/Admin/appAdminNested';
import btnTagEdit from '@/components/Admin/tags/btnTagEdit';
import btnCategoryEdit from '@/components/Admin/categories/btnCategoryEdit';
import btnFormatEdit from '@/components/Admin/format/btnFormatEdit';
import btnCompanyEdit from '@/components/Admin/company/btnCompanyEdit';
import btnSectorEdit from '@/components/Admin/sectors/btnSectorEdit';

/**
 * @vuese
 * Page d'affichage des paramètres d'administration
 * @group PAGES_ADMIN
 */
export default {
  name: 'AdminParamsPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Classement',
      meta: [
        {
          name: 'description',
          content:
              'Création et gestion du classement: types d\'éléments, mots-clés, secteurs...'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Classement'
        }
      ]
    };
  },
  components: {
    appAdminTable,
    appAdminNested,
    btnTagEdit,
    btnFormatEdit,
    btnCategoryEdit,
    btnCompanyEdit,
    btnSectorEdit
  },
  data() {
    return {
      company: {
        name: ''
      },
      category: {
        name: ''
      },
      tag: {
        name: ''
      },
      tagCategory: {
        uid: '',
        name: '',
        tags: []
      },
      format: {
        name: ''
      },
      sector: {
        parent: '',
        name: ''
      },
      items: [],
      timeout: '',
      load: false,
      item: {}
    };
  },
  methods: {
    /**
     * @vuese
     * Ouvre l'onglet mot-clé
     * @arg Ouvre en mode édition le mot-clé sélectionné
     */
    openTag(tag) {
      this.tag = tag;
      this.$refs['tagEdit'].openItem();
    },
    /**
     * @vuese
     * Ouvre l'onglet catégorie de mot-clé
     * @arg Ouvre en mode édition la catégorie de mot-clé sélectionné
     */
    openTagCategory(tagCategory) {
      this.tagCategory = tagCategory;
      this.$refs['tagCategoryEdit'].openItem();
    },
    /**
     * @vuese
     * Ouvre l'onglet formats
     * @arg Ouvre en mode édition le format sélectionné
     */
    openFormat(format) {
      this.format = format;
      this.$refs['formatEdit'].openItem();
    },
    /**
     * @vuese
     * Ouvre l'onglet types d'éléments
     * @arg Ouvre en mode édition le type d'élément sélectionné
     */
    openCategory(category) {
      this.category = category;
      this.$refs['categoryEdit'].openItem();
    },
    /**
     * @vuese
     * Ouvre l'onglet entreprises
     * @arg Ouvre en mode édition l'entreprise sélectionné
     */
    openCompany(company) {
      this.company = company;
      this.$refs['companyEdit'].openItem();
    },
    /**
     * @vuese
     * Ouvre l'onglet secteurs
     * @arg Ouvre en mode édition le secteur sélectionné
     */
    openSector(sector) {
      this.sector = sector;
      this.$refs['sectorEdit'].openItem();
    },
    /**
     * @vuese
     * Ouvre le mode édition de l'élément choisit
     * @arg Ouvre en mode édition l'élément sélectionné
     */
    getItems(ref) {
      this.$refs[ref].getItems();
    },
    /**
     * @vuese
     * Récupère la liste des témoignages
     * @returns {Promise<AxiosResponse<any>>}
     */
    getTestimonyList() {
      let url = '/api/testimonies';
      this.load = true;
      return http.get(url).then((res) => {
        this.items = res.data['hydra:member'];
        this.load = false;
        return res;
      });
    },
    /**
     * @vuese
     * Ouvre le mode édition d'un témoignage'
     * @arg Le témoignage a modifié
     */
    openItem(item) {
      this.$router.push({
        name: 'adminTestimonyEdit',
        params: {
          id: item.uid
        }
      });
    }
  },
  created() {
    this.getTestimonyList();
  }
};
</script>