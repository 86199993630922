<template>
  <b-col
      class="app-testimony flex align-items-center image-fondB"
      :style="{ backgroundImage: 'url(/img/forme_fond_B.svg)' }"
  >
    <b-col class="img-testimony">
      <b-img
          src="/img/illu_03_temoignage.svg"
          alt=""
          class="illu-testi"
      ></b-img>
    </b-col>
    <b-col sm="12" lg="6" class="block-testimony">
      <h3 class="position-relative">
        TÉMOIGNAGES
        <picture>
          <source srcset="/img/guillemet_endroit.webp" type="image/webp"/>
          <img
              src="/img/guillemet_endroit.png"
              alt=""
              class="position-absolute guillemet--endroit"
          />
        </picture>
        <picture>
          <source srcset="/img/guillemet_inversee.webp" type="image/webp"/>
          <img
              src="/img/guillemet_inversee.png"
              alt=""
              class="position-absolute guillemet--envers"
          />
        </picture>
      </h3>
      <hr/>
      <div>
        <b-carousel id="carousel-testimony" controls img-height="430">
          <b-carousel-slide
              v-for="testimony in testimonies"
              :key="testimony.content"
              class="carousel-testimony"
          >
            <p>{{ testimony.content }}</p>
            <div class="text-right capitalize">
              {{ testimony.firstName }} {{ testimony.lastName }}
            </div>
          </b-carousel-slide>
        </b-carousel>
      </div>
    </b-col>
  </b-col>
</template>

<script>
import {http} from '@/http';

/**
* @vuese
* Diaporama affichant les témoignages des utilisateurs de la plateforme
*/
export default {
  name: 'appTestimony',
  data() {
    return {
      testimonies: []
    };
  },
  methods: {
    /**
     * @vuese
     * Récupération de la liste des témoignages
     */
    getTestimonies() {
      let url = 'testimonies';

      http.get(url).then((res) => {
        this.testimonies = res.data['hydra:member'];
        return res;
      });
    },
    /**
     * @vuese
     * Ajout des aria-label pour l'accessibilité du carousel
     */
    addAriaLabel() {
      let prevLink = document.getElementsByClassName('carousel-control-prev');
      let nextLink = document.getElementsByClassName('carousel-control-next');

      prevLink.ariaLabel = 'Précédent';
      nextLink.ariaLabel = 'Suivant';
    }
  },
  created() {
    this.getTestimonies();
    this.addAriaLabel();
  }
};
</script>

<style lang="scss">
.app-testimony {
  padding-top: 10rem;
  margin-bottom: 15rem;
  @media screen and (max-width: 992px) {
    margin-bottom: 7.5rem;
  }
}

.illu-testi {
  width: 90% !important;
}

.image-fondB {
  background-repeat: no-repeat;
  background-position-x: 60%;
  background-position-y: top;
  height: 500px;
}

.block-testimony {
  background: var(--secondary-color);
  height: 566px;
  color: $white;
}

.block-testimony p {
  font-weight: bold;
  font-size: 25px;
  @media screen and (max-width: 1380px) {
    font-size: 20px;
  }
}

.block-testimony h3 {
  padding-top: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
}

.block-testimony hr {
  border-top: 3px solid $white;
  width: 70%;
  margin: auto;
  opacity: 1;
}

.img-testimony img {
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.img-fluid {
  width: 20% !important;
}

.guillemet--endroit {
  top: 60px;
  left: 10px;
}

.guillemet--envers {
  bottom: -495px;
  right: 10px;
}

@media screen and (max-width: 1380px) {
  .guillemet--endroit,
  .guillemet--envers {
    height: 50px;
    width: auto;
  }
}

.carousel-testimony {
  height: 499px !important;
  position: relative;
}

.carousel-item {
  float: left !important;
  display: none !important;
}

.carousel-item.active {
  display: flex !important;
}

.carousel-caption {
  position: relative !important;
  display: flex;
  flex-direction: column;
  align-content: center !important;
  align-items: center !important;
  justify-content: center;
  margin: 5% auto 0 auto;
  padding: 4rem;
  left: 0 !important;
  height: auto;
  width: 92%;
}

@media screen and (max-width: 425px) {
  .carousel-caption p {
    font-size: 16px;
  }
}

@media screen and (max-width: 325px) {
  .carousel-caption p {
    font-size: 14px;
  }
}

.carousel-inner {
  height: 499px;
}

.carousel-control-next,
.carousel-control-prev {
  height: 499px;
}

@media screen and (max-width: 1024px) {
  .img-testimony {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .image-fondB {
    background-size: 0;
  }
}

@media screen and (min-width: 992px) and (max-width: 1024px) {
  .block-testimony {
    width: 100% !important;
    max-width: 100%;
  }
}
</style>
