/* eslint-disable no-param-reassign */
import qs from 'qs';

export default {
  /**
   * Conversion en chaîne JSON
   * @param data
   * @returns {string}
   */
  buildQuery(data) {
    let str = qs.stringify(data);
    return str;
  },
  /**
   * Construction des données pour le formulaire
   * @param formData
   * @param data
   * @param parentKey
   * @returns {*}
   */
  buildFormData(formData, data, parentKey) {
    if (
        data &&
        typeof data === 'object' &&
        !(data instanceof Date) &&
        !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
        );
      });
    } else {
      const value = data == null ? '' : data;

      formData.append(parentKey, value);
    }

    return formData;
  },
  /**
   * Construction du formulaire à partir des données fournit
   * @param data
   * @returns {FormData}
   */
  jsonToFormData(data) {
    let formData = new FormData();

    formData = this.buildFormData(formData, data);

    return formData;
  },
  /**
   * Copie le texte à partir d'une chaîne de caractère fournit
   * @param str
   */
  copyText(str) {
    navigator.clipboard.writeText(str);
  }
};
