var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('b-overlay',{attrs:{"show":_vm.load}},[(!_vm.hideHeader)?_c('div',[_c('b-alert',{attrs:{"show":"","variant":"light"}},[_c('b-row',[_c('b-col',{attrs:{"offset":"3"}},[_c('div',[_c('span',[_vm._v("id : "+_vm._s(_vm._f("def")(_vm.form.uid,'-')))])]),_c('div',[_c('span',[_vm._v("slug : "),_c('router-link',{attrs:{"to":{
                    name: 'PagePage',
                    params: {
                      slug: _vm.form.slug,
                    },
                  }}},[_vm._v(_vm._s(_vm.form.slug))]),_c('btn-clip',{staticClass:"ml-1",attrs:{"text":_vm.form.slug}})],1),_c('div',[_c('router-link',{attrs:{"to":{
                    name: 'PagePage',
                    params: {
                      slug: _vm.form.slug,
                    },
                  },"target":"blank"}},[_c('b-icon-link'),_vm._v(" Accéder à la page ")],1)],1)])]),_c('b-col',{staticClass:"text-right"},[_c('div',[_vm._v("maj : "+_vm._s(_vm._f("def")(_vm._f("datetime")(_vm.form.updatedAt),'')))]),(_vm.form['@id'])?_c('b-btn',{staticClass:"mr-2",attrs:{"variant":"danger","title":"Supprimer le témoignage"},on:{"click":function($event){$event.preventDefault();return _vm.removePageCustom.apply(null, arguments)}}},[_c('b-icon-trash')],1):_vm._e()],1)],1)],1)],1):_vm._e(),_c('b-form-group',{attrs:{"label":"Titre :","label-for":"title"}},[_c('b-input',{staticClass:"mt-2",attrs:{"id":"title"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('b-form-group',{attrs:{"label":"Contenu :","label-for":"content"}},[_c('input-html',{staticClass:"mt-2 page-edition",attrs:{"id":"content"},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),(!_vm.hideFooter)?_c('div',{staticClass:"d-flex justify-content-center align-items-center mt-2"},[_c('b-button',{attrs:{"type":"submit","variant":_vm.load ? 'link' : 'dark',"size":"lg"}},[(!_vm.load)?_c('span',[_vm._v("Enregistrer")]):_c('span',[_c('b-spinner',{attrs:{"small":""}})],1)])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }