<template>
  <b-container class="app-actu">
    <b-row class="mt-5">
      <h2 class="mb-3 blue">À LA UNE</h2>
      <b-row class="pb-5 justify-content-md-center">
        <b-col
            sm="12"
            md="6"
            lg="4"
            v-for="(card, kcard) in showCard()"
            :key="kcard"
        >
          <app-card-actu
              :card="card"
              :edit="false"
              @saved="onCardSaved"
              class="mt-2"
          />
        </b-col>
      </b-row>
      <b-row>
        <router-link
            v-if="!user.uid"
            :to="{
            name: 'login',
          }"
        >
          <b-button class="w-100 btn-fleche btn-fleche--actu" variant="primary" title="">
            <span>
              <b-icon-arrow-right aria-hidden="true"/>
              Parcourir toutes les étapes
            </span>
          </b-button>
        </router-link>
        <router-link
            v-if="user.uid"
            :to="{
            name: 'reportNew',
          }"
        >
          <b-button class="w-100 btn-fleche btn-fleche--actu" variant="primary">
            <span>
              <b-icon-arrow-right aria-hidden="true"/>
              Parcourir toutes les étapes
            </span>
          </b-button>
        </router-link>
      </b-row>
    </b-row>
  </b-container>
</template>

<script>
import {http} from '@/http';
import {mapGetters} from 'vuex';

import AppCardActu from '@/components/appCardActu';

/**
* @vuese
* La liste des fiches climats misent en avant
*/
export default {
  name: 'AppActuList',
  components: {
    AppCardActu
  },
  data() {
    return {
      cards: []
    };
  },
  computed: {
    ...mapGetters('UserStore', ['user'])
  },
  methods: {
    /**
     * @vuese
     * Récupération des dernières étapes indirectes et directes publiées
     * @returns {Promise<AxiosResponse<any>>}
     */
    getCards() {
      return http.get('/cards/actu?').then((res) => {
        this.cards = res.data['hydra:member'];
        return res;
      });
    },
    /**
     * @vuese
     * Recherche d'une étape
     */
    onCardSaved() {
      this.$refs.cardsFilters.searchCardsFiltred();
    },
    /**
     * @vuese
     * Affiche les étapes climats récupérés
     * @returns {*[]}
     */
    showCard() {
      return this.cards.slice(0, 3);
    }
  },
  created() {
    this.getCards();
  }
};
</script>

<style scoped lang="scss">
  .app-actu {
    margin-bottom: 5rem;
  }

  .btn-fleche--actu {
    @media screen and (max-width: 420px) {
      height: fit-content;
    }
  }
</style>
