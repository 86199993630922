import {mapGetters, mapActions} from 'vuex';

export default {
  props: {},
  computed: {
    ...mapGetters('ReportStore', [
      'report',
      'reportPreview',
      'cardsByCategories',
      'cardCategories',
      'selectedCardsCalculate',
      'somme',
      'potentiel',
      'objectif',
      'effort'
    ])
  },
  methods: {
    ...mapActions('ReportStore', [
      'generatePdf',
      'newReport',
      'removeReportCard',
      'visualizeReport'
    ]),
    /**
     * Message de prévention avant suppression des données pour réaliser un nouveau rapport
     * @returns {Promise<BvMsgBoxData>}
     */
    confirmNewReport() {
      return this.$bvModal
          .msgBoxConfirm('Les données non enregistrées seront perdu.', {
            title: 'Nouveau rapport',
            size: 'sm',
            okTitle: 'Oui',
            cancelTitle: 'Non',
            footerClass: 'p-2 d-flex justify-content-center align-items-center',
            hideHeaderClose: false,
            centered: true
          })
          .then((value) => {
            if (value) {
              this.newReport();
              this.$router.push({name: 'reportNew'});
            }
          });
    }
  }
};
