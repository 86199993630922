<template>
  <b-container fluid>
    <b-row class="bread-crumbs">
      <b-col>
        <app-bread-crumbs
            :routes="[
            { name: 'adminHome', title: 'Administration' },
            { name: 'adminUsers', title: 'Gestion de site' },
          ]"
        />
        <div class="page-title-container d-flex justify-content-between">
          <h1 class="page-title">Gestion de site</h1>
          <nav class="d-flex">
            <btn-create-user
                v-if="user.isAdmin"
                class="ml-2"
                @register="refreshEmit('app-user-admin')"
            />
            <b-link class="btn btn-dark btn-lg ml-2" :href="exportUrl" download>
              <b-icon-box-arrow-down aria-label="true"/>
              Export
            </b-link>
          </nav>
        </div>
        <b-tabs no-fade :lazy="true">
          <b-tab title="A valider">
            <app-users
                type="waiting-approval"
                :btnApproved="true"
                :btnDeny="true"
            />
          </b-tab>
          <b-tab title="Adhérent">
            <app-users type="member" :btnNoMember="true" :btnEdit="true"/>
          </b-tab>
          <b-tab title="Non Adhérent">
            <app-users type="no-member" :btnMember="true"/>
          </b-tab>
          <b-tab title="Administrateur">
            <app-users ref="app-user-admin" type="admin"></app-users>
          </b-tab>
          <b-tab title="Supprimés">
            <app-users type="soft-deleted" :btnRestore="true"/>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import appUsers from '../../components/appUsers';
import btnCreateUser from '../../components/btnCreateUser';

/**
 * @vuese
 * Page de création d'un témoignage
 * @group PAGES_ADMIN
 */
export default {
  name: 'AdminUserPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Gestion de site',
      meta: [
        {
          name: 'description',
          content: 'Gestion des utilisateurs de Parcours ABC'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Gestion de site'
        }
      ]
    };
  },
  components: {
    appUsers,
    btnCreateUser
  },
  data() {
    return {
      exportUrl: process.env.VUE_APP_API_URL + '/admin/users/export',
      selected: []
    };
  },
  computed: {
    ...mapGetters('UserStore', ['user'])
  },
  methods: {
    ...mapActions('AdminUserStore', [
      'setMember',
      'setNoMember',
      'setApproved',
      'setApprovedBatch'
    ]),
    /**
     * @vuese
     * Actualisation des données des utilisateurs
     * @arg Les éléments qui doivent être actualisé
     */
    refreshEmit(ref) {
      this.$refs[ref].getItems();
    }
  }
};
</script>
