<template>
  <b-container class="bread-crumbs">
    <app-bread-crumbs
            :routes="[
            { name: 'adminHome', title: 'Administration' },
            { name: 'adminUsers', title: 'Gestion de site' },
            { name: 'adminUserEditPage', title: 'Modification d\'un compte utilisateur' }
          ]"
        />
    <b-row>
      <b-col>
        <h1 class="uppercase page-title">Modifier un compte utilisateur</h1>
        <form-admin-user-edit-account/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import formAdminUserEditAccount from '/src/components/Admin/formAdminUserEditAccount';

/**
 * @vuese
 * Page de modification d'un compte utilisateur par l'administrateur
 * @group PAGES
 */
export default {
  name: 'UserEditPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Modifier mon compte',
      meta: [
        {
          name: 'description',
          content:
              'Modifiez les informations relatives à un compte utilisateur.'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Modifier un compte'
        }
      ]
    };
  },
  components: {
    formAdminUserEditAccount
  }
};
</script>
