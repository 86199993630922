import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import Vue from 'vue';
import Vuex from 'vuex';
import VueNestable from 'vue-nestable';
import VueRecaptcha from 'vue-recaptcha';
import VueModal from '@kouts/vue-modal';
import appBreadCrumbs from '@/components/appBreadCrumbs';
import appCardsFilters from '@/components/appCardsFilters';
import appDraft from '@/components/appDraft';
import appContent from '@/components/appContent';
import appHelp from '@/components/appHelp';
import btnClip from '@/components/btnClip';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import formDyn from '@/components/dyn/formDyn';
import imgMedia from '@/components/imgMedia';
import imgMediaPreview from '@/components/imgMediaPreview';
import inputAutocomplete from '@/components/inputAutocomplete';
import inputCheckboxRecursive from '@/components/inputCheckboxRecursive';
import inputHtml from '@/components/inputHtml';
import inputUpload from '@/components/inputUpload';
import inputImage from '@/components/inputImage';
import inputImageEdit from '@/components/inputImageEdit';
import itemDyn from '@/components/dyn/itemDyn';
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueMq from 'vue-mq';
/**
 * @link https://www.epiloge.com/how-to-add-dynamic-meta-tags-to-your-vuejs-app-for-google-seo-0fa058
 */
import VueMeta from 'vue-meta';

/**
 * @link https://www.digitalocean.com/community/tutorials/vuejs-vue-media-queries
 */
Vue.use(VueMq, {
  breakpoints: {
    md: 1250,
    lg: Infinity
  }
});
Vue.component('Modal', VueModal);
Vue.component('Captcha', VueRecaptcha);
Vue.component('appHelp', appHelp);
Vue.component('appDraft', appDraft);
Vue.component('appContent', appContent);
Vue.component('appBreadCrumbs', appBreadCrumbs);
Vue.component('appCardsFilters', appCardsFilters);
Vue.component('btnClip', btnClip);
Vue.component('formDyn', formDyn);
Vue.component('imgMedia', imgMedia);
Vue.component('imgMediaPreview', imgMediaPreview);
Vue.component('inputAutocomplete', inputAutocomplete);
Vue.component('inputCheckboxRecursive', inputCheckboxRecursive);
Vue.component('inputHtml', inputHtml);
Vue.component('inputImage', inputImage);
Vue.component('inputImageEdit', inputImageEdit);
Vue.component('inputUpload', inputUpload);
Vue.component('itemDyn', itemDyn);

Vue.use(CKEditor);
Vue.use(BootstrapVue, {
  breakpoints: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
  BModal: {
    noFade: true,
    okVariant: 'success',
    okTitle: 'Ok',
    cancelVariant: 'link',
    cancelTitle: 'Annuler',
    noCloseOnEsc: true,
    ignoreEnforceFocusSelector: [
      '.ck-input-text',
      '.ck-input-select',
      '.app-upload'
    ]
  },
  BOverlay: {noFade: true, variant: 'white'},
  BPopover: {noFade: true},
  BButton: {noFade: true},
  BInput: {
    autocomplete: 'off',
    size: 'lg'
  },
  BFormGroup: {
    size: 'lg'
  }
});
Vue.use(BootstrapVueIcons);
Vue.use(VueNestable);
Vue.use(VueModal);
Vue.use(VueRecaptcha);
Vue.use(Vuex);
Vue.use(VueMeta);
