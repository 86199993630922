<template>
  <b-container>
    <!-- Titre Rapport + btn download -->
    <b-row>
      <b-col>
        <div
            class="d-flex flex-wrap justify-content-between align-items-end mt-5"
        >
          <div>
            <h1 class="page-title">RAPPORT</h1>
          </div>
          <div class="w-100 d-flex flex-wrap">
            <router-link
                :to="'/mon-parcours/edition'"
                :variant="load ? 'link' : 'primary'"
                class="btn btn-basic mr-2 d-block w-25 report-btn"
                title="Retour à l'édition"
            >
              <b-icon-arrow-right aria-hidden="true"/>&nbsp;Retour à l'édition
            </router-link>
            <b-btn
                @click.prevent="generatePdf"
                variant="basic"
                title="Télécharger le rapport PDF"
                aria-label="Télécharger le rapport PDF"
                class="mt-1 report-download-btn w-100 m-0 report-btn--download"
            >
              <b-icon-download aria-hidden="true"/>&nbsp;Télécharger
            </b-btn>
          </div>
        </div>
        <hr/>
      </b-col>
    </b-row>
    <!-- Nom créateur rapport + date -->
    <b-row>
      <div class="d-flex justify-content-between align-items-center">
        <p>
          <strong>{{ user.lastName }} {{ user.firstName }}</strong>
        </p>
        <span> {{ report.createdAt | date('m/d/Y') }}</span>
      </div>
    </b-row>
    <!-- Étapes climat-->
    <b-row>
      <b-col sm="12" md="12" lg="12">
        <!-- Liste des étapes climats -->
        <b-container
            v-for="(card, kcard) in getCardsFiltred(report.cards)"
            :key="kcard"
            class="mt-4 stepCol"
        >
          <b-row class="mt-2 value--small">
            <b-col
                v-if="card.val !== 0"
                cols="4"
                class="
                value
                d-flex
                align-items-center
                justify-content-center
                flex-wrap
              "
            >
              <span class="number-size mr-1">{{ card.val }}</span>
              <aside class="unit-info">
                <ul class="unit-info--ul">
                  <li>kgCO2e</li>
                  <li>par personne</li>
                  <li>par an</li>
                </ul>
              </aside>
            </b-col>
            <b-col
                v-else
                cols="4"
                class="
                value value--empty
                d-flex
                align-items-center
                justify-content-center
                flex-wrap
              "
            >
              <span class="mr-1"> Condition nécessaire </span>
            </b-col>
            <b-col class="title-ficheContainer" cols="8">
              <h2
                  class="
                  title-fiche
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                {{ card.name }}
              </h2>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col class="image-ficheContainer" cols="4">
              <b-img
                  class="image-fiche"
                  src="/img/illu_etape_html.png"
                  alt=""
              />
            </b-col>
            <b-col
                cols="8"
                class="description-fiche d-flex flex-column align-items-center"
                v-if="card.description"
            >
              <p v-html="card.description" class="text-description"></p>
            </b-col>
          </b-row>
          <b-row
              v-if="card.pictures.length > 0"
              class="repere-fiche mt-4 position-relative"
          >
            <h3 class="mb-3 repere-title position-absolute">REPÈRES</h3>
            <main class="mb-3 d-flex flex-wrap justify-content-around">
              <div
                  v-for="(picture, kpicture) in card.pictures"
                  :key="kpicture"
                  class="mt-4"
              >
                <img-media
                    :alt="picture.alt"
                    :src="picture.src"
                    class="repere-image"
                />
                <p v-html="picture.meta"></p>
              </div>
            </main>
          </b-row>
          <b-card v-if="card.action" class="mt-4">
            <h4 class="ta-center subtitle-fiche">
              <b-icon-gear-fill aria-hidden="true"/>&nbsp;ACTIONS
            </h4>
            <hr/>
            <p v-html="card.action"></p>
          </b-card>
          <b-card v-if="card.descriptionGoFurther" class="mt-4 mb-4">
            <h4 class="ta-center subtitle-fiche">
              <b-icon-binoculars-fill aria-hidden="true"/>&nbsp;ALLER PLUS LOIN
            </h4>
            <hr/>
            <p v-html="card.descriptionGoFurther"></p>
          </b-card>

          <b-row v-if="card.exemplaries.length > 0" class="mt-5 mb-4">
            <h3 class="subtitle-fiche--ilsLontFait">ILS L'ONT FAIT</h3>
            <b-row
                class="
                d-flex
                flex-wrap
                justify-content-around
                align-items-baseline
              "
            >
              <b-col
                  lg="4"
                  md="6"
                  sm="6"
                  v-for="(exemplary, kexemplary) in card.exemplaries.slice(0, 3)"
                  :key="kexemplary"
                  :value="exemplary.uid"
                  class="
                  exemplary-mobile
                  flex flex-column
                  align-items-center
                  mb-4
                "
              >
                <h5 class="mt-4 exemplary-title">{{ exemplary.title }}</h5>
                <router-link :to="'/ouvrage-exemplaire/' + exemplary.uid" class="exemplary-link">
                  <img-media
                      v-for="(imgPrimary, kimgPrimary) in exemplary.imgPrimary"
                      :key="kimgPrimary"
                      :src="imgPrimary.src"
                      class="imageExemplary mb-4"
                      :alt="imgPrimary.alt"
                  >
                  </img-media>
                </router-link>
                <router-link
                    :to="'/ouvrage-exemplaire/' + exemplary.uid"
                    class="h2 mt-0 mb-0 d-flex justify-content-center"
                >
                  <b-button
                      class="btn-basic btn-basic--exemplary w-100"
                      :variant="load ? 'link' : 'primary'"
                  >
                    <b-icon-eye-fill aria-hidden="true"/>
                    VOIR L'OUVRAGE
                  </b-button
                  >
                </router-link>
              </b-col>
            </b-row>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {http} from '@/http.js';
import {mapGetters} from 'vuex';
import _ from 'lodash';

import imgMedia from '@/components/imgMedia';
import appReportPreviewMixin from '@/components/appReportPreviewMixin.js';

/**
 * @vuese
 * Page de visualisation d'un rapport
 * @group PAGES
 */
export default {
  name: 'ReportPage',
  mixins: [appReportPreviewMixin],
  metaInfo() {
    return {
      title: 'Parcours ABC - Rapport',
      meta: [
        {
          name: 'description',
          content: 'Visualiser votre rapport avant de le télécharger.'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Rapport'
        }
      ]
    };
  },
  components: {
    imgMedia
  },
  data() {
    return {
      pdfUrl:
          process.env.VUE_APP_API_URL + '/pdf/reports/' + this.$route.params.id,
      report: {},
      exemplary: {}
    };
  },
  computed: {
    ...mapGetters('UserStore', ['user'])
  },
  methods: {
    /**
     * @vuese
     * Récupération des fiches climats du rapport créé par l'utilisateur
     * @returns {Promise<AxiosResponse<any>>}
     */
    open() {
      http.get('/api/reports/' + this.$route.params.id).then((res) => {
        this.report = res.data;
        return res;
      });
    },
    /**
     * @vuese
     * Récupération des fiches climats en fonction des filtres après modification d'une fiche
     * @returns {Promise<AxiosResponse<any>>}
     */
    onCardSaved() {
      this.$refs.cardsFilters.searchCardsFiltred();
    },
    /**
     * @vuese
     * Récupération des fiches climats uniquement si elle ne sont pas en brouillon et pas de type complétude
     * @arg La liste des fiches du rapport
     * @returns {Promise<AxiosResponse<any>>}
     */
    getCardsFiltred(cards) {
      return _.filter(cards, (card) => {
        if (card.cardFormat !== undefined) {
          return card.cardFormat.slug !== 'completude' && !card.draft;
        }
        return false;
      });
    }
  },
  created() {
    this.open();
  }
};
</script>

<style lang="scss" scoped>
  ol li {
    list-style-type: disc;
  }

  .card-render {
    margin-top: 100px;
  }

  .card-render h3 {
    margin-top: 50px;
  }

  .stepCol {
    padding-top: 1rem;
    border: 1px solid $grey;
    border-radius: 5px;
  }

  .category-name {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: inline;
    width: calc(100% / 6);
  }

  .category-name li {
    color: #cf5c78;
  }

  .value-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 50px;
  }

  .value {
    background-color: #329db1;
    color: #fff;
    padding: 30px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  .value-block p {
    width: auto;
    margin-left: 15px;
  }

  .block {
    display: flex;
    justify-content: space-around;
  }

  .actions-block {
    margin-left: -150px;
  }

  .image {
    max-width: 380px !important;
    min-width: 200px !important;
    height: auto !important;
  }

  .selection-card {
    margin-top: 50px;
  }

  .title {
    color: #329db1;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 991px) {
    .value {
      padding: 0 10px 10px 0;
    }
  }

  // Style fiche étape //
  ol li {
    list-style-type: disc;
  }

  .card-render {
    margin-top: 100px;
  }

  .card-render h3 {
    margin-top: 50px;
  }

  .value {
    background-color: #329db1;
    color: #fff;
    padding: 0.5rem;

    &--small {
      @media screen and (max-width: 425px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .text-description {
    margin-left: 10px;
    hyphens: none;
  }

  .repere-title {
    background-color: var(--background-color);
    top: -30px;
    width: fit-content;
    padding-left: 0.25rem;
    font-size: 1.5rem !important;
  }

  .repere-image {
    height: auto;
    width: 100%;
  }

  .repere-card {
    @media screen and (max-width: 768px) {
      margin-top: 1.5rem;
    }
  }

  .exemplary-mobile {
    @media screen and (max-width: 576px) {
      width: 60% !important;
    }
  }

  .exemplary-link {
    width: 100%;
  }

  .exemplary-title {
    height: 50px;
    text-align: center;
    @media screen and (max-width: 575px) {
      width: 85vw;
    }
    @media screen and (max-width: 347px) {
      width: 300px;
    }
  }

  .btn-basic {
    @media screen and (max-width: 576px) {
      &--exemplary {
        width: 100% !important;
      }
    }
  }

  .selection-card {
    margin-top: 50px;
  }

  .title-ficheContainer {
    @media screen and (max-width: 575px) {
      height: 53.5px;
    }
  }

  .title-fiche {
    background-color: #fded00;
    color: #282828;
    text-align: center;
    margin: 0 !important;
    height: 100%;
    padding: 0.5rem;
    @media screen and (max-width: 575px) {
      font-size: 16px !important;
    }
  }

  .subtitle-fiche {
    font-size: 1.5rem !important;

    &--ilsLontFait {
      border-bottom: 1px solid #c9c9c9;
      padding-bottom: 0.25rem;
    }

    &--etapesAssociees {
      border-bottom: 1px solid #c9c9c9;
      padding-bottom: 0.25rem;
    }
  }

  .image-ficheContainer {
    text-align: center;
  }

  .image-fiche {
    height: 200px;
    width: auto;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .description-fiche {
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .illu-fiche {
    margin: 1rem 0;
    height: 30vh;
    width: auto;
  }

  .repere-fiche {
    border: solid 1px var(--text-color);
    border-radius: 5px;
    margin-right: 0;
    margin-left: 0;
  }

  .number-size {
    font-size: 45px !important;

    @media screen and (max-width: 575px) {
      font-size: 25px !important;
    }
  }

  @media screen and (max-width: 991px) {
    .appReportPreview--mobile {
      margin-top: 1rem;
    }
  }

  /* =====
  = TAGS =
  ======*/

  .card-tags {
    page-break-inside: avoid;
    margin: 20px 0;
    padding: 0;
  }

  .card-tag {
    color: var(--secondary-color);
  }

  .report-btn {
    display: flex;
    justify-content: center;
    min-width: 280px;
  }

  .report-btn--download {
    min-width: 280px;
  }
</style>
