import {http} from '@/http.js';
import Ls from '@/ls.js';
import _ from 'lodash';
import router from '@/router';
import flat from '../flat.js';

export const ReportStore = {
  namespaced: true,
  getters: {
    cardCategories: (state) => {
      return _.sortBy(
          _.uniq(
              _.map(state.selected, (item) => {
                if (item.cardCategory) {
                  return item.cardCategory.name;
                }
              })
          )
      );
    },
    cardsByCategories: (state) => {
      const cardCategories = {};
      _.each(state.selected, (item) => {
        if (item.cardCategory) {
          let catName = item.cardCategory.name;
          if (cardCategories[catName] === undefined) {
            cardCategories[catName] = [];
          }
          cardCategories[catName].push(item);
        }
      });
      return cardCategories;
    },
    filters: (state) => state.filters,
    filtersPreview: (state) => state.filters,
    filtredSectorsIri: (state) =>
        state.filters.sectors.map((sectorUid) => '/api/sectors/' + sectorUid),
    filtredTagsIri: (state) => state.filters.tags.map((tag) => tag['@id']),
    loader: (state) => state.loader,
    report: (state) => state.report,
    hasA: (state) => {
      return state.filters.sectors.length > 0;
    },
    hasB: (state) => {
      return state.filters.sectors.length > 0 && state.selected.length > 0;
    },
    hasC: () => {
      return true;
    },
    reportPreview: (state) => {
      return {
        name: '',
        description: '',
        datas: state.datas,
        cards: state.selected,
        tags: state.filters.tags.map((tagUid) => '/api/tags/' + tagUid),
        sectors: state.filters.sectors,
        cardsSectors: state.selectedCardsSectors
      };
    },
    selected: (state) => state.selected,
    filteredSelectedSectorsIri: (state) =>
        state.selectedCardsSectors.map((sector) => sector['@id']),
    selectedCardsSectors: (state) => state.selectedCardsSectors,
    selectedCardsIri: (state) => state.selected.map((card) => card['@id']),
    selectedCardsCalculate: (state) => state.selectedCardsCalculate,
    somme: (state) => {
      return state.report.datas.somme;
    },
    potentiel: (state) => {
      return state.report.datas.potentiel;
    },
    objectif: (state) => {
      return state.report.datas.objectif;
    },
    effort: (state) => {
      return state.report.datas.effort;
    }
  },
  state: {
    reportDefault: {
      name: '',
      description: '',
      tags: [],
      sectors: [],
      cardsSectors: [],
      datas: {
        somme: 0,
        potentiel: 0,
        objectif: 0,
        effort: 0
      }
    },
    report: Ls.get('report', {
      name: '',
      description: '',
      tags: [],
      sectors: [],
      datas: {
        somme: 0,
        potentiel: 0,
        objectif: 0,
        effort: 0
      }
    }),
    filters: Ls.get('reportFilters', {
      tags: [],
      sectors: []
    }),
    selectedCardsSectors: Ls.get('selectedCardsSectors', []),
    selectedCardsCalculate: Ls.get('selectedCardsCalculate', []),
    selected: Ls.get('reportSelected', []),
    loader: false
  },
  mutations: {
    SET_FILTERS(state, filters) {
      state.filters = filters;
      Ls.set('reportFilters', filters);
    },
    SET_LOAD(state, loader) {
      state.loader = loader;
    },
    SET_REPORT_ID(state, report) {
      state.report['@id'] = report['@id'];
      state.report.uid = report.uid;
      state.report.id = report.id;
      state.report.name = report.name;
      Ls.set('report', state.report);
    },
    SET_REPORT(state, report) {
      state.report = report;
      state.selected = report.cards;
      Ls.set('report', state.report);
      Ls.set('reportSelected', state.selected);
    },
    SET_DATAS(state, datas) {
      state.report.datas = datas;
      state.report = Ls.set('report', state.report);
    },
    CLEAR_CARDS(state) {
      state.selected = Ls.set('reportSelected', []);
      state.selectedCardsCalculate = Ls.set('selectedCardsCalculate', []);
    },
    ADD_CARD(state, card) {
      // 1 flat children
      state.selectedCardsCalculate = Ls.set(
          'selectedCardsCalculate',
          _.uniqBy(flat.flatChild(card), 'uid')
      );
      state.selected.push(card);
      state.selected = _.uniqBy(state.selected, 'uid');

      Ls.set('reportSelected', state.selected);
    },
    REMOVE_CARD(state, card) {
      let idx = _.findIndex(state.selected, function (o) {
        return o.uid === card.uid;
      });
      state.selected.splice(idx, 1);

      state.selectedCardsCalculate = Ls.set(
          'selectedCardsCalculate',
          _.uniqBy(flat.flatChild(card), 'uid')
      );
      Ls.set('reportSelected', state.selected);
    },
    ADD_SECTOR(state, sector) {
      state.selectedCardsSectors.push(sector);
      Ls.set('selectedCardsSectors', state.selectedCardsSectors);
    },
    REMOVE_SECTOR(state, sector) {
      let idx = state.selectedCardsSectors.indexOf(sector);
      state.selectedCardsSectors.splice(idx, 1);
      Ls.set('selectedCardsSectors', state.selectedCardsSectors);
    },
    CLEAR_SECTORS(state) {
      state.selectedCardsSectors = [];
      Ls.set('selectedCardsSectors', state.selected);
    }
  },
  actions: {
    newReport({commit, state}) {
      let newReport = JSON.parse(JSON.stringify(state.reportDefault));
      commit('SET_REPORT', newReport);
      commit('CLEAR_CARDS');
      commit('CLEAR_SECTORS');
    },
    calcul({commit, state}) {
      const reportSectors = state.filters.sectors;

      // Somme
      let somme = 0;
      const reportCardsFlatten = _.uniqBy(flat.flat(state.selected), 'uid');
      reportCardsFlatten.forEach((item) => {
        if (item?.cardFormat?.slug !== 'indirect' && !item.draft) {
          somme += item.val;
        }
      });

      // Potentiel
      let potentiel = 0;
      reportSectors.forEach((item) => {
        potentiel += item.total;
      });

      // Objectif
      let objectif = Math.round(potentiel / 2);

      // Effort
      let effort = Math.round((somme / potentiel) * 100);

      const datas = {
        somme,
        potentiel,
        objectif,
        effort
      };
      commit('SET_DATAS', datas);
    },
    setReport({commit}, report) {
      commit('SET_REPORT', report);
    },
    setFilters({commit}, filters) {
      commit('SET_FILTERS', filters);
    },
    generateFromData({getters, state, commit}) {
      let dataSend = JSON.parse(JSON.stringify(state.report));
      dataSend.cards = getters.selectedCardsIri;

      if (dataSend.cards.length === 0) {
        return alert('Vous devez au moins sélectionner une étape.');
      }

      this.dispatch('UiStore/loaderOn', 'Préparation du rapport');
      dataSend.tags = getters.filtredTagsIri;
      dataSend.sectors = getters.filteredSelectedSectorsIri;
      if (state.report.uid) {
        dataSend.name = state.report.name;
      } else {
        // dataSend.name = prompt('Nom du rapport', state.report.name)
        let date = new Date();
        let day = date.getDate();
        let monthIndex = _.padStart(date.getMonth() + 1, 2, 0);
        let year = date.getFullYear();

        let name = year + monthIndex + day;
        name += '--' + dataSend.cards.length + '-etapes';
        dataSend.name = name;
      }
      let method = 'post';
      let url = '/api/reports';
      if (state.report.uid) {
        method = 'put';
        url += '/' + state.report.uid;
      }

      let cards = [];
      dataSend.cards.forEach((item) => {
        if (typeof item === 'object') {
          cards.push(item['@id']);
        } else {
          cards.push(item);
        }
      });
      dataSend.cards = cards;

      return http[method](url, dataSend).then((res) => {
        return http
            .post('/report/' + res.data.uid + '/calculate')
            .then((res2) => {
              commit('SET_REPORT_ID', res2.data);
              commit('SET_DATAS', res2.data.datas);
              return res2;
            });
      });
    },
    openReport({commit}, report) {
      commit('SET_REPORT', report);
      commit('SET_LOAD', 1);
      return http.get(report['@id']).then((res) => {
        commit('SET_REPORT', res.data);
        commit('SET_LOAD', false);
        return res;
      });
    },
    saveReport({commit}, report) {
      let formCopy = JSON.parse(JSON.stringify(report));
      formCopy.val = parseInt(formCopy.val);
      this.load = true;
      let url = '/api/reports';

      let method = 'post';
      if (formCopy['@id']) {
        url = formCopy['@id'];
        method = 'put';
      }

      let cards = [];
      formCopy.cards.forEach((item) => {
        if (typeof item === 'object') {
          cards.push(item['@id']);
        } else {
          cards.push(item);
        }
      });
      formCopy.cards = cards;
      commit('SET_LOAD', 1);
      return http[method](url, formCopy).then((res) => {
        commit('SET_REPORT', res.data);
        commit('SET_LOAD', 0);
        return res;
      });
    },
    removeReportCard({commit, state, dispatch}, card) {
      commit('REMOVE_CARD', card);
      // On profite de ma suppression d'une carte pour retirer les secteurs appartenant à cette carte.
      // Il faut vérifier s'il ne reste pas une carte avec un secteur identique
      let numberOfCardsWithThisSector = 0;
      state.selected.forEach((item) => {
        let cardSectorUid = item.sector.uid;
        if (cardSectorUid === card.sector.uid) {
          numberOfCardsWithThisSector++;
        }
      });
      if (numberOfCardsWithThisSector === 0) {
        commit('REMOVE_SECTOR', card.sector);
      }

      dispatch('calcul');
    },
    addReportCard({commit, state, dispatch}, card) {
      commit('ADD_CARD', card);
      // On profite de l'ajout d'une carte pour ajouter les secteurs appartenant à cette carte
      let idx = _.findIndex(state.selectedCardsSectors, function (o) {
        return o.uid === card.sector.uid;
      });
      if (idx === -1) {
        commit('ADD_SECTOR', card.sector);
      }
      dispatch('calcul');
    },
    downloadPdf({state}) {
      this.dispatch(
          'UiStore/loaderOn',
          'Génération de votre rapport personnalisé'
      );
      this.dispatch('UiStore/loaderVariant', 'info');

      let url = '/pdf/reports/' + state.report.uid;
      return http
          .get(url, {
            responseType: 'blob'
          })
          .then((res) => {
            this.dispatch('UiStore/loaderOff');

            const blob = new Blob([res.data], {type: 'application/pdf'});
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = state.report.name + '.pdf';
            link.click();
            return res;
          })
          .catch(() => {
            this.dispatch('UiStore/loaderOff');
          });
    },
    generatePdf({dispatch}) {
      return dispatch('generateFromData').then((res) => {
        dispatch('downloadPdf');
        return res;
      });
    },
    visualizeReport({dispatch}) {
      return dispatch('generateFromData').then((res) => {
        let reportUid = res.data.uid;
        router.push('/mon-parcours/' + reportUid);
        this.dispatch('UiStore/loaderOff');
      });
    }
  }
};
