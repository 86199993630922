<template>
  <div class="d-flex flex-column align-items-center">
    <b-col class="equal-signe"/>
    <b-col lg="5" class="pt-5 mb-4 rec-calcul">
      <b-row>
        <p class="uppercase">Je réduis les émissions de CO2 de mon opération</p>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <router-link
            v-if="!user.uid"
            :to="{
            name: 'login',
          }"
        >
          <b-btn class="btn-calcul uppercase" size="lg">
            <b-icon-calculator aria-hidden="true"/>
            je fais mon calcul
          </b-btn>
        </router-link>
        <router-link
            v-if="user.uid"
            :to="{
            name: 'reportNew',
          }"
        >
          <b-btn class="btn-calcul uppercase" size="lg">
            <b-icon-calculator aria-hidden="true"/>
            je fais mon calcul
          </b-btn>
        </router-link>
      </b-row>
    </b-col>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

/**
* @vuese
* Bloc avec bouton invitant l'utilisateur à faire le calcul de son potentiel de réduction
*/
export default {
  name: 'BtnCalculCo',
  computed: {
    ...mapGetters('UserStore', ['user'])
  }
};
</script>

<style lang="scss">
  .equal-signe {
    border-top: 8px solid var(--primary-color);
    border-bottom: 8px solid var(--primary-color);
    text-align: center;
    padding: 1%;
    margin: 5% 0;
    width: 6% !important;
  }

  .rec-calcul {
    background-color: var(--secondary-color);
    text-align: center;
    padding: 5% 3% 2%;
    color: var(--white-color);

    p {
      font-size: 25px;
    }
  }
</style>
