<template>
  <b-container>
    <app-welcome/>
    <app-actu/>
    <app-testimony/>
    <form-contact/>
  </b-container>
</template>

<script>
import AppTestimony from '@/components/appTestimony';
import AppWelcome from '@/components/appWelcome';
import FormContact from '@/components/formContact';
import AppActu from '@/components/appActu';

/**
 * @vuese
 * Page d'accueil de la plateforme
 * @group PAGES
 */
export default {
  name: 'HomePage',
  metaInfo() {
    return {
      title: 'Parcours ABC',
      meta: [
        {
          name: 'description',
          content:
              "Parcours ABC - Action Bas Carbone accompagne votre projet de construction, d'aménagement ou d'immobilier dans une démarche plus vertueuse. Quelles sont les étapes d'une opération immobilière ? Quel est l'ordre de grandeur de baisse de l'empreinte carbone nécessaire ? Quels sont les chiffres-clés, les actions à mener et les ressources à consulter ?"
        },
        {
          property: 'og:title',
          content: 'Parcours ABC'
        }
      ]
    };
  },
  components: {
    FormContact,
    AppWelcome,
    AppTestimony,
    AppActu
  }
};
</script>
