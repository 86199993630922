import VueRouter from 'vue-router';
import Ls from '@/ls';
import Vue from 'vue';

import ActuPage from '@/pages/ActuPage';
import AdminCardsHierarchyPage from '@/pages/Admin/AdminCardsHierarchyPage';
import AdminCardsIndexPage from '@/pages/Admin/AdminCardsIndexPage';
import AdminExemplaryEditPage from '@/pages/Admin/AdminExemplaryEditPage';
import AdminExemplaryIndexPage from '@/pages/Admin/AdminExemplaryIndexPage';
import AdminHomePage from '@/pages/Admin/AdminHomePage';
import AdminPageEditPage from '@/pages/Admin/AdminPageEditPage';
import AdminPageIndexPage from '@/pages/Admin/AdminPageIndexPage';
import AdminParamsPage from '@/pages/Admin/AdminParamsPage';
import AdminTestimonyEditPage from '@/pages/Admin/AdminTestimonyEditPage';
import AdminTestimonyNewPage from '@/pages/Admin/AdminTestimonyNewPage';
import AdminUserPage from '@/pages/Admin/AdminUserPage';
import AdminUserEditPage from '@/pages/Admin/AdminUserEditPage';
import CardPage from '@/pages/CardPage';
import ContactPage from '@/pages/ContactPage';
import Error404Page from '@/pages/Error404Page';
import ExemplaryPage from '@/pages/ExemplaryPage';
import FindOutMorePage from '@/pages/FindOutMorePage';
import ForgottenPasswordPage from '@/pages/ForgottenPasswordPage';
import HomePage from '@/pages/HomePage';
import LegalsPage from '@/pages/LegalsPage';
import LoginPage from '@/pages/LoginPage';
import LogoutPage from '@/pages/LogoutPage';
import MyAccountEditPage from '@/pages/MyAccountEditPage';
import myAccountPage from '@/pages/myAccountPage';
import PagePage from '@/pages/PagePage';
import RegistredPage from '@/pages/RegistredPage';
import ReportEditPage from '@/pages/ReportEditPage';
import ReportPage from '@/pages/ReportPage';
import ResetPasswordPage from '@/pages/ResetPasswordPage';
import SitemapPage from '@/pages/SitemapPage';
import WhoAreWePage from '@/pages/WhoAreWePage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/se-connecter',
    name: 'login',
    component: LoginPage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/inscription/envoyee',
    name: 'registred',
    component: RegistredPage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/password/forgotten',
    name: 'forgotten-password',
    component: ForgottenPasswordPage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/password/reset/:token',
    name: 'reset-password',
    component: ResetPasswordPage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/deconnexion',
    name: 'logout',
    component: LogoutPage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/a-la-une',
    name: 'actuPage',
    component: ActuPage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/mon-parcours/edition',
    name: 'reportNew',
    component: ReportEditPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mon-parcours/edition/:id',
    name: 'reportEdit',
    component: ReportEditPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mon-parcours/:id',
    name: 'openReport',
    component: ReportPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/accueil',
    name: 'home',
    component: HomePage
  },
  {
    path: '/',
    redirect: '/accueil'
  },
  {
    path: '/qui-sommes-nous',
    name: 'whoAreWe',
    component: WhoAreWePage
  },
  {
    path: '/en-savoir-plus',
    name: 'findOutMore',
    component: FindOutMorePage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/etape/:id',
    name: 'card',
    component: CardPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ouvrage-exemplaire/:id',
    name: 'exemplary',
    component: ExemplaryPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin',
    name: 'adminHome',
    component: AdminHomePage,
    meta: {
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: '/admin/etapes',
    name: 'adminCardsIndex',
    component: AdminCardsIndexPage,
    meta: {
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: '/admin/utilisateurs',
    name: 'adminUsers',
    component: AdminUserPage,
    meta: {
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: '/admin/parametrages',
    name: 'adminParams',
    component: AdminParamsPage,
    meta: {
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: '/admin/ouvrages-exemplaires',
    name: 'adminExemplaryIndex',
    component: AdminExemplaryIndexPage,
    meta: {
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: '/admin/ouvrage-exemplaire/nouveau',
    name: 'adminExemplaryNew',
    component: AdminExemplaryEditPage,
    meta: {
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: '/admin/ouvrage-exemplaire/:id',
    name: 'adminExemplaryEdit',
    component: AdminExemplaryEditPage,
    meta: {
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: '/admin/page/edition',
    name: 'adminPageIndex',
    component: AdminPageIndexPage,
    meta: {
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: '/admin/page/nouveau',
    name: 'adminPageNew',
    component: AdminPageEditPage,
    meta: {
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: '/admin/page/:id',
    name: 'adminPageEdit',
    component: AdminPageEditPage,
    meta: {
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: '/admin/temoignage/nouveau',
    name: 'adminTestimonyNew',
    component: AdminTestimonyNewPage,
    meta: {
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: '/admin/temoignage/:id',
    name: 'adminTestimonyEdit',
    component: AdminTestimonyEditPage,
    meta: {
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: '/admin/user/edit/:uid',
    name: 'adminUserEditPage',
    component: AdminUserEditPage,
    meta: {
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactPage,
    meta: {
      requiresAuth: false,
      admin: false
    }
  },
  {
    path: '/plan-de-site',
    name: 'planDeSite',
    component: SitemapPage,
    meta: {
      requiresAuth: false,
      admin: false
    }
  },
  {
    path: '/mentions-legales',
    name: 'legals',
    component: LegalsPage,
    meta: {
      requiresAuth: false,
      admin: false
    }
  },
  {
    path: '/admin/etapes/hierarchie',
    name: 'adminCardsHierarchy',
    component: AdminCardsHierarchyPage,
    meta: {
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: '/mon-compte',
    name: 'myAccountPage',
    component: myAccountPage,
    meta: {
      requiresAuth: true,
      admin: true
    }
  },
  {
    path: '/editer-mon-compte',
    name: 'myAccountEditPage',
    component: MyAccountEditPage,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:slug',
    name: 'PagePage',
    component: PagePage,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '*',
    name: '404',
    component: Error404Page,
    meta: {
      requiresAuth: false
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  
  localStorage.setItem('lastPageVisited', from.name);
  
  const user = Ls.get('user', {});
  if (to.meta.requiresAuth && !user.uid) {
    next('/se-connecter');
  }
  next();
});

export default router;
