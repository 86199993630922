<template>
  <span>
    <a href :id="id" size="sm" variant="info" pill>
      <b-icon-question-circle-fill aria-hidden="true"/>
    </a>
    <b-popover :target="id" triggers="hover">
      <slot></slot>
    </b-popover>
  </span>
</template>

<script>
/**
* @vuese
* Tooltip d'aide
*/
export default {
  name: 'PopOverHelp',
  props: {
    // Identifiant du lien
    id: {
      type: String,
      default() {
        const rand = Math.random().toString(36).substring(2);
        return 'app-help-' + rand;
      }
    }
  }
};
</script>