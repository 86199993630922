<template>
  <div>
    <component
        class="app-item-dyn"
        :ref="id"
        :is="component"
        :item="item"
        :type="type"
        :value="value"
        :itemShow="item"
        v-if="component"
    >
      <slot></slot>
    </component>
  </div>
</template>
<script>


export default {
  name: 'appItem',
  watch: {
    value() {
      this.loadComponent();
    },
    type() {
      this.loadComponent();
    }
  },
  props: {
    id: {
      type: String,
      default() {
        const rand = Math.random().toString(36).substring(2);
        return '' + rand;
      }
    },
    type: {
      type: String,
      default: () => 'actions'
    },
    value: {
      type: [String, Object],
      default: () => ''
    },
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      component: null
    };
  },
  methods: {
    // @vuese
    /**
     * Import d'un composant de façon dynamique
     */
    loadComponent() {
      this.component = () =>
          import('@/components/dyn/' + this.type + '/item.vue');
    }
  },
  created() {
    this.loadComponent();
  }
};
</script>
