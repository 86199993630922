<template>
  <div class="app-bread-crumbs">
    <div v-for="(route, kroute) in routes" :key="kroute" class="bread-item">
      <router-link :to="route"
      ><small>{{ route.title }}</small></router-link
      >
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
/**
* @vuese
* Fil d'Ariane
*/
export default {
  name: 'AppBreadCrumbs',
  props: {
    // La liste des routes
    routes: Array
  }
};
</script>