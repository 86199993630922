<template>
  <div class="contrast-btn">
    <input
        @change="toggleTheme"
        id="checkbox"
        type="checkbox"
        class="switch-checkbox"
    />
    <label 
      for="checkbox" 
      class="switch-label"
      @click="isActive = !isActive"
      :title="isActive ? 'Changer vers le mode de contraste: élevé' : 'Changer vers le mode de contraste: normal'" 
      :aria-label="isActive ? 'Changer vers le mode de contraste: élevé' : 'Changer vers le mode de contraste: normal'"
    >
      <span>🌙</span>
      <span>☀️</span>
      <div
          class="switch-toggle"
          :class="{ 'switch-toggle-checked': theme === 'dark-theme' }"
      ></div>
    </label>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

/**
* @vuese
* Bouton d'activation du mode contraste élevé
*/
export default {
  name: 'BtnContrast',
  data() {
    return {
      isActive: true,
    };
  },
  computed: {
    ...mapGetters('UiStore', ['theme'])
  },
  methods: {
    ...mapActions('UiStore', ['toggleTheme'])
  }
};
</script>
<!-- https://dev.to/tqbit/create-your-own-dark-mode-toggle-component-with-vue-js-1284 -->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  $elementSize: 4rem;
  $accentColor: #cacaca;
  $textPrimaryColor: #222222;
  $backgroundColorPrimary: #ebebeb;

  .contrast-btn {
    position: fixed;
    bottom: 4px;
    left: 4px;
    z-index: 5000;
  }

  .switch-checkbox {
    display: none;
  }

  .switch-label {
    align-items: center;
    background: $textPrimaryColor;
    outline: 3px solid rgba(#fff, 0.1);
    border-radius: $elementSize;
    cursor: pointer;
    display: flex;
    font-size: calc($elementSize) * 0.3;
    height: calc($elementSize) * 0.56;
    position: relative !important;
    padding: calc($elementSize) * 0.1;
    transition: background 0.5s ease;
    justify-content: space-between;
    width: $elementSize;
    z-index: 1;
  }

  .switch-toggle {
    position: absolute;
    background-color: $backgroundColorPrimary;
    border-radius: 50%;
    top: calc($elementSize) * 0.07;
    left: calc($elementSize) * 0.07;
    height: calc($elementSize) * 0.4;
    width: calc($elementSize) * 0.4;
    transform: translateX(0) !important;
    transition: transform 0.3s ease, background-color 0.5s ease;
  }

  .switch-toggle-checked {
    transform: translateX(calc($elementSize) * 0.45) !important;
  }
</style>
