<template>
  <section>
    <h2 class="title-pink uppercase">mot de passe oublié</h2>
    <p>Veuillez saisir votre adresse email</p>
    <b-form @submit.prevent="resetPasswordProcess()">
      <b-alert variant="success" :show="valid" type="valid">
        <b-icon-check-circle aria-hidden="true"/>
        Mot de passe réinitialisé avec
        <strong>succès</strong>.
      </b-alert>
      <b-alert variant="danger" :show="error" type="error">
        <b-icon-x-circle aria-hidden="true"/>
        Email <strong>invalide</strong> ou lien
        <strong>expiré</strong>.
      </b-alert>
      <b-alert variant="danger" :show="errorConfirmation" type="error">
        <b-icon-x-circle aria-hidden="true"/>
        Mots de passes <strong>non identiques</strong>.
      </b-alert>
      <b-form-group
          label="Email"
          label-for="email"
          class="mt-2"
          :hidden="valid"
      >
        <b-form-input
            type="email"
            name="email"
            id="email"
            required
            v-model="form.username"
            autofocus
            title="L'adresse mail doit contenir une .com, .fr etc. à la fin"
            pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
        />
      </b-form-group>
      <b-form-group
          label="Mot de passe"
          label-for="password"
          class="mt-2"
          :hidden="valid"
      >
        <b-form-input
            type="password"
            name="password"
            id="password"
            required
            v-model="form.password"
            autofocus
        />
      </b-form-group>
      <b-form-group
          label="Confirmation du mot de passe"
          label-for="password-confirm"
          class="mt-2"
          :hidden="valid"
      >
        <b-form-input
            type="password"
            name="password-confirm"
            id="password-confirm"
            required
            v-model="form.passwordConfirm"
            autofocus
        />
      </b-form-group>
      <div class="d-flex justify-content-center align-items-center">
        <b-button
            type="submit"
            :variant="load ? 'link' : 'primary'"
            size="lg"
            :disabled="load"
            class="btn-basic"
            :hidden="valid"
        >
          <span v-if="!load">
            Modifier mon mot de passe<b-icon-arrow-right aria-hidden="true"/>
          </span>
          <span v-else><b-spinner small/></span>
        </b-button>
        <b-button
            :variant="load ? 'link' : 'primary'"
            size="lg"
            :disabled="load"
            class="btn-basic"
            @click="$router.push('/se-connecter')"
            v-if="valid"
        >
          <span v-if="valid">Retour à l'accueil<b-icon-arrow-right aria-hidden="true"/></span>
        </b-button>
      </div>
    </b-form>
  </section>
</template>

<script>
import {mapActions} from 'vuex';

/**
* @vuese
* Formulaire réinitialisation du mot de passe utilisateur
*/
export default {
  name: 'formResetPassword',
  data() {
    return {
      load: false,
      form: {
        username: '',
        password: '',
        passwordConfirm: '',
        token: this.$route.params.token
      },
      error: false,
      errorConfirmation: false,
      valid: false
    };
  },
  methods: {
    ...mapActions('UserStore', ['resetPassword']),
    /**
     * @vuese
     * Procédure de reset du password
     * @returns {*}
     */
    resetPasswordProcess() {
      this.load = true;
      this.error = false;
      if (this.form.password === this.form.passwordConfirm) {
        return this.resetPassword(this.form)
            .then((res) => {
              this.load = false;
              if (res.data === 'Valid') {
                this.errorConfirmation = false;
                this.valid = true;
                return res;
              }
              this.error = true;
              return res;
            })
            .catch(() => {
              this.error = true;
              this.load = false;
            });
      } else {
        this.load = false;
        this.errorConfirmation = true;
      }
    }
  }
};
</script>
