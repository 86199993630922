import { render, staticRenderFns } from "./imgMediaPreview.vue?vue&type=template&id=edb05312&scoped=true"
import script from "./imgMediaPreview.vue?vue&type=script&lang=js"
export * from "./imgMediaPreview.vue?vue&type=script&lang=js"
import style0 from "./imgMediaPreview.vue?vue&type=style&index=0&id=edb05312&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edb05312",
  null
  
)

export default component.exports