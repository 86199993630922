<template>
  <div class="d-flex justify-content-center pt-5">
    <router-link
        v-if="!user.uid"
        :to="{
        name: 'login',
      }"
    >
      <b-btn class="btn-calcul btn-calcul--larger uppercase" size="lg">
        <b-icon-calculator aria-hidden="true"/>
        je fais mon calcul
      </b-btn>
    </router-link>
    <router-link
        v-if="user.uid"
        :to="{
        name: 'reportNew',
      }"
    >
      <b-btn class="btn-calcul btn-calcul--larger uppercase" size="lg">
        <b-icon-calculator aria-hidden="true"/>
        je fais mon calcul
      </b-btn>
    </router-link>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

/**
* @vuese
* Bloc avec bouton invitant l'utilisateur à faire le calcul de son potentiel de réduction, version simplifié
*/
export default {
  name: 'BtnCalculCo',
  computed: {
    ...mapGetters('UserStore', ['user'])
  }
};
</script>

<style scoped lang="scss">
  p {
    font-size: 25px;
  }
</style>
