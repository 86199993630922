<template>
  <div
      id="app-loader"
      class="app-loader d-flex justify-content-center align-items-center"
      :class="' ' + loader.variant"
      v-if="loader.show"
  >
    <div class="d-flex justify-content-center align-items-center flex-column">
      <b-spinner/>
      <p class="app-loader-message strong">{{ loader.message }}</p>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

/**
* @vuese
* Loader de la plateforme, s'affiche lors d'un chargement
*/
export default {
  name: 'AppLoader',
  computed: {
    ...mapGetters('UiStore', ['loader'])
  }
};
</script>

<style lang="scss">
  #app.loader {
    display: inline-block;
    height: 99vh !important;
    overflow: hidden;
  }

  #app-loader {
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: rgba(#fff, 0.9);
    z-index: 1500;

    -webkit-transition: all 275ms ease;
    -moz-transition: all 275ms ease;
    -ms-transition: all 275ms ease;
    -o-transition: all 275ms ease;
    transition: all 275ms ease;

    .app-loader-message {
      font-weight: bolder;
      font-size: 1.5rem;
    }

    &.info {
      background-color: rgba($blue, 0.9);
      color: #fff;
    }

    &.info-dark {
      background-color: rgba(darken($blue, 15%), 0.9);
      color: #fff;
    }
  }
</style>