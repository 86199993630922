<template>
  <b-col
      class="
      abc-step
      d-flex
      flex-column
      align-items-center
      justify-content-center
    "
  >
    <h2 class="abc-step__title">{{ stepLetter }}</h2>
    <p class="abc-step__exp">{{ stepExp }}</p>
  </b-col>
</template>

<script>
/**
* @vuese
* Bandeau bloc A + B + C
*/
export default {
  name: 'AppAbcStep',
  props: {
    // La lettre du bloc
    stepLetter: {
      type: String,
      default() {
        return 'Step Letter';
      }
    },
    // Le texte du bloc
    stepExp: {
      type: String,
      default() {
        return 'Step explanation';
      }
    },
    // Taille d'écran
    md: {
      type: Number,
      default() {
        return 4;
      }
    }
  }
};
</script>

<style lang="scss">
  .abc-step {
    color: $white;
    height: 8rem;
    text-align: center;
    font-weight: bold;

    &.abc-step-a {
      background-color: var(--secondary-color);
    }

    &.abc-step-b {
      background-color: var(--tertiary-color);
    }

    &.abc-step-c {
      color: var(--text-color-blocC);
      background-color: var(--contrast-color);
    }

    .abc-step__title {
      text-transform: uppercase;
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      font-size: 2rem !important;
    }
  }

  .abc-step__exp {
    max-width: 80%;
    margin-bottom: 0;
  }

  @media screen and (max-width: 1322px) {
    .abc-step__exp {
      font-size: 1rem;
      font-weight: bold;
    }
  }
</style>