<template>
  <b-container>
    <b-row>
      <h1>Désolé</h1>
      <p>La page que vous recherchez semble introuvable.</p>
      <p>Code d'erreur: 404</p>
      <router-link
          :to="{
          name: 'home'
        }"
      >
        <b-button class="w-25 btn-fleche" variant="primary">
          <span>
            <b-icon-arrow-right aria-hidden="true"/>
            Retour à l'accueil
          </span>
        </b-button>
      </router-link>
    </b-row>
  </b-container>
</template>

<script>

/**
 * @vuese
 * Page en cas d'erreur 404
 * @group PAGES
 */
export default {
  name: 'Error404Page'
};
</script>