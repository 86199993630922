<template>
  <div class="form-wrapper">
    <form @submit.prevent="save()">
      <b-form-group label="Nom" label-for="categoryName">
        <b-input
            required
            v-model="category.name"
            autofocus
            id="categoryName"
            class="mt-2"
        />
      </b-form-group>
      <div class="d-flex justify-content-center align-items-center">
        <b-btn
            type="submit"
            :variant="load ? 'link' : 'secondary'"
            size="lg"
            :disabled="load"
        >
          <span v-if="!load">Enregistrer</span>
          <span v-else><b-spinner small/></span>
        </b-btn>
      </div>
    </form>
  </div>
</template>

<script>
import {http} from '@/http';

/**
* @vuese
* Formulaire pour les types d'éléments
* @group COMPONENTS_ADMIN
*/
export default {
  name: 'FormAdminCategory',
  props: {
    // Le type d'élément
    category: Object
  },
  data() {
    return {
      load: false,
      form: {
        name: ''
      }
    };
  },
  methods: {
    /**
     * @vuese
     * Création d'un type d'élément
     * @returns {Promise<AxiosResponse<any>>}
     */
    save() {
      let dataSend = JSON.parse(JSON.stringify(this.category));

      this.load = true;

      let url = '/api/card_categories';
      let method = 'post';
      let mode = 'new';
      if (dataSend['@id']) {
        url = dataSend['@id'];
        method = 'put';
        mode = 'update';
      }

      return http[method](url, dataSend).then((res) => {
        // Sauvegarde du type d'élément
        this.$emit('saved', res.data);
        // Création ou mise à jour
        this.$emit(mode, res.data);
        this.load = false;
        return res;
      });
    }
  }
};
</script>