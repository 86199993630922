<template>
  <b-form-group :id="id" class="input-image" label-for="imageLabel">
    <template slot="label" id="imageLabel">
      {{ label }}
    </template>
    <div class="d-flex justify-content-start align-items-center mt-2"></div>
    <b-modal
        :id="id + '-image-modal'"
        :title="'Éditer : image'"
        scrollable
        hide-footer
        size="xl"
    >
      <input-upload @input="onInputUpload"/>
    </b-modal>

    <b-form-checkbox-group
        id="checkbox-categories"
        v-model="selected"
        class="ml-1"
    >
      <div class="d-flex justify-content-start align-items-top flex-wrap">
        <div
            v-for="(src, ksrc) in value"
            :key="ksrc"
            class="p-1 d-flex flex-column"
        >
          <div>
            <b-checkbox
                :value="ksrc"
                class="d-flex justify-content-center align-items-top p-0 m-0"
            >
              {{ ksrc + 1 }}.
            </b-checkbox>
          </div>
          <div @click="onClickItem(src)">
            <img-media-preview :src="src"/>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <btn-clip
                variant="light"
                size="sm"
                :text="urlImg + '/' + src.src"
                :messages="['Copier l url de l image', 'Copié']"
            />
          </div>
        </div>
        <button
            class="btn-add-img"
            v-b-modal="id + '-image-modal'"
            @click.prevent="newImage"
            title="Ajouter une image"
            aria-label="Ajouter une image"
        >
          <b-icon-plus aria-hidden="true"/>
        </button>
      </div>
    </b-form-checkbox-group>
    <b-btn
        v-if="selected.length > 0"
        variant="danger"
        @click.prevent="removeImages"
        title="Supprimer la ou les images"
        aria-label="Supprimer la ou les images"
    >
      <b-icon-trash aria-hidden="true"/>
      Supprimer la selection
    </b-btn>
  </b-form-group>
</template>

<script>
import _ from 'lodash';

/**
 * @vuese
 * Ajout d'une image
 */
export default {
  name: 'inputImage',
  props: {
    id: {
      type: String,
      default() {
        const rand = Math.random().toString(36).substring(2);
        return 'input-autocomplete-' + rand;
      }
    },
    value: {
      type: [Array, Object],
      default: () => []
    },
    type: {
      type: String,
      default: () => 'cards'
    },
    label: {
      type: String,
      default: () => 'cards'
    },
    create: {
      type: Boolean,
      default: () => true
    },
    responseKey: {
      type: String,
      default: () => 'name'
    }
  },
  data() {
    return {
      selected: [],
      imageEdit: '',
      urlImg: process.env.VUE_APP_IMG_FULL_URL
    };
  },
  methods: {
    removeImage(idxVal) {
      this.$bvModal
          .msgBoxConfirm('Supprimer l\'image de la liste', {
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Oui',
            noFade: true,
            cancelTitle: 'Non',
            footerClass: 'd-flex justify-content-center align-items-center',
            hideHeaderClose: false,
            centered: true
          })
          .then((res) => {
            if (res) {
              let valueCopy = JSON.parse(JSON.stringify(this.value));
              valueCopy.splice(idxVal, 1);
              valueCopy = _.uniq(valueCopy);
              this.$emit('input', valueCopy);
              this.value = valueCopy;
            }
            return res;
          });
    },
    removeImages() {
      this.$bvModal
          .msgBoxConfirm('Supprimer les images sélectionnées', {
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Oui',
            noFade: true,
            cancelTitle: 'Non',
            footerClass: 'd-flex justify-content-center align-items-center',
            hideHeaderClose: false,
            centered: true
          })
          .then((res) => {
            if (res) {
              let valueCopy = JSON.parse(JSON.stringify(this.value));
              _.forEach(this.selected, (key) => {
                valueCopy[key] = null;
              });
              valueCopy = _.compact(valueCopy);
              this.$emit('input', valueCopy);
              this.value = valueCopy;
              this.selected = [];
            }
            return res;
          });
    },
    onClickItem(item) {
      this.$emit('clickItem', item);
    },
    editImage(src) {
      this.imageEdit = src;
    },
    newImage() {
      this.imageEdit = '';
    },
    onSaved() {
      this.$root.$emit('bv::hide::modal', this.id + '-image-modal');
    },
    onCreated(file) {
      let valueCopy = JSON.parse(JSON.stringify(this.value));
      valueCopy.push(file);
      this.$emit('input', valueCopy);
      this.value = valueCopy;
    },
    addItem(item) {
      let valueCopy = JSON.parse(JSON.stringify(this.value));
      valueCopy.push(item['@id']);
      _.uniq(valueCopy);
      valueCopy = _.uniq(valueCopy);
      this.$emit('input', valueCopy);
      this.value = valueCopy;
      this.clearFilters();
    },
    onInputUpload(file) {
      let newImage = {
        src: file.tmp.itemsRename[0]
      };
      this.onCreated(newImage);
    }
  }
};
</script>

<style lang="scss">
.input-image {
  .custom-control-label {
    margin-top: 0 !important;
  }
}
</style>
