<template>
  <b-btn
      :variant="variant"
      :size="size"
      @click.prevent="copyClip"
      class="btn-clip"
      :class="css"
      aria-label="Copier le contenu"
  >
    <span class="tooltipText">{{ currentText }}</span>
    <b-icon-clipboard aria-hidden="true"/>
  </b-btn>
</template>

<script>
import methods from '@/methods.js';

/**
 * @vuese
 * Bouton de copie
 */
export default {
  name: 'btnClip',
  props: {
    id: {
      type: String,
      default() {
        const rand = Math.random().toString(36).substring(2);
        return 'clip-' + rand;
      }
    },
    text: {
      type: String,
      default() {
        return '';
      }
    },
    // Variante du bouton
    variant: {
      type: String,
      default: () => 'light'
    },
    // Taille du bouton
    size: {
      type: String,
      default: () => 'sm'
    },
    // Message à l'attention de l'utilisateur
    messages: {
      type: Array,
      default() {
        return ['Copier dans le presse papier', 'Copié'];
      }
    }
  },
  data() {
    return {
      currentText: this.messages[0],
      css: ''
    };
  },
  methods: {
    copyClip() {
      this.css = 'active';
      methods.copyText(this.text);
      this.currentText = this.messages[1];

      setTimeout(() => {
        this.currentText = this.messages[0];
        this.css = '';
      }, 2000);
    }
  }
};
</script>

<style lang="scss" scoped>
  .btn-clip {
    position: relative;
    display: inline-block;

    .tooltipText {
      visibility: hidden;
      line-height: 1;
      width: 140px;
      background-color: rgb(0, 0, 0);
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      font-size: 12px;
      bottom: 125%;
      left: 50%;
      margin-left: -75px;
      opacity: 0;
      transition: opacity 0.3s;

      &::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: rgb(0, 0, 0) transparent transparent transparent;
      }
    }

    &.active .tooltipText,
    &:hover .tooltipText {
      visibility: visible;
      opacity: 1;
    }
  }
</style>
