<template>
  <div class="input-html">
    <vue-nestable
        v-model="cardsSorted"
        keyProp="uid"
        @change="emitChange"
        :max-depth="50"
    >
      <vue-nestable-handle
          slot-scope="{ item }"
          :item="item"
          :class="
          'fiche fiche-' +
          item.cardFormat.slug +
          ' d-flex justify-content-between align-items-center'
        "
      >
        <div class="mr-1">
          <small class="m-1">{{ item.id }}.</small>
          <b-icon-grip-vertical aria-hidden="true"/>
        </div>
        <div
            :class="'legend legend-' + item.cardFormat.slug"
            :title="'fiche ' + item.cardFormat.name"
        >
          <h2 class="mt-0 mb-0">
            <strong>{{ item.name }}</strong>
          </h2>
        </div>
        <div
            class="d-flex justify-content-center align-items-center ml-auto"
            :title="
            item.draft
              ? 'Attention les étapes en brouillon ne sont pas prisent en compte dans les calcules hiérarchiques.'
              : item.val + 'kgCO2e par personne par an'
          "
        >
          <app-draft
              :draft="item.draft"
              class="mr-2"
              title="Attention les étapes en brouillon ne sont pas prisent en compte dans les calcules hiérarchiques"
          />
          <div
              class="card-value d-flex justify-content-start align-items-center"
              :class="{
              draft: item.draft,
            }"
          >
            <span v-if="item.val !== 0" class="unit-value">{{ item.val }}</span>
            <span v-if="item.val !== 0" class="unit-info">
              kgCO2e par personne par an
            </span>
            <span v-else>Condition nécessaire</span>
          </div>
          <btn-admin-card-edit
              :card="item"
              class="ml-1 mr-1"
              @saved="onSaved"
          />
        </div>
      </vue-nestable-handle>
    </vue-nestable>
  </div>
</template>

<script>
import {http} from '@/http.js';
// https://github.com/rhwilr/vue-nestable
/* eslint-disable */
import BtnAdminCardEdit from '@/components/Admin/btnAdminCardEdit.vue';

/**
* @vuese
* Hiérarchisation des fiches climats
*/
export default {
  name: 'InputCardNested',
  components: {
    BtnAdminCardEdit
  },
  data() {
    return {
      cardsSorted: [],
      cardsSortedFlat: []
    };
  },
  watch: {
    cards(nv) {
      this.cardsSorted = JSON.parse(JSON.stringify(nv));
    }
  },
  props: {
    id: {
      type: String,
      default() {
        const rand = Math.random().toString(36).substring(2);
        return 'input-card-nested-' + rand;
      }
    },
    sector: {
      type: Object,
      default() {
        return {};
      }
    },
    // Les fiches climats
    cards: {
      type: Array,
      default() {
        return {};
      }
    }
  },
  methods: {
    /**
     * @vuese
     * Mise à jour de la fiche quand on ajoute ou retire une carte
     * @returns {Promise<AxiosResponse<any>>}
     */
    emitChange() {
      const flatMe = (item) => {
        return {
          id: item.id,
          uid: item.uid,
          children: item.children.map((item) => {
            return flatMe(item);
          })
        };
      };
      const positions = this.cardsSorted.map((item) => {
        return flatMe(item);
      });
      this.load = true;
      return http.post('admin/cards/positions', {positions}).then((res) => {
        this.$emit('change', positions, this.sector.slug);
        this.load = false;
        return res;
      });
    },
    onSaved() {
      this.$emit('saved');
    }
  },
  created() {
    this.cardsSorted = JSON.parse(JSON.stringify(this.cards));
  }
};
</script>
