<template>
  <div class="footer">
    <btn-scroll-top/>
    <footer>
      <b-container class="position-relative">
        <b-row class="bt-2 pt-3 pb-3">
          <b-col class="flex flex-column justify-content-center" sm="12" md="4">
            <ul>
              <li>
                <router-link
                    :to="{ name: 'planDeSite' }"
                    class="app-footer-link--small"
                >Plan de site
                </router-link>
              </li>
              <li>
                <router-link
                    :to="{ name: 'legals' }"
                    class="app-footer-link--small"
                >Mentions légales
                </router-link>
              </li>
              <li>
                <b-link
                    href="https://fr.freepik.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="app-footer-link--small"
                >
                  Illustrations : Freepick.com
                </b-link>
              </li>
            </ul>
          </b-col>
          <b-col sm="12" md="8" class="app-footer--small bg-white--always">
            <span class="app-version p-1"
            ><span class="version" title="version">{{ version }}</span>
            </span>

            <app-logos-platform/>
          </b-col>
        </b-row>
      </b-container>
    </footer>
  </div>
</template>

<script>
const {version} = require('../../package.json');

import BtnScrollTop from '@/components/btnScrollTop';
import AppLogosPlatform from './appLogosPlatform.vue';

/**
* @vuese
* Une fiche climat directe
*/
export default {
  name: 'SiteFooter',
  components: {
    BtnScrollTop,
    AppLogosPlatform
  },
  methods: {
    /**
    * @vuese
    * Détecte la présence d'une barre de scroll
    */
    hasScrollBar() {
      return window.innerWidth > document.body.clientWidth;
    }
  },
  data() {
    return {
      version
    };
  }
};
</script>

<style scoped>
  .app-footer a {
    padding: 0.225rem;
  }

  @media screen and (max-width: 575px) {
    .app-footer--small {
      flex-direction: column !important;
    }

    .app-footer-link--small {
      margin-bottom: 0.5rem;
    }
  }

  .footer ul {
    list-style-type: none;
  }
</style>