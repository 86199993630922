<template>
  <div class="form-wrapper">
    <form @submit.prevent="save()">
      <b-form-group label="Nom" label-for="tagName">
        <b-input
            required
            v-model="tag.name"
            autofocus
            id="tagName"
            class="mt-2"
        />
      </b-form-group>
      <div class="d-flex justify-content-center align-items-center">
        <b-btn
            type="submit"
            :variant="load ? 'link' : 'secondary'"
            size="lg"
            :disabled="load"
        >
          <span v-if="!load">Enregistrer</span>
          <span v-else><b-spinner small/></span>
        </b-btn>
      </div>
    </form>
  </div>
</template>

<script>
import {http} from '@/http';

/**
* @vuese
* Formulaire pour les mot-clés
* @group COMPONENTS_ADMIN
*/
export default {
  name: 'formAdminTag',
  props: {
    // Nom du mot-clé
    tag: {
      type: Object,
      default() {
        return {
          name: ''
        };
      }
    }
  },
  data() {
    return {
      load: false,
      form: {
        name: '',
        tagCategory: ''
      },
      tag_categories: []
    };
  },
  methods: {
    /**
     * @vuese
     * Création d'un nouveau mot-clé
     * @returns {Promise<AxiosResponse<any>>}
     */
    save() {
      let dataSend = JSON.parse(JSON.stringify(this.tag));

      this.load = true;

      let url = '/api/tags';
      let method = 'post';
      let mode = 'new';
      if (dataSend['@id']) {
        url = dataSend['@id'];
        method = 'put';
        mode = 'update';
      }

      return http[method](url, dataSend).then((res) => {
        // Sauvegarde d'un mot-clé
        this.$emit('saved', res.data);
        // Création ou mise à jour
        this.$emit(mode, res.data);

        this.load = false;
        return res;
      });
    }
  }
};
</script>