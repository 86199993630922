<template>
  <b-col class="position-relative">
    <div class="bg-B--reverse bg-B--registration d-none d-lg-block"/>
    <header class="formRegistration-header">
      <h3 class="uppercase">inscrivez-vous</h3>
      <p>Faites votre demande d'inscription</p>
    </header>
    <b-form class="formRegistration-main" @submit.prevent="register(form)">
      
      <b-form-group label="Nom" label-for="nom" class="mt-2">
        <b-form-input v-model="form.lastName" autofocus required id="nom" minlength="2"/>
      </b-form-group>

      <b-form-group label="Prénom" label-for="prenom" class="mt-2">
        <b-form-input v-model="form.firstName" required id="prenom" minlength="2"/>
      </b-form-group>

      <b-form-group label="Email" label-for="emailRegistration" class="mt-2">
        <b-form-input
            v-model="form.email"
            type="email"
            required
            id="emailRegistration"
            title="L'adresse mail doit contenir une .com, .fr etc. à la fin"
            pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
        />
      </b-form-group>

      <b-form-group label="Numéro de téléphone" label-for="phone" class="mt-2">
        <b-form-input
            v-model="form.phone"
            type="tel"
            title="Le numéro de tél. doit être au format: 06 01 02 03 05 ou +33 6 01 02 03 04 05 ou 003360102030405"
            pattern="^(?:0|\(?\+33\)?\s?|0033\s?)[1-79](?:[\.\-\s]?\d\d){4}$"
            required
            id="phone"
        />
      </b-form-group>

      <b-form-group label="Structure" label-for="structure" class="mt-2">
        <b-form-input v-model="form.company" required id="structure"/>
      </b-form-group>

      <b-form-group label="Fonction" label-for="companyRole" class="mt-2">
        <b-form-input v-model="form.companyRole" required id="companyRole"/>
      </b-form-group>

      <b-form-group
          label="Mot de passe"
          label-for="passwordRegistration"
          class="mt-2"
      >
        <b-form-input
            v-model="form.password"
            type="password"
            required
            id="passwordRegistration"
        />
      </b-form-group>
      <b-form-group
          label="Confirmation du mot de passe"
          label-for="passwordSecure"
          class="mt-2"
      >
        <b-form-input
            v-model="form.passwordSecure"
            type="password"
            required
            id="passwordSecure"
        />
      </b-form-group>
      <b-form-group
          label="De quel organisme êtes-vous adhérent ?"
          label-for="organisme"
          class="mt-2"
      >
        <b-select
            v-model="form.from"
            :options="options"
            class="form-control custom-select"
            required
            id="organisme"
        />
      </b-form-group>
      <b-alert variant="danger" :show="showError" type="error">
        <b-icon-x-circle/>
        {{ error.message }}
      </b-alert>
      <div class="d-flex justify-content-center align-items-center">
        <b-button
            type="submit"
            :disabled="!validate()"
            :variant="load ? 'link' : 'basic'"
            size="lg"
        >
          <span v-if="!load" class="uppercase">
            <b-icon-arrow-right aria-hidden="true"/> envoyer ma demande</span>
          <span v-else><b-spinner/></span>
        </b-button>
      </div>
    </b-form>
  </b-col>
</template>
<script>
import {http} from '@/http';
import methods from '@/methods';
import _ from 'lodash';

/**
* @vuese
* Formulaire de création de compte utilisateur
*/
export default {
  name: 'formLogin',
  data() {
    return {
      load: false,
      options: [],
      showError: false,
      error: {
        message: ''
      },
      form: {
        lastName: '',
        firstName: '',
        email: '',
        phone: '',
        companyRole: '',
        company: '',
        from: ''
      },
      status: null
    };
  },
  methods: {
    register() {
      this.load = true;
      this.showError = false;
      const params = methods.jsonToFormData(this.form);
      return http
          .post('/register', params)
          .then((res) => {
            this.$emit('register', res.data);
            this.load = false;
            this.$router.push({name: 'registred'});
            return res;
          })
          .catch(() => {
            this.showError = true;
            this.error.message = 'Cette adresse mail est déjà utilisé !';
            setTimeout(this.load = false, 3000);
          });
    },
    /**
     * @vuese
     * Validation du mot de passe (identique)
     */
    validate() {
      let validate = true;

      if (this.form.password !== this.form.passwordSecure) {
        validate = false;
      }

      return validate;
    },
    getPopulate() {
      return http.get('/api/company_froms').then((res) => {
        this.options = _.map(res.data['hydra:member'], (item) => {
          return {
            value: item['@id'],
            text: item.name
          };
        });
        return res;
      });
    }
  },
  created() {
    this.getPopulate();
  }
};
</script>

<style scoped>
  .formRegistration-main {
    background-color: var(--background-color--transparent) !important;
    position: relative;
    z-index: 10;
    padding-top: 0;
    padding-left: 0;
  }

  .formRegistration-header {
    background-color: var(--background-color--transparent) !important;
    position: relative;
    z-index: 10;
  }

  .bg-B--registration {
    height: 1000px;
    width: 500px;
    top: -50px;
    right: -40px;
  }
</style>