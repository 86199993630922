<template>
  <b-container fluid>
    <!-- BREADCRUMBS -->
    <b-row class="bread-crumbs">
      <b-col>
        <app-bread-crumbs
            :routes="[
            { name: 'adminHome', title: 'Administration' },
            { name: 'adminCardsIndex', title: 'Étapes climats' },
          ]"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
          class="d-flex align-items-center justify-content-between flex-wrap mb-4"
      >
        <div>
          <h1 class="page-title">Étapes Climats</h1>
        </div>
        <div>
          <btn-admin-card-edit-new variant="secondary" size="lg" class="mr-2"/>
          <b-btn :to="{ name: 'adminCardsHierarchy' }" size="lg" variant="dark"
          >Hiérarchie des étapes
          </b-btn
          >
        </div>
      </b-col>
      <b-row class="d-lg-none">
        <app-cards-filters-mobile
            ref="appCardsFilters"
            @filtred="onFiltred"
            @loader="onLoaderMobile"
            :filter-name="filters.name"
            filter-draft="2"
        />
      </b-row>
    </b-row>
    <b-row>
      <b-col class="d-none d-lg-block" md="3">
        <app-cards-filters
            ref="appCardsFilters"
            @filtred="onFiltred"
            @loader="onLoader"
            :filter-name="filters.name"
            filter-draft="2"
        />
      </b-col>
      <!-- ADMIN CARDS -->
      <b-col col md="12" lg="9">
        <b-input-group size="lg">
          <b-input-group-prepend is-text class="bg-white">
            <b-icon-search aria-hidden="true"/>
          </b-input-group-prepend>
          <b-form-input
              required
              size="lg"
              v-model="filtersName"
              placeholder="Recherche libre"
              aria-label="Recherche libre"
          />
        </b-input-group>
        <h2
            class="d-flex justify-content-between align-items-center page-title"
        >
          <div>
            Étapes disponibles&nbsp;:&nbsp;
            <strong class="pl-1">{{ cardsFiltred.length - cardsCompletude.length }}</strong>
          </div>
        </h2>
        <card-legend :show="false"/>
        <b-overlay :show="loader" variant="white" rounded="sm" no-fade>
          <div v-for="(cat, kcat) in cardsFiltredCategories" :key="kcat">
            <b-row>
              <h3 class="sticky-top bg-white page-title">
                {{ cat }} :
                <strong>{{ cardsByCategories[cat] | length }}</strong>
              </h3>
              <b-col
                  sm="12"
                  md="6"
                  lg="3"
                  v-for="(card, kcard) in cardsByCategories[cat]"
                  :key="kcard"
              >
                <app-admin-card
                    :card="card"
                    :edit="true"
                    @saved="onCardSaved"
                    @error="onError"
                    class="mb-3"
                >
                </app-admin-card>
              </b-col>
            </b-row>
          </div>
          <!-- Le client ne souhaite pas que les étapes de complétudes apparaissent sur cette page (c'est déjà le cas dans les filtres) -->
          <!--div v-if="cardsCompletude.length > 0 && isAdmin">
            <b-row>
              <h3 class="sticky-top bg-white page-title">
                Complétude : <strong>{{ cardsCompletude | length }}</strong>
              </h3>
              <b-col
                  sm="12"
                  md="6"
                  lg="3"
                  v-for="(card, kcard) in cardsCompletude"
                  :key="kcard"
              >
                <app-admin-card
                    :card="card"
                    :edit="true"
                    @saved="onCardSaved"
                    class="mb-3"
                >
                </app-admin-card>
              </b-col>
            </b-row>
          </div-->
        </b-overlay>
      </b-col>
    </b-row>
    <b-modal id="cardEdit" hide-footer title="Créer une étape" size="lg">
      <form-admin-card @saved="onCardSaved" :value="{}"/>
    </b-modal>
  </b-container>
</template>

<script>
import _ from 'lodash';
import {mapGetters, mapActions} from 'vuex';

import AppAdminCard from '@/components/Admin/appAdminCard';
import BtnAdminCardEditNew from '@/components/Admin/btnAdminCardEditNew';
import CardLegend from '@/components/cardLegend';
import FormAdminCard from '@/components/Admin/formAdminCard';
import AppCardsFiltersMobile from '@/components/appCardsFiltersMobile';

/**
 * @vuese
 * Page affichant les fiches climats, pour l'admin
 * @group PAGES_ADMIN
 */
export default {
  name: 'AdminCardsIndexPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Étapes climats',
      meta: [
        {
          name: 'description',
          content: 'Création et modification des étapes climats.'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Étapes climats'
        }
      ]
    };
  },
  components: {
    AppAdminCard,
    BtnAdminCardEditNew,
    CardLegend,
    FormAdminCard,
    AppCardsFiltersMobile
  },
  computed: {
    filtersName: {
      get() {
        return this.$store.getters['CardStore/filtersName'];
      },
      set(value) {
        return this.setFilter({
          key: 'filtersName',
          value
        });
      }
    },
    ...mapGetters('UserStore', ['isAdmin']),
    /**
     * @vuese
     * Récupère les étapes selon un nom de catégorie donné
     * @returns {{}}
     */
    cardsBy() {
      const cardCategories = {};
      _.each(this.cardsFiltred, (item) => {
        let catName = item.cardCategory.name;
        if (cardCategories[catName] === undefined) {
          cardCategories[catName] = [];
        }
        cardCategories[catName].push(item);
      });
      return cardCategories;
    },
    /**
     * @vuese
     * Récupère les étapes selon un nom de catégorie donné
     * @returns {{}}
     */
    cardsByCategories() {
      const cardCategories = {};
      _.each(this.cardsFiltred, (item) => {
        if (item.cardFormat.name !== 'completude') {
          let catName = item.cardCategory.name;
          if (cardCategories[catName] === undefined) {
            cardCategories[catName] = [];
          }
          cardCategories[catName].push(item);
        }
      });
      return cardCategories;
    },
    /**
     * @vuese
     * Retourne les étapes de "complétude"
     * @returns {T[]}
     */
    cardsCompletude() {
      return _.compact(
          _.map(this.cardsFiltred, (item) => {
            if (item.cardFormat.name === 'completude') {
              return item;
            }
          })
      );
    },
    /**
     * @vuese
     * Retourne les étapes d'une catégorie
     * @returns {unknown[]}
     */
    cardsFiltredCategories() {
      return _.sortBy(
          _.compact(
              _.uniq(
                  _.map(this.cardsFiltred, (item) => {
                    if (item.cardFormat.name !== 'completude') {
                      return item.cardCategory.name;
                    }
                  })
              )
          )
      );
    }
  },
  data() {
    return {
      loader: false,
      item: {
        name: ''
      },
      cardsFiltred: [],
      filters: {}
    };
  },
  methods: {
    ...mapActions('CardStore', ['setFilter']),
    /**
     * @vuese
     * Que faire lors du chargement
     * @arg Le loader
     */
    onLoaderMobile(loader) {
      this.loader = loader;
    },
    /**
     * @vuese
     * Que faire lors du chargement sur mobile
     * @arg Le loader
     */
    onLoader(loader) {
      this.loader = loader;
    },
    /**
     * @vuese
     * Mise à jour des fiches filtrées
     * @arg Représente les données récupérées et filtrées
     */
    onFiltred(dataFiltred) {
      this.cardsFiltred = dataFiltred.cardsFiltred;
      this.filters = dataFiltred.filters;
    },
    /**
      * @vuese
      * Récupération des fiches climats en fonction des filtres après modification d'une fiche
      * @returns {Promise<AxiosResponse<any>>}
      */
    onCardSaved() {
      this.$refs.appCardsFilters.searchCardsFiltred();
      // Disparition de l'overlay lors de la fermeture de la fenêtre modale
      this.$root.$emit('bv::hide::modal', 'cardEdit');
    },
    /**
     * @vuese
     *Gestion des erreurs
     */
    onError() {
      this.$refs.appCardsFilters.searchCardsFiltred();
    },
    /**
     * @vuese
     * Création d'une nouvelle fiche
     */
    newCard() {
      // Apparition de l'overlay lors de l'ouverture de la fenêtre modale
      this.$root.$emit('bv::show::modal', 'cardEdit');
    }
  }
};
</script>