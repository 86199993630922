<template>
  <b-container class="mt-4">
    <b-row>
      <b-col v-html="page.content"></b-col>
    </b-row>
  </b-container>
</template>

<script>
import {http} from '@/http.js';

/**
 * @vuese
 * Page de modèle d'une page créé par un administrateur
 * @group PAGES
 */
export default {
  name: 'PagePage',
  data() {
    return {
      page: {}
    };
  },
  methods: {
    loadPage() {
      http.get('pages/' + this.$route.params.slug).then((res) => {
        this.page = res.data;
        return res;
      });
    }
  },
  created() {
    this.loadPage();
  }
};
</script>