<template>
  <b-container class="report-preview border-full position-relative">
    <div v-if="reportPreview.cards.length > 0">
      <div
        class="
          d-flex
          justify-content-center
          align-items-between
          flex-column
          my-2
          report-btn
        "
        v-if="reportPreview.cards.length > 0"
      >
        <b-btn
          @click.prevent="visualizeReport"
          variant="basic"
          title="Visualiser le rapport PDF"
          aria-label="Visualiser le rapport PDF"
          class="report-view-btn"
        >
          <b-icon-eye aria-hidden="true"/> Visualiser
        </b-btn>
        <b-btn
          @click.prevent="generatePdf"
          variant="basic"
          title="Télécharger le rapport PDF"
          aria-label="Télécharger le rapport PDF"
          class="mt-1 report-download-btn"
        >
          <b-icon-download aria-hidden="true"/> Télécharger
        </b-btn>
        <b-btn
          variant="basic"
          @click.prevent="confirmNewReport"
          title="Créer un nouveau rapport"
          aria-label="Créer un nouveau rapport"
          class="mt-1 report-new-btn"
        >
          Nouveau rapport
        </b-btn>
      </div>
      <div class="d-flex align-items-center flex-column">
        <app-report-graph />
      </div>
      <div>
        <h2 class="uppercase title-blue title-report-preview pl-1 pr-1 mt-0">
          Étapes sélectionnées
        </h2>
      </div>
      <div class="report-cards mt-2 pl-1 pr-1">
        <div
          v-for="(cardCategory, kcardCategory) in cardCategories"
          :key="kcardCategory"
        >
          <h3 class="mt-3 title-blue bb mb-1">
            <strong>{{ cardCategory }}</strong>
          </h3>
          <div
            v-for="(card, kcard) in cardsByCategories[cardCategory]"
            :key="kcard"
            class="
              report-cards-item
              d-flex d-flex
              justify-content-between
              align-items-center
            "
            :class="'report-cards-item-' + card.cardFormat.slug"
          >
            <b-btn
              size="sm"
              variant="danger"
              class="no-decoration b-trans p-1"
              @click="removeReportCard(card)"
              :title="'Retirer l\'étape : ' + card.name"
              :aria-label="'Retirer l\'étape : ' + card.name"
            >
              <b-icon-x aria-hidden="true"/>
              </b-btn>
            <strong class="pl-1 pr-1"
              ><a class="reportPreview-title" :href="'/etape/' + card.uid">
                {{ card.name }}
              </a></strong
            >
            <small v-if="card.draft" class="muted">brouillon</small>
            <strong
              class="
                small
                pl-1
                pr-1
                ml-auto
                report-preview-card-value
                d-flex
                justify-content-between
                align-items-center
              "
              :title="'valeur CO2 : ' + card.val"
              >{{ card.val }}</strong
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else class="p-3">
      <b-alert show class="mb-0"
        >Vous devez sélectionner au moins une étape.</b-alert
      >
    </div>
  </b-container>
</template>

<script>
import appReportGraph from "./appReportGraph.vue";
import appReportPreviewMixin from "./appReportPreviewMixin";

/**
* @vuese
* Prévisualisation du rapport
*/
export default {
  name: "AppReportPreview",
  mixins: [appReportPreviewMixin],
  components: {
    appReportGraph,
  },
};
</script>
<style lang="scss">
.report-cards {
  .report-cards-item {
    padding: 0;
    border-radius: 0.225rem;
    margin-bottom: 0.225rem;
  }
  .report-cards-item-indirect {
    background-color: #e9a8b7;
    .report-preview-card-value {
      background-color: #dc738b !important;
      color: #fff;
    }
  }
  .report-cards-item-direct {
    background-color: #72c7d7;
    .report-preview-card-value {
      background-color: #329db1 !important;
      color: #fff;
    }
  }
  .report-cards-item-completude {
    background-color: var(--fiche-completude-color);
    .report-preview-card-value {
      background-color: darken($greyLighter, 10%) !important;
      color: #fff;
    }
  }
  .report-cards-item {
    position: relative;
    .report-preview-card-value {
      height: 30px !important;
      display: inline-block;
      border-top-right-radius: 0.225rem;
      border-bottom-right-radius: 0.225rem;
    }
  }
}

.reportPreview-title {
  color: #323232 !important;
}

.report-download-btn,
.report-view-btn,
.report-new-btn {
  width: 95% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

@media screen and (max-width: 1200px) {
  .report-btn {
    flex-direction: column;
    justify-content: space-around;
  }
  .report-download-btn,
  .report-view-btn {
    margin-bottom: 1rem;
  }
  .title-report-preview {
    font-size: 23px !important;
  }
}

.bb {
  border-bottom: 2px solid var(--primary-color);
  margin-bottom: 1rem;
}

.report-preview {
  background-color: var(--background-color) !important;
  width: 100%;
  padding: 0 !important;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.16);
}
.title-report-preview {
  word-break: break-word;
}
</style>
