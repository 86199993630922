var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"app-menu",attrs:{"role":"navigation"}},[_c('b-navbar',{attrs:{"id":"navbar-connected","toggleable":"lg","type":"light"}},[_c('b-navbar-toggle',{attrs:{"id":"navbar-toggler-connected","target":"nav-collapse"}}),_c('b-collapse',{staticClass:"nav-center",attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"align-items-center"},[_c('b-nav-item',{attrs:{"to":{
            name: 'home',
          },"alt":"Logo action bas carbone","aria-label":"Accueil"}},[_c('b-img',{staticClass:"logo-menu",attrs:{"src":"/img/logo_abc.svg","alt":""}})],1),_c('b-nav-item',{staticClass:"links--connected menu-item menu-item--connected",attrs:{"to":{ name: 'home' }}},[_vm._v(" accueil ")]),_c('b-nav-item',{staticClass:"links--connected menu-item",attrs:{"to":{ name: 'whoAreWe' }}},[_vm._v("qui sommes-nous ? ")]),_c('b-nav-item',{staticClass:"links--connected menu-item menu-item--connected",attrs:{"to":{ name: 'actuPage' }}},[_vm._v(" à la une ")]),_c('b-nav-item',{staticClass:"links--connected menu-item menu-item--connected",attrs:{"to":{ name: 'reportNew' }}},[_vm._v("mon parcours ")]),_c('b-nav-item',{staticClass:"links--connected menu-item menu-item--connected",attrs:{"to":{ name: 'findOutMore' }}},[_vm._v(" en savoir plus ")]),_c('b-nav-item',{staticClass:"links--connected menu-item menu-item--connected",attrs:{"to":{ name: 'contact' }}},[_vm._v(" contact ")]),_c('b-nav-item',{staticClass:"links--connected menu-item--user",attrs:{"to":{
            name: 'myAccountPage',
          }}},[_c('b-icon',{attrs:{"icon":"person-circle","aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.user.firstName.substring(0, 1))+". "+_vm._s(_vm.user.lastName)+" ")],1),_c('b-nav-item',{staticClass:"links menu-item",attrs:{"to":{
            name: 'logout',
          },"title":"Déconnexion"}},[_c('b-icon',{attrs:{"icon":"box-arrow-right","aria-hidden":"true"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }