<template>
  <b-row>
    <b-col>
      <h2>Pages</h2>
      <b-list-group>
        <b-list-group-item to="accueil"> Accueil</b-list-group-item>
        <b-list-group-item to="qui-sommes-nous">
          Qui sommes-nous ?
        </b-list-group-item>
        <b-list-group-item to="en-savoir-plus">
          En savoir plus
        </b-list-group-item>
        <b-list-group-item to="a-la-une"> À la une</b-list-group-item>
        <b-list-group-item to="se-connecter">
          Connexion/Inscription
        </b-list-group-item>
        <b-list-group-item to="plan-de-site"> Plan de site</b-list-group-item>
        <b-list-group-item to="mentions-legales">
          Mentions légales
        </b-list-group-item>
      </b-list-group>
    </b-col>
    <b-col v-if="!isAdmin && user.uid">
      <h2>Pages Adhérents</h2>
      <b-list-group>
        <b-list-group-item to="/mon-parcours/edition">
          Mon parcours
        </b-list-group-item>
        <b-list-group-item to="mon-compte"> Mon compte</b-list-group-item>
        <b-list-group-item to="/editer-mon-compte">
          Modifier mon compte
        </b-list-group-item>
      </b-list-group>
    </b-col>
    <b-col v-if="isAdmin">
      <h2>Pages Admin</h2>
      <b-list-group>
        <b-list-group-item to="admin">
          Accueil administrateur
        </b-list-group-item>
        <b-list-group-item to="/admin/etapes">
          Étapes climats
        </b-list-group-item>
        <b-list-group-item to="/admin/ouvrages-exemplaires">
          <b-link>Ouvrages exemplaires</b-link>
        </b-list-group-item>
        <b-list-group-item to="/admin/page/edition"> Pages</b-list-group-item>
        <b-list-group-item to="/admin/parametrages">
          Classement
        </b-list-group-item>
        <b-list-group-item to="/admin/utilisateurs">
          Gestion de site
        </b-list-group-item>
        <b-list-group-item to="mon-compte"> Mon compte</b-list-group-item>
        <b-list-group-item to="/editer-mon-compte">
          Modifier mon compte
        </b-list-group-item>
      </b-list-group>
    </b-col>
  </b-row>
</template>

<script>
import {mapGetters} from 'vuex';

/**
* @vuese
* Plan du site
*/
export default {
  name: 'appPlanDeSite',
  computed: {
    ...mapGetters('UserStore', ['user', 'isAdmin'])
  }
};
</script>
