<template>
  <div class="app-admin-nested">
    <b-overlay :show="load">
      <div class="p-2 sticky-top">
        <slot></slot>
        <b-btn
            variant="success"
            size="lg"
            @click.prevent="savePosition"
            class="mt-2 mr-2"
        >
          Sauvegarder la hiérarchie
        </b-btn>
      </div>
      <vue-nestable
          v-model="items"
          :keyProp="keyProp"
          :childrenProp="childrenProp"
          :max-depth="maxDepth"
          @input="emitInput"
      >
        <!-- Place holder si aucun résultat -->
        <div slot="placeholder">
          <b>Aucun résultat</b>
        </div>
        <vue-nestable-handle slot-scope="{ item, index }" :item="item">
          <div
              class="d-flex justify-content-between align-items-center p-2 w-100"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div class="mr-1 d-flex justify-content-start align-items-center">
                <small class="m-1">{{ index + 1 }}.</small>
                <b-icon-grip-vertical aria-hidden="true"/>
                <strong>{{ item.name }}</strong>
              </div>
              <b-btn
                  class="ml-2"
                  variant="link"
                  size="sm"
                  title="Éditer"
                  aria-label="Éditer"
                  @click.prevent="emitEdit(item)"
              >
                <b-icon-pencil aria-hidden="true"/>
              </b-btn>
            </div>
            <b-btn
                size="sm"
                variant="link"
                title="Supprimer"
                aria-label="Supprimer"
                @click.prevent="emitDelete(item)"
            >
              <b-icon-trash aria-hidden="true"/>
            </b-btn>
          </div>
        </vue-nestable-handle>
      </vue-nestable>
    </b-overlay>
  </div>
</template>

<script>
import {http} from '@/http.js';
import methods from '@/methods.js';

/**
* @vuese
* La hiérarchie des fiches climats, administrable via un système de Glisser-déposer
* @group COMPONENTS_ADMIN
*/
export default {
  name: 'AppAdminNested',
  props: {
    // Type de la fiche
    type: String,
    // Groupe de fiches
    groups: {
      type: Array,
      default: () => []
    },
    // Liste des fiches parentes
    parent: {
      type: Array,
      default: () => []
    },
    // Identifiant de la fiche
    keyProp: {
      type: String,
      default: () => 'uid'
    },
    // Props de fiches enfants
    childrenProp: {
      type: String,
      default: () => 'children'
    },
    // Profondeur maximum de la hiérarchie
    maxDepth: {
      type: Number,
      default: () => 10
    }
  },
  data() {
    return {
      load: false,
      search: {
        name: ''
      },
      items: [],
      allPositions: []
    };
  },
  methods: {
    /**
     * @vuese
     * Récupération de la liste des éléments à la sauvegarde
     */
    onSaved() {
      this.getItems();
    },
    /**
     * @vuese
     * Mise à plat de l'élément quand la position a changé
     * @arg Valeur de la fiche climat
     * @returns {*}
     */
    emitInput(value) {
      const flatMe = (item) => {
        return {
          id: item.id,
          '@id': item['@id'],
          uid: item.uid,
          children: item.children.map((item) => {
            return flatMe(item);
          })
        };
      };
      this.allPositions = value.map((item) => {
        return flatMe(item);
      });
      return value;
    },
    /**
     * @vuese
     * Sauvegarde de la position des éléments
     * @returns {Promise<AxiosResponse<any>>}
     */
    savePosition() {
      this.load = true;
      let url = '/admin/' + this.type + '/positions';
      return http
          .post(url, {
            positions: this.allPositions
          })
          .then((res) => {
            this.getItems();
            this.load = false;
            return res;
          });
    },
    /**
     * @vuese
     * Récupération de la liste des éléments à afficher
     * @returns {Promise<AxiosResponse<any>>}
     */
    getItems() {
      this.load = true;
      let url = '/admin/' + this.type;
      let params = {
        name: this.search.name,
        groups: this.groups
      };

      url += '?' + methods.buildQuery(params) + '=null';
      return http.get(url).then((res) => {
        this.items = res.data['hydra:member'];
        this.emitInput(this.items);
        this.load = false;
        return res;
      });
    },
    /**
     * @vuese
     * Remise à zéro des filtres
     */
    clearFilter() {
      this.search = {
        name: ''
      };
      this.getItems();
    },
    /**
     * @vuese
     * Modification d'une fiche climat
     * @arg Une fiche climat
     */
    emitEdit(item) {
      // Emission de l'évènement d'édition d'un élément
      this.$emit('edit', item);
    },
    /**
     * Suppression d'une fiche climat
     * @arg Une fiche climat
     */
    emitDelete(item) {
      this.$bvModal
          .msgBoxConfirm('Confirmer la suppression', {
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Oui',
            noFade: true,
            cancelTitle: 'Non',
            footerClass: 'd-flex justify-content-center align-items-center',
            hideHeaderClose: false,
            centered: true
          })
          .then((value) => {
            if (value) {
              return http.delete(item['@id']).then((res) => {
                this.getItems();
                // Emission de l'évènement de suppression d'un élément
                this.$emit('delete', item);
                return res;
              });
            }
          });
    }
  },
  created() {
    this.getItems();
  }
};
</script>
