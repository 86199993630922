<template>
  <div>
    <b-overlay :show="load" variant="white" rounded="sm" no-fade>
      <div class="d-flex justify-content-start align-items-center pt-2 pb-2">
        <slot></slot>
        <b-btn
            @click.prevent="getItems()"
            size="lg"
            variant="success"
            class="mt-2 mr-2"
        >
          <b-icon-search aria-hidden="true"/>
          Rechercher
        </b-btn
        >
        <b-btn
            @click.prevent="clearFilter"
            variant="danger"
            class="mt-2 no-decoration"
            size="lg"
        >Vider la recherche
        </b-btn
        >
      </div>
      <table class="table">
        <thead>
        <tr>
          <th class="text-center">
            <label for="toggle-all" class="capitalize"
            >tout sélectionner</label
            >
            <b-form-checkbox
                v-model="allSelected"
                :indeterminate="indeterminate"
                @change="toggleAll"
                id="toggle-all"
            />
          </th>
          <th>
            <label for="firstname" class="capitalize">prénom</label>
            <b-input
                v-model="search.firstName"
                @keypress.enter="getItems()"
                placeholder="Rechercher"
                id="firstname"
            />
          </th>
          <th>
            <label for="lastname" class="capitalize">nom</label>
            <b-input
                @keypress.enter="getItems()"
                v-model="search.lastName"
                placeholder="Rechercher"
                id="lastname"
            />
          </th>
          <th>
            <label for="email" class="capitalize">email</label>
            <b-input
                @keypress.enter="getItems()"
                v-model="search.email"
                placeholder="Rechercher"
                id="email"
            />
          </th>
          <th class="capitalize">
            <label for="phone" class="capitalize">Téléphone</label>
            <b-input
                @keypress.enter="getItems()"
                v-model="search.phone"
                placeholder="Rechercher"
                id="phone"
            />
          </th>
          <th class="capitalize">structure</th>
          <th class="capitalize">fonction</th>
          <th class="capitalize">adhérent de</th>
          <th class="capitalize">dates</th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody v-if="items.length > 0">
        <tr v-for="(userItem, kuserItem) in items" :key="kuserItem">
          <td class="text-center">
            <label
                :for="'toggle-item:' + userItem.uid"
                class="capitalize td-label"
            >sélectionner</label
            >
            <b-form-checkbox
                v-model="selected"
                :value="userItem.uid"
                :id="'toggle-item:' + userItem.uid"
            />
          </td>
          <td>{{ userItem.firstName }}</td>
          <td>{{ userItem.lastName }}</td>
          <td>{{ userItem.email }}</td>
          <td>{{ userItem.phone }}</td>
          <td>
              <span v-if="userItem.company">
                {{ userItem.company.name }}
              </span>
            <span v-else>-</span>
          </td>
          <td>{{ userItem.companyRole }}</td>
          <td>
            <strong v-if="userItem.companyFrom">
              {{ userItem.companyFrom.name }}
            </strong>
          </td>
          <td class="text-right">
            <small>
              <div title="date de suppression">
                créé : {{ userItem.createdAt | datetime }}
              </div>
              <div v-if="!userItem.deletedAt" title="date de mise à jour">
                maj : {{ userItem.updatedAt | datetime }}
              </div>
              <div v-else title="date de suppression">
                sup : {{ userItem.deletedAt | datetime }}
              </div>
            </small>
          </td>
          <td class="text-right">
            <b-btn-group>
              <b-btn
                  v-if="btnMember"
                  variant="success"
                  class="mr-2"
                  @click.prevent="setAction('setMember', userItem)"
                  title="Définir l'utilisateur comme adhérent"
              >Définir en adhérent
              </b-btn
              >
              <b-btn
                  v-if="btnNoMember"
                  variant="danger"
                  class="mr-2"
                  @click.prevent="setAction('setNoMember', userItem)"
                  title="Définir l'utilisateur comme non adhérent"
                  aria-label="Définir l'utilisateur comme non adhérent"
              >Définir en Non adhérent
              </b-btn
              >
              <b-btn
                  v-if="userItem.waitingApproval && btnApproved"
                  @click.prevent="setAction('setApproved', userItem)"
                  title="Approuver l'utilisateur"
                  aria-label="Approuver l'utilisateur"
                  variant="success"
                  class="mr-2"
              >
                <b-icon-hand-thumbs-up aria-hidden="true"/>
                Approuver
              </b-btn
              >
              <b-btn
                  v-if="userItem.waitingApproval && btnDeny"
                  variant="danger"
                  title="Refuser l'inscription de l'utilisateur"
                  aria-label="Refuser l'inscription de l'utilisateur"
                  @click.prevent="setAction('setDeny', userItem)"
                  class="mr-2 white"
              >
                <b-icon-hand-thumbs-down aria-hidden="true"/>
                Refuser
              </b-btn
              >
              <b-btn
                  v-if="user.isAdmin && userItem.uid !== user.uid && btnRestore"
                  variant="success"
                  class="mr-2"
                  @click.prevent="restoreUser(userItem)"
                  title="Restorer l'utilisateur"
                  aria-label="Restorer l'utilisateur'"
              >
                <b-icon-arrow-counterclockwise aria-hidden="true"/>
              </b-btn>
              <b-btn
                  v-if="user.isAdmin && userItem.uid != user.uid && btnEdit"
                  :href="'/admin/user/edit/' + userItem.uid"
                  variant="info"
                  size="sm"
                  class="mr-2 white"
                  title="Modifier l'utilisateur"
                  aria-label="Modifier l'utilisateur"
              >
                <b-icon-pencil-square aria-label="true"/>
              </b-btn>
              <b-btn
                  v-if="user.isAdmin && userItem.uid != user.uid"
                  variant="danger"
                  :title="
                    !userItem.deletedAt
                      ? 'Supprimer l\'utilisateur'
                      : 'Supprimer définitivement l\'utilisateur'
                  "
                  :aria-label="
                    !userItem.deletedAt
                      ? 'Supprimer l\'utilisateur'
                      : 'Supprimer définitivement l\'utilisateur'
                  "
                  @click.prevent="deleteUser(userItem)"
              >
                <b-icon-trash aria-label="true"/>
              </b-btn>
            </b-btn-group>
          </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr>
          <td colspan="9" class="text-center uppercase">aucun résultat</td>
        </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-end align-items-center mb-3">
        <div class="d-flex justify-content-between align-items-center">
          <b-select
              v-model="pagination.perPage"
              @input="getItems(type)"
              class="mr-2 mb-0"
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </b-select>
          <b-pagination
              class="mb-0"
              v-model="pagination.page"
              :per-page="pagination.perPage"
              :total-rows="pagination.totalRows"
              @input="getItems(type)"
          />
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {http} from '@/http.js';
import {mapActions, mapGetters} from 'vuex';

/**
* @vuese
* Affichage de la liste des utilisateurs
* @group COMPONENTS_ADMIN
*/
export default {
  name: 'AppUsers',
  props: {
    // Membre 
    btnMember: {
      type: Boolean,
      default: () => false
    },
    // Non membre
    btnNoMember: {
      type: Boolean,
      default: () => false
    },
    // Approuvé
    btnApproved: {
      type: Boolean,
      default: () => false
    },
    // Modification
    btnEdit: {
      type: Boolean,
      default: () => false
    },
    // Restoration
    btnRestore: {
      type: Boolean,
      default: () => false
    },
    // Refus
    btnDeny: {
      type: Boolean,
      default: () => false
    },
    // Type d'utilisateur
    type: {
      type: String,
      default: () => 'adherent'
    }
  },
  computed: {
    ...mapGetters('UserStore', ['user'])
  },
  watch: {
    selected(newValue) {
      /**
      * @vuese
      * Handle changes in individual flavour checkboxes
      */
      if (newValue.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (newValue.length === this.items.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
    }
  },
  data() {
    return {
      load: false,
      exportUrl: process.env.VUE_APP_API_URL + '/admin/users/export',
      checkAll: false,
      selected: [],
      search: {
        firstName: null,
        lastName: null,
        email: null
      },
      pagination: {
        page: 1,
        perPage: 25,
        totalRows: 0
      },
      allSelected: false,
      indeterminate: false,
      items: []
    };
  },
  methods: {
    ...mapActions('AdminUserStore', [
      'getUsers',
      'setMember',
      'setNoMember',
      'setApproved',
      'setDeny',
      'setApprovedBatch'
    ]),
    /**
     * @vuese
     * Sélectionne tout les utilisateurs
     * @arg Utilisateur
     */
    toggleAll(checked) {
      if (checked) {
        this.selected = [];
        this.items.forEach((element) => {
          this.selected.push(element.uid);
        });
      } else {
        this.selected = [];
      }
    },
    /**
     * @vuese
     * L'action à effectuer
     * @arg L'action à effectuer
     * @arg L'utilisateur
     */
    setAction(action, user) {
      this[action](user).then((res) => {
        this.getItems(this.type);
        return res;
      });
    },
    /**
     * @vuese
     * Vider les filtres
     */
    clearFilter() {
      this.search = {
        firstName: null,
        lastName: null,
        email: null
      };
      this.getItems();
    },
    /**
     * @vuese
     * Récupération des utilisateurs
     */
    getItems() {
      this.load = true;
      this.getUsers({
        type: this.type,
        search: this.search,
        pagination: this.pagination
      }).then((res) => {
        this.items = res.data;
        this.load = false;
      });
    },
    /**
     * @vuese
     * Soft-delete un utilisateur dans un premier temps, puis complètement de la BDD
     * @arg Un utilisateur
     */
    deleteUser(item) {
      this.$bvModal
          .msgBoxConfirm('Confirmer la suppression', {
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Oui',
            noFade: true,
            cancelTitle: 'Non',
            footerClass: 'd-flex justify-content-center align-items-center',
            hideHeaderClose: false,
            centered: true
          })
          .then((value) => {
            if (value) {
              let iri = '/api/users/' + item.uid;
              return http.delete(iri).then((res) => {
                this.getItems();
                this.$emit('delete', item);
                return res;
              });
            }
          });
    },
    /**
     * @vuese
     * Annulation de la suppression d'un utilisateur
     * @arg Un utilisateur
     */
    restoreUser(item) {
      this.$bvModal
          .msgBoxConfirm('Confirmer la restauration .', {
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'success',
            okTitle: 'Oui',
            noFade: true,
            cancelTitle: 'Non',
            footerClass: 'd-flex justify-content-center align-items-center',
            hideHeaderClose: false,
            centered: true
          })
          .then((value) => {
            if (value) {
              let iri = '/admin/users/' + item.uid + '/restore';
              return http.post(iri).then((res) => {
                this.getItems();
                this.$emit('restore', item);
                return res;
              });
            }
          });
    }
  },
  created() {
    this.getItems();
  }
};
</script>

<style scoped>
  .td-label {
    font-size: 0.8rem;
    font-weight: 500 !important;
  }
  .white svg {
    color: white;
  }
</style>