<template>
  <div class="d-flex align-items-center flex-direction-row">
    <button
        v-if="inCardsList(card)"
        class="btn-trans btn-checkbox"
        @click.prevent="removeReportCard(card)"
        :title="'Retirer l\'étape : ' + card.name"
    >
      <b-iconstack class="text-md">
        <b-icon-square-fill stacked class="c-white-trans"/>
        <b-icon-check stacked variant="dark"/>
      </b-iconstack>
    </button>
    <button
        v-else
        class="btn-trans btn-checkbox"
        :title="'Ajouter l\'étape : ' + card.name"
        @click.prevent="addReportCard(card)"
    >
      <b-iconstack class="text-md">
        <b-icon-square-fill stacked class="c-white-trans"/>
        <b-icon-cursor-fill
            stacked
            scale="0.5"
            rotate="280"
            variant="secondary"
            class="opacity-25"
        />
      </b-iconstack>
    </button>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import _ from 'lodash';

/**
* @vuese
* Bouton ajout de la fiche au rapport
*/
export default {
  name: 'BtnAddReportCard',
  props: {
    card: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      loader: false,
      timeout: '',
      cardsFiltred: [],
      filters: {},
      cardsSelected: []
    };
  },
  computed: {
    ...mapGetters('UserStore', ['isAdmin']),
    ...mapGetters('ReportStore', ['report', 'selected']),
    cardsByCategories() {
      const cardCategories = {};
      _.each(this.cardsFiltred, (item) => {
        let catName = item.cardCategory.name;
        if (cardCategories[catName] === undefined) {
          cardCategories[catName] = [];
        }
        cardCategories[catName].push(item);
      });
      return cardCategories;
    },
    cardsFiltredCategories() {
      return _.sortBy(
          _.uniq(_.map(this.cardsFiltred, (item) => item.cardCategory.name))
      );
    }
  },
  methods: {
    ...mapActions('ReportStore', [
      'openReport',
      'addReportCard',
      'removeReportCard',
      'generateFromData',
      'setFilters'
    ]),
    /**
     * @vuese
     * Mise à jour des fiches filtrées
     * @arg Représente les données récupérées et filtrées
     */
    onFiltred(dataFiltred) {
      this.cardsFiltred = dataFiltred.cardsFiltred;
      this.setFilters(dataFiltred.filters);
    },
    onLoader(loader) {
      this.loader = loader;
    },
    inCardsList(cardCheck) {
      return _.find(this.selected, (card) => card.id === cardCheck.id);
    },
    inLoadCard(cardUid) {
      return _.find(this.loadCards, (loadCard) => loadCard === cardUid);
    },
    // Ouvre la fiche
    openCard(card) {
      // @arg la fiche
      this.$emit('open', card);
    }
  },
  created() {
    if (this.$route.name === 'adminReportEditPage') {
      this.openReport({
        '@id': '/api/reports/' + this.$route.params.id
      });
    }
  }
};
</script>
