export default {
  /**
   * Liste flatten
   */
  flatten: {},
  /**
   * Flat la liste des enfants d'un objet
   * @param object
   * @param key
   * @param id
   * @returns {*}
   */
  flatChild(object, key = 'children', id = 'all') {
    if (this.flatten[id] === undefined) {
      this.flatten[id] = [];
    }

    for (let index = 0; index < object[key].length; index++) {
      this.flatten[id].push(object[key][index]);
      if (object[key][index] !== undefined) {
        this.flatChild(object[key][index], key, id);
      }
    }
    return this.flatten[id];
  },
  /**
   * Mettre à plat la liste à partir d'un tableau d'objets
   * @param arrayObjects
   * @param key
   * @returns {*}
   */
  flat(arrayObjects, key = 'children') {
    const id = 'flat' + Math.random().toString(36).substring(2);
    this.flatten[id] = [];
    let item = {};
    for (let index = 0; index < arrayObjects.length; index++) {
      item = arrayObjects[index];
      if (key in item) {
        this.flatChild(item, key, id);
      }
      this.flatten[id].push(item);
    }
    return this.flatten[id];
  }
};
