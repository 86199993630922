<template>
  <ul class="list-light input-checkbox-recursive">
    <li v-for="(val, kval) in items" :key="kval">
      <b-form-checkbox
          v-if="val[childrenKey].length === 0"
          :value="keyValue !== '' ? val[keyValue] : val"
          @input="onInput"
      >
        <span
            :title="
            val.total !== undefined ? val.name + ' = ' + val.total : val.name
          "
        >{{ val.name }}</span
        >
      </b-form-checkbox>
      <label class="li-parent strong w-100" v-else>
        <b-form-checkbox
            v-if="parentCheck"
            :value="keyValue !== '' ? val[keyValue] : val"
            @input="onInput"
        />
        <a href @click.prevent="false" v-b-toggle="id + '-' + kval">
          <b-icon-caret-down-fill class="ico-down" aria-hidden="true"/>
          <b-icon-caret-right-fill class="ico-right" aria-hidden="true"/>
          {{ val.name }}
        </a>
        <a
            href
            @click.prevent="checkAllChildren($event, val, id + '-' + kval)"
            class="btn-checkAllChildren"
        >tout cocher <span class="check-all-name">{{ val.name }}</span></a
        >
      </label>
      <b-collapse :id="id + '-' + kval">
        <input-checkbox-recursive
            @checkAllChildren="onCheckAllChildren"
            @input="onInput"
            :items="val[childrenKey]"
            :ref="id + '-' + kval"
            :value="value"
            :keyValue="keyValue"
            :parent-check="parentCheck"
        />
      </b-collapse>
    </li>
  </ul>
</template>

<script>
import flat from '@/flat';

/**
* @vuese
* Les filtres par mot-clé, récursif
*/
export default {
  name: 'InputCheckboxRecursive',
  props: {
    id: {
      type: String,
      default() {
        const rand = Math.random().toString(36).substring(2);
        return 'inp-' + rand;
      }
    },
    childrenKey: {
      type: String,
      default() {
        return 'children';
      }
    },
    value: {
      type: Array,
      default() {
        return [];
      }
    },
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    keyValue: {
      type: String,
      default() {
        return '';
      }
    },
    keyFilter: {
      type: String,
      default() {
        return '';
      }
    },
    autocheckChildren: {
      type: Boolean,
      default() {
        return false;
      }
    },
    parentCheck: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    onClickItem(item) {
      let itemCopy = JSON.parse(JSON.stringify(item));
      let childrenRecursive = flat.flat([itemCopy], this.childrenKey);

      this.$emit('recursive', itemCopy, childrenRecursive);
    },
    checkAllChildren(ev, item) {
      ev.preventDefault();
      const target = ev.target;

      let checkStatus = target.getAttribute('data-checked') ?? 'false';

      if (checkStatus === 'true') {
        checkStatus = 'false';
      } else {
        checkStatus = 'true';
      }

      let allCheckValue = [];
      if (this.parentCheck) {
        allCheckValue = [item];
      } else {
        allCheckValue = item[this.childrenKey];
      }

      let childrenRecursive = flat.flat(allCheckValue, this.childrenKey);
      this.$emit(
          'checkAllChildren',
          ev,
          childrenRecursive,
          this.keyFilter,
          checkStatus
      );

      target.setAttribute('data-checked', checkStatus);
    },
    onCheckAllChildren(ev, value, keyFilter, checkStatus) {
      this.$emit('checkAllChildren', value, this.keyFilter, checkStatus);
    },
    onInput(value) {
      this.$emit('input', value);
    }
  }
};
</script>