<!--suppress ALL -->
<template>
  <div id="app" class="container-grid" :class="theme">
    <app-loader/>
    <div class="header fixed-top-generic">
      <app-menu-admin v-if="isAdmin"/>
      <app-menu v-else-if="isLoggedIn"/>
      <app-menu-anno v-else/>
    </div>
    <div
        class="content pt-content"
        :class="{ 'pt-content--user': isLoggedIn, 'pt-content--admin': isAdmin }"
    >
      <btn-contrast class="contrast"/>
      <router-view/>
    </div>
    <app-footer/>
    <app-cookie-consent-banner/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import AppCookieConsentBanner from '@/components/appCookieConsentBanner';
import AppFooter from '@/components/appFooter';
import AppLoader from '@/components/appLoader';
import AppMenu from '@/components/appMenu';
import AppMenuAdmin from '@/components/appMenuAdmin';
import AppMenuAnno from '@/components/appMenuAnno';
import BtnContrast from '@/components/btnContrast.vue';

export default {
  name: 'App',
  metaInfo() {
    return {
      title: 'Parcours ABC',
      meta: [
        {property: 'og:type', content: 'website'},
        {name: 'robots', content: 'index,follow'}
      ]
    };
  },
  components: {
    AppCookieConsentBanner,
    AppMenuAdmin,
    AppMenuAnno,
    AppMenu,
    AppLoader,
    AppFooter,
    BtnContrast
  },
  computed: {
    ...mapGetters('UserStore', ['isLoggedIn', 'isAdmin']),
    ...mapGetters('UiStore', ['show', 'theme'])
  }
};
</script>

<style lang="scss">
:root {
  --primary-color: #{$blue};
  --primary-color-lighter: #{$blueLighter};
  --secondary-color: #{$pink};
  --secondary-color-darker: #{$pinkDarker};
  --tertiary-color: #{$grey};
  --tertiary-color-lighter: #{$greyLighter};

  --text-color: #{$blackLighter};
  --text-color-slogan: #{$blackLighter};
  --text-color-blocC: #{$blackLighter};

  --title-color: #{$grey};
  --text-color-fiche: #{$black};
  --text-color-fiche-value: #{$white};

  --link-color: #{$blueDark};

  --select-color: #{$blueDarker};

  --border-color: #{$greyLight};
  --border-color-dark: var(--text-color);
  --graph-color: #{$light};

  --danger-color: #{$red};
  --success-color: #{$green};
  --contrast-color: #{$yellow};

  --btn-basic-color: #{$blackLighter};
  --btn-link--download: #{$black};
  --btn-basic-background: #{$white};
  --btn-basic-hover: #{$white};
  --btn-basic-background-hover: #{$blackLighter};

  --nav-focus-color: #{$white};

  --value-color: #{$greyDark};

  --white-color: #{$white};
  --white-variant: #{$whiteVariant};
  --black-color: #{$black};

  --background-color: var(--white-color);
  --background-color-light: #{$greyAdmin};
  --background-color-lightGrey: #{$greyAdmin};
  --background-color-footer: #{$light};
  --background-color-darken: #{$lightDarken};
  --background-color--transparent: #{$transparent};

  --background-form-color: #{$transparent};

  --tags-color: #{$blueDarker};
  --tags-color-report: var(--secondary-color);

  --focus-color: var(--text-color);
  --hover-color: rgba(var(--text-color), 0.2);
  --exemplaries-color: var(--contrast-color);
  --actions-color: var(--text-color);

  --card-color: var(--graph-color);
  --card-color-darker: var(--primary-color-lighter);

  --fiche-direct-color: var(--primary-color);
  --fiche-indirect-color: var(--secondary-color);
  --fiche-completude-color: var(--tertiary-color-lighter);
}

/* Define styles for the root window with dark - mode preference */
#app.dark-theme {
  --primary-color: #{$primaryColorDark};
  --primary-color-lighter: #{$blueLighter};
  --secondary-color: #{$secondaryColorDark};
  --secondary-color-darker: #{$secondaryColorDark};
  --tertiary-color: #{$grey};
  --tertiary-color-lighter: #{$greyLighter};

  --text-color: #{$textColorDark};
  --text-color-fiche-value: #{$yellow};
  --text-color-blocC: #{$black};

  --title-color: #{$yellow};
  --text-color-fiche: #{$yellow};
  --link-color: #{$blueDark};

  --border-color: #{$greyLight};
  --graph-color: #{$light};

  --danger-color: #{$red};
  --success-color: #{$green};
  --contrast-color: #{$yellow};

  --btn-basic-color: #{$yellow};
  --btn-link--download: #{$yellow};
  --btn-basic-background: #{$black};
  --btn-basic-hover: #{$black};
  --btn-basic-background-hover: #{$yellow};

  --nav-focus-color: #{$black};

  --white-color: #{$white};
  --white-variant: #{$whiteVariant};
  --black-color: #{$black};

  --background-color: #{$backgroundColorDark};
  --background-color--transparent: #{$backgroundColorDark};
  --background-color-light: #{$black};
  --background-color-lightGrey: #{$black};
  --background-color-footer: #{$backgroundColorDark-lighter};

  --background-form-color: #{$black};

  --tags-color: #{$blueDarker};
  --tags-color-report: var(--secondary-color);

  --focus-color: var(--text-color);
  --hover-color: rgba(var(--text-color), 0.2);
  --exemplaries-color: var(--contrast-color);
  --actions-color: var(--text-color);

  --card-color: var(--graph-color);
  --card-color-darker: var(--primary-color-lighter);
  --fiche-direct-color: var(--primary-color);
  --fiche-indirect-color: var(--secondary-color);
  --fiche-completude-color: var(--tertiary-color-lighter);
}

.dark-theme {
  .card {
    .unit-value,
    .unit-info--ul li,
    .btn,
    h2 {
      color: $greyDark !important;
    }
  }

  .report-graph-potentiel {
    .title-graph,
    .unit-value,
    .unit-info--ul li {
      color: $greyDark !important;
    }
  }

  .report-cards-item,
  .report-graph-effort {
    color: $greyDark !important;
  }
}
</style>