<template>
  <b-container fluid>
    <b-row class="d-flex flex">
      <h2 class="pink uppercase">qui sommes-nous ?</h2>
      <app-content slug="qui-sommes-nous-1" class="my-2"/>
      <app-content slug="qui-sommes-nous-2"/>
      <app-content slug="qui-sommes-nous-3" class="my-2"/>
      <app-content slug="qui-sommes-nous-4"/>
      <app-content slug="qui-sommes-nous-5" class="my-2"/>
    </b-row>
    <btn-calcul-co-simple/>
  </b-container>
</template>

<script>
import BtnCalculCoSimple from '@/components/btnCalculCoSimple';

/**
* @vuese
* Contenu de la page "Qui-sommes-nous?"
*/
export default {
  name: 'AppWhoAreWe',
  components: {
    BtnCalculCoSimple
  }
};
</script>

<style scoped lang="scss">
  .backg-A,
  .backg-B,
  .backg-C {
    color: var(--secondary-color);
    background: var(--background-color);
    width: 100%;
    text-align: center;
  }

  .backg-A span,
  .backg-B span,
  .backg-C span {
    font-weight: bold;
    font-size: 35px;
  }

  .backg-A p,
  .backg-B p,
  .backg-C p {
    font-weight: bold;
    font-size: 25px;
    text-transform: uppercase;
    text-align: center;
  }

  .backg-A img,
  .backg-B img,
  .backg-C img {
    height: 200px;
  }

  .backg-A h3,
  .backg-B h3,
  .backg-C h3 {
    font-weight: bold;
    font-size: 50px;
    text-align: left;
  }

  .more {
    display: flex;
    font-size: 100px;
    color: var(--text-color);
    align-items: center;
    justify-content: center;
  }

  @media screen and (min-width: 768px) and (max-width: 1440px) {
    .backg-A img,
    .backg-B img,
    .backg-C img {
      height: 150px;
    }

    .more {
      font-size: 60px;
    }
  }
</style>
