<template>
  <b-container>
    <b-row class="button-up">
      <b-button
          variant="basic"
          class="btn-basic btn-basic-center"
          @click.prevent="scrollToTop"
      >
        <div>
          <b-icon-arrow-up aria-hidden="true"/>
        </div>
        retour haut de page
      </b-button>
    </b-row>
  </b-container>
</template>

<script>

/**
* @vuese
* Bouton de retour en haut de page
*/
export default {
  name: 'btnScrollTop',
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style lang="scss">
  .button-up {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    margin-bottom: 30px;
  }

  .button-up button {
    width: 25%;
    margin-top: 20px;

    @media screen and (max-width: 1000px) {
      width: 35%;
    }

    @media screen and (max-width: 750px) {
      width: 55%;
    }

    @media screen and (max-width: 425px) {
      width: 70%;
    }
  }
</style>