<template>
  <b-container class="flex align-items-center justify-content-around flex-wrap">
    <a
        href="https://www.novabuild.fr/"
        target="_blank"
        rel="noopener noreferrer"
    >
      <picture>
        <source srcset="/img/logo_novabuild_footer.webp" type="image/webp"/>
        <img
            src="/img/logo_novabuild_footer.png"
            alt="Logo eco-site"
            class="footer-img footer-img--novabuild"
        />
      </picture>
    </a>
    <a
        href="https://www.dirigeantsresponsablesdelouest.fr/"
        target="_blank"
        rel="noopener noreferrer"
    >
      <picture>
        <source srcset="/img/logo_dro_footer.webp" type="image/webp"/>
        <img
            src="/img/logo_dro_footer_footer.jpg"
            alt="Logo eco-site"
            class="footer-img footer-img--dro"
        />
      </picture>
    </a>
    <a href="https://orangecarre.fr/" target="_blank" rel="noopener noreferrer">
      <picture>
        <source srcset="/img/logo-eco-site_footer.webp" type="image/webp"/>
        <img
            src="/img/logo-eco-site_footer.png"
            alt="Logo eco-site"
            class="footer-img footer-img--ecoSite"
        />
      </picture>
    </a>
  </b-container>
</template>

<script>
/**
* @vuese
* Bloc de présentation des logos des entreprises derrière la plateforme
*/
export default {
  name: 'AppLogosPlatform'
};
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 768px) {
    .logos-title--mobile {
      text-align: center;
      margin-bottom: 2rem;
      flex-direction: column;
    }

    .pb--mobile {
      padding-bottom: 1rem;
    }
  }

  .footer-img {
    width: auto;
  }

  .footer-img--novabuild {
    @media screen and (max-width: 768px) {
      height: 80px;
    }
  }

  .footer-img--dro {
    @media screen and (max-width: 768px) {
      height: 90px;
    }
  }

  .footer-img--ecoSite {
    @media screen and (max-width: 768px) {
      height: 60px;
    }
  }
</style>