<template>
  <span>
    <small v-if="draft" class="bg-light text-danger p-1">
      <b-icon-eye-slash aria-hidden="true"/>
      Brouillon</small
    >
    <small v-else class="bg-success text-white p-1 pr-3">
      <strong>
        <b-icon-check aria-hidden="true"/>
        Publié</strong
      >
    </small>
  </span>
</template>

<script>
/**
* @vuese
* Indique si une fiche est en brouillon ou publiée
*/
export default {
  name: 'Draft',
  props: {
    draft: {
      type: Boolean,
      default() {
        return false;
      }
    }
  }
};
</script>
