<template>
  <b-form-group
      :id="id + '-form-group'"
      class="input-autocomplete"
      :label-for="id + '-form-group-' + label"
  >
    <template slot="label" :id="id + '-form-group-' + label">
      {{ label }}
      <b-button-group>
        <b-btn
            variant="link"
            size="sm"
            class="p-1"
            @click.prevent="hideSearch"
            v-if="show"
        >
          Annuler
        </b-btn>
      </b-button-group>
    </template>
    <div
        class="
        input-autocomplete-values
        d-flex
        justify-content-start
        align-items-center
        mt-2
      "
    >
      <item-dyn
          v-for="(val, kval) in value"
          :key="kval"
          :value="val"
          :type="type"
          class="mb-1"
      >
        <button
            class="btn-trans p-0"
            title="Retirer la valeur de la liste"
            aria-label="Retirer la valeur de la liste"
            @click.prevent="removeVal(kval)"
        >
          <b-icon-x aria-hidden="true"/>
        </button>
      </item-dyn>
      <b-btn
          variant="light"
          size="sm"
          class="p-1 btn-add mb-1 ml-1"
          @click.prevent="showSearch"
          v-if="!show"
          title="Ajouter"
      >
        <b-icon-plus aria-hidden="true"/>
        Ajouter
      </b-btn>
    </div>
    <div v-if="show" class="mb-2 mt-2">
      <div class="d-flex justify-content-between align-items-center">
        <b-form-input
            v-model="search.name"
            placeholder="Rechercher"
            autocomplete="off"
            @keydown.prevent.up="focusSuggestUp"
            @keydown.prevent.down="focusSuggestDown"
            @keydown.prevent.enter="focusSuggestAdd"
            @focus="focus"
            @blur="onBlur"
            :id="id + '-input-search'"
            :ref="id + '-input-search'"
        />
        <b-btn
            variant="secondary"
            v-b-modal="id + '-new-modal'"
            class="ml-1"
            v-if="create"
        >
          Créer
        </b-btn>
      </div>
      <div class="input-autocomplete-suggest">
        <div
            v-for="(item, kitem) in itemsFilters"
            :key="kitem"
            :id="'suggest-' + kitem"
            :disabled="value.indexOf(item['@id']) >= 0"
            :class="focusIdx === kitem ? 'focus' : ''"
            class="
            input-autocomplete-suggest-item
            d-flex
            justify-content-start
            align-items-center
          "
            @click.prevent="addItem(item)"
        >
          <span class="small mr-1">{{ kitem + 1 }}.</span>
          <item-dyn :value="item" :type="type" :item="item"/>
        </div>
      </div>
    </div>

    <b-modal
        :id="id + '-new-modal'"
        :title="'Créer : ' + label"
        scrollable
        hide-footer
        v-if="create"
    >
      <form-dyn :type="type" @saved="onSaved"/>
    </b-modal>
  </b-form-group>
</template>

<script>
import {http} from '@/http.js';
import _ from 'lodash';
import itemDyn from './dyn/itemDyn.vue';

export default {
  components: {itemDyn},
  name: 'InputAutocomplete',
  props: {
    id: {
      type: String,
      default() {
        const rand = Math.random().toString(36).substring(2);
        return 'input-autocomplete-' + rand;
      }
    },
    value: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: () => 'cards'
    },
    label: {
      type: String,
      default: () => 'cards'
    },
    create: {
      type: Boolean,
      default: () => true
    },
    responseKey: {
      type: String,
      default: () => 'name'
    },
    url: {
      type: [Boolean, String],
      default: () => ''
    },
    autoblur: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      show: false,
      timeout: '',
      items: [],
      itemsFilters: [],
      focusIdx: 0,
      search: {
        name: ''
      }
    };
  },
  watch: {
    'search.name'(nv) {
      if (nv !== '') {
        this.getList();
      }
    },
    value() {
      this.$forceUpdate();
    }
  },
  methods: {
    focusScroll() {
      document
          .getElementById('suggest-' + this.focusIdx)
          .scrollIntoView({block: 'nearest', inline: 'nearest'});
    },
    focusSuggestUp() {
      if (this.focusIdx >= 1) {
        this.focusIdx--;
      }
      this.focusScroll();
    },
    focusSuggestDown() {
      if (this.focusIdx < this.items.length - 1) {
        this.focusIdx++;
        this.focusScroll();
      }
    },
    focusSuggestAdd() {
      let item = JSON.parse(JSON.stringify(this.items[this.focusIdx]));
      this.addItem(item);
    },
    hideSearch() {
      this.show = false;
    },
    showSearch() {
      this.show = true;
      setTimeout(() => {
        this.$refs[this.id + '-input-search'].focus();
      }, 100);
    },
    focus() {
      this.getList();
    },
    onBlur() {
      setTimeout(() => {
        this.hideSearch();
        this.clearFilters();
      }, 250);
    },
    clearFilters() {
      this.items = [];
      this.search.name = '';
    },
    onSaved(item) {
      this.$root.$emit('bv::hide::modal', this.id + '-new-modal');
      this.addItem(item);
    },
    removeVal(idxVal) {
      let valueCopy = JSON.parse(JSON.stringify(this.value));

      valueCopy.splice(idxVal, 1);

      valueCopy = _.uniq(valueCopy);

      this.$emit('input', valueCopy);
      // this.value = valueCopy
      this.clearFilters();
    },
    addItem(item) {
      let valueCopy = JSON.parse(JSON.stringify(this.value));

      // * Dans le cas des ouvrages exemplaires, c'est juste un tableau avec les url
      // * des endpoints que l'on récupère, je pousse que ça.

      if (item['@type'] == 'Exemplary') {
        let itemAPIURL = item['@id'];
        valueCopy.push(itemAPIURL);
      } else {
        valueCopy.push(item);
        _.uniq(valueCopy);
        valueCopy = _.uniqBy(valueCopy, '@id');
      }

      this.$emit('input', valueCopy);
      // this.value = valueCopy
      this.clearFilters();
      this.getList(); // ? Est-ce nécessaire ? on récupère la liste que lorsque l'user clic dans les champs.
    },
    getList() {
      clearTimeout(this.timeout);
      this.load = true;
      this.timeout = setTimeout(() => {
        let url = '/api/' + this.type;
        if (this.url != '') {
          url = this.url;
        }
        if (this.type == 'exemplaries') {
          return http
              .get(
                  url +
                  '?draft=false' +
                  '&' +
                  this.responseKey +
                  '=' +
                  this.search.name
              )
              .then((res) => {
                this.items = res.data['hydra:member'];
                this.itemsFilters = _.map(this.items, (item) => {
                  return item;
                });
                this.load = false;
                this.focusIdx = 0;
                return res;
              });
        } else {
          return http
              .get(url + '?' + this.responseKey + '=' + this.search.name)
              .then((res) => {
                this.items = res.data['hydra:member'];
                this.itemsFilters = _.map(this.items, (item) => {
                  return item;
                });

                this.load = false;
                this.focusIdx = 0;
                return res;
              });
        }
      }, 500);
    }
  }
};
</script>

<style lang="scss">
.btn.btn-add {
  transition-delay: 0s !important;
  background-color: var(--border-color);
  border-radius: 1rem !important;
  padding: 0.125rem 0.75rem !important;
  border: 0;

  &:hover {
    background-color: darken($greyLight, 10%);
  }
}

.input-autocomplete {
  position: relative;
  width: inherit !important;

  .input-autocomplete-suggest {
    position: absolute;
    min-width: 350px !important;
    max-width: 100% !important;
    width: inherit !important;
    margin-top: -1px;
    max-height: 150px;
    overflow: auto;
    z-index: 900;
    border: 2px solid var(--text-color) !important;
    border-top-color: #fff !important;
    background-color: #fff !important;
    border-bottom-left-radius: 0.225rem;
    border-bottom-right-radius: 0.225rem;

    .input-autocomplete-suggest-item {
      padding: 0.5rem 1rem;
      border-bottom: 1px solid var(--border-color);
      cursor: pointer;

      &.focus,
      &:hover {
        background-color: rgba(var(--border-color), 0.3);
      }
    }
  }
}

.input-autocomplete-values {
  flex-wrap: wrap;

  .app-item-dyn {
    margin-bottom: 0.225rem;
  }
}
</style>
