<template>
  <component
      class="app-item-dyn"
      :ref="id"
      :is="component"
      :item="item"
      :type="type"
      :value="iri"
      @saved="onSaved"
      v-if="component"
  >
    <slot></slot>
  </component>
</template>
<script>


export default {
  name: 'appItem',
  watch: {
    type() {
      this.loadComponent();
    }
  },
  props: {
    type: {
      type: String,
      default: () => 'actions'
    },
    iri: {
      type: String,
      default: () => 'actions'
    }
  },
  data() {
    return {
      component: null
    };
  },
  methods: {
    loadComponent() {
      this.component = () =>
          import('@/components/dyn/' + this.type + '/form.vue');
    },
    onSaved(item) {
      this.$emit('saved', item);
    }
  },
  created() {
    this.loadComponent();
  }
};
</script>
