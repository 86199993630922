<template>
  <b-container>
    <FormForgottenPassword class="mt-4"/>
  </b-container>
</template>

<script>
import FormForgottenPassword from '@/components/formForgottenPassword';

/**
 * @vuese
 * Page permettant à un utilisateur de demander un mail de réinitialisation de mot de passe
 * @group PAGES
 */
export default {
  name: 'ForgottenPasswordPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Mot de passe oublié',
      meta: [
        {
          name: 'description',
          content:
              'En saisissant votre identifiant dans le champs, un mail vous permettant de réinitialiser votre mot de passe vous sera envoyé automatiquement à cette adresse.'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Mot de passe oublié'
        }
      ]
    };
  },
  components: {
    FormForgottenPassword
  }
};
</script>