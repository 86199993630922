import Vue from 'vue';
import Vuex from 'vuex';
import { http } from '@/http';
import Ls from '../ls';
import modules from './stores';
import router from '@/router';

Vue.use(Vuex);

const store = {
  namespaced: true,
  modules,
  getters: {
    apiUrl: () => process.env.VUE_APP_API_URL,
    cardsCategories: (state) => state.cardsCategories,
    editMode: (state) =>
      !(state.editMode === 'false' || !state.editMode),
    filteredCards: (state) => state.filteredCards,
    fullReport: (state) => state.fullReport,
    report: (state) => state.report,
  },
  state: {
    editMode: Ls.get('editMode', false),
    report: Ls.get('report', {}),
    fullReport: Ls.get('fullReport', {}),
    filteredCards: Ls.get('filteredCards', []),
    cardsCategories: Ls.get('cardsCategories', []),
    reportDefault: {
      name: null,
      description: null,
      tags: [],
      cards: [],
      version: '1',
      user: '',
      co2_total: '',
    },
  },
  mutations: {
    TOGGLE_EDIT_MODE(state) {
      state.editMode = Ls.set('editMode', !state.editMode);
    },
    SET_REPORT(state, dataReport) {
      state.report = Ls.set('report', dataReport);
    },
    SET_FULL_REPORT(state, dataReport) {
      this.state.fullReport = dataReport;
      state.fullReport = Ls.set('fullReport', dataReport);
    },
    SET_CARDS_IN_LOCAL_REPORT(state, data) {
      state.report.cards = data;
      state.report = Ls.set('report', state.report);
    },
    SET_TAGS_IN_LOCAL_REPORT(state, data) {
      state.report.tags = data;
    },
    SET_CARDS_CATEGORIES(state, data) {
      state.cardsCategories = data;
      state.cardsCategories = Ls.set('cardsCategories', state.cardsCategories);
    },
    SET_FILTERED_CARDS(state, data) {
      state.filteredCards = data;
      state.filteredCards = Ls.set('filteredCards', state.filteredCards);
    },
    CLEAR_TAGS(state) {
      state.report.tags = [];
      state.report = Ls.set('report', state.report);
    },
    CLEAR_CARDS(state) {
      state.report.cards = [];
      state.report = Ls.set('report', state.report);
    },
    CLEAR_FULL_REPORT(state) {
      state.fullReport = {};
      state.fullReport = Ls.set('fullReport', state.fullReport);
    },
  },
  actions: {
    toggleEditMode({ commit }) {
      commit('TOGGLE_EDIT_MODE');
    },
    newReport({ commit, state }, data) {
      /**
       * TODO test clear current report
       */
      let emptyReport = JSON.parse(JSON.stringify(state.reportDefault));
      emptyReport.name = data.name;
      emptyReport.description = data.description;
      commit('CLEAR_FULL_REPORT');
      commit('SET_REPORT', emptyReport);
      router.push('/search');
    },
    async saveReport({ commit, getters, state }, data) {
      if (!state.report.uid) {
        data.user = getters.currentUserIri;
        await http.post('/api/reports', data).then(async (res) => {
          await commit('SET_REPORT', res.data);
          return res;
        });
      } else {
        await http.put(state.report['@id'], data).then(async (res) => {
          await commit('SET_REPORT', res.data);
          return res;
        });
      }
    },
    async openReport({ commit }, data) {
      await http.get(data['@id']).then(async (res) => {
        await commit('CLEAR_FULL_REPORT');
        await commit('SET_REPORT', res.data);
        await router.push('/search');
        return res;
      });
    },
    clearTags({ commit }) {
      commit('CLEAR_TAGS');
    },
    clearCards({ commit }) {
      commit('CLEAR_CARDS');
    },
    setCardsInLocalReport({ commit }, data) {
      commit('SET_CARDS_IN_LOCAL_REPORT', data);
    },
    setTagsInLocalReport({ commit }, data) {
      commit('SET_TAGS_IN_LOCAL_REPORT', data);
    },
    getCardsCategories({ commit }) {
      http.get('/api/card_categories').then((res) => {
        commit('SET_CARDS_CATEGORIES', res.data['hydra:member']);
      });
    },
    getFilteredCards({ commit }, data) {
      http.post('cards/filtered', { tags: data }).then((res) => {
        commit('SET_FILTERED_CARDS', res.data);
        router.push('/cards');
      });
    },
    async buildReport({ commit, dispatch }) {
      await dispatch('saveReport', this.state.report);
      await http.get('report/' + this.state.report.uid).then(async (res) => {
        commit('SET_FULL_REPORT', res.data);
        await router.push('report/generated');
        return res;
      });
    },
  },
};

export default new Vuex.Store(store);
