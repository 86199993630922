import {mapGetters, mapActions} from 'vuex';
import _ from 'lodash';
import {http} from '@/http';
import qs from 'qs';

export default {
  name: 'AppCardsFilters',
  props: {
    id: {
      type: String,
      default: () => 'card-filter'
    }
  },
  computed: {
    /**
     * Récupère les filtres disponibles
     * @type {{set(any): void, get(): any}}
     */
    filters: {
      get() {
        return this.$store.getters['CardStore/filters'];
      },
      set(value) {
        this.setFilters(value);
      }
    },
    /**
     * Get et Set les étapes via leur catégorie
     * @type {{set(any): any, get(): any}}
     */
    filtersCategories: {
      get() {
        return this.$store.getters['CardStore/filtersCategories'];
      },
      set(value) {
        return this.setFilter({
          key: 'filtersCategories',
          value
        });
      }
    },
    /**
     * Get et Set les étapes via leur status
     * @type {{set(any): any, get(): any}}
     */
    filtersDraft: {
      get() {
        return this.$store.getters['CardStore/filtersDraft'];
      },
      set(value) {
        return this.setFilter({
          key: 'filtersDraft',
          value
        });
      }
    },
    /**
     * Get et Set les étapes via leur format
     * @type {{set(any): any, get(): any}}
     */
    filtersFormats: {
      get() {
        return this.$store.getters['CardStore/filtersFormats'];
      },
      set(value) {
        return this.setFilter({
          key: 'filtersFormats',
          value
        });
      }
    },
    /**
     * Get et Set les étapes via les secteurs
     * @type {{set(any): any, get(): any}}
     */
    filtersSectors: {
      get() {
        return this.$store.getters['CardStore/filtersSectors'];
      },
      set(value) {
        return this.setFilter({
          key: 'filtersSectors',
          value
        });
      }
    },
    /**
     * Get et Set les étapes via les mots clés
     * @type {{set(any): any, get(): any}}
     */
    filtersTags: {
      get() {
        return this.$store.getters['CardStore/filtersTags'];
      },
      set(value) {
        return this.setFilter({
          key: 'filtersTags',
          value
        });
      }
    },
    /**
     * Retourne les noms des filtres
     */
    filtersName: {
      get() {
        return this.$store.getters['CardStore/filtersName'];
      },
      set(value) {
        return this.setFilter({
          key: 'filtersName',
          value
        });
      }
    },
    ...mapGetters('UserStore', ['user', 'isAdmin']),
    ...mapGetters('ReportStore', ['report']),
    ...mapGetters('CardStore', [
      'cardsCategories',
      'cardsFormats',
      'tagCategories',
      'sectors',
      'toggle',
      'time',
      'tags'
    ]),
    /**
     * Permet de vérifier si on est sur la page dédiée à l'administrateur du site
     * @returns {boolean}
     */
    isAdminCardsIndexPage() {
      return this.$route.name === 'adminCardsIndex';
    }
  },
  data() {
    return {
      load: false,
      timeout: null
    };
  },
  watch: {
    filtersName: {
      handler() {
        this.searchCardsFiltred();
      },
    }
  },
  methods: {
    ...mapActions('CardStore', [
      'getPopulate',
      'setFilter',
      'addFilter',
      'removeFilter',
      'clearFilters',
      'checkAllSectors',
      'toggleCheckAllFilters'
    ]),
    /**
     * Recherche dans les étapes filtrée
     */
    searchCardsFiltred() {
      this.load = true;
      this.$emit('loader', true);

      let filtersCopy = JSON.parse(JSON.stringify(this.filters));
      let filtersSectors = _.map(filtersCopy.sectors, (sector) => sector.uid);
      filtersCopy.sectors = filtersSectors;

      let filtersTags = _.uniq(_.map(filtersCopy.tags, (tag) => tag.uid));

      filtersCopy.tags = filtersTags;

      const filters = qs.stringify({filters: filtersCopy});

      http.get('admin/cards/search?' + filters, this.filters).then((res) => {
        this.cardsFiltred = res.data['hydra:member'];
        this.load = false;
        this.$emit('loader', false);

        this.$emit('filtred', {
          cardsFiltred: this.cardsFiltred,
          filters: this.filters
        });
      });
    }
  },
  mounted() {
    this.getPopulate();
  }
};
