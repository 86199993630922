import Ls from '@/ls';

export const UiStore = {
  namespaced: true,
  getters: {
    loader: (state) => state.loader,
    message: (state) => state.loader.message,
    show: (state) => state.loader.show,
    theme: (state) => state.theme
  },
  state: {
    theme: Ls.get('theme', 'light-theme'),
    loader: {
      show: false,
      message: 'Chargement en cours',
      variant: 'default'
    }
  },
  mutations: {
    TOGGLE_THEME(state) {
      let theme = state.theme;
      if (theme === 'dark-theme') {
        theme = 'light-theme';
      } else {
        theme = 'dark-theme';
      }
      state.theme = Ls.set('theme', theme);
    },
    SET_ON(state) {
      state.loader.show = true;
    },
    SET_OFF(state) {
      state.loader.show = false;
    },
    SET_MESSAGE(state, message) {
      state.loader.message = message;
    },
    SET_VARIANT(state, variant) {
      state.loader.variant = variant;
    },
    SET_CLEAR(state) {
      state.loader = {
        show: false,
        message: 'Chargement en cours',
        variant: 'default'
      };
    }
  },
  actions: {
    toggleTheme({commit}) {
      commit('TOGGLE_THEME');
    },
    loaderOn({commit}, message = null) {
      document.getElementById('app').classList.add('loader');
      commit('SET_ON');
      if (message != null) {
        commit('SET_MESSAGE', message);
      }
    },
    loaderOff({commit}) {
      document.getElementById('app').classList.remove('loader');
      commit('SET_OFF');
      commit('SET_CLEAR');
    },
    loaderMessage({commit}, message) {
      commit('SET_MESSAGE', message);
    },
    loaderVariant({commit}, variant) {
      commit('SET_VARIANT', variant);
    },
    loaderClear({commit}) {
      commit('SET_CLEAR');
    }
  }
};
