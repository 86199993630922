<template>
  <div
      :id="'block-' + block.slug"
      :class="'app-content block-' + block.status + ' user-' + (user.isAdmin ? 'admin' : 'member') + ' ' + (editMode ? 'mode-edit' : 'mode-view')"
  >
    <div
        v-html="block.content">
      >
    </div>
    <div v-if="user.isAdmin && editMode">
      <b-button
          v-if="block.status === 'not-found'"
          @click="createAndEditBlock"
          class="btn-edit"
      >
        Créer le contenu manquant
      </b-button>
      <b-button
          v-else
          @click="editBlock"
          class="btn-edit--lighter"
      >
        <b-icon-pencil aria-hidden="true"/>
        Éditer : <span>{{ slug }}</span>
      </b-button>
      <b-modal
          :id="'edit-block-' + slug"
          title="Éditer le bloc"
          size="lg"
          scrollable
          @ok="submit"
          @hide="loadBlock"
      >
        <formPage
            :ref="'formPage-' + slug"
            :id="block.uid"
            :redirect="false"
            :hideHeader="true"
            :hideFooter="true"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import {http} from '@/http.js';
import {mapGetters} from 'vuex';
import formPage from '@/components/formPage';

/**
* @vuese
* Un bloc de contenu éditable sur une page
*/
export default {
  name: 'BlocTexteEditableFrontOffice',
  components: {
    formPage
  },
  data() {
    return {
      block: {}
    };
  },
  props: {
    // Identifiant du bloc
    id: {
      type: String,
      default() {
        const rand = Math.random().toString(36).substring(2);
        return 'block-' + rand;
      }
    },
    // Slug du contenu à afficher
    slug: {
      type: String,
      default: () => 'accueil-1'
    }
  },
  computed: {
    ...mapGetters(['editMode']),
    ...mapGetters('UserStore', ['user'])
  },
  methods: {
    /**
    * @vuese
    * Chargement du bloc éditable
    */
    loadBlock() {
      return http.get('pages/' + this.slug).then((res) => {
        if (res.data !== null) {
          this.block = res.data;
          this.block.status = 'ok';
        } else {
          this.block = {
            slug: this.slug,
            status: 'not-found',
            content: 'Contenu "' + this.slug + '" a créer'
          };
        }
        return res;
      }).catch((err) => {
        this.block = {
          slug: this.slug,
          status: 'not-found',
          content: 'Contenu "' + this.slug + '" a créer'
        };
        return err;
      });
    },
    /**
    * @vuese
    * Création/Modification du bloc
    */
    createAndEditBlock() {
      return http.post('api/pages', {
        title: this.slug,
        content: ' '
      }).then((res) => {
        this.block = res.data;
        this.editBlock();

        return res;
      });
    },
    /**
    * @vuese
    * Modification du bloc
    */
    editBlock() {
      // Ouverture de la fenêtre modale
      this.$root.$emit('bv::show::modal', 'edit-block-' + this.slug);
    },
    /**
    * @vuese
    * Sauvegarde du bloc
    */
    submit() {
      this.$refs['formPage-' + this.slug].save();
    }
  },
  created() {
    this.loadBlock();
  }
};
</script>
