<template>
  <b-container fluid>
    <b-modal id="modalÉtapes" scrollable centered hide-footer>
      <template slot="modal-title">
        {{ reportPreview.cards.length }} Étapes sélectionnées
      </template>
      <div class="report-cards mt-2 pl-1 pr-1">
        <div
            v-for="(cardCategory, kcardCategory) in cardCategories"
            :key="kcardCategory"
        >
          <h4 class="mt-3 title-blue bb mb-1">
            <strong>{{ cardCategory }}</strong>
          </h4>
          <div
              v-for="(card, kcard) in cardsByCategories[cardCategory]"
              :key="kcard"
              class="
              report-cards-item
              d-flex
              justify-content-start
              align-items-center
              flex-wrap
            "
              :class="'report-cards-item-' + card.cardFormat.slug"
          >
            <b-btn
                size="sm"
                variant="danger"
                class="no-decoration b-trans p-1"
                @click="removeReportCard(card)"
                :title="'Retirer la carte : ' + card.name"
                :aria-label="'Retirer la carte : ' + card.name"
            >
              <b-icon-x aria-hidden="true"/>
            </b-btn>
            <strong
            ><a :href="'/etape/' + card.uid">
              {{ card.name }}
            </a></strong>
            <div class="ml-auto">
              <strong class="small" :title="'valeur CO2 : ' + card.val">
                {{ card.val }}
              </strong>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-row>
      <app-report-graph-horizontal-mobile :report="reportPreview" class="pt-2">
        <template slot="left">
          <b-col
              class="
              abc-step
              d-flex
              justify-content-center
              align-items-center
              flex-column
              p-0
            "
          >
            <h2 class="abc-step__title">C</h2>
            <b-button
                variant="outline"
                class="c-white w-100"
                @click.prevent="false"
                v-b-modal.modalÉtapes
            >
              {{ reportPreview.cards.length }} Étapes sélectionnées
            </b-button>
          </b-col>
        </template>
      </app-report-graph-horizontal-mobile>
    </b-row>
  </b-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import appReportPreviewMixin from './appReportPreviewMixin';
import AppReportGraphHorizontalMobile from './appReportGraphHorizontalMobile.vue';

/**
* @vuese
* Prévisualisation du rapport, version mobile
*/
export default {
  name: 'appReportPreviewMobile',
  components: {
    AppReportGraphHorizontalMobile
  },
  mixins: [appReportPreviewMixin],
  computed: {
    ...mapGetters('ReportStore', [
      'report',
      'reportPreview',
      'cardsByCategories',
      'cardCategories'
    ])
  },
  methods: {
    ...mapActions('ReportStore', ['removeReportCard'])
  }
};
</script>

<style scoped lang="scss">
  .report-cards {
    .report-cards-item {
      padding: 0.2rem 0.5rem;
      border-radius: 0.225rem;
      margin-bottom: 0.225rem;
    }

    .report-cards-item-indirect {
      background-color: var(--fiche-indirect-color);
    }

    .report-cards-item-direct {
      background-color: #72c7d7;
    }

    .report-cards-item-completude {
      background-color: var(--fiche-completude-color);
    }
  }
</style>