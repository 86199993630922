<template>
  <div class="report-graph-vertical" v-if="reportPreview.cards">
    <div class="report-graph-somme d-flex flex-column">
      <strong class="title"> Somme des étapes </strong>
      <div class="w-100 d-flex justify-content-center align-items-center">
        <span class="number-size">
          <strong>{{ somme | default('somme') }}</strong>
        </span>
        <aside class="unit-info pl-1">
          <ul class="unit-info--ul">
            <li>kgCO2e</li>
            <li>par personne</li>
            <li>par an</li>
          </ul>
        </aside>
      </div>
      <div
          class="
          report-graph-effort
          l-1
          c-dark
          l-1
          d-flex
          justify-content-center
          align-items-center
          mt-2
        "
      >
        <strong class="mb-0 mt-0 pt-0 mt-0"
        >{{ effort | default('effort') }}%&nbsp;</strong
        >
        du potentiel de réduction
      </div>
    </div>
    <div class="report-graph-objectif">
      <div class="graph-value">
        <strong class="title-graph"> Objectif 2030 </strong>
        <div class="d-flex justify-content-center align-items-center">
          <span
              class="unit-value unit-value--dark"
          >{{ objectif | default('objectif') }}</span
          >
          <aside class="unit-info unit-info--dark">
            <ul class="unit-info--ul">
              <li>kgCO2e</li>
              <li>par personne</li>
              <li>par an</li>
            </ul>
          </aside>
        </div>
      </div>
      <div class="triangle-right--white"></div>
      <div class="triangle-left--white"></div>
    </div>
    <div class="report-graph-potentiel">
      <strong class="title-graph"> Potentiel de réduction </strong>
      <div class="graph-value d-flex justify-content-center align-items-center">
        <span class="unit-value">{{ potentiel | default('potentiel') }}</span>
        <aside class="unit-info">
          <ul class="unit-info--ul">
            <li>kgCO2e</li>
            <li>par personne</li>
            <li>par an</li>
          </ul>
        </aside>
      </div>
    </div>
    <div class="triangle-right--blue"></div>
    <div class="triangle-left--blue"></div>
  </div>
</template>

<script>
import appReportGraphMixin from './appReportGraphMixin';

/**
* @vuese
* Graphique apparaissant sur le rapport
*/
export default {
  name: 'AppReportGraph',
  mixins: [appReportGraphMixin]
};
</script>

<style lang="scss">
  .report-graph-vertical {
    width: 100%;
  }

  .graph-value:first-child {
    background-color: var(--background-color);
  }

  .report-graph-objectif,
  .report-graph-somme {
    text-align: center;

    .title-graph {
      border-bottom: 2px solid var(--text-color);
      text-transform: uppercase;
    }

    position: relative;
  }

  .report-graph-somme {
    margin-bottom: 0;
  }

  .report-graph-potentiel {
    background-color: $light;
    color: var(--text-color);
    text-align: center;

    .title-graph {
      border-bottom: 2px solid var(--text-color);
      text-transform: uppercase;
    }

    position: relative;
  }

  .report-graph-objectif {
    background-color: $light;
    color: var(--text-color);
  }

  .report-graph-effort {
    background-color: $yellow;
    color: var(--text-color);
    padding: 0.8rem;
  }

  .report-graph-somme {
    margin: 0.5rem;
    background-color: var(--primary-color);
    padding: 1rem;

    .title-graph {
      border-bottom: 2px solid $white;
      padding-bottom: 0.5rem;
      margin-bottom: 1rem !important;
      text-transform: uppercase;
    }

    color: $white;
  }

  .unit-info--ul {
    list-style: none;
    width: 6.25rem;
    margin: 0;
    padding: 0;
    text-align: left;
  }

  .triangle-right--blue {
    display: inline-block;
    width: 50%;
    height: 55px;
    background: linear-gradient(
            to bottom left,
            $light 0%,
            $light 50%,
            var(--background-color) 50%,
            var(--background-color) 100%
    );
  }

  .triangle-left--blue {
    display: inline-block;
    width: 50%;
    height: 55px;
    background: linear-gradient(
            to bottom right,
            $light 0%,
            $light 50%,
            var(--background-color) 50%,
            var(--background-color) 100%
    );
  }

  .triangle-right--white {
    display: inline-block;
    width: 50%;
    height: 55px;
    background: linear-gradient(
            to bottom left,
            var(--background-color) 0%,
            var(--background-color) 50%,
            $light 50%,
            $light 100%
    );
  }

  .triangle-left--white {
    display: inline-block;
    width: 50%;
    height: 55px;
    background: linear-gradient(
            to bottom right,
            var(--background-color) 0%,
            var(--background-color) 50%,
            $light 50%,
            $light 100%
    );
  }
</style>
