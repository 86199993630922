<template>
  <b-navbar class="mt-4 mb-0 pb-0 pt-0 submenu">
    <div v-if="report.name !== ''" class="report-title">
      <h2 class="mb-1 mt-0">
        {{ report.name }}
      </h2>
      <small class="l-1 small" v-if="report.updatedAt">
        {{ report.updatedAt | datetime }}
      </small>
    </div>
    <b-navbar-nav class="ml-1">
      <b-nav-item
          class="links"
          size="sm"
          @click="confirmNewReport"
          title="Nouveau rapport"
          aria-label="Nouveau rapport"
      >
        <b-icon-file aria-hidden="true"/>
      </b-nav-item>
      <b-nav-item
          v-b-modal="'report-open'"
          class="links"
          size="sm"
          title="Ouvrir un rapport"
          aria-label="Ouvrir un rapport"
      >
        <b-icon-folder aria-hidden="true"/>
      </b-nav-item>
      <b-nav-item
          v-if="report.uid"
          class="links"
          size="sm"
          :to="{
          name: 'openReport',
          params: {
            id: report.uid,
          },
        }"
          title="Voir le rapport"
          aria-label="Voir le rapport"
      >
        <b-icon-eye aria-hidden="true"/>
      </b-nav-item>

      <b-nav-item
          class="links"
          @click="onGenerateFromData"
          ref="generateReport"
      >
        <span v-if="report.uid">Enregistrer</span>
        <span v-else> Générer </span>
      </b-nav-item>
      <b-nav-item class="links" v-if="loader">
        <b-spinner small/>
      </b-nav-item>
      <slot></slot>
    </b-navbar-nav>
    <b-modal id="report-edit" :title="getTitle" no-fade size="lg" hide-footer>
      <form-admin-report @saved="saved" @deleted="deleted" :item="report"/>
    </b-modal>
    <b-modal
        id="report-open"
        title="Ouvrir"
        no-fade
        size="lg"
        hide-footer
        @show="onShow"
    >
      <b-form
          inline
          @submit.prevent="getList"
          class="d-flex justify-content-start align-items-center p-0 mb-3"
      >
        <b-form-input
            autofocus
            @keypress.enter="getList"
            autocomplete="off"
            placeholder="Rechercher"
            required
            size="lg"
            v-model="search.name"
        />
        <b-btn class="ml-2" size="lg" variant="success"> Rechercher</b-btn>
      </b-form>
      <b-overlay :show="load">
        <b-list-group>
          <b-list-group-item
              v-for="(item, kitem) in items"
              :key="kitem"
              button
              @click.prevent="openItem(item)"
              class="d-flex justify-content-center align-items-center"
          >
            <span class="small">{{ kitem + 1 }}.</span>
            <strong>{{ item.name }}</strong>
            <span class="small ml-auto text-muted">
              {{ item.updatedAt | datetime }}</span>
          </b-list-group-item>
        </b-list-group>
      </b-overlay>
    </b-modal>
  </b-navbar>
</template>

<script>
import {http} from '@/http';
import {mapGetters, mapActions} from 'vuex';
import formAdminReport from './Admin/formAdminReport.vue';

/**
* @vuese
* Menu pour les rapports
*/
export default {
  name: 'AppMenuReport',
  components: {
    formAdminReport
  },
  computed: {
    ...mapGetters('ReportStore', ['report', 'loader']),
    getTitle() {
      return this.report['@id'] === undefined ? 'Nouveau Rapport' : 'Éditer';
    }
  },
  data() {
    return {
      items: [],
      timeout: '',
      load: false,
      item: {},
      search: {
        name: ''
      },
      pdfUrl: process.env.VUE_APP_API_URL + '/pdf/reports/'
    };
  },
  watch: {
    'search.name'() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getList();
      }, 500);
    }
  },
  methods: {
    ...mapActions('UiStore', [
      'loaderOn',
      'loaderOff',
      'loaderVariant',
      'loaderMessage'
    ]),
    ...mapActions('ReportStore', [
      'generateFromData',
      'setReport',
      'openReport',
      'newReport',
      'saveReport',
      'downloadPdf'
    ]),
    onGenerateFromData() {
      this.generateFromData().then((res) => {
        this.downloadPdf();
        return res;
      });
    },
    onShow() {
      this.search = {
        name: ''
      };
      this.getList();
    },
    /**
     * @vuese
     * Message préventif avant reset des données pour commencer un nouveau rapport
     * @returns {Promise<BvMsgBoxData>}
     */
    confirmNewReport() {
      return this.$bvModal
          .msgBoxConfirm('Les données non enregistrées seront perdu.', {
            title: 'Nouveau rapport',
            size: 'sm',
            okTitle: 'Oui',
            cancelTitle: 'Non',
            footerClass: 'p-2 d-flex justify-content-center align-items-center',
            hideHeaderClose: false,
            centered: true
          })
          .then((value) => {
            if (value) {
              this.newReport();
              this.$router.push({
                name: 'reportNew'
              });
            }
          });
    },
    /**
     * @vuese
     * Récupère la liste des rapports
     * @returns {Promise<Any>}
     */
    getList() {
      let url = '/api/reports';
      if (this.search.name !== '') {
        url += '?name=' + this.search.name;
      }
      this.load = true;
      return http.get(url).then((res) => {
        this.items = res.data['hydra:member'];
        this.load = false;
        return res;
      });
    },
    openItem(item) {
      this.openReport(item);
      // @arg Le rapport
      this.$emit('open', item);
      // Fermeture de la fenêtre modale
      this.$root.$emit('bv::hide::modal', 'report-open', item);
      this.$router.push({
        name: 'reportEdit',
        params: {
          id: item.uid
        }
      });
    },
    saved(item) {
      // @arg Le rapport
      this.$emit('saved', item);
      // Ouverture de la fenêtre modale
      this.openReport(item);
      this.$root.$emit('bv::hide::modal', 'report-edit');
    },
    deleted(item) {
      // @arg Le rapport
      this.$emit('deleted', item);
      // Fermeture de la fenêtre modale
      this.$root.$emit('bv::hide::modal', 'report-edit');
      this.$router.push({
        name: 'adminReportIndex'
      });
    }
  }
};
</script>
