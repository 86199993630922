<template>
  <b-container class="actuPage pt-nav position-relative">
    <div class="bg-A bg-A-actu--first d-none d-lg-block"/>
    <div class="bg-B bg-B-actu--second d-none d-lg-block"/>
    <app-bread-crumbs
        :routes="[
        { name: 'home', title: 'Accueil' },
        { name: 'actuPage', title: 'À la une' },
      ]"
        class="capitalize"
    />
    <card-legend/>
    <b-container>
      <b-row>
        <b-col
            sm="12"
            md="6"
            lg="4"
            v-for="(card, kcard) in cards"
            :key="kcard"
        >
          <app-card-actu
              :card="card"
              :edit="false"
              @saved="onCardSaved"
              class="mb-3"
          >
          </app-card-actu>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {http} from '@/http';

import AppCardActu from '@/components/appCardActu';
import CardLegend from '../components/cardLegend.vue';

/**
 * Page mettant en avant les fiches climats
 * @group PAGES
 * @vuese
 */
export default {
  name: 'ActuPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - À la une',
      meta: [
        {
          name: 'description',
          content: 'Les étapes à la une.'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - À la une'
        }
      ]
    };
  },
  components: {
    AppCardActu,
    CardLegend
  },
  data() {
    return {
      cards: [],
      pdfUrl: process.env.VUE_APP_API_URL + '/pdf/cards'
    };
  },
  methods: {
    ...mapActions(['clearCards']),
    ...mapGetters('UserStore', ['user']),
    
     /**
      * @vuese
      * Récupération des fiches climats à mettre en avant
      * @returns {Promise<AxiosResponse<any>>}
      */
    getCardsActu() {
      return http.get('/cards/actu?').then((res) => {
        this.cards = res.data['hydra:member'];
        return res;
      });
    },
     /**
      * @vuese
      * Récupération des fiches climats en fonction des filtres après modification d'une fiche
      * @returns {Promise<AxiosResponse<any>>}
      */
    onCardSaved() {
      this.$refs.cardsFilters.searchCardsFiltred();
    }
  },
  created() {
    this.getCardsActu();
  }
};
</script>

<style scoped>
  .bg-A-actu--first {
    height: 900px;
    width: 400px;
    top: 30px;
    left: 12px;
  }

  .bg-B-actu--second {
    height: 900px;
    width: 400px;
    right: 12px;
    top: 65px;
  }
</style>