<!-- FILTRE MOBILE -->
<template>
  <div>
    <b-col>
      <b-btn v-b-toggle.filtersMobile class="btn-pink capitalize mb-4"
      >filtres
      </b-btn
      >
    </b-col>
    <b-sidebar
        id="filtersMobile"
        backdrop
        no-header-close
        header-class="d-flex justify-content-center align-items-center p-0"
        body-class="pb-3"
    >
      <template slot="header">
        <aside
            class="
            d-flex
            flex-column
            align-items-center
            justify-content-center
            w-100
            filter-aside
          "
        >
          <p class="ta-center bold">Je sélectionne mes filtres</p>
          <div class="h3 mb-0 bg-dark-trans w-100 text-center pb-2 pt-2">
            {{ cardsFiltred.length }} Étapes
          </div>
        </aside>
      </template>
      <app-cards-filters
          id="report-admin-edit"
          ref="cardsFilters"
          :filter-name="filtersPreview.name"
          filter-draft="all"
          @filtred="onFiltred"
          @loader="onLoader"
      />
      <b-btn
          @click.prevent="filterActive"
          title="Appliquer les filtres"
          class="mt-3 mb-3 w-100 mobile-button-sticky position-sticky"
      >
        Mettre à jour les résultats
      </b-btn>
      <div class="d-flex justify-content-center align-items-center">
        <b-btn
            squared
            variant="danger"
            size="sm"
            @click="clearFilters"
            title="Nettoyer les filtres"
            aria-label="Nettoyer les filtres"
            class="p-2 mt-3 capitalize"
        >
          Nettoyer
          <b-icon-trash aria-hidden="true"/>
        </b-btn>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import appReportStepsMixin from '@/components/dyn/appReportStepsMixin';
import appCardsFiltersMixin from '@/components/dyn/appCardsFiltersMixin';

/**
* @vuese
* Les filtres des fiches climats, affichage mobile
*/
export default {
  name: 'AppCardsFiltersMobile',
  mixins: [appReportStepsMixin, appCardsFiltersMixin],
  methods: {
    filterActive(){
      this.searchCardsFiltred();
    }
  },
};
</script>

<style lang="scss" scoped>
  .no-margin {
    margin: 0;
  }

  .filter-aside {
    padding-top: 2rem;
    background-color: $pink;
    color: $white;
  }
</style>