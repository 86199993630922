<template>
  <span>
    <b-btn
        @click="newItem"
        v-b-modal="'tag-edit-' + id"
        :size="size"
        class="mt-2 mr-2"
        variant="dark"
        title="Nouveau mot-clé"
        aria-label="Nouveau mot-clé"
    ><b-icon-plus aria-hidden="true"/><slot></slot
    ></b-btn>
    <b-modal
        :id="'tag-edit-' + id"
        title="Mot-clé"
        no-fade
        size="lg"
        scrollable
        hide-footer
    >
      <form-admin-tag
          :tag="tag"
          @saved="onSaved"
          @deleted="onDeleted"
          @new="onNew"
          @update="onUpdate"
      />
    </b-modal>
  </span>
</template>

<script>
import formAdminTag from './formAdminTag.vue';

/**
* @vuese
* Bouton d'édition d'un mot-clé
* @group COMPONENTS_ADMIN
*/
export default {
  name: 'BtnAdminTagEdit',
  components: {
    formAdminTag
  },
  props: {
    // Le mot-clé
    tag: {
      type: Object,
      default() {
        return {
          name: ''
        };
      }
    },
    // Type de bouton
    variant: {
      type: String,
      default() {
        return 'link';
      }
    },
    // Identifiant du mot-clé
    id: {
      type: String,
      default() {
        const rand = Math.random().toString(36).substring(2);
        return '' + rand;
      }
    },
    // Taille du bouton
    size: {
      type: String,
      default: () => 'md'
    }
  },
  methods: {
    /**
     * @vuese
     * Modification du mot-clé
     */
    openItem() {
      // Ouverture de la fenêtre modale
      this.$root.$emit('bv::show::modal', 'tag-edit-' + this.id);
    },
    /**
     * @vuese
     * Nouveau mot-clé
     */
    newItem() {
      this.tag = {
        name: ''
      };
    },
     /**
     * @vuese
     * Sauvegarde du mot-clé
     * @arg Le mot-clé
     */
    onSaved(item) {
      // Sauvegarde du mot-clé
      // @arg Le mot-clé
      this.$emit('saved', item);
      // Fermeture de la fenêtre modale
      this.$root.$emit('bv::hide::modal', 'tag-edit-' + this.id);
    },
    /**
     * @vuese
     * Suppression du mot-clé
     */
    onDeleted(item) {
      // Suppression du mot-clé
      // @arg Le mot-clé
      this.$emit('deleted', item);
      // Fermeture de la fenêtre modale
      this.$root.$emit('bv::hide::modal', 'tag-edit-' + this.id);
    },
    /**
     * @vuese
     * Mise à jour du mot-clé
     * @arg Le mot-clé
     */
    onUpdate(item) {
      // Mise à jour du mot-clé
      // @arg Le mot-clé
      this.$emit('update', item);
    },
    /**
     * @vuese
     * Nouveau mot-clé
     * @arg Le mot-clé
     */
    onNew(item) {
      // Nouveau mot-clé
      // @arg Le mot-clé
      this.$emit('new', item);
    }
  }
};
</script>
