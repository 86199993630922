<template>
  <b-container>
    <app-bread-crumbs
        :routes="[
        { name: 'home', title: 'accueil' },
        { name: 'findOutMore', title: 'Qui sommes-nous ?' },
      ]"
        class="capitalize"
    />
    <app-who-are-we class="mt-2"/>
  </b-container>
</template>

<script>
import AppWhoAreWe from '@/components/appWhoAreWe';
import AppBreadCrumbs from '@/components/appBreadCrumbs';

/**
 * @vuese
 * Page présentant Novabuild et DRO
 * @group PAGES
 */
export default {
  name: 'WhoAreWePage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Qui sommes-nous?',
      meta: [
        {
          name: 'description',
          content:
              "Les Dirigeants Responsables de l'Ouest fédèrent des dirigeants engagés et actifs qui partagent les mêmes objectifs : mettre la RSE au cœur de la stratégie de nos entreprises et faire rayonner sur le territoire nos convictions."
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Qui sommes-nous?'
        }
      ]
    };
  },
  components: {
    AppBreadCrumbs,
    AppWhoAreWe
  }
};
</script>