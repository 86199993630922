<template>
  <address class="address d-flex flex-column mt-4">
    <header
        class="address-header d-flex justify-content-center align-items-center"
    >
      <b-link :href="companyWebsite" target="_blank" rel="noopener noreferrer">
        <b-img
            :src="'/img/' + imgFile"
            class="address-image"
            :alt="'logo du site :' + companyWebsite"
        />
      </b-link>
    </header>

    <b-btn
        class="
        btn-basic--companyContact
        d-flex
        align-items-center
        justify-content-center
      "
        variant="basic"
        :href="companyWebsite"
        target="_blank"
        rel="noopener noreferrer"
    >
      <b-icon-globe2/>&nbsp;site internet
    </b-btn>
  </address>
</template>

<script>
/**
* @vuese
* Bloc présentant une entreprise
*/
export default {
  name: 'AppBlockCompany',
  props: {
    // Le logo de l'entreprise
    imgFile: {
      type: String,
      default() {
        return 'logo_dro.jpg';
      }
    },
    // Le lien du site de l'entreprise
    companyWebsite: {
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
  .address {
    position: relative;
    z-index: 10;

    &-header {
      border: var(--tertiary-color) solid 1px;
      height: 14rem;
      background-color: $white;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.16);
    }

    &-image {
      height: 130px;
      width: auto;
      margin-bottom: 20px;
    }
  }

  .btn-basic--companyContact {
    height: 4rem;
    width: 50%;
    margin-top: -2rem;
    margin-right: auto;
    margin-left: auto;
    background: var(--btn-basic-background) !important;
    border: 1px solid var(--text-color) !important;
    text-transform: uppercase;
    font-weight: bold !important;
    color: var(--text-color) !important;
  }
</style>

