<template>
  <b-container>
    <b-row class="mt-4">
      <b-col
          class="d-flex flex-column justify-content-center align-items-center"
      >
        <b-alert
            show
            variant="themeInfo"
            class="d-flex justify-content-center align-items-center"
        >
          <b-icon-check class="t-xxl" aria-hidden="true"/>
          <p class="mb-0 t-lg">
            Votre compte est en validation par un administrateur, 
            vous pouvez tester l'outil en attendant.
          </p>
        </b-alert>
        <div class="d-flex justify-content-center align-items-center">
          <b-btn
              :to="{
              name: 'login',
            }"
              size="lg"
              variant="basic"
          >Se connecter
          </b-btn
          >
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {mapGetters} from 'vuex';

/**
 * @vuese
 * Page confirmant l'adhésion d'un utilisateur à la plateforme
 * @group PAGES
 */
export default {
  name: 'RegisteredPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Adhésion',
      meta: [
        {
          name: 'description',
          content:
              'Votre compte est en validation par un administrateur, vous pouvez tester l\'outil en attendant.'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Adhésion'
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['fullReport', 'report'])
  },
  data() {
    return {
      datacollection: null,
      graphic: null
    };
  },
  mounted() {
    this.fillData();
  },
  methods: {
    generatePdf() {
      window.open(
          process.env.VUE_APP_API_URL + '/report/' + this.fullReport.uid + '/pdf'
      );
    },
    fillData() {
      this.datacollection = {
        labels: Object.keys(this.fullReport.datas),
        datasets: [
          {
            label: this.fullReport.name,
            backgroundColor: '#13445F',
            data: Object.values(this.fullReport.datas)
          }
        ]
      };
    }
  }
};
</script>

<style scoped>
</style>