<template>
  <b-form @submit.prevent="save()">
    <div>
      <b-alert show variant="light">
        <b-row>
          <b-col offset="3">
            <div>
              <span>id : {{ form.uid | def('-') }}</span>
            </div>
          </b-col>
          <b-col class="text-right">
            <div>maj : {{ form.updatedAt | datetime | def('') }}</div>
            <b-btn
                v-if="form['@id']"
                @click.prevent="removeTestimony"
                variant="danger"
                title="Supprimer le témoignage"
                aria-label="Supprimer le témoignage"
                class="mr-2"
            >
              <b-icon-trash aria-hidden="true"/>
            </b-btn>
          </b-col>
        </b-row>
      </b-alert>
    </div>
    <b-form-group label="Nom :" label-for="testimonyLastName">
      <b-form-input required v-model="form.lastName" id="testimonyLastName"/>
    </b-form-group>
    <b-form-group label="Prénom :" label-for="testimonyFirstname">
      <b-form-input required v-model="form.firstName" id="testimonyFirstname"/>
    </b-form-group>
    <b-form-group label="Témoignage :" label-for="testimonyContent">
      <b-textarea v-model="form.content" id="testimonyContent" class="mt-2"/>
    </b-form-group>

    <div class="d-flex justify-content-center align-items-center mt-2">
      <b-button type="submit" :variant="load ? 'link' : 'dark'" size="lg">
        <span v-if="!load">Enregistrer</span>
        <span v-else><b-spinner small/></span>
      </b-button>
    </div>
  </b-form>
</template>

<script>
import {http} from '@/http';

/**
* @vuese
* Formulaire de création/modification d'un témoignage
*/
export default {
  name: 'formTestimony',
  props: {
    id: {
      type: [String, Boolean],
      default: () => false
    }
  },
  data() {
    return {
      load: false,
      form: {
        lastName: '',
        firstName: '',
        content: ''
      }
    };
  },
  methods: {
    save() {
      this.load = true;

      let url = '/api/testimonies';
      let method = 'post';
      let mode = 'new';
      if (this.form.uid) {
        url = this.form['@id'];
        method = 'put';
        mode = 'update';
      }

      return http[method](url, this.form).then((res) => {
        this.load = false;
        this.$emit('saved', res.data);
        if (mode === 'new') {
          this.$router.push({
            name: 'adminTestimonyEdit',
            params: {
              id: res.data.id
            }
          });
        } else {
          this.form = res.data;
        }
        this.$router.push('/admin/parametrages');

        return res;
      });
    },
    getItem() {
      this.load = true;
      return http.get('/api/testimonies/' + this.id, this.form).then((res) => {
        this.form = res.data;
        this.load = false;
        return res;
      });
    },
    removeTestimony() {
      return this.$bvModal
          .msgBoxConfirm('Supprimer le témoignage.', {
            title: 'Veuillez confirmer',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Oui',
            cancelTitle: 'Non',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
          .then((value) => {
            if (value) {
              return http.delete(this.form['@id']).then((res) => {
                this.$emit('deleted', this.form);
                this.$router.push({
                  name: 'adminParams'
                });
                return res;
              });
            }
          });
    }
  },
  created() {
    if (this.id) {
      this.getItem();
    }
  }
};
</script>
