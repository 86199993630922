<template>
  <b-container
      class="image-fondC pb"
      :style="{ backgroundImage: 'url(/img/forme_fond_C.svg)' }"
  >
    <b-row>
      <header class="form-contact-header">
        <h2 class="uppercase">contact</h2>
        <p>Pour toute demande de contact, n'hésitez pas :</p>
      </header>

      <div class="form-contact">
        <b-col lg="3">
          <img class="illu-contact" src="/img/illu_02_contact.svg" alt=""/>
        </b-col>

        <b-col class="form-contact--info" sm="12" lg="5" v-if="!sended">
          <b-form @submit.prevent="contact(form)" class="form-contact">
            <b-col md="12">
              <b-form-group label="Nom" label-for="lastname">
                <b-form-input
                    v-model="form.lastname"
                    id="lastname"
                    class="input"
                    minlength="2"
                    required
                />
              </b-form-group>

              <b-form-group label="Prénom" label-for="firstname">
                <b-form-input
                    v-model="form.firstname"
                    id="firstname"
                    class="input"
                    minlength="2"
                    required
                />
              </b-form-group>

              <b-form-group label="Email" label-for="email">
                <b-form-input
                    v-model="form.email"
                    id="email"
                    type="email"
                    class="input"
                    title="L'adresse mail doit contenir une .com, .fr etc. à la fin"
                    pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                    required
                />
              </b-form-group>

              <b-form-group label="Numéro de téléphone" label-for="phone">
                <b-form-input
                    type="tel"
                    v-model="form.phone"
                    id="phone"
                    required
                    title="Le numéro de tél. doit être au format: 06 01 02 03 05 ou +33 6 01 02 03 04 05 ou 003360102030405"
                    pattern="^(?:0|\(?\+33\)?\s?|0033\s?)[1-79](?:[\.\-\s]?\d\d){4}$"
                    class="input"
                />
              </b-form-group>

              <b-form-group label="Structure" label-for="structure">
                <b-form-input
                    v-model="form.structure"
                    required
                    id="structure"
                    class="input"
                    minlength="2"
                />
              </b-form-group>
            </b-col>

            <b-col sm="12" md="12" lg="10" class="message-contact">
              <b-form-group label="Message" label-for="message">
                <b-form-textarea
                    v-model="form.message"
                    id="message"
                    class="input mt-1"
                    required
                    no-resize
                    rows="8"
                />
              </b-form-group>

              <b-form-checkbox-group required>
                <b-form-checkbox
                    name="notRobotValidation"
                    v-model="form.notRobotValidation"
                    required
                >
                  Je ne suis pas un robot
                </b-form-checkbox>
              </b-form-checkbox-group>
              <b-form-checkbox-group required>
                <b-form-checkbox
                    name="infoAcceptation"
                    v-model="form.infoAcceptation"
                    required
                >
                  En cochant cette case, j'accepte que Novabuild et DRO
                  récupèrent mes informations.
                </b-form-checkbox>
              </b-form-checkbox-group>

              <b-form-group>
                <Captcha
                    :sitekey="sitekey"
                    :loadRecaptchaScript="true"
                    ref="recaptcha"
                    @verify="onCaptchaVerified"
                    @expired="onCaptchaExpired"
                    size="invisible"
                >
                </Captcha>
              </b-form-group>
              <b-button
                  type="submit"
                  :variant="load ? 'link' : 'basic'"
                  class="btn-send w-100"
                  size="lg"
              >
                <span v-if="!load" class="uppercase">
                  <b-icon-arrow-right aria-hidden="true"/> envoyer
                </span>
              </b-button>
            </b-col>
          </b-form>
        </b-col>
        <b-col v-else>
          <h3>Votre message a bien été envoyé.</h3>
        </b-col>
      </div>
    </b-row>
  </b-container>
</template>

<script>
import appContactFormMixin from '@/components/dyn/appContactFormMixin';

/**
* @vuese
* Formulaire de contact de la page d'accueil
*/
export default {
  name: 'FormContactHomepage',
  mixins: [appContactFormMixin]
};
</script>

<style lang="scss">
  .form-contact-header {
    background-color: var(--background-color--transparent) !important;
  }

  .form-contact {
    background-color: var(--background-color--transparent) !important;
    display: flex;
  }

  .image-fondC {
    background-repeat: no-repeat;
    background-position-x: 27%;
    background-size: 22%;
  }

  .illu-contact {
    height: auto;
    width: 80%;
  }

  .bg-wave-A {
    background-repeat: no-repeat;
    background-position: bottom center;
  }

  .formContact {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .message-contact {
    margin-left: 20px;
  }

  .message-contact textarea {
    height: 52%;
  }

  @media screen and (max-width: 1024px) {
    .image-fondC {
      background-size: 0;
    }

    .form-contact {
      display: block;
    }
    .message-contact {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 1024px) {
    .illu-contact {
      display: none;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1024px) {
    .form-contact--info,
    .message-contact {
      width: 100% !important;
    }
  }
</style>
