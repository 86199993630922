<template>
  <b-overlay :show="load">
    <b-form @submit.prevent="saveCard()" class="form-card">
      <div
          v-if="form['@id'] && header"
          class="d-flex justify-content-between align-items-center"
      >
        <app-draft :draft="form.draft"/>
        <b-btn
            v-if="form['@id'] && form.draft"
            @click.prevent="publishCard"
            title="Publier l'étape"
            variant="success"
            class="mr-2"
        >
          Publier
        </b-btn>
        <b-btn
            variant="light"
            class="b-trans"
            v-if="form['@id'] && !form.draft"
            @click.prevent="draftCard"
            title="Mettre l'étape en état de brouillon"
        >
          Mettre en brouillon
        </b-btn>
      </div>
      <b-form-group
          label="Type de l'étape : *"
          label-for="cardFormat"
          :invalid-feedback="'Veuillez saisir un type d\'étape.'"
          :state="status || form.cardFormat !== null"
      >
        <b-form-select
            id="formFormat"
            size="xl"
            required
            v-model="form.cardFormat"
            class="mt-2"
        >
          <template #first>
            <b-form-select-option :value="''" disabled>
              -- Sélectionner le type de l'étape --
            </b-form-select-option>
          </template>
          <option
              v-for="format in cardsFormats"
              :value="format['@id']"
              :key="format.uid"
              :data-format="format.name"
          >
            {{ format.name }}
          </option>
        </b-form-select>
      </b-form-group>
      <div v-if="form.cardFormat !== ''">
        <b-form-group
            label="Nom de l'étape : *"
            label-for="name"
            :invalid-feedback="'Veuillez saisir un nom.'"
            :state="status || form.name !== ''"
        >
          <b-input id="name" required v-model="form.name" class="mt-2"/>
        </b-form-group>
        <input type="hidden" v-model="form.idxFull"/>
        <b-form-group label="Identifiant de la fiche :">
          <b-row>
            <b-col>
              <b-form-group label="ID" label-for="id">
                <b-input
                    placeholder="ID"
                    v-model="form.idx"
                    title="Id"
                    id="id"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Version" label-for="version">
                <b-input
                    v-model="form.version"
                    type="number"
                    placeholder="Version"
                    title="Version"
                    min="1"
                    id="version"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Date" label-for="date">
                <b-input-group>
                  <b-form-input
                      id="example-input"
                      v-model="form.idxDate"
                      type="text"
                      placeholder="YYYY-MM-DD"
                      autocomplete="off"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                        v-model="form.idxDate"
                        button-only
                        locale="fr-FR"
                        button-variant="form"
                        aria-controls="example-input"
                        hide-header
                        hide-footer
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>
      </div>

      <!--
==========================
      FORMAT DIRECT
==========================
-->
      <div v-if="formatSetting === 'direct'">
        <b-form-group
            label="Paragraphe de présentation :"
            label-for="description"
        >
          <input-html
              v-model="form.description"
              id="description"
              class="mt-2 input-html-sm"
          />
        </b-form-group>
        <b-form-group
            label="Attribuer un secteur : *"
            label-for="sector"
            :invalid-feedback="'Veuillez saisir un secteur.'"
            :state="status || form.sector !== null"
        >
          <b-form-select
              id="sector"
              required
              :disabled="form.parent != null && form.sector != null"
              v-model="form.sector"
              class="mt-2"
          >
            <template #first>
              <b-form-select-option :value="''" disabled
              >-- Sélectionner le secteur --
              </b-form-select-option
              >
            </template>
            <option
                v-for="sector in sectors"
                :value="sector['@id']"
                :key="sector.uid"
            >
              {{ sector.name }}
            </option>
          </b-form-select>
        </b-form-group>
        <input-card-tag
            label="Attribuer des Mots-clés :"
            type="tags"
            v-model="form.tags"
            :value="form.tags"
        />
        <b-form-group
            label="Attribuer un filtre : *"
            label-for="cardCategory"
            :invalid-feedback="'Veuillez saisir un filtre.'"
            :state="status || form.cardCategory !== null"
        >
          <b-form-select
              required
              v-model="form.cardCategory"
              id="cardCategory"
              class="mt-2"
          >
            <template #first>
              <b-form-select-option :value="''" disabled
              >-- Sélectionner le filtre --
              </b-form-select-option
              >
            </template>
            <option
                v-for="category in cardsCategories"
                :value="category['@id']"
                :key="category.uid"
            >
              {{ category.name }}
            </option>
          </b-form-select>
        </b-form-group>

        <b-form-group label="KGCO2E - Par personne - par an :" label-for="val">
          <b-input
              type="number"
              name="val"
              id="val"
              v-model="form.val"
              class="mt-2"
          />
        </b-form-group>

        <b-form-group>
          <input-image label="Images :" v-model="form.pictures"/>
        </b-form-group>

        <b-form-group label="Actions :" label-for="action">
          <input-html v-model="form.action" id="action" class="mt-2"/>
        </b-form-group>
        <input-autocomplete
            label="Ils l'ont fait :"
            type="exemplaries"
            responseKey="title"
            v-model="form.exemplaries"
        />
        <b-form-group
            label="Aller plus loin :"
            label-for="descriptionGoFurther"
        >
          <input-html
              v-model="form.descriptionGoFurther"
              id="descriptionGoFurther"
              class="mt-2"
          />
        </b-form-group>
      </div>
      <!--
===========
    END
===========
-->

      <!--
===========================
      FORMAT INDIRECT
===========================
-->
      <div v-if="formatSetting === 'indirect'">
        <b-form-group
            label="Paragraphe de présentation :"
            label-for="description"
        >
          <input-html
              v-model="form.description"
              id="description"
              class="mt-2 input-html-sm"
          />
        </b-form-group>
        <b-form-group
            label="Attribuer un Secteur : *"
            label-for="sector"
            :invalid-feedback="'Veuillez saisir un secteur.'"
            :state="status || form.sector !== null"
        >
          <b-form-select
              id="sector"
              required
              v-model="form.sector"
              :disabled="form.parent != null && form.sector != null"
              class="mt-2"
          >
            <template #first>
              <b-form-select-option :value="''" disabled
              >-- Sélectionner le secteur l'étape --
              </b-form-select-option
              >
            </template>
            <option
                v-for="sector in sectors"
                :value="sector['@id']"
                :key="sector.uid"
            >
              {{ sector.name }}
            </option>
          </b-form-select>
        </b-form-group>
        <input-card-tag
            label="Attribuer des Mots-clés :"
            type="tags"
            v-model="form.tags"
            :value="form.tags"
        />
        <input-autocomplete
            label="Étapes associées :"
            type="cards"
            url="/cards/search"
            v-model="form.children"
            class="mb-0"
        />

        <div v-if="form.children.length > 0">
          <small>total : </small><strong class="h3 mt-1">{{ total }}</strong>
          <small>kgCO2e par personne par an</small>
        </div>
        <b-form-group
            label="Attribuer un filtre :"
            label-for="card-category"
            v-if="form.cardFormat !== ''"
        >
          <b-form-select
              v-model="form.cardCategory"
              id="card-category"
              class="mt-2"
          >
            <option
                v-for="category in cardsCategories"
                :value="category['@id']"
                :key="category.uid"
            >
              {{ category.name }}
            </option>
          </b-form-select>
        </b-form-group>

        <b-form-group>
          <input-image label="Images :" v-model="form.pictures"/>
        </b-form-group>

        <b-form-group label="Actions :" label-for="action">
          <input-html v-model="form.action" id="action" class="mt-2"/>
        </b-form-group>
        <input-autocomplete
            label="Ils l'ont fait :"
            type="exemplaries"
            responseKey="title"
            v-model="form.exemplaries"
        />
        <b-form-group
            label="Aller plus loin :"
            label-for="descriptionGoFurther"
        >
          <input-html
              v-model="form.descriptionGoFurther"
              id="descriptionGoFurther"
              class="mt-2 input-html-sm"
          />
        </b-form-group>
      </div>
      <!--
===========
    END
===========
-->

      <!--
=============================
      FORMAT COMPLETUDE
=============================
-->
      <div v-if="formatSetting === 'completude'">
        <b-form-group label="KGCO2E - Par personne - par an :" label-for="val">
          <b-input
              type="number"
              name="val"
              id="val"
              v-model="form.val"
              class="mt-2"
          />
        </b-form-group>
        <b-form-group
            label="Attribuer un secteur : *"
            label-for="sector"
            :invalid-feedback="'Veuillez saisir un secteur.'"
            :state="status || form.sector !== null"
        >
          <b-form-select
              id="sector"
              required
              :disabled="form.parent != null && form.sector != null"
              v-model="form.sector"
              class="mt-2"
          >
            <template #first>
              <b-form-select-option :value="''" disabled
              >-- Sélectionner le secteur --
              </b-form-select-option
              >
            </template>
            <option
                v-for="sector in sectors"
                :value="sector['@id']"
                :key="sector.uid"
            >
              {{ sector.name }}
            </option>
          </b-form-select>
        </b-form-group>
      </div>
      <!--
===========
    END
===========
-->
      <b-form-group
          v-if="form.cardFormat"
          label="Note de version :"
          label-for="releaseNote"
      >
        <input-html v-model="form.releaseNote" id="releaseNote" class="mt-2"/>
      </b-form-group>
      <div v-if="footer">
        <hr/>
        <div class="d-flex justify-content-between align-items-center">
          <b-btn
              @click.prevent="removeCard"
              class="b-trans"
              title="Supprimer l'étape climat"
              aria-label="Supprimer l'étape climat"
              variant="light"
          >
            <b-icon-trash aria-hidden="true"/>
          </b-btn>
          <b-btn
              :disabled="load"
              :variant="load ? 'light' : 'success'"
              type="submit"
              size="lg"
          >
            <span v-if="!load">Enregistrer</span>
            <span v-else><b-spinner small/></span>
          </b-btn>
        </div>
      </div>
    </b-form>
  </b-overlay>
</template>

<script>
import {http} from '@/http';
import Ls from '@/ls';
import inputAutocomplete from '../inputAutocomplete.vue';
import InputHtml from '../inputHtml.vue';
import InputCardTag from '../inputCardTag.vue';

/**
* @vuese
* Formulaire de création d'une fiche climat
* @group COMPONENTS_ADMIN
*/
export default {
  components: {inputAutocomplete, InputHtml, InputCardTag},
  name: 'FormAdminCard',
  props: {
    // Information de la fiche
    value: {
      type: Object,
      default: () => {
      }
    },
    // Header du formulaire
    header: {
      type: Boolean,
      default: () => true
    },
    // Footer du formulaire
    footer: {
      type: Boolean,
      default: () => true
    }
  },
  watch: {
    'form.idx'() {
      this.setIdxFull();
    },
    'form.idxDate'() {
      this.setIdxFull();
    },
    'form.version'() {
      this.setIdxFull();
    }
  },
  computed: {
    /**
     * @vuese
     * Récupération de la somme des étapes enfants pour une étape indirecte
     * @returns {number}
     */
    total() {
      let total = 0;
      this.form.children.forEach((child) => (total += child.val));
      return total;
    },
    /**
     * @vuese
     * Récupération des tags disponibles pour une étape
     * @returns {*}
     */
    availableOptions() {
      return this.cardsTags.map((tag) => {
        return {
          value: tag.name,
          text: tag.name
        };
      });
    },
    /**
     * @vuese
     * Récupération du format de l'étape: complétude, indirecte ou direct
     * @returns {null}
     */
    formatSetting() {
      let cardFormatSlug = null;
      if (this.cardsFormatsObject[this.form.cardFormat]) {
        cardFormatSlug = this.cardsFormatsObject[this.form.cardFormat].slug;
      }
      return cardFormatSlug;
    }
  },
  data() {
    return {
      required: {
        name: ''
      },
      status: true,
      errors: [],
      cardsCategories: Ls.get('cardsCategories', []),
      cardsFormats: Ls.get('cardsFormats', []),
      cardsFormatsObject: Ls.get('cardsFormatsObject', []),
      cardsTags: Ls.get('cardsTags', []),
      populated: Ls.get('populated', false),
      sectors: Ls.get('sectors', []),
      tagCategories: Ls.get('tagCategories', []),
      load: false,
      form: {
        action: '',
        cardCategory: null,
        cardFormat: null,
        children: [],
        description: '',
        descriptionGoFurther: '',
        descriptionPicture: '',
        draft: true,
        exemplaries: [],
        goal: 0,
        idx: '',
        idxDate: '',
        idxFull: '',
        name: '',
        pictures: [],
        releaseNote: '',
        sector: null,
        tags: [],
        val: 0,
        version: '1'
      }
    };
  },
  methods: {
    /**
     * @vuese
     * Controls the validation state of the feedback. true force shows valid-feedback
     * @arg Clé
     * @returns {boolean}
     */
    state(key) {
      return this.required[key] !== undefined;
    },
    /**
     * @vuese
     * Création/Mise à jour de l'identifiant unique d'une étape à partir de son idx, version et date
     */
    setIdxFull() {
      this.form.idxFull = this.form.idx + this.form.version + this.form.idxDate;
    },
    /**
     * @vuese
     * Suppression d'une étape
     * @returns {Promise<BvMsgBoxData>}
     */
    removeCard() {
      return this.$bvModal
          .msgBoxConfirm('Supprimer l\'étape.', {
            title: 'Veuillez confirmer',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Oui',
            cancelTitle: 'Non',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
          .then((value) => {
            if (value) {
              return http.delete(this.form['@id']).then((res) => {
                // Suppression de la fiche
                this.$emit('deleted', this.form);
                this.$router.go({
                  name: 'adminCardsIndex'
                });
                return res;
              });
            }
          });
    },
    /**
     * @vuese
     * Publication d'une étape: passage de l'état brouillon à l'état publié
     * @returns {Promise<AxiosResponse<any>>}
     */
    publishCard() {
      this.load = true;
      return http.put(this.form['@id'], {draft: false}).then((res) => {
        this.load = false;
        // Passage de l'état de la fiche à publiée
        this.$emit('published', res.data);
        // Sauvegarde de la fiche
        this.$emit('saved', res.data);
      });
    },
    /**
     * @vuese
     * Mise à l'état brouillon d'une étape
     * @returns {Promise<AxiosResponse<any>>}
     */
    draftCard() {
      this.load = true;
      return http.put(this.form['@id'], {draft: true}).then((res) => {
        this.load = false;
        // Passage de l'état de la fiche à brouillon
        this.$emit('drafted', res.data);
        // Sauvegarde de la fiche
        this.$emit('saved', res.data);
      });
    },
    /**
     * @vuese
     * Récupération de la carte
     * @returns {Promise<AxiosResponse<any>>}
     */
    getItem() {
      return http.get(this.value['@id']).then((res) => {
        this.$emit('input', res.data);
        this.mapForm();
      });
    },
    /**
     * @vuese
     * Création d'une nouvelle étape
     * @returns {Promise<AxiosResponse<any>>}
     */
    saveCard() {
      this.setIdxFull();
      // validation
      let validation = this.validate(this.form);
      if (validation.status) {
        let valueCopy = JSON.parse(JSON.stringify(this.form));
        valueCopy.val = parseInt(valueCopy.val);

        valueCopy.children = valueCopy.children.map((item) => {
          let iri = typeof item === 'object' ? item['@id'] : item;
          return iri;
        });

        valueCopy.tags = valueCopy.tags.map((item) => {
          let iri = typeof item === 'object' ? item['@id'] : item;
          return iri;
        });

        valueCopy.exemplaries = valueCopy.exemplaries.map((item) => {
          let iri = typeof item == 'object' ? item['@id'] : item;
          return iri;
        });

        if (valueCopy.sector != null) {
          valueCopy.sector =
              typeof valueCopy.sector === 'object'
                  ? valueCopy.sector['@id']
                  : valueCopy.sector;
        }

        valueCopy.cardFormat =
            typeof valueCopy.cardFormat === 'object'
                ? valueCopy.cardFormat['@id']
                : valueCopy.cardFormat;

        let url = '/api/cards';
        let method = 'post';
        let mode = 'new';
        if (valueCopy['@id']) {
          url = valueCopy['@id'];
          method = 'put';
          mode = 'update';
        }

        this.load = true;
        return http[method](url, valueCopy).then((res) => {
          // Sauvegarde de la fiche
          this.$emit('saved', res.data);
          // Création ou mise à jour de la fiche
          this.$emit(mode, res.data);
          this.load = false;
          return res;
        });
      } else {
        // Retour en cas d'erreur
        this.$emit('error', validation);
        this.$bvModal.msgBoxOk('Champs manquants', {
          title: 'Veuillez saisir les champs manquants',
          size: 'sm',
          buttonSize: 'lg',
          okVariant: 'danger',
          footerClass: 'p-2 d-flex justify-content-center align-items-center',
          hideHeaderClose: false,
          hideHeader: true,
          centered: true,
          noFade: true
        });
      }
    },
    /**
     * @vuese
     * Validation des données envoyée lors de la création d'une étape
     * @returns {{errors: *[], status: boolean}}
     */
    validate() {
      let status = true;
      let errors = [];

      let required = {
        null: ['cardFormat'],
        '/api/card_formats/completude': ['name'],
        '/api/card_formats/indirect': ['name', 'sector', 'cardCategory'],
        '/api/card_formats/direct': ['name', 'sector', 'cardCategory']
      };

      const CardFormat = this.form.cardFormat ?? 'null';
      required[CardFormat].forEach((itemKey) => {
        const dataKey = this.form[itemKey];
        if (dataKey === undefined || dataKey == null || dataKey === '') {
          status = false;
          errors.push(itemKey);
        }
      });
      this.status = status;
      return {
        status,
        errors
      };
    },
    /**
     * @vuese
     * Remplissage d'une étape lors de l'édition de celle-ci
     * @returns {Promise<AxiosResponse<any>>}
     */
    populateForm() {
      return http.get('admin/cards/populate').then((res) => {
        this.tagCategories = Ls.set('tagCategories', res.data.tagCategories);
        this.cardsCategories = Ls.set('cardsCategories', res.data.categories);
        this.cardsFormats = Ls.set('cardsFormats', res.data.formats);
        this.cardsTags = Ls.set('cardsTags', []);
        this.sectors = Ls.set('sectors', res.data.sectors);
        this.populated = Ls.set('populated', true);

        this.cardsFormatsObject = {};
        this.cardsFormats.forEach((item) => {
          this.cardsFormatsObject[item['@id']] = item;
        });
        this.cardsFormatsObject = Ls.set(
            'cardsFormatsObject',
            this.cardsFormatsObject
        );
        return res;
      });
    },
    /**
     * @vuese
     * Récupération des données d'une étape lors de l'édition de celle-ci
     */
    mapForm() {
      let itemCopy = JSON.parse(JSON.stringify(this.value));

      try {
        if (typeof itemCopy.cardFormat === 'object') {
          itemCopy.cardFormat = itemCopy.cardFormat['@id'];
        }
        if (itemCopy.sector !== null && typeof itemCopy.sector === 'object') {
          itemCopy.sector = itemCopy.sector['@id'];
        }

        itemCopy.tags = itemCopy.tags.map((item) => {
          let iri = typeof item === 'object' ? item['@id'] : item;
          return iri;
        });

        if (
            itemCopy.cardCategory !== null &&
            typeof itemCopy.cardCategory === 'object'
        ) {
          itemCopy.cardCategory = itemCopy.cardCategory['@id'];
        }
        this.form = itemCopy;
      } catch (error) {
        this.form = this.value;
      }
    }
  },
  created() {
    this.populateForm();
    if (this.value['@id']) {
      this.mapForm();
      this.getItem();
    }
  }
};
</script>

<style lang="scss">
  .form-card legend,
  label {
    position: relative !important;
  }

  .b-calendar {
    footer {
      display: none;
    }

    small {
      font-size: 0.6rem !important;
    }

    .btn-outline-light:hover {
      background-color: #ccc;
    }
  }

  .b-calendar-inner {
    width: unset !important;
  }

  .b-calendar .b-calendar-grid-body .col[data-date] .btn {
    margin: 0 auto !important;
  }
</style>