<template>
  <b-container fluid>
    <!-- Bloc ABC -->
    <b-row class="mb-lg-5">
      <b-col md="2">
        <app-abc-step
            stepLetter="a"
            stepExp="Je sélectionne mes filtres"
            class="abc-step-a"
        />
      </b-col>
      <b-col md="7">
        <app-abc-step
            stepLetter="b"
            stepExp="Je sélectionne mes étapes climats"
            class="abc-step-b"
        />
      </b-col>
      <b-col md="3">
        <app-abc-step
            stepLetter="c"
            stepExp="J'évalue la baisse de mes émissions de CO2"
            class="abc-step-c"
        />
      </b-col>
    </b-row>
    <!-- Filtre + cartes + Étapes sélectionnées : desktop-->
    <b-row>
      <!-- Filtres -->
      <b-col col md="2">
        <app-cards-filters
            id="report-admin-edit"
            ref="cardsFilters"
            :filter-name="filtersName"
            filter-draft="2"
            @filtred="onFiltred"
            @loader="onLoader"
        />
      </b-col>
      <!-- Étapes disponibles -->
      <b-col col md="7">
        <div v-if="hasA">
          <b-input-group size="lg" class="mb-3">
            <b-input-group-prepend is-text class="bg-white">
              <b-icon-search aria-hidden="true"/>
            </b-input-group-prepend>
            <b-form-input
                required
                size="lg"
                v-model="filtersName"
                placeholder="Recherche libre"
                aria-label="Recherche libre"
            />
          </b-input-group>
          <div class="page-title-container position-relative">
            <div class="d-flex justify-content-between align-items-center">
              <h1 class="page-title page-title--smaller">
                Étapes disponibles :<span
              ><strong class="pl-1">{{ cardsFiltred.length - cardsCompletude.length }}</strong></span
              >
              </h1>
              <div class="ml-auto">
                <b-btn
                    class="ml-auto mr-2"
                    variant="light"
                    title="Cocher toutes les étapes de la liste ci-dessous"
                    aria-label="Cocher toutes les étapes de la liste ci-dessous"
                    @click.prevent="checkAllFiltred"
                >
                  <b-icon-check2-square aria-hidden="true"/>
                  Tout cocher
                </b-btn>
                <b-btn
                    class="ml-auto"
                    variant="light"
                    title="Gérer les étapes climats"
                    aria-label="Gérer les étapes climats"
                    v-if="isAdmin"
                    :to="{
                    name: 'adminCardsIndex',
                  }"
                >
                  <b-icon-gear-fill aria-label="true"/>
                  Gérer
                </b-btn>
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <card-legend/>
            </div>
          </div>
          <b-overlay
              :show="loader"
              variant="white"
              rounded="sm"
              no-fade
              v-if="hasA"
          >
            <div v-for="(cat, kcat) in cardsFiltredCategories" :key="kcat">
              <h2
                  class="
                  sticky-top
                  bd-bottom
                  uppercase
                  page-title page-title--category
                  pb-1
                  pt-1
                "
              >
                {{ cat }} :
                <strong>{{ cardsByCategories[cat] | length }}</strong>
              </h2>
              <b-row>
                <b-col
                    sm="12"
                    md="6"
                    lg="4"
                    v-for="(card, kcard) in cardsByCategories[cat]"
                    :key="kcard"
                >
                  <app-admin-card
                      :card="card"
                      :edit="true"
                      class="mb-3 d-flex"
                      @saved="onCardSaved"
                  >
                    <template slot="body-left">
                      <btn-add-report-card :card="card"/>
                    </template>
                  </app-admin-card>
                </b-col>
              </b-row>
            </div>
          </b-overlay>
        </div>
        <div v-else>
          <b-alert show>Sélectionnez un secteur et les mots-clés relatifs à votre opération</b-alert>
        </div>
      </b-col>
      <!-- Report preview -->
      <b-col col md="3">
        <app-report-preview v-if="hasB"/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import appReportStepsMixin from '@/components/dyn/appReportStepsMixin';
import btnAddReportCard from './btnAddReportCard.vue';
import CardLegend from './cardLegend.vue';

/**
* @vuese
* Le contenu de la page "Mon Parcours"
*/
export default {
  components: {btnAddReportCard, CardLegend},
  name: 'AppReportSteps',
  mixins: [appReportStepsMixin]
};
</script>

<style scoped>
  .page-title--category {
    font-size: 1.25rem !important;
  }
</style>