<template>
  <b-container>
    <app-bread-crumbs
        :routes="[{ name: 'login', title: 'Connexion / Inscription' }]"
    >
    </app-bread-crumbs>
    <p>
      Accéder à Parcours ABC vous permettra d'évaluer en quelques clics le poids carbone de vos choix de construction.
      Développé par NOVABUILD et DRO, Parcours ABC est désormais ouvert à tous gratuitement.
    </p>
    <h3 class="text-center mb-5 uppercase">
      devenir adhérent
      <b-link
          class="underline uppercase"
          href="https://www.dirigeantsresponsablesdelouest.fr/nous-rejoindre"
          target="blank"
      >dro
      </b-link
      >
      ou
      <b-link
          class="underline uppercase"
          href="https://www.novabuild.fr/adherer"
          target="blank"
      >novabuild
      </b-link
      >
    </h3>
    <b-row>
      <b-col xl="14" lg="6" sm="12">
        <form-login/>
      </b-col>
      <b-col>
        <form-registration/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AppBreadCrumbs from '@/components/appBreadCrumbs';
import FormLogin from '@/components/formLogin';
import FormRegistration from '@/components/formRegistration';

/**
 * @vuese
 * Page de connection à la plateforme
 * @group PAGES
 */
export default {
  name: 'LoginPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Se connecter',
      meta: [
        {
          name: 'description',
          content:
              "Accéder à Parcours ABC vous permettra d'évaluer en quelques clics le poids carbone de vos choix de construction. Parcours ABC est ouvert gratuitement aux adhérents de NOVABUILD et de DRO. Si vous n'êtes pas adhérents, sautez le pas : rejoignez-nous, et en guise de bienvenue, vous pouvez accéder à Parcours ABC pour une journée, dès maintenant, gratuitement."
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Se connecter'
        }
      ]
    };
  },
  components: {
    AppBreadCrumbs,
    FormLogin,
    FormRegistration
  }
};
</script>
