<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <app-bread-crumbs
            :routes="[
            { name: 'adminHome', title: 'Administration' },
            { name: 'adminPageIndex', title: 'Gestion des pages' },
            { name: 'adminPageEdit', title: 'page' },
          ]"
        />
        <div
            class="
            page-title-container
            d-flex
            align-items-center
            justify-content-between
          "
        >
          <h1 class="page-title">
            <b-btn
                :to="{ name: 'adminPageIndex' }"
                variant="link"
                title="Retour"
            >
              <b-icon-arrow-left aria-hidden="true"/>
            </b-btn>
            Édition de page
          </h1>
          <b-btn
              class="ml-auto"
              variant="dark"
              size="lg"
              @click.prevent="$refs.formPage.save()"
          >
            Enregistrer
          </b-btn>
        </div>
        <formPage ref="formPage" :id="$route.params.id"/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import formPage from '@/components/formPage';

/**
 * @vuese
 * Page d'édition d'une page custom
 * @group PAGES_ADMIN
 */
export default {
  name: 'AdminPagesEditPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Édition de page',
      meta: [
        {
          name: 'description',
          content: 'Visualisation et modification d\'une page.'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Édition de page'
        }
      ]
    };
  },
  components: {
    formPage
  }
};
</script>