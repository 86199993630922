import {http} from '@/http';
import methods from '@/methods';

export default {
  data() {
    return {
      sitekey: process.env.VUE_APP_RECAP,
      load: false,
      sended: false,
      form: {
        lastname: '',
        firstname: '',
        email: '',
        phone: '',
        structure: '',
        message: '',
        notRobotValidation: false,
        infoAcceptation: false,
        captchaToken: ''
      }
    };
  },
  methods: {
    /**
     * Activation du challenge reCAPTCHA
     */
    contact() {
      this.$refs.recaptcha.execute();
      this.load = true;
    },
    /**
     * Envoie du formulaire de contact si reCAPTCHA ok + reset de celui-ci
     * @returns {Promise<AxiosResponse<any>>}
     */
    onCaptchaVerified: function () {
      this.$refs.recaptcha.reset();
      const params = methods.jsonToFormData(this.form);
      this.load = false;
      this.sended = true;
      return http.post('/contact', params).then((res) => {
        this.$emit('contact', res.data);
        this.load = false;
        this.sended = true;
        return res;
      });
    },
    /**
     * Reset du reCAPTCHA quand il est expiré
     */
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    }
  }
};
