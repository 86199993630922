<template>
  <b-col>
    <h3 class="uppercase">connectez-vous</h3>
    <p>Connectez-vous grâce à votre identifiant et votre mot de passe</p>
    <b-form @submit.prevent="loginProcess()" ref="form" class="formLogin">
      <b-alert variant="danger" :show="error" type="error">
        <b-icon-x-circle aria-hidden="true"/>
        Votre <strong>identifiant</strong> ou votre
        <strong>mot de passe</strong> est incorrect.
      </b-alert>
      <b-form-group label="Email" label-for="email" class="mt-2">
        <b-form-input
            type="email"
            name="email"
            id="email"
            required
            v-model="form.username"
            autofocus
        />
      </b-form-group>
      <b-form-group label="Mot de passe" label-for="password" class="mt-2">
        <b-form-input
            type="password"
            name="password"
            id="password"
            required
            v-model="form.password"
        />
      </b-form-group>
      <div class="d-flex justify-content-center align-items-center">
        <b-button
            type="submit"
            :variant="load ? 'link' : 'primary'"
            size="lg"
            :disabled="load"
            class="btn-basic"
        >
          <span v-if="!load"><b-icon-arrow-right aria-hidden="true"/> Se connecter</span>
          <span v-else><b-spinner small/></span>
        </b-button>
      </div>
      <router-link to="/password/forgotten">
        <a class="flex-center pt-2">Mot de passe oublié ?</a>
      </router-link>
    </b-form>
    <b-img
        src="img/illu_inscription.svg"
        alt="Illustration"
        class="illuInscription w-100"
    />
  </b-col>
</template>

<script>
import {mapActions} from 'vuex';

/**
* @vuese
* Formulaire de connection
*/
export default {
  name: 'FormUserLogin',
  data() {
    return {
      load: false,
      form: {
        username: '',
        password: ''
      },
      error: false
    };
  },
  methods: {
    ...mapActions('UserStore', ['login']),
    /**
    * @vuese
    * Procédure de connection à la plateforme
    */
    loginProcess() {
      this.load = true;
      this.error = false;
      return this.login(this.form)
          .then((res) => {
            return res;
          })
          .catch(() => {
            this.$refs.form.scrollIntoView({behavior: 'smooth'});
            this.error = true;
            this.load = false;
          });
    }
  }
};
</script>

<style scoped>
.formLogin {
  padding-top: 0;
  padding-left: 0;
}
</style>
