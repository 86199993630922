<template>
  <b-container fluid>
    <b-row class="bread-crumbs">
      <b-col>
        <app-bread-crumbs
            :routes="[
            { name: 'adminHome', title: 'Administration' },
            { name: 'adminExemplaryIndex', title: 'Ouvrage exemplaire' },
            { name: 'adminExemplaryEditPage', title: 'Nouveau' },
          ]"
        >
        </app-bread-crumbs>
        <h1 class="page-title">
          <b-btn
              :to="{ name: 'adminExemplaryIndex' }"
              variant="link"
              title="Retour"
          >
            <b-icon-arrow-left aria-hidden="true"/>
          </b-btn>
          Ouvrage exemplaire
        </h1>
        <div>
          <b-container fluid>
            <b-row>
              <b-col>
                <form-exemplary :id="$route.params.id" @saved="onSaved"/>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import formExemplary from '@/components/formExemplary';

/**
 * @vuese
 * Page d'édition d'un ouvrage exemplaire
 * @group PAGES_ADMIN
 */
export default {
  name: 'AdminExemplaryEditPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Création d\'un ouvrage exemplaire',
      meta: [
        {
          name: 'description',
          content: 'Création d\'un ouvrage exemplaire.'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Création d\'un ouvrage exemplaire'
        }
      ]
    };
  },
  components: {
    formExemplary
  },
  methods: {
    /**
     * @vuese
     * Mise à jour de la fiche en base de données lors de la sauvegarde de celle-ci
     */
    onSaved(res) {
      this.$router.push({
        name: 'exemplaryIndex',
        params: {
          id: res.data.id
        }
      });
    }
  }
};
</script>