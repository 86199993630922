/* eslint-disable */

class LsModule {
  constructor() {
    this.state = {
      data: localStorage,
    };
  }

  set(index, data) {
    const val = typeof data === 'object' ? JSON.stringify(data) : data;
    localStorage.setItem(index, val);
    return data;
  }

  remove(index) {
    localStorage.removeItem(index);
  }

  get(index, def) {
    try {
      if (localStorage[index] !== undefined) {
        const type = typeof def;
        let val = localStorage[index];
        if (type === 'object') {
          val = JSON.parse(val);
        } else if (type === 'int') {
          val = parseInt(val);
        } else if (type === 'float') {
          val = parseFloat(val);
        } else if (type === 'bool') {
          val = JSON.parse(val);
        }
        return val;
      }
      if (def !== undefined) {
        return this.set(index, def);
      }
      return '';
    } catch (error) {
      return def;
    }
  }

  clear() {
    localStorage.clear();
  }
}

export default new LsModule();
