<template>
  <b-form @submit.prevent="editAccount()">
      <b-form-input
          hidden
          v-model="form.uid"
          id="userUid"
      />
    <b-form-group label="Nom" label-for="userLastName">
      <b-form-input
          autofocus
          required
          v-model="form.lastName"
          id="userLastName"
      />
    </b-form-group>

    <b-form-group label="Prénom" label-for="userFirstName">
      <b-form-input
          autofocus
          required
          v-model="form.firstName"
          id="userFirstName"
      />
    </b-form-group>

     <b-form-group label="Email" label-for="userEmail">
      <b-form-input
          autofocus
          required
          v-model="form.email"
          id="userMail"
      />
    </b-form-group>

    <b-form-group label="Numéro de téléphone" label-for="userPhone">
      <b-form-input
          autofocus
          required
          type="tel"
          title="Le numéro de tél. doit être au format: 06 01 02 03 05 ou +33 6 01 02 03 04 05 ou 003360102030405"
          pattern="^(?:0|\(?\+33\)?\s?|0033\s?)[1-79](?:[\.\-\s]?\d\d){4}$"
          v-model="form.phone"
          id="userPhone"
      />
    </b-form-group>

    <b-form-group label="Structure" label-for="structure" class="mt-2">
        <b-form-input v-model="form.company.name" required id="structure"/>
      </b-form-group>

    <b-form-group label="Fonction" label-for="userCompanyRole">
      <b-form-input
          autofocus
          required
          v-model="form.companyRole"
          id="userCompanyRole"
      />
    </b-form-group>

    <b-form-group
          label="Adhérent de :"
          label-for="organisme"
          class="mt-2"
      >
        <b-form-select
            v-model="form.from"
            :options="options"
            class="form-control custom-select"
            required
            id="organisme"
        />
      </b-form-group>

    <div class="d-flex justify-content-center align-items-center">
      <b-button
          @click="editAccount"
          :variant="load ? 'link' : 'dark'"
          size="lg"
          class="mt-4 mb-4"
      >
        <span v-if="!load">Enregistrer</span>
        <span v-else><b-spinner/></span>
      </b-button>
    </div>

    <b-alert :show="sended" variant="success">
      <b-icon-check-circle aria-hidden="true"/>
      Les données sont mises à jour.
    </b-alert
    >
  </b-form>
</template>
<script>
import {http} from '@/http';
import methods from '@/methods';

/**
* @vuese
* Modification d'un compte utilisateur
*/
export default {
  name: 'FormAdminUserEditAccount',
  data() {
    return {
      load: false,
      sended: false,
      selected: null,
      options: [],
      form: {
        lastName: '',
        firstName: '',
        email: '',
        phone: '',
        companyRole: '',
        company: '',
        from: '',
        uid: ''
      }
    };
  },
  methods: {
    getPopulate() {
      return http.get('/api/company_froms').then((res) => {
        this.options = _.map(res.data['hydra:member'], (item) => { // eslint-disable-line
          return {
            value: item['@id'],
            text: item.name
          };
        });
        return res;
      });
    },
    getMember() {
      const userUid = window.location.pathname.split("/").pop();
      return http.get('/admin/users/member?search%5Buid%5D=' + userUid + '&type=member').then((res) => {
        this.form.uid = res.data[0].uid;
        this.form.firstName = res.data[0].firstName;
        this.form.lastName = res.data[0].lastName;
        this.form.email = res.data[0].email;
        this.form.phone = res.data[0].phone;
        this.form.company = res.data[0].company;
        this.form.companyRole = res.data[0].companyRole;
      });
    },
        /**
     * @vuese
     * Modification et sauvegarde d'un compte utilisateur
     */
    editAccount() {
      this.load = true;
      const userUid = window.location.pathname.split("/").pop();
      const params = methods.jsonToFormData(this.form);
      return http.post('/admin/users/' + userUid + '/edit', params).then((res) => {
        // Sauvegarde des modifications
        this.$emit('saved', res.data);
          this.sended = true;
          setTimeout(() => {
            this.sended = false;
          }, 15000);
          this.load = false;
      });
    },
  },
  created() {
    this.getPopulate();
    this.getMember();
  }
};
</script>