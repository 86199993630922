<template>
  <div>
    <b-overlay :show="load">
      <div class="p-2">
        <slot></slot>
        <b-btn
            class="mt-2 mr-2"
            @click.prevent="getItems()"
            size="lg"
            variant="success"
        >
          <b-icon-search aria-hidden="true"/>
          Rechercher
        </b-btn
        >
        <b-btn
            @click.prevent="clearFilter"
            variant="danger"
            class="mt-2 no-decoration"
            size="lg"
        >Vider la recherche
        </b-btn>
      </div>
      <table class="table table-hover">
        <thead>
        <tr>
          <th class="tr-position"></th>
          <th v-for="(field, kfield) in fields" :key="kfield">
            {{ field.title }}

            <b-input
                v-if="field.search"
                v-model="search[field.key]"
                @keypress.enter="getItems()"
                placeholder="Rechercher"
            />
          </th>
          <th class="td-action"></th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(item, kitem) in items"
            :key="kitem"
            @click.prevent="emitEdit(item)"
        >
          <td class="tr-position">
            <small>{{ kitem + 1 }}.</small>
          </td>
          <td v-for="(field, kfield) in fields" :key="kfield">
            {{ item[field.key] }}
          </td>
          <td>
            <b-btn
                size="sm"
                variant="danger"
                @click.stop.prevent="emitDelete(item)"
            >
              <b-icon-trash aria-hidden="true"/>
            </b-btn>
          </td>
        </tr>
        </tbody>
      </table>
    </b-overlay>
  </div>
</template>

<script>
import {http} from '@/http.js';

/**
* @vuese
* Tableau des utilisateurs de Parcours ABC
* @group COMPONENTS_ADMIN
*/
export default {
  name: 'AppAdminTable',
  props: {
    // Type d'utilisateur
    type: String,
    // Informations utilisateurs
    fields: {
      type: Array,
      default: () => {
        return [
          {
            key: 'name',
            search: true,
            title: 'Nom'
          }
        ];
      }
    }
  },
  data() {
    return {
      load: false,
      search: {
        name: ''
      },
      items: []
    };
  },
  methods: {
    /**
     * @vuese
     * Récupération de la liste des membres de Parcours ABC
     * @returns {Promise<AxiosResponse<any>>}
     */
    getItems() {
      this.load = true;
      let url = '/api/' + this.type;
      if (this.search.name !== '') {
        url += '?name=' + this.search.name;
      }
      return http.get(url).then((res) => {
        this.items = res.data['hydra:member'];
        this.load = false;
        return res;
      });
    },
    /**
     * @vuese
     * Remise à zéro des filtres
     */
    clearFilter() {
      this.search = {
        name: ''
      };
      this.getItems();
    },
    /**
     * @vuese
     * Edition d'un utilisateur
     * @arg Un utilisateur
     */
    emitEdit(item) {
      // Emission de l'évènement d'édition d'un utilisateur
      this.$emit('edit', item);
    },
    /**
     * @vuese
     * Suppression d'un utilisateur
     * @arg Un utilisateur
     * @returns {Promise<AxiosResponse<any>>}
     */
    emitDelete(item) {
      this.$bvModal
          .msgBoxConfirm('Confirmer la suppression', {
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'Oui',
            noFade: true,
            cancelTitle: 'Non',
            footerClass: 'd-flex justify-content-center align-items-center',
            hideHeaderClose: false,
            centered: true
          })
          .then((value) => {
            if (value) {
              return http.delete(item['@id']).then((res) => {
                this.getItems();
                // Emission de l'évènement de suppression d'un utilisateur
                this.$emit('delete', item);
                return res;
              });
            }
          });
    }
  },
  created() {
    this.getItems();
  }
};
</script>
