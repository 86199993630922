<template>
  <img :alt="alt | strippedContent" :src="urlImg + '/' + src" class="img-media" loading="lazy"/>
</template>

<script>

/**
* @vuese
* Une image
*/
export default {
  name: 'ImgMedia',
  filters: {
    strippedContent: function(string) {
           return string.replace(/<\/?[^>]+>/ig, " ").replaceAll('&nbsp;', ""); 
    }
  },
  props: {
    alt: {
      type: String,
      default: () => '',
    },
    src: {
      type: String,
      default: () => 'default.png'
    }
  },
  data() {
    return {
      urlImg: process.env.VUE_APP_IMG_FULL_URL
    };
  }
};
</script>

<style>
  .img-media {
    height: 250px;
        width: 100%;
      object-fit: cover;
  }

  @media screen and (max-width: 1300px) {
    .img-media {
      height: 225px;
      width: 100%;
      object-fit: cover;
    }
  }

  @media screen and (max-width: 1150px) {
    .img-media {
      height: 200px;
      width: 100%;
      object-fit: cover;
    }
  }

  @media screen and (max-width: 1150px) {
    .img-media {
      height: 175px;
      width: 100%;
      object-fit: cover;
    }
  }
</style>