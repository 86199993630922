<template>
  <div>
    <b-container class="position-relative">
      <div class="bg-A bg-A-contact--first d-none d-lg-block"/>
      <div class="bg-B bg-B-contact--second d-none d-lg-block"/>
      <div class="bg-C bg-C-contact--third d-none d-lg-block"/>
      <app-bread-crumbs
          :routes="[
          { name: 'home', title: 'Accueil' },
          { name: 'contact', title: 'Contact' },
        ]"
      >
      </app-bread-crumbs>
    </b-container>

    <form-page-contact/>
    <b-container>
      <b-row>
        <b-col lg>
          <app-block-company
              imgFile="logo_novabuild_pageContact.png"
              companyWebsite="https://www.novabuild.fr/"
          />
        </b-col>
        <b-col lg>
          <app-block-company
              imgFile="logo_dro_pageContact.jpg"
              companyWebsite="https://www.dirigeantsresponsablesdelouest.fr/"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import AppBreadCrumbs from '@/components/appBreadCrumbs';
import AppBlockCompany from '../components/appBlockCompany.vue';
import FormPageContact from '../components/formPageContact.vue';

/**
 * Page de contact de la plateforme
 * @group PAGES
 * @vuese
 */
export default {
  name: 'ContactPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Contact',
      meta: [
        {
          name: 'description',
          content:
              'Une question? Une remarque ? N\'hésitez-pas à nous contacter !'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Contact'
        }
      ]
    };
  },
  components: {
    AppBreadCrumbs,
    AppBlockCompany,
    FormPageContact
  }
};
</script>

<style scoped>
.bg-A-contact--first {
  height: 500px;
  width: 250px;
  left: 12px;
  top: 100%;
}

.bg-B-contact--second {
  height: 500px;
  width: 250px;
  left: 42%;
  top: 2000%;
}

.bg-C-contact--third {
  height: 500px;
  width: 250px;
  top: 100%;
  left: 85%;
}
</style>
