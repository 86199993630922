<template>
  <div>
    <b-btn v-b-modal.createUser size="lg" variant="dark">
      <b-icon-plus aria-hidden=""/>
      Créer un utilisateur
    </b-btn>

    <b-modal
        id="createUser"
        no-fade
        @ok="register(form)"
        title="Créer un utilisateur"
        okTitle="Enregistrer"
        okVariant="secondary"
        cancelVariant="link"
        cancelTitle="Annuler"
        :ok-disabled="!validate()"
        scrollable
    >
      <b-form @submit.prevent="register(form)">
        <b-form-group label="Nom" label-for="userLastName">
          <b-input-group class="mt-2">
            <b-form-input v-model="form.lastName" required id="userLastName"/>
          </b-input-group>
        </b-form-group>

        <b-form-group label="Prénom" label-for="userFirstName">
          <b-input-group class="mt-2">
            <b-form-input
                v-model="form.firstName"
                required
                id="userFirstName"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group label="Mail" label-for="userEmail">
          <b-form-input
              v-model="form.email"
              type="email"
              required
              id="userEmail"
              class="mt-2"
          />
        </b-form-group>

        <b-form-group label="Téléphone" label-for="userPhone">
          <b-form-input
              v-model="form.phone"
              type="tel"
              required
              id="userPhone"
              class="mt-2"
          />
        </b-form-group>

        <b-form-group label="Structure" label-for="userCompany">
          <b-form-input
              v-model="form.company"
              required
              id="userCompany"
              class="mt-2"
          />
        </b-form-group>

        <b-form-group label="Fonction" label-for="userCompanyRole">
          <b-form-input
              v-model="form.companyRole"
              required
              id="companyRole"
              class="mt-2"
          />
        </b-form-group>

        <b-form-group
            label="Rôle de l'utilisateur :"
            label-for="userRoles"
            required
        >
          <b-input-group class="mt-2">
            <b-select
                v-model="form.role"
                :options="roles"
                class="form-control custom-select"
                id="userRoles"
            />
          </b-input-group>
        </b-form-group>

        <b-form-group label="Mot de passe" label-for="userPassword">
          <b-form-input
              v-model="form.password"
              type="password"
              minlength="8"
              required
              id="userPassword"
              class="mt-2"
          />
        </b-form-group>

        <b-form-group
            label="Ressaisir mot de passe"
            label-for="userPasswordSecure"
        >
          <b-form-input
              v-model="form.passwordSecure"
              type="password"
              minlength="8"
              required
              id="userPasswordSecure"
              class="mt-2"
          />
        </b-form-group>

        <b-form-group
            label="Organisme de l'utilisateur :"
            label-for="userOptions"
            required
        >
          <b-input-group class="mt-2">
            <b-select
                v-model="form.from"
                :options="options"
                class="form-control custom-select"
                id="userOptions"
            />
          </b-input-group>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import {http} from '@/http';
import methods from '@/methods';
import _ from 'lodash';

/**
* @vuese
* Bouton de création d'un utilisateur (adhérent ou admin) de la plateforme
*/
export default {
  name: 'BtnCreateUserAdmin',
  data() {
    return {
      selected: null,
      roles: ['Adhérent', 'Administrateur'],
      options: ['DRO', 'NovaBuild'],
      form: {
        lastName: '',
        firstName: '',
        email: '',
        phone: '',
        password: '',
        passwordSecure: '',
        structure: '',
        role: ''
      },
      status: null
    };
  },
  methods: {
    /**
     * @vuese
     * Création de l'utilisateur
     */
    register() {
      const params = methods.jsonToFormData(this.form);

      if (this.form.role === "Administrateur") {
        return http
        .post('/admin/users', params)
        .then((res) => {
          this.$emit('register', res.data);
          this.load = false;
          return res;
        });
      } else {
        return http
        .post('/admin/users/member', params)
        .then((res) => {
          this.$emit('register', res.data);
          this.load = false;
          return res;
        });
      }
    },
    /**
     * @vuese
     * Validation du mot de passe (identique)
     */
    validate() {
      let validate = true;

      if (this.form.password !== this.form.passwordSecure) {
        validate = false;
      }

      return validate;
    },
    /**
     * @vuese
     * Récupération des entreprises disponibles à la sélection
     */
    getPopulate() {
      return http.get('/api/company_froms').then((res) => {
        this.options = _.map(res.data['hydra:member'], (item) => {
          return {
            value: item['@id'],
            text: item.name
          };
        });
        return res;
      });
    }
  },
  created() {
    this.getPopulate();
  }
};
</script>
