<template>
  <div class="app-upload">
    <div class="d-flex justify-content-center align-items-center">
      <label :for="id + '-input-files'" class="btn btn-dark"
      >Ajouter vos fichiers</label
      >
      <input
          :id="id + '-input-files'"
          class="input-file"
          type="file"
          :multiple="true"
          @input="onInputFile"
      />
    </div>
    <div
        class="
        app-upload-header
        mb-2
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <file-upload
          :post-action="getPostUploadUrl()"
          chunk-enabled
          :chunk="{
          action: getPostUploadUrl(true),
          headers,
          minSize: 10548576,
          maxActive: 3,
          maxRetries: 5,
        }"
          :extensions="extensions.join(',')"
          :accept="accept"
          :headers="headers"
          :drop="true"
          :data="data"
          :multiple="true"
          :thread="3"
          :size="0"
          v-model="files"
          @input-filter="inputFilter"
          @input-file="inputFile"
          :ref="id"
          :autoUpload="true"
          no-caret
      />
      <div class="dropdown-menu">
        <label class="dropdown-item pointer pl-3 strong" :for="name">
          Ajouter un fichier
        </label>
        <a
            class="dropdown-item pointer pl-3 strong"
            href="#"
            @click="onAddFolder"
        >
          Ajouter un dossier
        </a>
      </div>
      <div class="ml-auto">
        <slot name="header-menu"></slot>
      </div>
    </div>
    <label id="app-upload-title" class="mb-0">
      <div class="l-1 mb-2">
        <small class="text-sm text-muted">*{{ extensions.join(', ') }}</small>
      </div>
    </label>
    <div v-show="$refs[id] && $refs[id].dropActive" class="drop-active">
      <h3>Fichiers</h3>
    </div>
    <div class="upload">
      <div class="table-responsive mb-0">
        <table class="table">
          <thead class="sticky bg-white">
          <tr>
            <th class="text-center">#</th>
            <th></th>
            <th class="col-name">Nom</th>
            <th>Progression</th>
            <th>Taille</th>
            <th>Vitesse</th>
            <th class="text-center">
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="!files.length">
            <td colspan="8" class="p-0 bg-light">
              <label
                  :for="id + '-input-files'"
                  class="text-center p-5 w-100 pointer"
              >
                <div>
                  <b-icon-cloud-upload class="h1" style="font-size: 4rem" aria-hidden="true"/>
                </div>
                <h4 class="mb-0 l-1">
                  <slot name="content">
                    <strong>Glisser vos fichiers</strong>
                    <div><small>ou</small></div>
                    <strong>sélectionner</strong>
                  </slot>
                </h4>
                <small class="text-sm text-muted"
                >*{{ extensions.join(', ') }}</small
                >
              </label>
            </td>
          </tr>
          <tr
              v-for="(file, index) in files"
              :key="file.id"
              :class="{
                'is-active': file.active,
              }"
          >
            <td class="text-center">
              <small class="strong">{{ index + 1 }}</small>
            </td>
            <td class="text-center">
              <img
                  v-if="file.thumb"
                  :src="file.thumb"
                  width="40"
                  height="auto"
                  alt=""
              />
              <small v-else class="l-1 word-wrap"
              >.{{ file.name | extension }}</small
              >
            </td>
            <td>
              <div class="filename word-wrap">
                {{ file.name }}
              </div>
            </td>
            <td>
              <div
                  class="progress"
                  v-if="file.active || file.progress !== '0.00'"
              >
                <div
                    :class="{
                      'progress-bar': true,
                      'bg-danger': file.error,
                      'bg-success': !file.error,
                      'progress-bar-animated': file.active,
                    }"
                    role="progressbar"
                    :style="{ width: file.progress + '%' }"
                >
                  {{ file.progress }}%
                </div>
              </div>
            </td>
            <td>
              <small>{{ file.size | formatSize }}</small>
            </td>
            <td>
              <small>{{ file.speed | formatSize }}/s</small>
            </td>

            <td v-if="file.error" class="text-center text-danger">
              <strong>{{ file.error }}</strong>
            </td>
            <td v-else-if="file.success" class="text-center">
                <span class="text-success strong">
                  <b-icon-check class="h2 mb-0" aria-hidden="true"/>
                </span>
            </td>
            <td v-else></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
// https://lian-yue.github.io/vue-upload-component/#/en/documents

import Ls from '@/ls.js';
import mimes from '@/mimes';
import _ from 'lodash';
import FileUpload from 'vue-upload-component';

/**
 * @vuese
 * Gestion de l'upload
 */
export default {
  name: 'InputUpload',
  components: {
    FileUpload
  },
  props: {
    id: {
      type: String,
      default: () => {
        let rand = Math.random().toString(36).substring(2);
        return 'app-upload' + rand;
      }
    },
    uploadHookParams: {
      type: Object,
      default: () => {
      }
    },
    uploadHook: {
      type: String,
      default: () => null
    },
    variant: {
      type: String,
      default: () => 'secondary'
    },
    extensions: {
      type: Array,
      default: () => ['gif', 'jpeg', 'jpg', 'png', 'svg']
    },
    type: {
      type: String,
      default: () => ''
    },
    path: {
      type: String,
      default: () => ''
    },
    uid: {
      type: String,
      default: () => null
    },
    value: {
      type: [Array, Object],
      default: () => []
    }
  },
  data() {
    return {
      hook: {
        uploadHook: this.uploadHook,
        uploadHookParams: this.uploadHookParams
      },
      map: {
        image: ['gif', 'jpg', 'jpeg', 'png', 'bmp', 'webp'],
        video: ['mp4', 'm3u8', 'rmvb', 'avi', 'swf', '3gp', 'mkv', 'flv'],
        audio: ['mp3', 'wav', 'wma', 'ogg', 'aac', 'flac'],
        document: [
          'doc',
          'txt',
          'docx',
          'pages',
          'epub',
          'pdf',
          'numbers',
          'csv',
          'xls',
          'xlsx',
          'keynote',
          'ppt',
          'pptx'
        ]
      },

      files: [],
      minSize: 0,
      maxSize: 10737418240,
      multiple: true,
      directory: false,
      drop: true,
      dropDirectory: true,
      addIndex: false,
      thread: 3,
      name: 'file',
      postAction: `${process.env.VUE_APP_API_KATE_URL}/files/upload`,
      headers: {
        Authorization: Ls.get('token', '')
      },
      data: {
        type: this.type,
        uid: this.uid,
        uploadHook: this.uploadHook,
        uploadHookParams: JSON.stringify(this.uploadHookParams)
      },
      uploadAuto: true,
      uploadInstance: {}
    };
  },
  watch: {
    'uploadInstance.uploaded': function (nv) {
      if (nv) {
        alert('ok');
      }
    },
    uploadHook() {
      this.setUploadSetting();
    },
    uploadHookParams() {
      this.setUploadSetting();
    },
    extensions() {
      this.setUploadSetting();
    },
    'addData.show': function (show) {
      if (show) {
        this.addData.name = '';
        this.addData.type = '';
        this.addData.content = '';
      }
    }
  },
  methods: {
    onInputFile(event) {
      _.forEach(event.target.files, (file) => {
        this.$refs[this.id].add(file);
      });
    },
    addText() {
      let file = new window.File(['foo'], 'foo.txt', {
        type: 'text/plain'
      });
      this.$refs[this.id].add(file);
    },
    getPostUploadUrl(chunk) {
      let url = `${process.env.VUE_APP_API_URL}/files`;
      if (this.type !== '') {
        url += `/${this.type}/${this.uid}`;
      }

      url += '/upload';

      if (chunk) {
        url += '/chunk';
      }
      if (this.path !== '') {
        url += `?path=${this.path}`;
      }
      return url;
    },
    setUploadSetting() {
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file

        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }

        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
      }

      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        // Create a blob field
        newFile.blob = '';
        const URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }

        // Thumbnails
        newFile.thumb = '';
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob;
        }
      }
    },


    /**
     * Add, update, remove File Event
     * @param newFile
     * @param oldFile
     */
    inputFile(newFile, oldFile) {
      if (newFile && oldFile) {
        // update

        if (newFile.active && !oldFile.active) {
          // beforeSend

          // min size
          if (
              newFile.size >= 0 &&
              this.minSize > 0 &&
              newFile.size < this.minSize
          ) {
            this.$refs[this.id].update(newFile, {error: 'size'});
          }
        }

        if (newFile.progress !== oldFile.progress) {
          this.$emit('progressFile', newFile.progress);
          // progress
        }

        if (newFile.error && !oldFile.error) {
          this.$emit('errorFile', newFile.error);
          // error
        }

        if (newFile.success && !oldFile.success) {
          this.$emit('successFile', newFile.response);
          this.$emit('input', newFile.response);
          // success
        }
      }

      // Automatically activate upload
      if (
          Boolean(newFile) !== Boolean(oldFile) ||
          oldFile.error !== newFile.error
      ) {
        if (this.uploadAuto && !this.$refs[this.id].active) {
          this.$refs[this.id].active = true;
        }
      }
    },
    onEditFileShow(file) {
      this.editFile = {...file, show: true};
      this.$refs[this.id].update(file, {error: 'edit'});
    },
    onEditorFile() {
      if (!this.$refs[this.id].features.html5) {
        this.alert('Your browser does not support');
        this.editFile.show = false;
        return;
      }
      const data = {
        name: this.editFile.name
      };
      if (this.editFile.cropper) {
        const binStr = atob(
            this.editFile.cropper
                .getCroppedCanvas()
                .toDataURL(this.editFile.type)
                .split(',')[1]
        );
        const arr = new Uint8Array(binStr.length);
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < binStr.length; i++) {
          arr[i] = binStr.charCodeAt(i);
        }
        data.file = new File([arr], data.name, {type: this.editFile.type});
        data.size = data.file.size;
      }
      this.$refs[this.id].update(this.editFile.id, data);
      this.editFile.error = '';
      this.editFile.show = false;
    },


    /**
     *  Add folder
     */
    onAddFolder() {
      if (!this.$refs[this.id].features.directory) {
        this.alert('Your browser does not support');
        return;
      }

      const input = this.$refs[this.id].$el.querySelector('input');
      input.directory = true;
      input.webkitdirectory = true;
      this.directory = true;

      input.onclick = null;
      input.click();
      input.onclick = () => {
        this.directory = false;
        input.directory = false;
        input.webkitdirectory = false;
      };
    },

    onAddData() {
      this.addData.show = false;
      if (!this.$refs[this.id].features.html5) {
        this.alert('Your browser does not support');
        return;
      }

      const file = new window.File([this.addData.content], this.addData.name, {
        type: this.addData.type
      });
      this.$refs[this.id].add(file);
    },
    setAccept() {
      this.accept = _.compact(
          _.map(mimes, (mime, kmime) => {
            if (this.extensions.indexOf(kmime) >= 0) return mime.mime;
          })
      ).join(',');
      this.uploadInstance = this.$refs[this.id];
    }
  },
  created() {
    this.setAccept();
  }
};
</script>
<style lang="scss">
.app-upload td {
  vertical-align: middle !important;
  height: 50px;
}

.app-upload {
  .app-upload-footer {
    .btn-group .dropdown-menu {
      display: block;
      visibility: hidden;
      transition-delay: 200ms;
    }

    .btn-group:hover > .dropdown-menu {
      visibility: visible;
    }
  }
}

.app-upload label.dropdown-item {
  margin-bottom: 0;
}

.app-upload .btn-group .dropdown-toggle {
  margin-right: 0.6rem;
}

.app-upload .btn-is-option {
  margin-top: 0.25rem;
}

.app-upload .app-upload-footer {
  border-top: 1px solid #e9ecef;
  padding-top: 0.5rem;
}

.app-upload .edit-image img {
  max-width: 100%;
}

.app-upload .edit-image-tool {
  margin-top: 0.6rem;
}

.app-upload .edit-image-tool .btn-group {
  margin-right: 0.6rem;
}

.app-upload .footer-status {
  padding-top: 0.4rem;
}

.app-upload .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}

.app-upload tr.is-active {
  td {
    background: rgba(#000, 0.05);

    * {
      font-weight: bolder;
    }
  }
}

.app-upload .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}

label.btn {
  cursor: pointer;
  border: 0;
  border-radius: 0 !important;
  padding: 0.5rem 1rem !important;
  font-size: 18px;
  color: #282828;
  font-weight: 600;
  text-decoration: none;
  place-self: flex-end;
}

.input-file {
  position: absolute;
  width: 1px;
  overflow: hidden;
  opacity: 0.01;
}

.pointer {
  cursor: pointer;
}
</style>
