export default {
    /**
   * Type de document: Adobe Portable Document Format (PDF)
   *
   * Type MIME: application/pdf
   */
  pdf: {
    type: 'application',
    mime: 'application/pdf',
    editor: 'iframe',
  },
    /**
   * Type de document: fichier Electronic publication (EPUB)
   *
   * Type MIME: application/epub+zip
   */
  epub: {
    type: 'application',
    mime: 'application/epub+zip',
    editor: 'iframe',
  },
    /**
   * Type de document: élément iCalendar
   *
   * Type MIME: text/calendar
   */
  ics: {
    type: 'text',
    mime: 'text/calendar',
    editor: 'calendar',
  },
    /**
   * Type de document:
   *
   * Type MIME: text/vcf
   */
  vcf: {
    type: 'text',
    mime: 'text/vcf',
    editor: 'reader',
  },
    /**
   * Type de document: fichier Graphics Interchange Format (GIF)
   *
   * Type MIME: image/gif
   */
  gif: {
    type: 'image',
    mime: 'image/gif',
    editor: 'image',
  },
    /**
   * Type de document: image JPEG
   *
   * Type MIME: image/jpeg
   */
  jpeg: {
    type: 'image',
    mime: 'image/jpeg',
    editor: 'image',
  },
    /**
   * Type de document: image JPEG
   *
   * Type MIME: image/jpg
   */
  jpg: {
    type: 'image',
    mime: 'image/jpg',
    editor: 'image',
  },
    /**
   * Type de document: fichier Portable Network Graphics
   *
   * Type MIME: image/png
   */
  png: {
    type: 'image',
    mime: 'image/png',
    editor: 'image',
  },
    /**
   * Type de document: fichier Scalable Vector Graphics (SVG)
   *
   * Type MIME: image/svg+xml
   */
  svg: {
    type: 'image',
    mime: 'image/svg+xml',
    editor: 'image',
  },
    /**
   * Type de document: fichier vidéo
   *
   * Type MIME: image/mp4
   */
  mp4: {
    type: 'image',
    mime: 'image/mp4',
    editor: 'video',
  },
    /**
   * Type de document: fichier audio
   *
   * Type MIME: audio/mp3
   */
  mp3: {
    type: 'audio',
    mime: 'audio/mp3',
    editor: 'audio',
  },
    /**
   * Type de document: fichier audio
   *
   * Type MIME: audio/m4a
   */
  m4a: {
    type: 'audio',
    mime: 'audio/m4a',
    editor: 'audio',
  },
    /**
   * Type de document: fichier Comma-separated values (CSV)
   *
   * Type MIME: text/csv
   */
  csv: {
    type: 'text',
    mime: 'text/csv',
    editor: 'spreadsheet',
  },
    /**
   * Type de document: Microsoft Excel
   *
   * Type MIME: application/xls
   */
  xls: {
    type: 'application',
    mime: 'application/xls',
    editor: 'spreadsheet',
  },
    /**
   * Type de document: Microsoft Excel (OpenXML)
   *
   * Type MIME: application/xlsx
   */
  xlsx: {
    type: 'application',
    mime: 'application/xlsx',
    editor: 'spreadsheet',
  },
    /**
   * Type de document: fichier HyperText Markup Language (HTML)
   *
   * Type MIME: text/html
   */
  html: {
    type: 'text',
    mime: 'text/html',
    editor: 'codemirror',
  },
    /**
   * Type de document: JavaScript (ECMAScript) pour Vue.js
   *
   * Type MIME: application/vue
   */
  vue: {
    type: 'application',
    mime: 'application/vue',
    editor: 'codemirror',
  },
    /**
   * Type de document: fichier texte
   *
   * Type MIME: text/plain
   */
  txt: {
    type: 'text',
    mime: 'text/plain',
    editor: 'text',
  },
    /**
   * Type de document: fichier texte
   *
   * Type MIME: text/plain
   */
  md: {
    type: 'text',
    mime: 'text/plain',
    editor: 'md',
  },
    /**
   * Type de document: JavaScript (ECMAScript)
   *
   * Type MIME: application/js
   */
  js: {
    type: 'application',
    mime: 'application/js',
    editor: 'codemirror',
  },
    /**
   * Type de document: donnée au format JSON
   *
   * Type MIME: application/json
   */
  json: {
    type: 'application',
    mime: 'application/json',
    editor: 'codemirror',
  },
    /**
   * Type de document: archive ZIP
   *
   * Type MIME: application/zip
   */
  zip: {
    type: 'application',
    mime: 'application/zip',
    editor: 'extract',
  },
};
