<template>
  <section>
    <h2 class="title-pink uppercase">mot de passe oublié</h2>
    <p>Veuillez saisir votre adresse email</p>
    <b-form @submit.prevent="forgottenPasswordProcess()">
      <b-alert variant="success" :show="valid" type="valid">
        <b-icon-check-circle/>
        Email <strong>envoyé</strong>.
      </b-alert>
      <b-alert variant="danger" :show="error" type="error">
        <b-icon-x-circle aria-hidden="true"/>
        Votre <strong>adresse e-mail</strong> est
        incorrecte.
      </b-alert>
      <b-form-group
          label="Email"
          label-for="email"
          class="mt-2"
          :hidden="valid"
      >
        <b-form-input
            type="email"
            name="email"
            id="email"
            required
            v-model="form.username"
            autofocus
            title="L'adresse mail doit contenir une .com, .fr etc. à la fin"
            pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
        />
      </b-form-group>
      <div class="d-flex justify-content-center align-items-center">
        <b-button
            type="submit"
            :variant="load ? 'link' : 'primary'"
            size="lg"
            :disabled="load"
            class="btn-basic"
            :hidden="valid"
        >
          <span v-if="!load"><b-icon-arrow-right aria-hidden="true"/>Envoyer</span>
          <span v-else><b-spinner small/></span>
        </b-button>
        <b-button
            :variant="load ? 'link' : 'primary'"
            size="lg"
            :disabled="load"
            class="btn-basic"
            @click="$router.push('/se-connecter')"
            v-if="valid"
        >
          <span v-if="valid"
          >Retour à la page de connexion<b-icon-arrow-right aria-hidden="true"/></span>
        </b-button>
      </div>
    </b-form>
  </section>
</template>

<script>
import {mapActions} from 'vuex';

/**
* @vuese
* Formulaire de de demande de réinitialisation de mot de passe
*/
export default {
  name: 'FormUserForgottenPassword',
  data() {
    return {
      load: false,
      form: {
        username: ''
      },
      error: false,
      valid: false
    };
  },
  methods: {
    ...mapActions('UserStore', ['forgottenPassword']),
    /**
    * @vuese
    * Procédure de réinitialisation de mot de passe
    */
    forgottenPasswordProcess() {
      this.load = true;
      this.error = false;
      return this.forgottenPassword(this.form)
          .then((res) => {
            this.load = false;
            if (res.data === 'Valid') {
              this.valid = true;
              return res;
            }
            this.error = true;
            return res;
          })
          .catch(() => {
            this.error = true;
            this.load = false;
          });
    }
  }
};
</script>
