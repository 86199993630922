<template>
  <b-container>
    <app-bread-crumbs
        :routes="[
        { name: 'home', title: 'Accueil' },
        { name: 'reportNew', title: 'Rapport' },
        { name: 'card', title: 'Étape climat' },
      ]"
    />
    <b-row class="mt-2 mb-2">
      <b-col class="mb-3">
        <b-img
            src="/img/logo_abc_full.svg"
            height="100px"
            alt="Logo Parcours ABC"
        />
      </b-col>
    </b-row>
    <app-card-direct/>
  </b-container>
</template>

<script>
import appCardDirect from '../components/appCardDirect.vue';
import {mapActions} from 'vuex';
import _ from 'lodash';

/**
 * Page gérant l'affichage d'une fiche climat
 * @group PAGES
 * @vuese
 */
export default {
  components: {
    appCardDirect
  },
  name: 'CardPage',
  metaInfo() {
    return {
      title: 'Parcours ABC - Étape',
      meta: [
        {
          name: 'description',
          content:
              'Pour en savoir plus sur une étape: les actions à mener, des ressources supplémentaires...'
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - Étape'
        }
      ]
    };
  },
  data() {
    return {
      filters: {}
    };
  },
  methods: {
    ...mapActions('ReportStore', ['clearCards', 'addReportCard', 'setFilters']),
    /**
     * @vuese
     * Récupération des fiches climats en fonction des filtres après modification d'une fiche
     * @returns {Promise<AxiosResponse<any>>}
     */
    onCardSaved() {
      this.$refs.cardsFilters.searchCardsFiltred();
    },
    /**
     * @vuese
     * Mise à jour des fiches filtrées
     * @arg Représente les données récupérées et filtrées
     */
    onFiltred(dataFiltred) {
      this.cardsFiltred = dataFiltred.cardsFiltred;
      this.setFilters(dataFiltred.filters);
    },
    /**
     * @vuese
     * Vérifie si la fiche climat est déjà sélectionnée par l'utilisateur
     * @arg La fiche climat en cours
     */
    inCardsList(cardCheck) {
      return _.find(this.selected, (card) => card.id === cardCheck.id);
    },
    /**
     * @vuese
     * La fiche climat à charger
     * @arg L'argument correspond à l'UID de la fiche climat
     */
    inLoadCard(cardUid) {
      return _.find(this.loadCards, (loadCard) => loadCard === cardUid);
    },
    /**
     * @vuese
     * Que faire lors du chargement
     * @arg Le loader
     */
    onLoader(loader) {
      this.loader = loader;
    }
  }
};
</script>

<style scoped>
  @media screen and (max-width: 991px) {
    .selection-card,
    .filter {
      display: none;
    }

    .logo-abc {
      width: 40%;
    }
  }
</style>
