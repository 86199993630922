<template>
  <b-container class="findOutMore pb-4">
    <app-bread-crumbs
        :routes="[
        { name: 'home', title: 'accueil' },
        { name: 'findOutMore', title: 'En savoir plus' },
      ]"
        class="capitalize"
    />

    <h2 class="pink uppercase find-out-more-title">En savoir plus</h2>
    <b-row class="pb d-flex align-items-start">
      <b-col sm="12" lg="6" class="position-relative">
        <app-content slug="en-savoir-plus-1"/>
        <div class="bg-A--pink bg-A-findOutMore--first"/>
      </b-col>
      <b-col lg="6">
        <b-img src="img/illu_01_etape_1.svg" alt="" class="illu-one"></b-img>
      </b-col>
    </b-row>

    <b-row class="pb d-flex align-items-end">
      <b-col lg="6" class="position-relative">
        <b-img src="img/illu_02_etape_2.svg" alt="" class="illu-two"></b-img>
      </b-col>
      <b-col sm="12" lg="6" class="position-relative">
        <app-content slug="en-savoir-plus-2"/>
        <div class="bg-B--pink bg-B-findOutMore--second"/>
      </b-col>
    </b-row>

    <b-row class="pb d-flex align-items-center">
      <b-col sm="12" lg="6" class="position-relative">
        <app-content slug="en-savoir-plus-3"/>
        <div class="bg-C--pink bg-C-findOutMore--third"/>
      </b-col>
      <b-col lg="6">
        <b-img src="img/illu_03_etape_3.svg" alt="" class="illu-three"></b-img>
      </b-col>
    </b-row>

    <div class="d-flex justify-content-center align-items-center">
      <b-button
          v-if="!user.uid"
          :to="{ name: 'login' }"
          variant="primary"
          size="lg"
          class="btn-fleche"
      >
        <b-icon-arrow-right aria-hidden="true"/>
        Vers mon parcours
      </b-button>
    </div>
    <div class="d-flex justify-content-center align-items-center">
      <b-button
          v-if="user.uid"
          :to="{ name: 'reportNew' }"
          variant="primary"
          size="lg"
          class="btn-fleche"
      >
        <b-icon-arrow-right aria-hidden="true"/>
        Vers mon parcours
      </b-button>
    </div>
  </b-container>
</template>

<script>
import AppBreadCrumbs from '@/components/appBreadCrumbs';
import {mapGetters} from 'vuex';

/**
 * @vuese
 * Page "En savoir plus"
 * @group PAGES
 */
export default {
  name: 'FindOutMorePage',
  metaInfo() {
    return {
      title: 'Parcours ABC - En savoir plus',
      meta: [
        {
          name: 'description',
          content:
              "Parcours ABC sensibilise aux ordres de grandeur de baisse de émissions carbone de la construction, de l'aménagement et de l'immobilier pour réussir la neutralité carbone en 2050."
        },
        {
          property: 'og:title',
          content: 'Parcours ABC - En savoir plus'
        }
      ]
    };
  },
  computed: {
    ...mapGetters('UserStore', ['user'])
  },
  components: {
    AppBreadCrumbs
  }
};
</script>

<style lang="scss">
  .illu-one,
  .illu-two,
  .illu-three {
    position: relative;
    z-index: 100;
  }

  .bg-A-findOutMore--first {
    height: 780px;
    width: 500px;
    top: 15px;
    left: 12px;
  }

  .bg-B-findOutMore--second {
    height: 500px;
    width: 400px;
    top: -200px;
    right: 12px;
  }

  .bg-C-findOutMore--third {
    height: 500px;
    width: 400px;
    top: 0;
    left: 12px;
  }

  @media screen and (max-width: 991px) {
    .bg-A-findOutMore--first,
    .bg-B-findOutMore--second,
    .bg-C-findOutMore--third {
      display: none;
    }
  }

  .find-out-more-title {
    position: relative;
    z-index: 100;
    background-color: var(--background-color);
  }
</style>
